// @flow

import * as React from "react";
import { Link } from "react-router-dom";

type ButtonType = {
    children: any,
    className?: string,
    to: string
};

const BreadcrumbLink = (props: ButtonType): any => {
    return (
        <Link
            {...props}
            className={`breadcrumb-link ${
                props.className ? props.className : ""
            }`}
        >
            {props.children}
        </Link>
    );
};

export default BreadcrumbLink;
