// @flow

import _ from "lodash";
import ActionTypes from "@actionTypes";
import {
    intialProductState,
    setComboLevel,
    calculateProductTotals
} from "@utils/ProductUtils";

export default function productReducer(
    state: any = intialProductState,
    action: any = {}
): any {
    switch (action.type) {
        case ActionTypes.SET_PRODUCT:
            // remove parent category unused menuitems
            if (action.parentCategory) {
                delete action.parentCategory.menu_items;
            }

            if (action.menuItems.length) {
                return {
                    ...intialProductState,
                    productNotFound: false,
                    menuItems: _.cloneDeep(
                        _.sortBy(action.menuItems, [
                            function(menuItem: any): any {
                                return menuItem.combo_level || -1;
                            }
                        ])
                    ),
                    parentCategory: action.parentCategory
                };
            } else {
                return {
                    ...state,
                    productNotFound: true
                };
            }
        case ActionTypes.CALCULATE_PRODUCT_TOTALS:
            return {
                ...state,
                ...calculateProductTotals(state)
            };
        case ActionTypes.SET_COMBO_LEVEL:
            return {
                ...state,
                ...setComboLevel(
                    state.menuItems,
                    state.sales_items,
                    action.combo_level
                )
            };
        case ActionTypes.SET_QUANTITY:
            return {
                ...state,
                quantity: action.quantity
            };
        case ActionTypes.SET_SALES_ITEM:
            // compare product sales_items in sales group items to find currently selected
            return {
                ...state,
                sales_items: state.sales_items.map(
                    (salesItem: any, salesItemIndex: number): any => {
                        let salesItemToReplace =
                            _.find(action.salesGroup.sales_item_ids, {
                                sales_item_id: salesItem.sales_item_id
                            }) && action.salesItemIndex === salesItemIndex
                                ? action.newSalesItem.sales_item_id ===
                                  salesItem.sales_item_id
                                    ? salesItem
                                    : action.newSalesItem
                                : salesItem;

                        salesItemToReplace.sales_item_name =
                            salesItemToReplace.name;

                        return _.cloneDeep(salesItemToReplace);
                    }
                )
            };
        case ActionTypes.RESET_SALES_ITEM_MODIFICATIONS:
            return {
                ...state,
                sales_items: state.sales_items.map(
                    (salesItem: any, salesItemIndex: number): any => {
                        if (salesItemIndex === action.salesItemIndex) {
                            return {
                                ...salesItem,
                                modifiers: [], // reset applied modifiers array
                                modifier_groups: salesItem.modifier_groups.map(
                                    (modifierGroup: any): any => {
                                        // delete/reset applied modifier group counts
                                        delete modifierGroup.appliedDistinctModifiers;
                                        delete modifierGroup.appliedModifiersTotalQuantity;
                                        return modifierGroup;
                                    }
                                )
                            };
                        } else {
                            return salesItem;
                        }
                    }
                )
            };
        case ActionTypes.SET_SALES_ITEM_MODIFIER:
            // compare product sales_items in sales group items to find currently selected

            // get modifier parent group using the found item modifier
            let modifierGroup = _.find(action.salesItem.modifier_groups, {
                modifier_group_id: action.modiferRules.modifier_group_id
            });

            // use modifier default/fallback as base modifer quantity
            const defaultQuantity = action.modiferRules.default_quantity || 0;

            // max modifier quantity
            const maxQuantity = Math.max(
                action.modiferRules.max_quantity,
                modifierGroup.per_option_max
            );

            // min modifier quantity
            const minQuantity = Math.max(
                action.modiferRules.min_quantity,
                modifierGroup.per_option_min
            );

            return {
                ...state,
                sales_items: state.sales_items.map((salesItem: any): any => {
                    if (
                        salesItem.combo_line_item_number ===
                        action.comboLineItemNumber
                    ) {
                        // sales item to modify
                        let existingModifier;
                        let modiferParentGroup = _.find(
                            salesItem.modifier_groups,
                            {
                                modifier_group_id:
                                    action.modiferRules.modifier_group_id
                            }
                        );

                        // number of total modifier quantities for the modifier group
                        const appliedModifiersTotalQuantity = _.reduce(
                            salesItem.modifiers,
                            (sum: number, modifier: any): any => {
                                if (
                                    modifier.action === 1 &&
                                    modifier.modifier_group_id ===
                                        modifierGroup.modifier_group_id
                                ) {
                                    return sum + modifier.quantity;
                                } else {
                                    return sum;
                                }
                            },
                            0
                        );

                        // number of total altered modifier items for the modifier group
                        const appliedDistinctModifiers = _.reduce(
                            salesItem.modifiers,
                            (sum: number, modifier: any): any => {
                                if (
                                    modifier.modifier_group_id ===
                                    modifierGroup.modifier_group_id
                                ) {
                                    return sum + 1;
                                } else {
                                    return sum;
                                }
                            },
                            0
                        );

                        // set applied modifiers total/distinct options in parent modifier group (used by UI)
                        modiferParentGroup.appliedModifiersTotalQuantity =
                            (modiferParentGroup.appliedModifiersTotalQuantity ||
                                0) +
                            appliedModifiersTotalQuantity +
                            action.updateQuantity;
                        modiferParentGroup.appliedDistinctModifiers = appliedDistinctModifiers;

                        // try to find existing modifer by modifier_id in salesItem.modifiers
                        salesItem.modifiers = salesItem.modifiers || [];
                        salesItem.modifiers.forEach(
                            (modifier: any, modifierIndex: number): any => {
                                if (
                                    modifier.modifier_id ===
                                    action.modiferItem.modifier_id
                                ) {
                                    // update existing modifer action
                                    existingModifier = true;

                                    // calculate updated total after modifier action
                                    const updatedQuantityTotal =
                                        (modifier.action === 2
                                            ? -modifier.quantity
                                            : modifier.quantity) +
                                        action.updateQuantity;

                                    // total number of modifer items including the default amount
                                    const totalWithDefault =
                                        updatedQuantityTotal + defaultQuantity;

                                    if (totalWithDefault === defaultQuantity) {
                                        // default product modifier quantity, remove modifier object
                                        salesItem.modifiers = [
                                            ...salesItem.modifiers.slice(
                                                0,
                                                modifierIndex
                                            ),
                                            ...salesItem.modifiers.slice(
                                                modifierIndex + 1
                                            )
                                        ];
                                    } else if (
                                        totalWithDefault > defaultQuantity &&
                                        (updatedQuantityTotal <
                                            modifier.quantity ||
                                            appliedModifiersTotalQuantity <
                                                modifierGroup.max_items)
                                    ) {
                                        // positive modifer action
                                        // only update positive action if new total is greater than default quantity
                                        // and if the new update is less than the max amount of modifier quantities for the parent group

                                        const modifierAction =
                                            action.modiferRules.actions[1];

                                        salesItem.modifiers[modifierIndex] = {
                                            ...modifier,
                                            action_id:
                                                modifierAction.modifier_id,
                                            pos_item_id:
                                                modifierAction.pos_item_id,
                                            quantity:
                                                updatedQuantityTotal +
                                                    defaultQuantity >
                                                maxQuantity
                                                    ? maxQuantity -
                                                      defaultQuantity
                                                    : updatedQuantityTotal
                                        };
                                    } else if (
                                        totalWithDefault < defaultQuantity
                                    ) {
                                        // negative modifer action
                                        const modifierAction =
                                            action.modiferRules.actions[2];

                                        salesItem.modifiers[modifierIndex] = {
                                            ...modifier,
                                            action_id:
                                                modifierAction.modifier_id,
                                            pos_item_id:
                                                modifierAction.pos_item_id,
                                            price: 0,
                                            energy_kj: 0,
                                            quantity: Math.abs(
                                                updatedQuantityTotal +
                                                    defaultQuantity <
                                                    minQuantity
                                                    ? minQuantity -
                                                          defaultQuantity
                                                    : updatedQuantityTotal
                                            )
                                        };
                                    }
                                }
                            }
                        );

                        // only add new modifer object if the modifer doesnt exist
                        // and the if modifiers group max has not been reached
                        // and the modifers parent group max distinct options has not been reached
                        if (
                            !existingModifier &&
                            (action.updateQuantity < 1 ||
                                appliedModifiersTotalQuantity <
                                    modifierGroup.max_items) &&
                            appliedDistinctModifiers <
                                modifierGroup.max_distinct_options
                        ) {
                            // add new modifier action

                            // 1 = above default quantity , 2 = below default quantity
                            const actionID = action.updateQuantity < 0 ? 2 : 1;

                            // find action key in item modifier actions
                            const modifierAction =
                                action.modiferRules.actions[actionID];

                            // set quantity with limits based off the modifers minQuantity/maxQuanity
                            const minMaxQuantity =
                                actionID === 1
                                    ? action.updateQuantity + defaultQuantity >
                                      maxQuantity
                                        ? maxQuantity - defaultQuantity
                                        : action.updateQuantity
                                    : action.updateQuantity + defaultQuantity <
                                      minQuantity
                                    ? minQuantity - defaultQuantity
                                    : action.updateQuantity;

                            if (modifierAction) {
                                // only push found actions
                                salesItem.modifiers.push({
                                    action: actionID,
                                    action_id: modifierAction.modifier_id,
                                    action_group_id:
                                        action.modiferRules.action_group_id,
                                    price:
                                        actionID === 1
                                            ? modifierAction.price
                                            : 0,
                                    energy_kj:
                                        actionID === 1
                                            ? modifierAction.energy_kj
                                            : 0,
                                    quantity: Math.abs(minMaxQuantity),
                                    modifier_name: action.modiferItem.name,
                                    modifier_id: action.modiferItem.modifier_id,
                                    modifier_group_id:
                                        action.modiferRules.modifier_group_id,
                                    pos_item_id: modifierAction.pos_item_id,
                                    allergens: action.modiferItem.allergens,
                                    isHidden: action.modiferItem.isHidden
                                });
                            }
                        }

                        return salesItem;
                    } else {
                        return salesItem;
                    }
                })
            };
        case ActionTypes.RESET_PRODUCT_CHANGES:
            return {
                ...action.initialProduct
            };
        case ActionTypes.EDIT_CART_ITEM:
            return _.merge({}, state, action.cartItem);
        case ActionTypes.PRODUCT_UNEDITABLE:
            return {
                ...state,
                quantityUneditable: true
            };
        default:
            return state;
    }
}
