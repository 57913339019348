import moment from "moment-timezone";
import Config from "@Config";

export default class DateService {
    static initSyncTime() {
        if (!this.syncInterval) {
            this.timeDifferenceInSecs = 0;

            // only trigger once
            DateService.triggerResolveTimeAsync();

            // this.syncInterval = setInterval(() => {
            //     DateService.triggerResolveTimeInterval();
            // }, 1000 * 60); // trigger per min
        }
    }

    static async triggerResolveTimeAsync() {
        const newUrl = `${
            process.env.CORS_PROXY ? 'https:' : document.location.protocol
        }//${Config.apiGatewayHost()}`;
        const corsProxyUrl = `${document.location.protocol}//${document.location.hostname}:${Config.corsProxyPort}/`;
        const apiHost = process.env.CORS_PROXY ? `${corsProxyUrl}${newUrl}` : newUrl;

        // use /user/me endpoint without AWS header to get 403 as 4XX response are not cached and get more accurate 'date' header
        var response = await fetch(apiHost + "/api/v1/user/me");
        DateService.resolveTimeDifference(response);
    }

    static now() {
        return moment().add(this.timeDifferenceInSecs, "s");
    }

    static resolveTimeDifference(response) {
        if (!response) return;

        const serverDateMoment = moment(response.headers.get("date"));
        const clientDateMoment = moment();

        // if the difference is greater than 1 min, do the sync
        const diffInSecs = serverDateMoment.diff(clientDateMoment, "seconds");
        if (Math.abs(diffInSecs) > 60) {
            this.timeDifferenceInSecs = diffInSecs;
            console.log("resolveTimeDifference", this.timeDifferenceInSecs);
        }
    }
}
