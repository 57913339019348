// @flow

import * as React from "react";
import { connect } from "react-redux";

import { appboyLoadFeed } from "@utils/AppBoy";
import ImageSlider from "@components/organisms/ImageSlider";

const { useEffect, useRef } = React;

type PropsType = {
    className: string,
    user: any,
    cardCategories: any
};

const VerticalBrazeFeed = (props: PropsType): React.Node => {
    const sliderRef = useRef(null);
    useEffect(() => {
        if (sliderRef.current) {
            const childContainerRef = sliderRef.current.childrenContainerRef;
            if (childContainerRef.current) {
                appboyLoadFeed(
                    props.user,
                    childContainerRef.current,
                    props.cardCategories
                );
            }
        }
    }, [sliderRef]);
    return (
        <ImageSlider className={props.className} gaps={false} ref={sliderRef} />
    );
};

const mapStateToProps = (state: any): any => ({
    user: state.user
});

export default connect(
    mapStateToProps,
    {}
)(VerticalBrazeFeed);
