// @flow

//import { type ProfileType } from "@SharedTypes";

import React, { Component } from "react";
import { connect } from "react-redux";
//import { withRouter } from "@utils/hooks/withRouter";
import { ReactForm, ReactFormField } from "@adrenalin/react-form";
// import GlobalModals from "@Modals";

import actions from "@actions";
import Button from "@components/atoms/Button";
import TextButton from "@components/atoms/TextButton";
// import Icon from "@components/atoms/Icon";
// import LoaderCover from "@components/atoms/LoaderCover";

type PropsType = {
    applyCompCode: any,
    openAlertModal: (alert: any) => any,
    closeAlertModal: () => any,
    onSuccess: (input: any) => void,
    onClose: () => void
};

type StateType = {
    loading: boolean,
    error: ?string,
    compCode: string
};

class CompCodeModal extends Component<PropsType, StateType> {
    state = {
        loading: false,
        error: null,
        compCode: ""
    };

    handleCompCodeChange = (
        event: SyntheticInputEvent<HTMLInputElement>
    ): any => {
        const { value } = event.target;
        this.setState({ compCode: value });
    };

    render(): any {
        return (
            <div className="alert">
                <div className="alert__title">
                    <h2 className="heading-3">Enter Promo code</h2>
                </div>
                <ReactForm>
                    <ReactFormField
                        type="text"
                        name="compCode"
                        label="Promo code"
                        onChange={this.handleCompCodeChange}
                    />
                </ReactForm>
                <div className="alert__buttons">
                    <Button
                        disabled={!this.state.compCode}
                        onClick={() => {
                            if (this.state.compCode) {
                                // To do - add promo code name
                                window.dataLayer.push({
                                    event: "use_promo_code",    // hardcoded value
                                    promo_code: this.state.compCode    // dynamic value
                                  });
                                  
                                this.props
                                    .applyCompCode(this.state.compCode)
                                    .then(result => {
                                        if (result) {
                                            this.props.onSuccess();
                                        }
                                    })
                                    .catch(error => {
                                        this.props.openAlertModal({
                                            title:
                                                error.title ||
                                                "Unable to use promotion",
                                            body: (
                                                <p>
                                                    {error.friendly_message ||
                                                        "Invalid promo code"}
                                                </p>
                                            ),
                                            onConfirm: () => {
                                                this.props.closeAlertModal();
                                            }
                                        });
                                    });
                            }
                        }}
                    >
                        USE CODE
                    </Button>
                    <TextButton onClick={this.props.onClose}>
                        No, cancel
                    </TextButton>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any): any => ({});

export default connect(
    mapStateToProps,
    {
        applyCompCode: actions.cart.applyCompCode,
        openAlertModal: actions.globalModals.openAlertModal,
        closeAlertModal: actions.globalModals.closeAlertModal
    }
)(CompCodeModal);
