// @flow

import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";

import actions from "@actions";
import { formatMoney } from "@utils";

import QuantityControl from "@components/molecules/QuantityControl";
import RoundedButton from "@components/atoms/RoundedButton";
import ProductImage from "@components/atoms/ProductImage";
import AppliedModifiers from "@components/atoms/AppliedModifiers";
import Icon from "@components/atoms/Icon";
import FormatSymbols from "@components/atoms/FormatSymbols";
import * as GoogleAnalytics from "@utils/GoogleAnalytics";

type PropsType = {
    className?: string,
    dispatch: any,
    menu: any,
    product: any,
    name: string,
    image: string,
    selectionMode?: boolean,
    comboLineItemNumber: number,
    salesItem: {
        name: string,
        image: string,
        sales_item_id: number,
        pos_item_id: number,
        diff: number,
        modifier_groups: [],
        item_modifiers: [],
        modifiers: [],
        per_option_max: number,
        combo_line_item_number?: number
    },
    salesItemIndex?: number,
    salesGroup?: {
        name: string,
        sales_item_ids: []
    },
    modifierItem?: {
        modifier_id: number,
        item_modifiers: [],
        maxedQuantity: number
    },
    modifierGroup?: {
        max_items: number,
        appliedModifiersTotalQuantity?: number
    },
    onButtonClick: () => void,
    editMode: ?boolean
};

class SalesItem extends Component<PropsType> {
    componentDidMount() {
        // set default sales item for product on init
        if (this.getSelectedSalesItem()) {
            this.props.dispatch(
                actions.product.setSalesItem(
                    this.props.salesGroup,
                    this.props.salesItem,
                    this.props.salesItemIndex
                )
            );
        }
    }

    getSelectedSalesItem(): any {
        // finds the matching sales item in the product either by index of id
        if (
            this.props.salesItem &&
            this.props.product.sales_items[this.props.salesItemIndex] &&
            this.props.product.sales_items[this.props.salesItemIndex]
                .sales_item_id === this.props.salesItem.sales_item_id
        ) {
            return _.find(this.props.product.sales_items, {
                sales_item_id: this.props.salesItem.sales_item_id
            });
        }
    }

    getActivemodifier(): any {
        // attempt to find an existing modifier attached to the product
        if (this.props.modifierItem) {
            return _.find(this.props.salesItem.modifiers, {
                modifier_id: this.props.modifierItem.modifier_id
            });
        }
    }

    handleSalesItemChange(
        salesGroup: any,
        salesItem: any,
        salesItemIndex: ?number,
        comboLineItemNumber: number
    ) {
        this.props.dispatch(
            actions.product.setSalesItem(
                salesGroup,
                { combo_line_item_number: comboLineItemNumber, ...salesItem },
                salesItemIndex
            )
        );
        GoogleAnalytics.logEvent({
            category: "Menu",
            action: "Select",
            label: "Side",
            value: salesItem.sales_item_id
        });
    }

    salesItemChangeMode(): any {
        if (
            this.props.onButtonClick &&
            !this.props.selectionMode &&
            this.props.salesGroup &&
            this.props.salesGroup.sales_item_ids.length > 1
        ) {
            return true;
        } else if (
            this.props.onButtonClick &&
            (!this.props.selectionMode ||
                (this.props.selectionMode && this.getSelectedSalesItem())) &&
            this.props.salesItem.modifier_groups &&
            this.props.salesItem.modifier_groups.length
        ) {
            return false;
        }
    }

    renderSalesItemButton(): any {
        if (this.salesItemChangeMode() === true) {
            return (
                <RoundedButton onClick={this.props.onButtonClick}>
                    Change
                </RoundedButton>
            );
        } else if (this.salesItemChangeMode() === false) {
            return (
                <RoundedButton onClick={this.props.onButtonClick}>
                    Customise
                </RoundedButton>
            );
        }
    }

    renderModifierControl(): any {
        // get matching item modifier actions/rules for the specific pos item
        const itemModifier = this.props.modifierItem
            ? _.find(this.props.modifierItem.item_modifiers, {
                  sales_item_pos_item_id: this.props.salesItem.pos_item_id
              })
            : null;

        if (itemModifier && this.props.modifierItem) {
            const defaultQuantity = itemModifier.default_quantity || 0;
            let modifierQuantity = defaultQuantity;
            let modiferMaxQuantity;

            // current quantity for actively modified items
            if (this.getActivemodifier()) {
                modifierQuantity =
                    this.getActivemodifier().action === 1
                        ? defaultQuantity + this.getActivemodifier().quantity
                        : defaultQuantity - this.getActivemodifier().quantity;
            }

            // set max quantity amount based off max per item, max per group rules
            if (!itemModifier.actions[1]) {
                // no add action, set max to default
                modiferMaxQuantity = itemModifier.default_quantity;
            } else if (
                this.props.modifierGroup &&
                this.props.modifierGroup.appliedModifiersTotalQuantity &&
                this.props.modifierGroup.appliedModifiersTotalQuantity >=
                    this.props.modifierGroup.max_items
            ) {
                // max items per group reached, use current setting as max
                modiferMaxQuantity = this.props.modifierGroup.max_items + 1;
            } else if (itemModifier.max_quantity) {
                // use the modifiers max quantity amount
                modiferMaxQuantity = itemModifier.max_quantity;
            } else {
                // use the sales item per modifier option max quantity amount
                modiferMaxQuantity = this.props.salesItem.per_option_max;
            }

            return (
                <QuantityControl
                    quantity={
                        this.getActivemodifier()
                            ? modifierQuantity
                            : defaultQuantity
                    }
                    min={itemModifier.min_quantity || 0}
                    max={modiferMaxQuantity}
                    onDecrement={(): any => {
                        this.props.dispatch(
                            actions.product.updateModifierQuantity(
                                -1,
                                this.props.salesItem,
                                this.props.modifierItem,
                                this.props.modifierGroup,
                                this.props.comboLineItemNumber
                            )
                        );
                    }}
                    onIncrement={(): any => {
                        this.props.dispatch(
                            actions.product.updateModifierQuantity(
                                1,
                                this.props.salesItem,
                                this.props.modifierItem,
                                this.props.modifierGroup,
                                this.props.comboLineItemNumber
                            )
                        );
                    }}
                />
            );
        } else {
            return null;
        }
    }

    renderAppliedModifiers(): any {
        if (!this.props.modifierItem) {
            if (
                this.salesItemChangeMode() ||
                this.getSelectedSalesItem() ||
                (!this.salesItemChangeMode() && !this.props.editMode)
            ) {
                return <AppliedModifiers salesItem={this.props.salesItem} />;
            }
        }
    }

    render(): any {
        const TagName = this.props.salesGroup ? "label" : "div";
        return (
            <TagName
                className="sales-item"
                htmlFor={
                    this.props.salesItem
                        ? this.props.salesItem.sales_item_id
                        : ""
                }
            >
                <div className="sales-item__image">
                    <ProductImage
                        alt={this.props.name}
                        src={
                            _.get(this, "props.menu.images_item_basepath") +
                            this.props.image
                        }
                    />
                </div>

                <div className="sales-item__name">
                    <FormatSymbols>{this.props.name}</FormatSymbols>
                    <div className="sales-item__modifications">
                        {/* Switched sales item difference */}
                        {this.props.selectionMode &&
                        this.props.salesItem.diff &&
                        this.getSelectedSalesItem()
                            ? `+ ${formatMoney(this.props.salesItem.diff)}`
                            : null}

                        {/* Modified sales item difference */}
                        {this.getActivemodifier() &&
                        this.getActivemodifier().price
                            ? `+ ${formatMoney(
                                  this.getActivemodifier().price *
                                      this.getActivemodifier().quantity
                              )}`
                            : null}

                        {/* Sales item modifiers */}
                        {this.renderAppliedModifiers()}
                    </div>
                </div>

                <div className="sales-item__button">
                    {/* Sales item change/customise button logic */}
                    {this.renderSalesItemButton()}

                    {/* Sales item modifier quantity control */}
                    {this.renderModifierControl()}
                </div>

                {/* Side item radio handling */}
                {this.props.selectionMode && this.props.salesGroup ? (
                    <div className="radio-tick">
                        <input
                            type="radio"
                            name={this.props.salesGroup.name}
                            id={this.props.salesItem.sales_item_id}
                            value={this.props.salesItem.sales_item_id}
                            defaultChecked={this.getSelectedSalesItem()}
                            onChange={(): any => {
                                this.handleSalesItemChange(
                                    this.props.salesGroup,
                                    this.props.salesItem,
                                    this.props.salesItemIndex,
                                    this.props.comboLineItemNumber
                                );
                            }}
                        />
                        <RoundedButton className="radio-tick__indicator">
                            <Icon name="tick" />
                        </RoundedButton>
                    </div>
                ) : null}
            </TagName>
        );
    }
}

const mapStateToProps = (state: any): any => {
    return {
        menu: state.menu,
        product: state.product
    };
};
export default connect(mapStateToProps)(SalesItem);
