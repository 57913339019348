import ActionTypes from "@actionTypes";

const initialState = {
    isFetching: false,
    error: null,
    past: [],
    active: [],
    upcoming: []
};

const ordersReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case ActionTypes.GET_ALL_ORDERS_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case ActionTypes.GET_ALL_ORDERS_SUCCESS:
            return {
                ...state,
                isFetching: false
            };
        case ActionTypes.GET_ALL_ORDERS_FAIL:
            return {
                ...state,
                isFetching: false,
                error: action.error
            };
        case ActionTypes.GET_PAST_ORDERS_SUCCESS:
            return {
                ...state,
                past: action.orders.map(order => ({
                    ...order,
                    frontend_order_type: "past" // add additional key to help us ID later
                }))
            };
        case ActionTypes.GET_ACTIVE_ORDERS_SUCCESS:
            const active = action.orders
                .filter(
                    order =>
                        !action.status_progress_steps[0].status_keys.includes(
                            order.order_status_key
                        ) || order.expected_for_asap
                )
                .map(order => ({
                    ...order,
                    frontend_order_type: "active" // add additional key to help us ID later
                }));
            const upcoming = action.orders.map(order => ({
                ...order,
                frontend_order_type: "active" // add additional key to help us ID later
            }));

            // debug extra orders
            // active.push(action.orders[0]);
            // active.push(action.orders[0]);
            // active.push(action.orders[0]);

            return {
                ...state,
                active,
                upcoming
            };
        // case ActionTypes.GET_UPCOMING_ORDERS_SUCCESS:
        //     return {
        //         ...state,
        //         upcoming: action.orders.map(order => ({
        //             ...order,
        //             frontend_order_type: "upcoming" // add additional key to help us ID later
        //         }))
        //     };
        case ActionTypes.LOGOUT:
            return initialState;
        default:
            return state;
    }
};

export default ordersReducer;
