// @flow

import { type OrderType } from "@SharedTypes";

import * as React from "react";
import { useSelector } from "react-redux";

import OrderApi from "@apis/OrderApi";

const { useState, useEffect } = React;

const useOrderDetails = (
    orderId: ?string,
    orderToken: ?string
): [any, boolean] => {
    const { status_progress_steps } = useSelector((state: any): any => ({
        status_progress_steps: state.config.order_delivery.status_progress_steps
    }));
    const [order, setOrder] = useState(null);
    const [loading, setLoading] = useState(false);

    const determineOrderType = (order: OrderType): string => {
        if (
            status_progress_steps[
                status_progress_steps.length - 1
            ].status_keys.includes(order.order_status_key)
        ) {
            return "past";
        } else if (
            status_progress_steps[0].status_keys.includes(
                order.order_status_key
            ) &&
            !order.expected_for_asap
        ) {
            return "upcoming";
        } else {
            return "active";
        }
    };

    const modifyAndSetOrder = (o: any) => {
        setOrder({
            ...o,
            frontend_order_type: determineOrderType(o)
        });
    };

    useEffect(() => {
        if (orderId) {
            setLoading(true);
            OrderApi.getOrderDetailById(orderId)
                .then(modifyAndSetOrder)
                .catch(console.error)
                .finally(() => {
                    setLoading(false);
                });
        } else if (orderToken) {
            setLoading(true);
            OrderApi.getOrderDetailByToken(orderToken)
                .then(modifyAndSetOrder)
                .catch(console.error)
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [orderId, orderToken]);

    return [order, loading];
};

export default useOrderDetails;
