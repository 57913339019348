// @flow
import Configs from "@Config";
import API from "./API";

export default class CMSAPI {
    static getMenuSpecialCategories(): Promise<any> {
        return API.get(`${Configs.menuApi}/categories/special`);
        // return fetch(`https://next.json-generator.com/api/json/get/VkxsawR_8`)
        //     .then((res: any): any => res.json())
        //     .catch((err: any) => {
        //         console.error(err);
        //         throw err;
        //     });
    }
    static getCMSContent(): Promise<any> {
        return API.get(`${Configs.contentApi}`);
    }

    static getTermsAndConditions(): Promise<any> {
        return API.get(`${Configs.tncApi}`);
    }
}
