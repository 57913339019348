// @flow

import _ from "lodash";
import moment from "moment";
import * as React from "react";
import { connect } from "react-redux";
import Config from "@Config";
import * as GoogleAnalytics from "@utils/GoogleAnalytics";

import actions from "@actions";
import Strings from "@Strings";
import GlobalModals from "@Modals";
import Icon from "@components/atoms/Icon";
import Button from "@components/atoms/Button";
import InfoText from "@components/atoms/InfoText";
import RoundedButton from "@components/atoms/RoundedButton";
import SupportedPayments from "@components/atoms/SupportedPayments";

type SelectedOrderMethodType = {
    className?: string,
    session: any,
    cart: any,
    type: "cart" | "sidebar",
    openGlobalModal: typeof actions.globalModals.openGlobalModal,
    upsellsPrecheckoutCheck: typeof actions.cart.upsellsPrecheckoutCheck,
    tooltips: boolean,
    defaultTime: string
};

const SelectedOrderMethod = (props: SelectedOrderMethodType): any => {
    const handleDeliveryCTAClick = () => {
        GoogleAnalytics.logEvent({
            category: "Menu_CartOverlay",
            action: "Open",
            label: "Change_StartOrder"
        });
        props.openGlobalModal({
            modalId: GlobalModals.ADDRESS_LOCATOR,
            toPath:
                window.location.pathname.indexOf(Config.routes.cart) > -1
                    ? Config.routes.cart
                    : null
        });
    };

    const handleCheckoutClick = () => {
        GoogleAnalytics.logEvent({
            category: "Menu_CartOverlay",
            action: "CheckOut",
            label: "Cart"
        });
        props.upsellsPrecheckoutCheck();
    };
    return (
        <div
            className={`selected-order-method selected-order-method--${props.type}`}
        >
            {!_.get(props, "session.orderMethod") ? (
                <React.Fragment>
                    <h3 className="heading-3">Start Ordering</h3>

                    <p>Satisfy your Whopper craving with a delivery order.</p>

                    <Button styleType="green" onClick={handleDeliveryCTAClick}>
                        Get Started
                    </Button>
                </React.Fragment>
            ) : (
                <div className="selected-order-method__change between-xs">
                    <div className="selected-order-method__change__heading heading-5">
                        {_.get(props, "session.orderMethod") === "pickup"
                            ? "Pick Up "
                            : "Your delivery order "}
                    </div>

                    {props.tooltips ? (
                        // <Tooltip
                        //     title="Change Order Details"
                        //     identifier="change-order-details"
                        //     message={`You can change your order details here at any time`}
                        //     position="bottom"
                        //     locked={true}
                        // >
                        <RoundedButton
                            className="selected-order-method__change__button"
                            onClick={handleDeliveryCTAClick}
                        >
                            Change
                        </RoundedButton>
                    ) : (
                        // </Tooltip>
                        <RoundedButton
                            className="selected-order-method__change__button"
                            onClick={handleDeliveryCTAClick}
                        >
                            Change
                        </RoundedButton>
                    )}
                </div>
            )}

            {_.get(props, "session.orderMethod") === "delivery" ? (
                <React.Fragment>
                    {_.get(props, "session.deliveryTime") ? (
                        <div className="selected-order-method__detail">
                            <Icon
                                className="selected-order-method__detail__icon"
                                name="clock"
                            />
                            <div className="selected-order-method__detail__label">
                                Deliver{" "}
                                {_.get(props, "session.deliveryTime") === "asap"
                                    ? "ASAP"
                                    : moment(
                                          _.get(props, "session.deliveryTime")
                                      ).format("dddd, DD/MM/YY, h:mm a")}
                                <InfoText>
                                    {_.get(props, "session.deliveryTime") ===
                                        "asap" &&
                                        Strings.asapDeliveryEstimate +
                                            props.defaultTime}
                                </InfoText>
                            </div>
                        </div>
                    ) : null}

                    <div className="selected-order-method__detail">
                        <Icon
                            className="selected-order-method__detail__icon"
                            name="map-pin-outline"
                        />
                        <div className="selected-order-method__detail__label">
                            {_.get(
                                props,
                                "session.deliveryLocation.full_address"
                            )}
                        </div>
                    </div>
                </React.Fragment>
            ) : null}

            {_.get(props, "session.orderMethod") && props.type === "sidebar" ? (
                <div className="selected-order-method__checkout">
                    <Button
                        styleType="green"
                        loading={props.cart.isFetching}
                        disabled={
                            !props.cart.line_items.length ||
                            !_.get(props, "session.selectedStore.store_id")
                        }
                        onClick={handleCheckoutClick}
                    >
                        Check out
                    </Button>
                    <SupportedPayments className="selected-order-method__payment-methods" />
                </div>
            ) : null}
        </div>
    );
};

SelectedOrderMethod.defaultProps = {
    type: "sidebar"
};

const mapStateToProps = (state: any): any => {
    return {
        session: state.session,
        cart: state.cart,
        defaultTime:
            state.config.order_delivery.default_expected_time_display.text
    };
};

export default connect(
    mapStateToProps,
    {
        openGlobalModal: actions.globalModals.openGlobalModal,
        upsellsPrecheckoutCheck: actions.cart.upsellsPrecheckoutCheck
    }
)(SelectedOrderMethod);
