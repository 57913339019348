import _ from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import { Navigate, Routes, Route } from "react-router-dom";
import { withRouter } from "@utils/hooks/withRouter";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import SideNav from "../components/molecules/SideNav";
import { responsive } from "@utils";
import Footer from "@components/organisms/Footer";
import Config from "@Config";
import * as GoogleAnalytics from "@utils/GoogleAnalytics";

// Account routes
import MyVouchers from "../components/organisms/MyVouchers";
import DeliveryAddress from "../components/organisms/DeliveryAddresses";
import ChangePassword from "../components/organisms/ChangePassword";
import Profile from "../components/organisms/Profile";
import EmailPreferences from "../components/organisms/EmailPreferences";
import MyOrders from "../components/organisms/MyOrders";
import PaymentMethods from "../components/organisms/PaymentMethods";
import FacebookConnect from "../components/organisms/FacebookConnect";
import AppleConnect from "../components/organisms/AppleConnect";
import MappedMetaTags from "@containers/MappedMetaTags";

const { Component } = React;

type PropsType = {
    navigator: any,
    voucherCount: number
};

type StateType = {
    viewport: { width: number, height: number },
    accountRoutes: any
};

const routeValidations = {
    isEmailUser: (user: any): boolean => {
        return user.is_email_user;
    },
    isNotFBUser: (user: any): boolean => {
        return !user.is_fb_user;
    },
    isNotAppleUser: (user: any): boolean => {
        return !user.is_apple_user;
    }
};

export const AccountRoutes = [
    {
        path: `my-orders`,
        href: `${Config.routes.account}/my-orders`,
        name: "My Orders",
        element: <MyOrders />,
        onClick: () => {
            GoogleAnalytics.logEvent({
                category: "MyAccount",
                action: "Open",
                label: "MyOrder"
            });
        },
    },
    {
        path: `my-vouchers`,
        href: `${Config.routes.account}/my-vouchers`,
        name: "My Rewards",
        element: <MyVouchers />,
        onClick: () => {
            GoogleAnalytics.logEvent({
                category: "MyAccount",
                action: "Open",
                label: "MyVouchers"
            });
        },
    },
    {
        path: `payment-methods`,
        href: `${Config.routes.account}/payment-methods`,
        name: "Payment Methods",
        element: <PaymentMethods />,
        onClick: () => {
            GoogleAnalytics.logEvent({
                category: "MyAccount",
                action: "Open",
                label: "PaymentMethod"
            });
        },
    },
    {
        path: `delivery-addresses`,
        href: `${Config.routes.account}/delivery-addresses`,
        name: "Delivery Addresses",
        element: <DeliveryAddress />,
        onClick: () => {
            GoogleAnalytics.logEvent({
                category: "MyAccount",
                action: "Open",
                label: "DeliveryAddress"
            });
        },
    },
    {
        path: `profile`,
        href: `${Config.routes.account}/profile`,
        name: "Profile",
        element: <Profile />,
        onClick: () => {
            GoogleAnalytics.logEvent({
                category: "MyAccount",
                action: "Open",
                label: "Profile"
            });
        },
    },
    {
        path: `password`,
        href: `${Config.routes.account}/password`,
        name: "Password",
        element: <ChangePassword />,
        onClick: () => {
            GoogleAnalytics.logEvent({
                category: "MyAccount",
                action: "Open",
                label: "Password"
            });
        },
    },
    {
        path: `facebook-connect`,
        href: `${Config.routes.account}/facebook-connect`,
        name: "Facebook Connect",
        element: <FacebookConnect />,
        onClick: () => {
            GoogleAnalytics.logEvent({
                category: "MyAccount",
                action: "Open",
                label: "Facebook"
            });
        },
        routeValidations: [routeValidations.isNotFBUser]
    },
    {
        path: `apple-connect`,
        href: `${Config.routes.account}/apple-connect`,
        name: "Apple Connect",
        element: <AppleConnect />,
        onClick: () => {
            GoogleAnalytics.logEvent({
                category: "MyAccount",
                action: "Open",
                label: "Apple"
            });
        },
        routeValidations: [routeValidations.isNotAppleUser]
    },
    {
        path: `email-preferences`,
        href: `${Config.routes.account}/email-preferences`,
        name: "Email Preferences",
        element: <EmailPreferences />,
        onClick: () => {
            GoogleAnalytics.logEvent({
                category: "MyAccount",
                action: "Open",
                label: "EmailPreference"
            });
        },
    }
]

class Account extends Component<PropsType, StateType> {
    state = {
        viewport: {},
        alertCount: 0,
    };

    static getDerivedStateFromProps(
        nextProps: PropsType,
        prevState: StateType
    ): any {
        if (prevState.alertCount !== nextProps.voucherCount) {
            return {
                ...prevState,
                alertCount: nextProps.voucherCount
            };
        }
        return null;
    }

    componentDidMount() {
        // set viewport sizes in state to trigger state updates (redirect) on resize
        window.addEventListener("resize", this.handleResizeEvent, false);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResizeEvent, false);
    }

    handleResizeEvent = (e: any) => {
        this.setState({
            viewport: {
                width: window.innerWidth,
                height: window.innerHeight
            }
        });
    };

    render(): any {
        // Filter out routes based on the routeValidations
        const filteredRoutes = AccountRoutes.filter(
            (route: any): boolean => {
                let isValid = true;
                _.get(route, "routeValidations", []).forEach(
                    (validation: any) => {
                        if (!validation(_.get(this, "props.user"))) {
                            isValid = false;
                        }
                    }
                );
                return isValid;
            }
        );

        if (this.props.router.location.pathname === "/account" && !responsive.isMobileBreakpoint()) {
            const firstRoute = AccountRoutes[0].path;
            return <Navigate to={`${firstRoute}`} />
        }

        return (
            <div
                className={`view-flex-row ${
                    responsive.isMobileBreakpoint() && this.props.router.location.pathname === "/account"
                        ? "beige-bg"
                        : ""
                }`}
            >
                <MappedMetaTags defaultTitle="My Account" />

                <SideNav
                    active={this.props.router.location.pathname === "/account"}
                    items={filteredRoutes}
                />

                <div className="center-content">
                    <TransitionGroup component={null}>
                        <CSSTransition
                            key={this.props.router.location.pathname}
                            timeout={Config.pageTransitionDuration}
                            classNames="page-transition"
                            unmountOnExit
                        >
                            <Routes location={this.props.router.location}>
                                {AccountRoutes.map(route => {
                                    return route.hidden ? null :
                                    <Route
                                        {...route}
                                        key={route.path}
                                    />
                                })}
                            </Routes>
                        </CSSTransition>
                    </TransitionGroup>
                    <Footer />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any): any => {
    return {
        navigator: state.navigator,
        session: state.session,
        user: state.user,
        voucherCount:
            _.get(state, "vouchers.vouchers.length", 0) +
            _.get(state, "vouchers.comps.length", 0)
    };
};

const mapDispatchToProps = (dispatch: any): any => {
    return {};
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Account)
);
