// @flow

import * as React from "react";

type ServerErrorMessageType = {
    children?: React.Node,
    className?: string
};

const ServerErrorMessage = (props: ServerErrorMessageType): any => {
    if (props.children) {
        return (
            <fieldset className="server-error-message">
                {props.children}
            </fieldset>
        );
    } else {
        return null;
    }
};

export default ServerErrorMessage;
