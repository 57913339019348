// @flow

import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";

import Actions from "@actions";
import GlobalModals from "../../constants/GlobalModals";
import { Row, Col } from "@containers/Grid";
import Button from "@components/atoms/Button";
import SavedCard from "@components/atoms/SavedCard";
import TextButton from "@components/atoms/TextButton";
import RoundedButton from "@components/atoms/RoundedButton";
import Icon from "@components/atoms/Icon";
import * as GoogleAnalytics from "@utils/GoogleAnalytics";

type PropsType = {
    onConfirm: () => void,
    onClose: () => void,
    getCards: () => void,
    addPaymentMethod: () => void,
    setSelectedCard: typeof Actions.adyen.setSelectedCard,
    adyen: any
};
type StateType = {
    selectedReference: string
};

class ChangeCreditCard extends Component<PropsType, StateType> {
    state = {
        selectedReference: this.props.adyen.lastCardReference
    };

    handleCardChange(recurringDetailReference: string) {
        this.setState({
            selectedReference: recurringDetailReference
        });
    }

    handleUseThisCard() {
        GoogleAnalytics.logEvent({
            category: "ReviewOrder",
            action: "UseCard",
            label: "PaymentMethod"
        });
        this.props.setSelectedCard(this.state.selectedReference);
    }

    render(): any {
        const adyenCards = _.get(this, "props.adyen.cards");
        const activeCardRecurringDetail = _.get(
            this,
            "props.adyen.activeCard.recurringDetail.recurringDetailReference"
        );

        return (
            <div className="change-credit-card prompt-modal">
                <div className="prompt-modal__title">
                    <h3 className="heading-3">Payment Method</h3>
                </div>

                <div className="prompt-modal__section start-xs">
                    <div className="change-credit-card__selector">
                        {adyenCards
                            ? adyenCards.map(
                                  (card: any, index: number): any => {
                                      return (
                                          <label
                                              key={index}
                                              className="change-credit-card__selector__item"
                                          >
                                              <Row className="row--no-wrap between-xs">
                                                  <Col>
                                                      <SavedCard
                                                          card={
                                                              card.recurringDetail
                                                          }
                                                      />
                                                  </Col>
                                                  <Col>
                                                      <div className="radio-tick">
                                                          <input
                                                              type="radio"
                                                              name="selected-payment-method"
                                                              value={
                                                                  card
                                                                      .recurringDetail
                                                                      .recurringDetailReference
                                                              }
                                                              defaultChecked={
                                                                  card
                                                                      .recurringDetail
                                                                      .recurringDetailReference ===
                                                                  activeCardRecurringDetail
                                                              }
                                                              onChange={(): any =>
                                                                  this.handleCardChange(
                                                                      card
                                                                          .recurringDetail
                                                                          .recurringDetailReference
                                                                  )
                                                              }
                                                          />
                                                          <RoundedButton className="radio-tick__indicator">
                                                              <Icon name="tick" />
                                                          </RoundedButton>
                                                      </div>
                                                  </Col>
                                              </Row>
                                          </label>
                                      );
                                  }
                              )
                            : null}
                    </div>

                    <TextButton
                        big={true}
                        icon="plus"
                        iconCircle={true}
                        onClick={() => {
                            GoogleAnalytics.logEvent({
                                category: "ReviewOrder",
                                action: "AddCard",
                                label: "PaymentMethod"
                            });
                            this.props.addPaymentMethod();
                        }}
                        className="change-credit-card__new-btn"
                    >
                        Add new card
                    </TextButton>
                </div>

                <Button onClick={(): any => this.handleUseThisCard()}>
                    Use this card
                </Button>

                <div className="form-footer">
                    <p className="body-small">
                        Your payment information is stored securely. Saved cards
                        can be deleted anytime by visiting your account.
                    </p>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state: any): any => {
    return {
        adyen: state.adyen
    };
};

const mapDispatchToProps = (dispatch: any): any => ({
    getCards: (): any => dispatch(Actions.adyen.getCards()),
    addPaymentMethod: (): any => {
        // first close the change payment modal
        dispatch(
            Actions.globalModals.closeGlobalModal(
                GlobalModals.CHANGE_PAYMENT_METHOD
            )
        );
        // then open the add payment modal
        dispatch(
            Actions.globalModals.openGlobalModal({
                modalId: GlobalModals.ADD_PAYMENT_METHOD
            })
        );
    },
    setSelectedCard: (recurringDetailReference: string): any => {
        // first close the change payment modal
        dispatch(
            Actions.globalModals.closeGlobalModal(
                GlobalModals.CHANGE_PAYMENT_METHOD
            )
        );
        // then set the new active card
        dispatch(Actions.adyen.setSelectedCard(recurringDetailReference));
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChangeCreditCard);
