// @flow

import * as React from "react";
import { connect } from "react-redux";

import actions from "@actions";
import Icon from "@components/atoms/Icon";
import { formatMoney } from "@utils";

type PropsType = {
    voucher_name: string,
    editable?: boolean,
    small?: boolean,
    discount?: number,
    isCartItem?: boolean,
    // Below from redux connect HOC
    removeCompCode: typeof actions.cart.removeCompCode,
    error?: boolean
};

const defaultProps = {
    editable: false
};

const OrderComp = (props: PropsType): React.Node => {
    const baseClassname = "order-voucher";
    const classes = [
        baseClassname,
        props.small ? `${baseClassname}--small` : `${baseClassname}--regular`,
        props.isCartItem ? `${baseClassname}--cart-item` : null
    ];
    return (
        <div className={classes.join(" ")}>
            <div className={`${baseClassname}__name`}>
                <Icon
                    name={props.error ? "minus" : "voucher"}
                    circle={props.error}
                />

                {props.voucher_name}

                {!props.error
                    ? !!props.discount || " applied at checkout"
                    : null}
            </div>
            {/* Voucher discount price here */}
            {!!props.discount && (
                <div className={`${baseClassname}__price`}>
                    {`-${formatMoney(props.discount)}`}
                </div>
            )}

            {props.editable ? (
                <div className={`${baseClassname}__remove`}>
                    <button
                        className={`${baseClassname}__remove__btn`}
                        type="button"
                        onClick={(event: any): any => {
                            event.preventDefault();
                            props.removeCompCode();
                        }}
                    >
                        <Icon name="close" />
                    </button>
                </div>
            ) : null}
        </div>
    );
};

OrderComp.defaultProps = defaultProps;

const mapStateToProps = (props: any): any => ({});

export default connect(
    mapStateToProps,
    {
        removeCompCode: actions.cart.removeCompCode
    }
)(OrderComp);
