// @flow

import * as React from "react";
import { connect } from "react-redux";

import CartTotals from "@components/molecules/CartTotals";
import OrderButtons from "@components/molecules/OrderButtons";

type PropsType = {};

const MobileCartFooter = (props: PropsType): any => {
    return (
        <div className="mobile-cart-footer fixed-footer__section hide-desktop">
            <CartTotals hideNotifications={true} items={["total"]} />
            <OrderButtons />
        </div>
    );
};

const mapStateToProps = (state: any): any => {
    return {};
};

export default connect(
    mapStateToProps,
    {}
)(MobileCartFooter);
