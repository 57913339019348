// @flow

// Centralised Error Reporting

type ErrorType = {
    msg: string,
    data?: any
};

export const ErrorReporter = (error: ErrorType): any => {
    console.error(`ErrorReporter: ${error.msg}`, error.data || "");
    // TODO - implement reporting service (sentry.io)
    // FabricHelper.logError(err, action);
};

export default ErrorReporter;
