// @flow

import React, { Component } from "react";
import { createPortal } from "react-dom";
import { CSSTransition } from "react-transition-group";
import { createUniqueID } from "@utils";
import Classes from "@Classes";

type PropsType = {
    className?: string,
    active?: boolean,
    fixed?: boolean,
    absolute?: boolean,
    solid?: boolean
};

const bodySelector = document.querySelector("body");

class LoaderCover extends Component<PropsType> {
    uniqueId = createUniqueID();

    render(): any {
        const activeClass = this.props.active ? Classes.active : "";
        const fixedClass = this.props.fixed ? "loader-cover--fixed" : "";
        const absoluteClass = this.props.absolute
            ? "loader-cover--absolute"
            : "";
        const solidClass = this.props.solid ? "loader-cover--solid" : "";

        const loaderMarkup = (
            <CSSTransition
                key={this.uniqueId}
                in={this.props.active}
                timeout={1000}
                classNames="fade"
                mountOnEnter
                unmountOnExit
            >
                <div
                    key={this.uniqueId}
                    className={`loader-cover ${fixedClass} ${absoluteClass} ${solidClass} ${activeClass} ${
                        this.props.className ? this.props.className : ""
                    }`}
                >
                    <div className="loader-cover__loader loader" />
                </div>
            </CSSTransition>
        );

        if (this.props.fixed && bodySelector) {
            return createPortal(loaderMarkup, bodySelector);
        } else {
            return loaderMarkup;
        }
    }
}

export default LoaderCover;
