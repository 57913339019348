// @flow

import * as React from "react";
import LinkHandler from "@components/atoms/LinkHandler";

const { useEffect, useState } = React;

type ButtonTypesType =
    | "green"
    | "red"
    | "clear"
    | "white"
    | "facebook"
    | "apple"
    | "inline"
    | "black"
    | "disabled";

type ButtonType = {
    type?: string,
    children: any,
    className?: string,
    styleType?: ?ButtonTypesType | Array<ButtonTypesType>,
    size?: string,
    to?: string,
    disabled?: boolean,
    success?: boolean,
    loading?: boolean,
    minWidth?: number
};

const Button = (props: ButtonType): any => {
    // Pull out specific props we don't want to pass down using spread
    const {
        children,
        className,
        styleType,
        size,
        success = false,
        loading = false,
        minWidth,
        disabled,
        to,
        ...remainingProps
    } = props;

    // This is the hook used for enabling/disabling the
    // success state of the button
    const showSuccess = useTimedSuccessState(success);

    // Base class list
    let classes = ["button"];

    // Type classes
    if (styleType) {
        const typeString = (t: string): string => `button--type-${t}`;
        classes.push(
            typeof styleType === "string"
                ? typeString(styleType)
                : styleType.map(typeString).join(" ")
        );
    }

    // Size class
    if (size) {
        classes.push(`button--size-${size}`);
    }

    // Loading class
    if (loading) {
        classes.push("is-loading");
    }

    // Success class
    if (showSuccess) {
        classes.push("is-success");
    }

    // Additional classes
    if (className) {
        classes.push(className);
    }

    return (
        <LinkHandler
            disabled={loading || disabled}
            {...remainingProps}
            className={classes.join(" ")}
            type={props.type ? props.type : "button"}
            {...(minWidth ? { style: { minWidth } } : null)}
            to={to}
        >
            {/* <i className="icon icon-tick success-tick" /> */}
            {showSuccess ? (
                <i className="icon icon-tick success-tick" />
            ) : (
                children
            )}
            <div className="loader-container">
                <div className="loader loader--in-button" />
            </div>
        </LinkHandler>
    );
};

const useTimedSuccessState = (success: boolean): boolean => {
    // Success state variable
    const [successState, setSuccessState] = useState(false);
    // State to hold the timer IDs
    const [timer, setTimer] = useState(null);
    // The effect to set the timer
    useEffect((): any => {
        if (success) {
            setSuccessState(true);
            setTimer(
                setTimeout(() => {
                    setSuccessState(false);
                }, 1500)
            );
        }
    }, [success]);
    // The effect to clear the timer
    useEffect((): any => {
        return () => {
            clearTimeout(timer);
        };
    }, [timer]);
    // Returns the success state
    return successState;
};

export default Button;
