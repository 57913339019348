// @flow

import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { type OrderType } from "@SharedTypes";

import OrderApi from "@apis/OrderApi";
import promiseSetInterval from "../promiseSetInterval";
import useOrderStatuses from "./useOrderStatuses";

export const mapToOrderWithStatusInfo = (o: ?OrderType): any => {
    const statuses = useOrderStatuses();

    const order_status_unknown_handling = useSelector(
        (state: any): any =>
            state.config.order_delivery.order_status_unknown_handling
    );

    if (!o) {
        return null;
    }

    const foundStatus = _.find(statuses, (status: any): boolean =>
        status.status_keys.includes(o.order_status_key)
    );

    const status = foundStatus
        ? foundStatus
        : { ...order_status_unknown_handling, weight: 0 };

    const determineOrderType = (order: OrderType): string => {
        if (
            statuses[statuses.length - 1].status_keys.includes(
                order.order_status_key
            )
        ) {
            return "past";
        } else if (
            statuses[0].status_keys.includes(order.order_status_key) &&
            !order.expected_for_asap
        ) {
            return "upcoming";
        } else {
            return "active";
        }
    };

    let newOrder = {
        ...o,
        ...status,
        frontend_order_type: determineOrderType(o),
        isDelivered: statuses[statuses.length - 1].status_keys.includes(
            o.order_status_key
        )
    };

    return newOrder;
};

const useOrderStatusPolling = (
    orderId: ?string,
    orderToken: ?string
): [any, boolean] => {
    const [loading, setLoading] = useState(true);

    // Get data from redux store
    const statuses = useOrderStatuses();

    // Get data from redux store
    const pollingInterval = useSelector(
        (state: any): any =>
            state.config.order_delivery.status_polling_interval_in_ms || 45000
    );

    // Init the orderStatus property with a barPercent of 0 so that it animates in on page load
    const [orderStatus, setOrderStatus] = useState({
        ...statuses[0],
        barPercent: 0
    });

    const handleFetchOrderError = (error: any) => {
        console.error(error);
        setOrderStatus(null);
    };

    // Hook for starting/stopping the polling of the order detail endpoint
    useEffect((): (() => void) => {
        setLoading(true);
        const interval = promiseSetInterval(
            (): any => {
                if (orderId) {
                    return OrderApi.getOrderDetailById(orderId)
                        .then(setOrderStatus)
                        .catch(handleFetchOrderError)
                        .finally(() => {
                            setLoading(false);
                        });
                } else if (orderToken) {
                    return OrderApi.getOrderDetailByToken(orderToken)
                        .then(setOrderStatus)
                        .catch(handleFetchOrderError)
                        .finally(() => {
                            setLoading(false);
                        });
                }
            },
            pollingInterval,
            false
        );
        return () => {
            interval.remove();
        };
    }, [orderId, orderToken]);

    return [orderStatus, loading];
};

export default useOrderStatusPolling;
