// @flow

import * as React from "react";
import { connect } from "react-redux";

import actions from "@actions";
import { formatMoney } from "@utils";
import { Row, Col } from "@containers/Grid";
import Button from "@components/atoms/Button";
import CounterDot from "@components/atoms/CounterDot";

type PropsType = {
    className?: string,
    session: any,
    cart: any,
    upsellsCartCheck: any,
};

const MobilePersistentCartFooter = (props: PropsType): any => {
    return (
        <div className="mobile-persistent-cart-footer fixed-footer__section hide-desktop">
            <Row className="between-xs middle-xs">
                <Col xs={6}>
                    <div className="product-detail__totals">
                        {/*Product configuration totals*/}
                        {formatMoney(props.cart.total_amount)} /
                        {props.cart.total_kj}kJ
                    </div>
                </Col>
                <Col xs={6}>
                    {/*Add to cart/update cart button*/}
                    <Button
                        styleType="clear"
                        onClick={props.upsellsCartCheck}
                    >
                        <span>View Cart</span>
                        <CounterDot
                            color="green"
                            count={props.cart.line_items.reduce(
                                (sum: number, item: any): number =>
                                    sum + item.quantity,
                                0
                            )}
                        />
                    </Button>
                </Col>
            </Row>
        </div>
    );
};

const mapStateToProps = (state: any): any => {
    return {
        cart: state.cart
    };
};

export default connect(
    mapStateToProps,
    { 
        upsellsCartCheck: actions.cart.upsellsCartCheck
    }
)(MobilePersistentCartFooter);
