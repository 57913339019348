import React from "react";

type DisabledButtonClickWrapperProps = {
    children?: any;
    condition?: boolean;
    clickEvent?: () => void;
}

const DisabledButtonClickWrapper = (props: DisabledButtonClickWrapperProps) => {
    if (props.condition) {
        return (
            <div style={{ position: "relative", }}>
                {props.children}
                <div 
                    style={{
                        position: "absolute",
                        height: "100%",
                        width: "100%",
                        top: 0,
                        left: 0,
                        }}
                    onClick={props.clickEvent}
                ></div>
            </div>
        )
    } else {
        return props.children;
    }
}

export default DisabledButtonClickWrapper;
