// @flow

import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import Config from "@Config";
import Icon from "@components/atoms/Icon";
import PastOrder from "@components/molecules/PastOrder";
import { Row, Col } from "@containers/Grid";
import * as GoogleAnalytics from "@utils/GoogleAnalytics";
import _ from "lodash";
import { type OrderType } from "@SharedTypes";

type PropsType = {
    orders: {
        isFetching: boolean,
        past: Array<OrderType>,
        upcoming: Array<OrderType>,
        actve: Array<OrderType>
    }
};

const ReorderRecent = (props: PropsType): React.Node => {
    const isAuthenticated = _.get(props, "session.isAuthenticated", false);
    const historyLink = (): React.Node => (
        <Link
            to={`${Config.routes.account}/my-orders`}
            onClick={() => {
                GoogleAnalytics.logEvent({
                    category: "OrderHome",
                    action: "Open",
                    label: "OrderHistory"
                });
            }}
        >
            <span>View order history</span> <Icon name="arrow-right" />
        </Link>
    );

    const orders = _.filter(props.orders.past, { is_voucher_order: false });
    if (orders.length) {
        return (
            <section className="reorder-recent">
                <div className="container-fluid">
                    <Row className="middle-xs reorder-recent__row">
                        <Col
                            xs={12}
                            md={3}
                            className="reorder-recent__left-section"
                        >
                            <h2 className="heading-2">Re-order meals</h2>
                            {isAuthenticated && (
                                <p className="visible-md">{historyLink()}</p>
                            )}
                        </Col>
                        <Col xs={12} md={9}>
                            <div className="reorder-recent__orders">
                                {orders
                                    .slice(0, 2)
                                    .map((order: OrderType): React.Node => (
                                        <div
                                            className="reorder-recent__orders__item"
                                            key={order.order_id}
                                        >
                                            <PastOrder
                                                order={order}
                                                tile={true}
                                                onReorder={() => {
                                                    GoogleAnalytics.logEvent({
                                                        category: "OrderHome",
                                                        action: "ReOrder",
                                                        label: "Past_Order"
                                                    });
                                                }}
                                            />
                                        </div>
                                    ))}
                                {!isAuthenticated && (
                                    <div className="reorder-recent__orders__item">
                                        <div className="reorder-recent__signup_prompt">
                                            <Link to={Config.routes.register}>
                                                Sign-up
                                            </Link>
                                            &nbsp;or&nbsp;
                                            <Link to={Config.routes.login}>
                                                Login
                                            </Link>
                                            &nbsp;to view all Past Orders
                                        </div>
                                    </div>
                                )}
                            </div>
                        </Col>
                        {isAuthenticated && (
                            <p className="invisible-md">{historyLink()}</p>
                        )}
                    </Row>
                </div>
            </section>
        );
    } else {
        return null;
    }
};

const mapStateToProps = (state: any): any => {
    return {
        session: state.session,
        orders: state.orders
    };
};

export default connect(
    mapStateToProps,
    {}
)(ReorderRecent);
