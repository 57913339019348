// @flow

import * as React from "react";

type PropsType = {
    children: ?string
};

const FormatSymbols = ({ children }: PropsType): React.Node => {
    if (typeof children !== "string") {
        return children || null;
    } else {
        const formattedString = children
            .replace(
                /((?!<sup>\s*))&reg;((?!\s*<\/sup>))/gi,
                "<sup>&reg;</sup>"
            )
            .replace(/((?!<sup>\s*))®((?!\s*<\/sup>))/gi, "<sup>&reg;</sup>");

        return (
            <span dangerouslySetInnerHTML={{ __html: formattedString }}></span>
        );
    }
};

export default FormatSymbols;
