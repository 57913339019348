// @flow

import * as React from "react";
import _ from "lodash";
import Config from "@Config";
import ActionTypes from "@actionTypes";
import ApiFactory from "@apis/APIFactory";
import actions from "@actions";
import GlobalModals from "@Modals";
import { push, replace } from "@lagunovsky/redux-react-router";
import { brazeUpdateCoffeeLoyalty } from "../utils/AppBoy";

export const getVouchersRequest = (): any => ({
    type: ActionTypes.GET_VOUCHERS_REQUEST
});

export const getVouchersSuccess = (result: any): any => ({
    type: ActionTypes.GET_VOUCHERS_SUCCESS,
    result
});

export const getVouchersFailure = (error: any): any => ({
    type: ActionTypes.GET_VOUCHERS_FAILURE,
    error
});

export const getVouchers = (): any => (dispatch: any => void): any => {
    dispatch(getVouchersRequest());
    return ApiFactory.VoucherAPI.getVouchers()
        .then((response: any): any => {
            dispatch(getVouchersSuccess(response));
            return response;
        })
        .catch((error: any) => {
            dispatch(getVouchersFailure(error));
            throw error;
        });
};

export const activeVoucher = (voucher_id: any): any => ({
    type: ActionTypes.ACTIVE_VOUCHER,
    voucher_id
});

export const deactiveVoucher = (): any => ({
    type: ActionTypes.DEACTIVE_VOUCHER
});

//
// Handle voucher deeplink
//
export const voucherDeeplink = (voucher: any): any => (
    dispatch: any => void,
    getState: () => any
): any => {
    const { session, menu, cart } = getState();

    const selectedStoreID = _.get(session, "selectedStore.store_id");

    const voucherApplyHandling = () => {
        if (voucher.is_apply_all_items) {
            // apply to entire cart
            dispatch(actions.cart.applyVoucherToCart(voucher));

            // redirect to cart
            dispatch(push(Config.routes.cart));
        } else {
            // voucher item, save voucher, load menu and add voucher item to cart

            // store voucher deeplink config
            dispatch({
                type: ActionTypes.SAVE_DEEPLINK,
                voucher
            });

            if (menu.store_id) {
                // menu already loaded, check the voucher
                dispatch(checkAndApplyVoucher());
            } else if (selectedStoreID) {
                // Push the user to the menu route to load the menu and find product
                dispatch(push(`${Config.routes.menu}/${selectedStoreID}`));
            } else {
                // no store selected and no menu loaded, show address modal
                dispatch(
                    actions.globalModals.openGlobalModal({
                        modalId: GlobalModals.ADDRESS_LOCATOR
                    })
                );
            }
        }
    };

    // check for already applied vouchers to cart or cart items
    if (cart.hasDiscount || cart.hasDiscountedItem) {
        // show confirm overwrite voucher prompt
        dispatch(
            actions.globalModals.openAlertModal({
                title: "Only one discount per cart!",
                body: (
                    <p>
                        {`To use this discount instead, please remove the ${cart.hasDiscount ||
                            cart.hasDiscountedItem} currently in your cart.`}
                    </p>
                ),
                confirmText: "VIEW CART",
                onConfirm: () => {
                    dispatch(actions.globalModals.closeAlertModal());
                    dispatch(replace(Config.routes.cart));
                }
            })
        );
    }
    // check if set store supports required voucher payment method constraints
    else if (selectedStoreID) {
        // check if store supports new vouchers payment constraints, apply voucher if supported
        dispatch(
            actions.menu.checkStoreSupportsPayments(
                voucher.payment_methods_constraints,
                voucherApplyHandling
            )
        );
    } else {
        // run voucher apply handling
        voucherApplyHandling();
    }
};

//
// Apply Voucher - takes a promoId and adds the product to cart with voucher flag
//
export const checkAndApplyVoucher = (): any => (
    dispatch: any => void,
    getState: () => any
): any => {
    const { menu, session } = getState();

    const voucherDeeplink = _.get(session, "deeplink.voucher");

    // Only run if there is a deeplink in the session
    if (!voucherDeeplink) {
        return;
    }

    // try to find matching voucher promo item in menu
    const voucherItem = _.find(menu.menu_items, {
        promo_id: voucherDeeplink.promo_id
    });

    if (voucherItem) {
        // create cart item through product reducer
        dispatch(
            actions.product.setProductFromPromoID(
                voucherItem.promo_id,
                null,
                true,
                voucherDeeplink.modifier_pos_item_id
            )
        );

        // push product to cart with voucher flag
        dispatch(
            actions.cart.addItemToCart(
                getState().product,
                session.deeplink.voucher,
                false
            )
        );
    } else {
        // Could not find menu item error
        dispatch(
            actions.globalModals.openAlertModal({
                title: "Unable to use this reward",
                body: <p>Sorry, the offer is not valid at your selected restaurant or address.</p>,
                confirmText: "OK",
                onConfirm: () => {
                    dispatch(actions.globalModals.closeAlertModal());
                }
            })
        );
    }

    // clear deeplink
    dispatch({
        type: ActionTypes.CLEAR_DEEPLINK
    });
};

// Coffee loyalty - guest user cms fields
export const getGuestCoffeeLoyaltyRequest = (): any => ({
    type: ActionTypes.GET_GUEST_COFFEE_LOYALTY_REQUEST
});

export const getGuestCoffeeLoyaltySuccess = (result: any): any => ({
    type: ActionTypes.GET_GUEST_COFFEE_LOYALTY_SUCCESS,
    result
});

export const getGuestCoffeeLoyaltyFailure = (error: any): any => ({
    type: ActionTypes.GET_GUEST_COFFEE_LOYALTY_FAILURE,
    error
});

export const getGuestCoffeeLoyalty = (): any => (
    dispatch: any => void
): any => {
    dispatch(getGuestCoffeeLoyaltyRequest());

    return ApiFactory.VoucherAPI.getGuestCoffeeLoyalty()
        .then((response: any): any => {
            dispatch(getGuestCoffeeLoyaltySuccess(response));
            return response;
        })
        .catch((error: any) => {
            dispatch(getGuestCoffeeLoyaltyFailure(error));
            throw error;
        });
};

// Coffee loyalty - user assigned vouchers and progress
export const getUserCoffeeLoyaltyRequest = (): any => ({
    type: ActionTypes.GET_COFFEE_LOYALTY_REQUEST
});

export const getUserCoffeeLoyaltySuccess = (result: any): any => ({
    type: ActionTypes.GET_COFFEE_LOYALTY_SUCCESS,
    result
});

export const getUserCoffeeLoyaltyFailure = (error: any): any => ({
    type: ActionTypes.GET_COFFEE_LOYALTY_FAILURE,
    error
});

export const getUserCoffeeLoyalty = (): any => (dispatch: any => void): any => {
    dispatch(checkCoffeeLoyalty());

    dispatch(getUserCoffeeLoyaltyRequest());

    return ApiFactory.VoucherAPI.getUserCoffeeLoyalty()
        .then((response: any): any => {
            brazeUpdateCoffeeLoyalty(response);
            dispatch(getUserCoffeeLoyaltySuccess(response));
            return response;
        })
        .catch((error: any) => {
            dispatch(getUserCoffeeLoyaltyFailure(error));
            throw error;
        });
};

// Coffee loyalty - eligiblity of cart items
export const checkCoffeeLoyaltyRequest = (): any => ({
    type: ActionTypes.GET_COFFEE_LOYALTY_ELIGABLE_REQUEST
});

export const checkCoffeeLoyaltySuccess = (result: any): any => ({
    type: ActionTypes.GET_COFFEE_LOYALTY_ELIGABLE_SUCCESS,
    result
});

export const checkCoffeeLoyaltyFailure = (error: any): any => ({
    type: ActionTypes.GET_COFFEE_LOYALTY_ELIGABLE_FAILURE,
    error
});

export const checkCoffeeLoyalty = (): any => (
    dispatch: any => void,
    getState: () => any
): any => {
    const { cart, session } = getState();
    
    if (!session.isAuthenticated || _.isEmpty(cart.line_items)) {
        // don't check if not logged in or has an empty cart
        return false;
    }
    
    dispatch(checkCoffeeLoyaltyRequest());

    return ApiFactory.VoucherAPI.checkCoffeeLoyaltyEligibility(cart)
        .then((response: any): any => {
            dispatch(checkCoffeeLoyaltySuccess(response));
            return response;
        })
        .catch((error: any) => {
            dispatch(checkCoffeeLoyaltyFailure(error));
            throw error;
        });
};
