// @flow

import Configs from "@Config";
import API from "./API";

export default class AddressAPI {
    static search(input: string): Promise<any> {
        const query = encodeURIComponent(input);
        return API.get(`${Configs.addressApi}/search?addr=${query}`);
    }

    static getDeliveryStore(
        locationRecordId: string,
        deliveryTime: string
    ): Promise<any> {
        const location = encodeURIComponent(locationRecordId);
        const time = encodeURIComponent(deliveryTime);
        return API.get(
            `/api/v2/stores/address/${location}?expectedFor=${time}`
        );
    }

    static getSavedAddresses(userKey: string): Promise<any> {
        return API.get(`${Configs.orderApi}/user/me/addresses`);
    }

    static saveUserAddress(
        userKey: string,
        record_id: string,
        nickname: string,
        delivery_note?: string = "",
        unit_number?: string = ""
    ): Promise<any> {
        const url = `${Configs.orderApi}/user/me/addresses`;
        const body = {
            record_id,
            unit_number,
            delivery_note,
            nickname
        };
        return API.post(url, null, body);
    }

    static deleteUserAddress(
        userKey: string,
        address_id: string
    ): Promise<any> {
        const url = `${Configs.orderApi}/user/me/addresses/${address_id}`;
        return API.delete(url, null, {});
    }

    static editUserAddress(userkey: string, address: any): Promise<any> {
        return API.put(
            `${Configs.orderApi}/user/me/addresses/${address.address_id}`,
            null,
            address
        );
    }

    static updateUserAddress(
        userKey: string,
        record_id: string,
        nickname: string,
        delivery_note?: string = "",
        unit_number?: string = "",
        address_id: string
    ): Promise<any> {
        const url = `${Configs.orderApi}/user/me/addresses/${address_id}`;
        const body = {
            record_id,
            unit_number,
            delivery_note,
            nickname
        };
        return API.put(url, null, body);
    }
}
