import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import NumberIndicator from "./NumberIndicator";

const VoucherCount = ({ count }) => {
    return (
        <span className="voucher-count">
            <i className="voucher-count__icon icon icon-voucher-no-dollar" />
            {count > 0 && <NumberIndicator count={count} />}
        </span>
    );
};

const mapStateToProps = state => ({
    count:
        _.get(state, "vouchers.vouchers.length", 0) +
        _.get(state, "vouchers.comps.length", 0)
});

export default connect(mapStateToProps)(VoucherCount);
