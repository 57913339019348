// @flow

//import { type ProfileType } from "@SharedTypes";

import React, { Component } from "react";
import { connect } from "react-redux";
//import { withRouter } from "@utils/hooks/withRouter";
import { ReactForm } from "@adrenalin/react-form";
import GlobalModals from "@Modals";

import actions from "@actions";
import AdyenCheckout from "@components/molecules/AdyenCheckout";
import SupportedPayments from "@components/atoms/SupportedPayments";
import Button from "@components/atoms/Button";
import Icon from "@components/atoms/Icon";
import LoaderCover from "@components/atoms/LoaderCover";
import ServerErrorMessage from "@components/atoms/ServerErrorMessage";

type PropsType = {
    dispatch: any,
    user: any,
    adyen: any,
    onSuccess: (input: any) => void,
    onClose?: () => void
};

type StateType = {
    adyenLoaded: boolean,
    loading: boolean,
    error: ?string
};

class AddCreditCard extends Component<PropsType, StateType> {
    state = {
        adyenLoaded: false,
        loading: false,
        error: null
    };

    adyenCheckoutRef = React.createRef<any>();

    handleSaveCardClick(e: any): any {
        if (this.adyenCheckoutRef.current) {
            this.adyenCheckoutRef.current.submit(e);
        }

        //this.setState({ loading: true, error: null });
    }

    handleAdyenOnLoad() {
        this.setState({
            adyenLoaded: true
        });
    }

    handleAdyenOnSubmit() {
        this.setState({ loading: true });
    }

    handleAdyenOnComplete(paymentData: any) {
        // pass paymentData object top veriy card action
        const oldCardsLength = this.props.adyen.cards.length;
        this.props
            .dispatch(actions.adyen.verifyAddedCard(paymentData))
            .then(res => {
                this.setState({ loading: false, error: null });
                if (this.props.onSuccess) {
                    this.props.onSuccess(res);
                    this.props.dispatch(
                        actions.globalModals.closeGlobalModal(
                            GlobalModals.ADD_PAYMENT_METHOD
                        )
                    );
                } else {
                    this.props.dispatch(
                        actions.globalModals.closeGlobalModal(
                            GlobalModals.ADD_PAYMENT_METHOD
                        )
                    );
                    const newCardsLength = res.details ? res.details.length : 0;
                    if (newCardsLength <= oldCardsLength) {
                        this.props.dispatch(
                            actions.globalModals.openAlertModal({
                                title: "Sorry, we couldn’t save the card",
                                body: (
                                    <p>
                                        Please make sure 'Save for my next
                                        payment' is ticked and the card does not
                                        already exist in your wallet
                                    </p>
                                ),
                                confirmText: "OK",
                                onConfirm: () => {
                                    this.props.dispatch(
                                        actions.globalModals.closeAlertModal()
                                    );
                                }
                            })
                        );
                    }
                }
            })
            .catch((err: any) => {
                // reset adyen sdk - commented out for now as these errors should not require a new adyen payment session
                // if (this.adyenCheckoutRef.current) {
                //     this.adyenCheckoutRef.current.init();
                // }

                // show errors and reset loading state
                this.setState({
                    loading: false,
                    error:
                        err.friendly_message ||
                        "Something went wrong, your card could not be validated."
                });
            });
    }

    handleAdyenOnError(err: any) {
        this.setState({ loading: false });
    }

    handleFormValidated() {}

    render(): any {
        return (
            <div className="prompt-modal">
                <LoaderCover solid={true} active={this.state.loading} />

                <div className="prompt-modal__title">
                    <h2 className="heading-2">Add new card</h2>
                </div>

                <p>
                    Please note that you will be asked to pay $0.00 to validate
                    your card.
                </p>

                <SupportedPayments />

                <ServerErrorMessage>{this.state.error}</ServerErrorMessage>

                <AdyenCheckout
                    className="centred"
                    ref={this.adyenCheckoutRef}
                    mode="recurring"
                    userId={this.props.user.key}
                    onLoad={this.handleAdyenOnLoad.bind(this)}
                    onSubmit={this.handleAdyenOnSubmit.bind(this)}
                    onComplete={this.handleAdyenOnComplete.bind(this)}
                    onError={this.handleAdyenOnError.bind(this)}
                />

                <ReactForm
                    onSuccess={this.handleSaveCardClick.bind(this)}
                    className="start-xs"
                >
                    {/* <ReactFormField
                        type="checkbox"
                        label="Remember this card"
                        name="saveCardAgree"
                        required={true}
                        errorMessages={{
                            required:
                                "To save this card, we need to remember your card details"
                        }}
                    /> */}

                    <Button
                        type="submit"
                        className="prompt-modal__button prompt-modal__button--with-icon"
                        disabled={!this.state.adyenLoaded || this.state.loading}
                    >
                        <Icon name="lock-with-hole" /> Save card
                    </Button>
                </ReactForm>

                <div className="form-footer">
                    <p className="body-small">
                        Your payment information is stored securely. Saved cards
                        can be deleted anytime by visiting your account.
                    </p>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any): any => ({
    user: state.user,
    adyen: state.adyen
});

export default connect(
    mapStateToProps,
    null
)(AddCreditCard);
