// @flow

import * as React from "react";
import { connect } from "react-redux";
import _ from "lodash";

import Config from "@Config";
import InfoText from "@components/atoms/InfoText";

type PropsType = {
    className?: string,
    align?: string,
    cart?: {
        line_items?: any,
        compCode?: any
    }
};

const SupportedPayments = (props: PropsType): any => {
    const alignClass = props.align ? `supported-payments--${props.align}` : "";

    // check if promo and/or vouchers are applied and check their payment
    // constraints to hide unappplicable payment methods
    // filter payment methods based on promo code constraints
    let supportedPayments = _.filter(
        Config.PaymentOptions,
        (paymentOption: any): any => {
            if (
                props.cart &&
                props.cart.compCode &&
                props.cart.compCode.payment_methods_constraints
            ) {
                if (
                    props.cart.compCode.payment_methods_constraints.includes(
                        paymentOption.constraint
                    )
                ) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return true;
            }
        }
    );

    // filter payment methods based on product voucher constraints
    supportedPayments = _.filter(
        supportedPayments,
        (paymentOption: any): any => {
            if (props.cart && props.cart.line_items) {
                let voucherPaymentMethodAllowed = true;

                props.cart.line_items.forEach((lineItem: any): any => {
                    if (
                        lineItem.payment_methods_constraints &&
                        !lineItem.payment_methods_constraints.includes(
                            paymentOption.constraint
                        )
                    ) {
                        voucherPaymentMethodAllowed = false;
                    }
                });

                return voucherPaymentMethodAllowed;
            } else {
                return true;
            }
        }
    );

    return (
        <div
            className={`supported-payments ${alignClass} ${
                props.className ? props.className : ""
            }`}
        >
            <InfoText>We accept</InfoText>

            {supportedPayments.map((paymentOption: any, index: number): any => {
                return (
                    <img
                        key={index}
                        alt={paymentOption.text}
                        src={paymentOption.img}
                    />
                );
            })}
        </div>
    );
};

const mapStateToProps = (state: any): any => ({
    cart: state.cart
});

export default connect(
    mapStateToProps,
    {}
)(SupportedPayments);
