// @flow

import * as React from "react";
import { connect } from "react-redux";

import actions from "@actions";
import SavedCard from "@components/atoms/SavedCard";
import TextButton from "@components/atoms/TextButton";

import useAsyncState from "@utils/hooks/useAsyncState";

type PropsType = {
    card: any,
    disableCard: actions.adyen.disableCard
};

const PaymentMethodRow = ({ card, ...props }: PropsType): React.Node => {
    const [state, actions] = useAsyncState();
    const handleRemoveCard = () => {
        actions.request();
        props
            .disableCard(card.recurring_detail_reference)
            .then(actions.success)
            .catch((error: any) => {
                console.error(error);
                actions.fail(error.friendly_message || "Something went wrong");
            });
    };

    const classes = [
        "payment-method-row",
        state.loading ? "payment-method-row--removing" : null
    ];

    return (
        <div className={classes.join(" ")}>
            <SavedCard card={card} inline />
            <TextButton onClick={handleRemoveCard}>Remove</TextButton>
        </div>
    );
};

const mapStateToProps = (state: any): any => {
    return {
        user: state.user,
        adyen: state.adyen
    };
};

export default connect(
    mapStateToProps,
    {
        disableCard: actions.adyen.disableCard
    }
)(PaymentMethodRow);
