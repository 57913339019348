// @flow

import Configs from "@Config";
import API from "./API";

export default class VoucherAPI {
    static getVouchers(): Promise<any> {
        return API.get(`/api/v2/rewards/user/me`, null, null);
    }

    static undeferVoucher(voucher_id: string): Promise<any> {
        return API.put(`${Configs.voucherApi}/voucher/user/me/status`, null, {
            voucher_id,
            status: false
        });
    }

    static deferVoucher(voucher_id: string): Promise<any> {
        return API.put(`${Configs.voucherApi}/voucher/user/me/status`, null, {
            voucher_id,
            status: true
        });
    }

    static getCompDetail(compCode: string): Promise<any> {
        return API.get(
            `${Configs.voucherApi}/comps/${encodeURIComponent(compCode)}`,
            null,
            null
        );
    }

    static saveCompCode(userId: string, compCode: string): Promise<any> {
        return API.put(`${Configs.voucherApi}/users/me/comps`, null, {
            comp_code: compCode
        });
        //   .then(() => {
        //     Toast.show('Promo code saved', {
        //       duration: 4000,
        //       position: -100,
        //       shadow: true,
        //       animation: true,
        //       hideOnPress: true,
        //     });
        //   })
        //   .catch((err) => {
        //     Toast.show(err.friendly_message || 'Promo code not saved', {
        //       duration: 4000,
        //       position: -100,
        //       shadow: true,
        //       animation: true,
        //       hideOnPress: true,
        //     });
        //   });
    }

    static getGuestCoffeeLoyalty(): Promise<any> {
        return API.get(
            `${Configs.voucherApi}/coffee-loyalty/guest`,
            null,
            null
        );
    }

    static getUserCoffeeLoyalty(): Promise<any> {
        return API.get(
            `${Configs.voucherApi}/coffee-loyalty/user/me/profile`,
            null,
            null
        );
    }

    static checkCoffeeLoyaltyEligibility(cart: any): Promise<any> {
        return API.post(
            `${Configs.voucherApi}/coffee-loyalty/user/me/order/eligibility`,
            null,
            cart
        );
    }
}
