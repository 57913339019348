// @flow

import * as React from "react";
import { connect } from "react-redux";
import _ from "lodash";

import { formatAddressComponents } from "@utils";
// import LoaderCover from "@components/atoms/LoaderCover";

import {
    type SavedAddressType,
    type QuickSearchResultType
} from "@SharedTypes";

// Classlist helper function
const classList = (classes: Array<?string>): string =>
    classes.filter((a: ?string): boolean => !!a).join(" ");

type PropsType = {
    value: string,
    results: QuickSearchResultType[],
    loading: boolean,
    savedLocations: SavedAddressType[],
    selectedRecordId: any,
    selectedAddressId: any,
    geolocationSupported: boolean,
    hasResults: boolean,
    submitButton: React.Node,
    onChange: (event: SyntheticInputEvent<HTMLInputElement>) => void,
    onUseLocationClick: (lat: number, lng: number) => any,
    onResultSelect: (
        result: QuickSearchResultType,
        isSavedAddress?: boolean
    ) => void
};

type StateType = {};

class QuickSearch extends React.Component<PropsType, StateType> {
    isSearchedAddressSelected = (): boolean => {
        if (!this.props.selectedRecordId) return false;
        const address = this.props.savedLocations.find(
            (a: SavedAddressType): boolean =>
                a.address.record_id === this.props.selectedRecordId &&
                a.address_id === this.props.selectedAddressId
        );
        return !address;
    };

    renderResult(result: QuickSearchResultType): React.Node {
        const { address_line, locality, state, postcode } = result;
        const addressComponents = [
            [address_line],
            [locality],
            [state, String(postcode)]
        ];
        return (
            <button
                type="button"
                key={result.record_id}
                onClick={(): any => this.props.onResultSelect(result)}
                className="quicksearch-results__result"
            >
                <p className="location-address-dark">
                    {formatAddressComponents(addressComponents)}
                </p>
            </button>
        );
    }

    renderSavedLocation(location: SavedAddressType): React.Node {
        const {
            address_line,
            locality,
            state,
            postcode,
            latitude,
            longitude,
            record_id
        } = location.address;
        const addressComponents = [
            [address_line],
            [locality],
            [state, String(postcode)]
        ];
        return (
            <button
                type="button"
                key={location.address_id}
                className={`quicksearch-saved-locations__location ${
                    this.props.selectedRecordId === record_id &&
                    this.props.selectedAddressId === location.address_id
                        ? "active"
                        : ""
                }`}
                onClick={(): any =>
                    this.props.onResultSelect(
                        {
                            ...location,
                            record_id:
                                location.record_id ||
                                location.address.record_id,
                            address_line: address_line,
                            locality: locality,
                            state: state,
                            postcode: postcode,
                            latitude: latitude,
                            longitude: longitude
                        },
                        true
                    )
                }
            >
                <p className="location-title">
                    <strong>{location.nickname}</strong>
                </p>
                <p className="location-address">
                    {formatAddressComponents(addressComponents)}
                </p>
            </button>
        );
    }

    renderResultsList(): any {
        // Pull out props
        const { results, hasResults } = this.props;
        if (hasResults) {
            if (results.length > 0) {
                return (
                    <div className="quicksearch-results">
                        {results.map(
                            (result: QuickSearchResultType): React.Node =>
                                this.renderResult(result)
                        )}
                    </div>
                );
            } else {
                return (
                    <div className="quicksearch-loading">
                        <p>No results found</p>
                    </div>
                );
            }
        }
    }

    renderSavedLocations(): any {
        // Pull out props
        const { results, savedLocations } = this.props;

        // Calculate show/hide vars
        const showResults = results.length > 0;
        const showSavedLocations = !showResults;

        if (showSavedLocations) {
            return (
                <div className="quicksearch-saved-locations">
                    {savedLocations.map(
                        (
                            location: SavedAddressType,
                            index: number
                        ): React.Node => this.renderSavedLocation(location)
                    )}
                </div>
            );
        }
    }

    renderResults(): React.Node {
        // Pull out props
        // const { loading } = this.props;

        return (
            <div className="quicksearch-results-container">
                {/* <LoaderCover active={loading} /> */}

                {this.renderResultsList()}

                {this.renderSavedLocations()}

                {this.props.submitButton}
            </div>
        );
    }

    render(): React.Node {
        const isSearchedAddressSelected = this.isSearchedAddressSelected();
        //const hasInputValue = [...this.props.value].length === 0;

        return (
            <div className="quicksearch">
                <div
                    className={classList([
                        "quicksearch-input-container",
                        isSearchedAddressSelected ? "active" : null
                    ])}
                >
                    <label
                        htmlFor="address-input"
                        className="quicksearch-input"
                    >
                        <input
                            id="address-input"
                            name="address-input"
                            type="search"
                            className="quicksearch-input__input"
                            placeholder="Enter your address or place the pin"
                            value={this.props.value}
                            onChange={this.props.onChange}
                        />
                        <label
                            htmlFor="address-input"
                            className="quicksearch-input__label"
                        >
                            Delivery Address
                        </label>
                        <div
                            htmlFor="address-input"
                            className="quicksearch-input__icon"
                        >
                            <i className="icon icon-magnifying-glass" />
                        </div>
                    </label>
                    <button
                        type="button"
                        onClick={this.props.onUseLocationClick}
                        className={`quicksearch__use-location visible`}
                    >
                        <i className="icon icon-location" /> Use my location
                    </button>
                </div>
                {this.renderResults()}
            </div>
        );
    }
}

const mapStateToProps = (state: any): any => ({
    geolocationSupported: _.get(state, "device.geolocation.supported", false)
});

export default connect(mapStateToProps)(QuickSearch);
