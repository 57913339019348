// @flow

import React, { Component } from "react";
import { createPortal } from "react-dom";
import { CSSTransition } from "react-transition-group";
import { createUniqueID } from "@utils";
import { enableBodyScroll, disableBodyScroll } from "@utils/toggleBodyScroll";

type PropsType = {
    children: any,
    className?: string,
    active: boolean,
    aside?: boolean,
    mobileFullscreen?: boolean,
    maxWidth?: number,
    hideClose?: boolean,
    onClose?: any,
    internalScroller?: boolean,
    // clickBackgroundClose?: boolean,
    overlayStyle?: boolean,
    darkClose?: boolean
};

class Modal extends Component<PropsType> {
    transitionDuration = 500;
    uniqueId = createUniqueID();
    bodySelector = document.querySelector(".modals");

    modalOpened() {
        setTimeout(() => {
            disableBodyScroll();
        }, this.transitionDuration);
    }

    modalClosed() {
        enableBodyScroll();
    }

    render(): any {
        const { hideClose = false } = this.props;

        if (this.bodySelector) {
            const innerModalStyle = {};
            if (this.props.maxWidth) {
                innerModalStyle.maxWidth = `${this.props.maxWidth}px`;
            }

            return createPortal(
                <CSSTransition
                    key={this.uniqueId}
                    in={this.props.active}
                    timeout={this.transitionDuration}
                    classNames={this.props.aside ? "modal-slide-in" : "fade"}
                    onEntered={this.modalOpened}
                    onExited={this.modalClosed}
                    mountOnEnter
                    unmountOnExit
                >
                    <div
                        // onClick={
                        //     clickBackgroundClose
                        //         ? this.props.onClose
                        //         : undefined
                        // }
                        key={this.uniqueId}
                        className={`modal
                            ${this.props.active ? "is-active" : ""}
                            ${this.props.aside ? "modal--aside" : ""}
                            ${this.props.overlayStyle ? "modal--overlay" : ""}
                            ${
                                this.props.mobileFullscreen
                                    ? "modal--mobile-fullscreen"
                                    : ""
                            }
                            ${
                                this.props.internalScroller
                                    ? "modal--internal-scroller"
                                    : ""
                            } 
                            ${this.props.className || ""}
                        `}
                    >
                        <div className="modal__inner" style={innerModalStyle}>
                            {hideClose === true ? null : (
                                <button
                                    onClick={this.props.onClose}
                                    className={`modal__close ${this.props.darkClose ? 'dark' : ''}`}
                                    type="button"
                                >
                                    <span className="icon icon-close" />
                                </button>
                            )}
                            <div className="modal__content">
                                {this.props.children}
                            </div>
                        </div>
                    </div>
                </CSSTransition>,
                this.bodySelector
            );
        } else {
            return null;
        }
    }
}

export default Modal;
