// @flow

import { combineReducers } from "redux";
import {
    createRouterReducer
} from '@lagunovsky/redux-react-router'
import device from "./device";
import session from "./session";
import initialFetchAll from "./initialFetchAll";
import cms from "./cms";
import user from "./user";
import stores from "./stores";
import menu from "./menu";
import product from "./product";
import globalModals from "./globalModals";
import cart from "./cart";
import orders from "./orders";
import vouchers from "./vouchers";
import savedAddresses from "./savedAddresses";
import adyen from "./adyen";
import config from "./config";
import upsells from "./upsells";
import launchDarkly from "./launchDarkly";

const reducers = (history: any): any =>
    combineReducers({
        navigator: createRouterReducer(history),
        device,
        session,
        initialFetchAll,
        cms,
        user,
        stores,
        menu,
        product,
        cart,
        globalModals,
        vouchers,
        savedAddresses,
        orders,
        adyen,
        upsells,
        config,
        launchDarkly
    });

export default reducers
