// @flow

import * as React from "react";
import { connect } from "react-redux";
// import { TransitionGroup, CSSTransition } from "react-transition-group";

import { Row, Col } from "@containers/Grid";
import ProductImage from "@components/atoms/ProductImage";
import FormatSymbols from "@components/atoms/FormatSymbols";
// import { createUniqueID } from "@utils";

type PropsType = {
    children?: React.Node,
    className?: string,
    title: string,
    description?: string,
    productImgSrc: string,
    hideProductOnMobile: boolean,
    category?: boolean
};

const ProductHero = (props: PropsType): React.Node => {
    const hideProductMobileClass = props.hideProductOnMobile
        ? "product-hero--mobile-hide-product"
        : "";

    const offsetClass = props.hideProductOnMobile
        ? "offset-next-xs"
        : "offset-next-md";

    return (
        <div
            className={`product-hero ${offsetClass} ${
                props.className ? props.className : ""
            } ${hideProductMobileClass}`}
        >
            <div className="product-hero__overlay" />

            <div className={`product-hero__inner`}>
                <div className="container-fluid-small">
                    {props.children}

                    <Row className="center-xs start-md middle-sm">
                        <Col
                            xs={12}
                            sm={props.category ? 12 : 7}
                            className={`product-hero__copy-container`}
                        >
                            <div className={`product-hero__copy`}>
                                <h1 className={`product-hero__title heading-1`}>
                                    <FormatSymbols>{props.title}</FormatSymbols>
                                </h1>
                                <div className="product-hero__description">
                                    <FormatSymbols>
                                        {props.description}
                                    </FormatSymbols>
                                </div>
                            </div>
                        </Col>
                        {!props.category && (
                            <Col
                                xs={12}
                                sm={5}
                                className={`product-hero__product`}
                            >
                                <div
                                    className={`product-hero__product__container`}
                                >
                                    <ProductImage
                                        key={props.productImgSrc}
                                        className={`product-hero__product__image`}
                                        alt={props.title}
                                        src={props.productImgSrc}
                                    />
                                    {/*props.productImgSrc ? (
                                    <TransitionGroup>
                                        <CSSTransition
                                            key={props.productImgSrc}
                                            timeout={1000}
                                            classNames="fade-in"
                                        >
                                            <ProductImage
                                                key={props.productImgSrc}
                                                className={`product-hero__product__image`}
                                                alt={props.title}
                                                src={props.productImgSrc}
                                            />
                                        </CSSTransition>
                                    </TransitionGroup>
                                ) : null*/}
                                </div>
                            </Col>
                        )}
                    </Row>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state: any): any => ({});

const mapDispatchToProps = (dispatch: any): any => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductHero);
