// @flow

import ActionTypes from "@actionTypes";
import _ from "lodash";
import { cookie, createUniqueID } from "@utils";
import DateService from "@utils/DateService";
import { ROUTER_ON_LOCATION_CHANGED } from "@lagunovsky/redux-react-router"

// session id cookie check and set
if (!cookie.get("hjuser_session_id")) {
    cookie.set("hjuser_session_id", `web-${createUniqueID()}`, 31);
}
// cookie.set("hjuser_test_group", "UseTestStores", 31);

let deliveryTime = "asap";
const savedTime = window.localStorage.getItem("deliveryTime") || "";
if (savedTime.match(/\d\d\d\d/)) {
    // DT: The delivery time uses user timezone
    if (DateService.now().diff(savedTime, "seconds") < 0) {
        deliveryTime = savedTime;
    } else {
        window.localStorage.removeItem("deliveryTime");
    }
}

let initialState = {
    sessionId: cookie.get("hjuser_session_id") || null,
    testGroup: cookie.get("hjuser_test_group") || null,
    paymentMethodName: "",
    currentRoute: "",
    prevRoute: "",
    isAuthenticated: false,
    isGuestUser: false,
    showPersistentCart: false,
    hideNav: false,
    selectedStore: JSON.parse(window.localStorage.getItem("selectedStore")),
    orderMethod: window.localStorage.getItem("orderMethod") || "",
    deliveryTime,
    deliveryLocation: window.localStorage.getItem("orderMethod")
        ? JSON.parse(window.localStorage.getItem("deliveryLocation"))
        : {},
    tooltipIds: JSON.parse(window.localStorage.getItem("tooltipIds")) || [],
    hideActiveOrder: false,
    guestForm: {}
};

const localSession = localStorage.getItem("tempSession");

if (
    window.location.pathname === "/checkout" &&
    window.location.search &&
    localSession
) {
    initialState = JSON.parse(localSession);
}

localStorage.removeItem("tempSession");

export default function sessionReducer(
    state: any = initialState,
    action: any = {}
): any {
    switch (action.type) {
        case ROUTER_ON_LOCATION_CHANGED:
            return {
                ...state,
                prevRoute: state.currentRoute,
                currentRoute: `${action.payload.location.pathname}${action.payload.location.search}`
            };
        case ActionTypes.SHOW_LOADING:
            return {
                ...state,
                loading: true
            };
        case ActionTypes.HIDE_LOADING:
            return {
                ...state,
                loading: false
            };
        case ActionTypes.AUTHENTICATED:
            return {
                ...state,
                isGuestUser: false,
                isAuthenticated: true
            };
        case ActionTypes.GUEST_LOGIN_SUCCESS:
            return {
                ...state,
                isGuestUser: true,
                isAuthenticated: false
            };
        case ActionTypes.GUEST_LOGIN_RESET:
            return {
                ...state,
                isGuestUser: false
            };
        case ActionTypes.LOGOUT:
            return {
                ...state,
                isGuestUser: false,
                isAuthenticated: false
            };
        case ActionTypes.SET_SELECTED_STORE: {
            const {
                address,
                suburb,
                postcode,
                state: stateOrTerritory
            } = action.store.location;
            const fullAddress = `${address}, ${suburb}, ${postcode} ${stateOrTerritory}`;
            return {
                ...state,
                selectedStore: {
                    ...action.store,
                    fullAddress
                }
            };
        }
        case ActionTypes.SET_ORDER_METHOD:
            return {
                ...state,
                orderMethod: action.orderMethod
            };
        case ActionTypes.RESET_ORDERING:
            return {
                ...state,
                orderMethod: null,
                selectedStore: null,
                deliveryLocation: {},
                deliveryTime: "asap"
            };
        case ActionTypes.CONFIRM_ORDER_SUCCESS:
            return {
                ...state,
                deliveryTime: "asap"
            };
        case ActionTypes.SET_TOOLTIP_IDS:
            return {
                ...state,
                tooltipIds: action.tooltipIds
            };
        case ActionTypes.TOGGLE_PERSISTENT_CART:
            return {
                ...state,
                showPersistentCart: action.showPersistentCart
            };
        case ActionTypes.TOGGLE_NAV:
            return {
                ...state,
                hideNav: action.hideNav
            };
        case ActionTypes.TOGGLE_ACTIVE_ORDER:
            return {
                ...state,
                hideActiveOrder: action.hideActiveOrder
            };
        case ActionTypes.SET_DELIVERY_LOCATION:
            action.location.full_address = `${action.location.address_line} ${action.location.locality} ${action.location.state} ${action.location.postcode}`;
            return {
                ...state,
                deliveryLocation: action.location
            };
        case ActionTypes.SET_DELIVERY_TIME:
            return {
                ...state,
                deliveryTime: action.time
            };
        case ActionTypes.SAVE_DEEPLINK:
            return {
                ...state,
                deeplink: {
                    voucher: action.voucher,
                    posID: action.posID,
                    promoID: action.promoID,
                    uneditable: _.get(action, "uneditable", false),
                    categoryID: action.categoryID,
                    jacksCafeCheck: action.jacksCafeCheck
                }
            };
        case ActionTypes.CLEAR_DEEPLINK:
            return {
                ...state,
                deeplink: null
            };
        // case ActionTypes.SET_LOGIN_REDIRECT:
        //     return {
        //         ...state,
        //         loginRedirect: action.redirect
        //     };
        // case ActionTypes.CLEAR_LOGIN_REDIRECT:
        //     return {
        //         ...state,
        //         loginRedirect: null
        //     };
        case ActionTypes.STORE_GUEST_FORM:
            // remove password field as it should now be retained
            delete action.guestForm.password;

            return {
                ...state,
                guestForm: action.guestForm,
                validGuestForm: action.isValid
            };
        case ActionTypes.SET_PAYMENT_METHOD:
            return {
                ...state,
                paymentMethodName: action.name
            };
        default:
            return state;
    }
}
