// @flow

import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "@utils/hooks/withRouter";
import Button from "@components/atoms/Button";

import actions from "@actions";
// import Config from "@Config";

import GlobalModals from "../constants/GlobalModals";
// import TextButton from "@components/atoms/TextButton";

// import * as GoogleAnalytics from "@utils/GoogleAnalytics";

import FeatureFlags from "../constants/FeatureFlags";

const { Component, Fragment } = React;

type PropsType = {
    text: string,
    mode: "connect" | "register",
    inline: ?string,
    history: any,
    appleLogin: (token: string, user?: any, tnc?: boolean) => any,
    connectApple: (token: string) => Promise<any>,
    onSuccess: any => void,
    onError: (error: string) => void,
    openGlobalModal: (modal: any) => void,
    isAccountCreationDisabled?: boolean,
    openAlertModal: typeof actions.user.openAlertModal,
    closeAlertModal: typeof actions.user.closeAlertModal
};

type StateType = {
    loading: boolean,
    error: string
};

class AppleSignIn extends Component<PropsType, StateType> {
    state = {
        loading: false,
        error: ""
    };

    globalHandleSuccess = () => {
        this.props.onSuccess(true);
    };

    globalHandleError = (error: any) => {
        console.error(error);
        const { friendly_message = "" } = error;
        this.props.onError(friendly_message || "Unknown error");
    };

    handleClick = () => {
        window.AppleID.auth
            .signIn()
            .then(({ authorization: { id_token: token }, user }) => {
                if (this.props.mode === "connect") {
                    this.handleConnect(token);
                } else {
                    if (user && user.name) {
                        this.handleLogin(token, {
                            first_name: user.name.firstName,
                            last_name: user.name.lastName
                        });
                    } else {
                        this.handleLogin(token);
                    }
                }
            });
    };

    handleConnect = (token: string) => {
        this.setState({ loading: true, error: "" });
        this.props
            .connectApple(token)
            .then(this.globalHandleSuccess)
            .catch(this.globalHandleError)
            .finally(() => {
                this.setState({ loading: false });
            });
    };

    handleLogin = (token, user) => {
        this.setState({ loading: true });
        this.props
            .appleLogin(token, user)
            .then(() => {
                this.setState({ loading: false });
                this.globalHandleSuccess();
            })
            .catch((error: any) => {
                this.setState({ loading: false });
                switch (error.code) {
                    // NCR issue
                    case "7017":
                        if (this.props.isAccountCreationDisabled) {
                            this.props.openAlertModal({
                                title: "Signing up is not available right now",
                                body: (
                                    <React.Fragment>
                                        <p>
                                            You can still place a delivery order as a guest. 
                                        </p>
                                    </React.Fragment>
                                ),
                                confirmText: "OK",
                                onConfirm: () => {
                                    this.props.closeAlertModal();
                                }
                            });
                        } else {
                            // Generic error
                            this.globalHandleError(error);
                        }
                        break;
                    case "7015":
                        // Email permission not supplied
                        this.props.openGlobalModal({
                            modalId: GlobalModals.NAME,
                            onSuccess: (user): any =>
                                this.handleLogin(token, {
                                    first_name: user.firstName,
                                    last_name: user.lastName
                                })
                        });
                        break;
                    default:
                        // Generic error
                        this.globalHandleError(error);
                        break;
                }
            });
    };

    handleTermsConfirmed = () => {};

    handleReRequestPermissions = () => {};

    render(): any {
        return (
            <Fragment>
                <Button
                    onClick={this.handleClick}
                    styleType={["apple"]}
                    disabled={this.state.loading}
                >
                    <span className="icon icon-apple" />
                    <span className="button-text">
                        {this.state.loading ? "Loading" : this.props.text}
                    </span>
                </Button>
            </Fragment>
        );
    }
}

const mapStateToProps = (state: any): any => ({
    isAccountCreationDisabled:
        state.launchDarkly.flags[FeatureFlags.IS_ACCOUNT_CREATION_DISABLED]
});

export default withRouter(
    connect(
        mapStateToProps,
        {
            appleLogin: actions.user.appleLogin,
            connectApple: actions.user.connectApple,
            openGlobalModal: actions.globalModals.openGlobalModal,
            openAlertModal: actions.globalModals.openAlertModal,
            closeAlertModal: actions.globalModals.closeAlertModal
        }
    )(AppleSignIn)
);
