// @flow

import * as React from "react";
import { connect } from "react-redux";

type PropsType = {
    children: React.Node,
    className: string
};

const Hero = (props: PropsType): React.Node => {
    return (
        <div className={`hero ${props.className} nav-primary-pad`}>
            <div className="container-fluid">
                <div className="hero__inner">{props.children}</div>
            </div>
        </div>
    );
};

const mapStateToProps = (state: any): any => ({});

const mapDispatchToProps = (dispatch: any): any => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Hero);
