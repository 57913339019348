// @flow

// Main Sass Import
import "@scss/index.scss";

import React from "react";
import { createRoot } from 'react-dom/client';
import { Provider } from "react-redux";
import { ReduxRouter } from '@lagunovsky/redux-react-router'
import { store, history, routerSelector } from "@Store";

import Config from "@Config";
import App from "@containers/App";
import Kentico from "@containers/Kentico";
import branch from "branch-sdk";
import * as serviceWorker from "./serviceWorker";

// set user agent on document element
const doc = document.documentElement;
doc && doc.setAttribute("data-useragent", navigator.userAgent);

// append fixed footer portal elements to the page (for both ordering and marketing sites)
if (document.body) {
    document.body.insertAdjacentHTML(
        "beforeend",
        `<div class="fixed-footer-container" hj>
            <div id="${Config.appFixedFooterSelector.replace(
                "#",
                ""
            )}" class="fixed-footer" hj></div>
            <div id="${Config.ordersFixedFooterSelector.replace(
                "#",
                ""
            )}" class="fixed-footer" hj></div>
        </div>`
    );
}

// Fetch HJ environment variable from config.json
// load config file from /public (local) or /build (int, dev, uat, prod) folder
// config file will be switched out on each enviromnent deploy
const initApps = () => {
    // window.onlineOrderingURL should exist when these scripts are loaded through the Kentico marketing site
    const fetchEnvLocation = window.onlineOrderingURL
        ? `${window.onlineOrderingURL}/env.json`
        : "./env.json";

    const cacheKillerDate = Date.now();
    fetch(`${fetchEnvLocation}?lastUpdated=${cacheKillerDate}`)
        .then((res: any): any => res.json())
        .then((json: any) => {
            // define hungry jacks environment var, api gateway host and keys (int, dev, uat, prod)
            // init branch
            branch.init(json.branch);

            // Init appboy
            // initAppboy(json.braze_key, json.braze_sdk_base_url);

            window.braze_key = json.braze_key;
            window.braze_sdk_base_url = json.braze_sdk_base_url;
            window.hj_env = json.env;
            window.api_gateway_url = json.api_gateway_url;
            window.api_gateway_key = json.api_gateway_key;
            window.launch_darkly_key = json.launch_darkly_key;

            // Main app render root
            const container = document.getElementById('hj-app');
            if (container) {
                const root = createRoot(container);
                if (root) {
                    window.hj_marketing_site = false;
                    root.render(
                        <Provider store={store}>
                            <ReduxRouter history={history} routerSelector={routerSelector}>
                                <App />
                            </ReduxRouter>
                        </Provider>
                    );
                }
            }

            // Kentico marketing site app (logged in state, active orders)
            const kenticoContainer = document.getElementById(
                "hj-react-kentico"
            );
            if (kenticoContainer) {
                const kenticoRoot = createRoot(kenticoContainer);
                if (kenticoRoot) {
                    window.hj_marketing_site = true;
                    kenticoRoot.render(
                        <Provider store={store}>
                            <ReduxRouter history={history} routerSelector={routerSelector}>
                                <Kentico />
                            </ReduxRouter>
                        </Provider>
                    );
                }
            }
        })
        .then(() => {
            const loadedEvent = new Event("onlineorder-loaded");
            const body = document.querySelector("body");
            if (body !== null) {
                body.dispatchEvent(loadedEvent);
            }
        })
        .catch(err => {
            console.log(err);
        });
};
initApps();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
