// @flow

import * as React from "react";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { push } from "@lagunovsky/redux-react-router";

import { ReactForm, ReactFormField } from "@adrenalin/react-form";
import * as GoogleAnalytics from "@utils/GoogleAnalytics";

import { pubsub } from "@utils";
import Actions from "@actions";
// import GlobalModals from "../../constants/GlobalModals";
import { Row, Col } from "@containers/Grid";
import Button from "@components/atoms/Button";
import TextButton from "@components/atoms/TextButton";
import ServerErrorMessage from "@components/atoms/ServerErrorMessage";
import useAsyncState from "@utils/hooks/useAsyncState";

import FeatureFlags from "../../constants/FeatureFlags";

type PropsType = {
    user: any,
    session: any,
    register: (profile: any, shouldLogin: boolean) => Promise<any>,
    guestLogin: (profile: any) => Promise<any>,
    getInfo: () => Promise<any>,
    storeGuestForm: any,
    onComplete: (formData?: any) => void,
    push: (path: string) => any,
    openGlobalModal: (modal: any) => void,
    isAccountCreationDisabled?: boolean,
    privacy_policy_url: string,
    terms_conditions_url: string
};

const GuestForm = (props: PropsType): React.Node => {
    const guestFormRef = React.createRef<any>();
    const [state, localActions] = useAsyncState();
    const [shouldValidateGuestForm] = useState(
        props.session.guestForm.first_name && !props.session.validGuestForm
    );
    const [hasPassword, setHasPassword] = useState(false);

    // validate guest form to show erros on init if already partially complete and still invalid
    useEffect(() => {
        if (shouldValidateGuestForm && guestFormRef.current) {
            guestFormRef.current.validateForm(true);
        }
    }, [shouldValidateGuestForm]);

    // subscribe to checkout form submit event to trigger submit method and react form ref
    pubsub.subscribe("GUEST_FORM_SUBMIT", () => {
        if (guestFormRef.current) {
            guestFormRef.current.submit();
        }
    });

    // Checks whether to register user or just proceed as guest only
    const handleFormSubmit = (formData: any) => {
        GoogleAnalytics.logEvent({
            category: "GuestContactDetails",
            action: "Continue",
            label: "ReviewOrder"
        });

        if (formData.password) {
            // register the user first (show terms), continue checkout as guest on callback of register

            // open terms modal
            handleTermsConfirmed(formData);
            // props.openGlobalModal({
            //     modalId: GlobalModals.TNC,
            //     onSuccess: (): any => handleTermsConfirmed(formData)
            // });
        } else {
            // guest only
            handleGuestLogin(formData);
        }
    };

    // As form updates handle local state and track in redux state
    const handleFormUpdate = (formData: any, stateInputs: any) => {
        // check if form is valid
        let isGuestFormValid = true;
        for (let input of stateInputs) {
            if (!input.valid) {
                isGuestFormValid = false;
            }
        }

        if (formData.password) {
            setHasPassword(true);
        } else {
            setHasPassword(false);
        }

        // store form state in redux
        props.storeGuestForm(formData, isGuestFormValid);
    };

    // Triggered after T&C Agreed to
    // Trigger the register request
    const handleTermsConfirmed = (formData: any) => {
        // continue with guest login with registration flag (user will be registered on confirm order success)
        handleGuestLogin(formData, true);
    };

    // Triggered either directly from guest form if no password set or after registration if password passed
    const handleGuestLogin = (formData: any, registerGuest?: boolean) => {
        localActions.request();

        props
            .guestLogin(formData)
            .then(() => {
                if (props.onComplete)
                    props.onComplete(registerGuest ? formData : null);
            })
            .catch((error: any) => {
                localActions.fail(
                    error.friendly_message || "Something went wrong"
                );
            });
    };

    return (
        <div className="guest-form">
            <ServerErrorMessage>{state.error}</ServerErrorMessage>

            <ReactForm
                ref={guestFormRef}
                onSuccess={handleFormSubmit}
                onUpdate={handleFormUpdate}
                disableSubmitUntilValid
            >
                <Row>
                    <Col xs={12} md={6}>
                        <ReactFormField
                            defaultValue={props.session.guestForm.first_name}
                            type="text"
                            name="first_name"
                            required={true}
                            validators={["name"]}
                            errorMessages={{
                                required: "Please enter your first name."
                            }}
                            label="First Name"
                        />
                    </Col>
                    <Col xs={12} md={6}>
                        <ReactFormField
                            defaultValue={props.session.guestForm.last_name}
                            type="text"
                            name="last_name"
                            required={true}
                            validators={["name"]}
                            errorMessages={{
                                required: "Please enter your last name."
                            }}
                            label="Last Name"
                        />
                    </Col>
                </Row>

                <ReactFormField
                    defaultValue={props.session.guestForm.email}
                    type="email"
                    name="email"
                    autoComplete="username"
                    required={true}
                    validators={["email"]}
                    errorMessages={{
                        required: "Please enter your email address."
                    }}
                    label="Email"
                    info="We will send your order receipt to this email"
                />

                <ReactFormField
                    defaultValue={props.session.guestForm.phone}
                    type="tel"
                    name="phone"
                    required={true}
                    validators={["mobile"]}
                    errorMessages={{
                        required: "Please enter your mobile number."
                    }}
                    label="Mobile number"
                    info="We may need to contact you about your order"
                />

                {!props.isAccountCreationDisabled &&
                    <div className="guest-form__create-account">
                        <p>
                            Want to checkout even faster next time? Add a password
                            to create an account.
                        </p>
                        <div className="guest-form__create-account__password">
                            <ReactFormField
                                className="guest-form__create-account__input"
                                type="password"
                                info="Your password must be at least 8 characters long and should include 1 uppercase letter, 1 lower case letter, 1 number and 1 special character"
                                hideErrors
                                passwordVisibility={true}
                                name="password"
                                required={false}
                                showErrors={false}
                                validators={["password"]}
                                label="Password (optional)"
                                autoComplete="new-password"
                            />
                            {hasPassword &&
                                <p className="guest-form__create-account__terms" aria-live="polite">
                                    By creating an account you agree to our{" "}
                                    <TextButton
                                        styletype={"gray-underline"}
                                        big
                                        target="_blank"
                                        to={props.terms_conditions_url}
                                    >
                                        T&Cs
                                    </TextButton>{" "}
                                    and{" "}
                                    <TextButton
                                        styletype={"gray-underline"}
                                        big
                                        target="_blank"
                                        to={props.privacy_policy_url}
                                    >
                                        Privacy Policy
                                    </TextButton>
                                </p>
                            }
                        </div>
                    </div>
                }


                <Button
                    type="submit"
                    styleType="green"
                    loading={state.loading}
                    className="hide-sm"
                >
                    Continue
                </Button>
            </ReactForm>
        </div>
    );
};

const mapStateToProps = (state: any): any => {
    return {
        user: state.user,
        session: state.session,
        privacy_policy_url: state.cms.privacy_policy_url,
        terms_conditions_url: state.cms.terms_conditions_url,
        isAccountCreationDisabled:
            state.launchDarkly.flags[FeatureFlags.IS_ACCOUNT_CREATION_DISABLED]
    };
};

export default connect(
    mapStateToProps,
    {
        register: Actions.user.register,
        guestLogin: Actions.user.guestLogin,
        getInfo: Actions.user.getInfo,
        storeGuestForm: Actions.session.storeGuestForm,
        openGlobalModal: Actions.globalModals.openGlobalModal,
        push
    }
)(GuestForm);
