// @flow

import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "@utils/hooks/withRouter";
import { push, replace } from "@lagunovsky/redux-react-router";
import TextButton from "@components/atoms/TextButton";
//import actions from "@actions";
//import GlobalModals from "@Modals";
import * as GoogleAnalytics from "@utils/GoogleAnalytics";

import Config from "@Config";
import actions from "@actions";
import Button from "@components/atoms/Button";
import DisabledButtonClickWrapper from "@components/atoms/DisabledButtonClickWrapper";
import FacebookSignIn from "@containers/FacebookSignIn";
import AppleSignIn from "@containers/AppleSignIn";
import ServerErrorMessage from "@components/atoms/ServerErrorMessage";

import FeatureFlags from "../../constants/FeatureFlags";

type PropsType = {
    onConfirm: () => void,
    onClose: (keepOnSuccess?: boolean) => void,
    dispatch: (push: any) => void,
    privacy_policy_url: string,
    terms_conditions_url: string,
    toPath?: string,
    fromCoffeeLoyalty?: number,
    isAccountCreationDisabled?: boolean,
    handleProductDeeplink: any
};
type StateType = {};

class LoginPrompt extends Component<PropsType, StateType> {
    state = {};
    redirectLink = this.props.toPath || Config.routes.checkout;

    handleButtonClick(redirectMethod: any, redirectRoute: any) {
        // do redirect path
        this.props.dispatch(redirectMethod(redirectRoute));

        // close modal
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    render(): any {
        return (
            <div className="prompt-modal-container">
                <div className="prompt-modal modal-extra-padding">
                    <div className="prompt-modal__title">
                        <h2 className="heading-2">
                            How would you like to continue?
                        </h2>
                    </div>

                    {this.props.isAccountCreationDisabled &&
                        <ServerErrorMessage>
                            <p style={{
                                fontWeight: "bold",
                                margin: "0 0 4px",
                                lineHeight: "18px"
                            }}>Signing up is not available right now.</p>
                            <p style={{
                                fontWeight: "400",
                                margin: "0",
                                lineHeight: "18px"
                            }}>If you already have an account, you can continue to log in.</p>
                        </ServerErrorMessage>
                    }

                    <div className="prompt-modal__buttons">
                        <FacebookSignIn
                            inline="true"
                            text="Continue with Facebook"
                            onSuccess={(): any => {
                                this.handleButtonClick(push, this.redirectLink);
                            }}
                            onError={err => {
                                this.props.dispatch(
                                    actions.globalModals.openAlertModal({
                                        title: "Something went wrong",
                                        body: <p>{err}</p>,
                                        confirmText: "OK",
                                        onConfirm: () => {
                                            this.props.dispatch(
                                                actions.globalModals.closeAlertModal()
                                            );
                                        }
                                    })
                                );
                            }}
                        />
                        <AppleSignIn
                            text="Continue with Apple"
                            onSuccess={(): any => {
                                this.handleButtonClick(push, this.redirectLink);
                            }}
                            onError={err => {
                                this.props.dispatch(
                                    actions.globalModals.openAlertModal({
                                        title: "Something went wrong",
                                        body: <p>{err}</p>,
                                        confirmText: "OK",
                                        onConfirm: () => {
                                            this.props.dispatch(
                                                actions.globalModals.closeAlertModal()
                                            );
                                        }
                                    })
                                );
                            }}
                        />

                        <Button
                            onClick={(): any => {
                                GoogleAnalytics.logEvent({
                                    category: "LoginPanel",
                                    action: "Open",
                                    label: "Login"
                                });
                                this.handleButtonClick(replace, {
                                    pathname: Config.routes.login,
                                    state: { from: this.redirectLink }
                                });
                            }}
                        >
                            Log in with email
                        </Button>

                        <DisabledButtonClickWrapper
                            condition={this.props.isAccountCreationDisabled}
                            clickEvent={() => {
                                this.props.dispatch(
                                    actions.globalModals.openAlertModal({
                                        title: "Signing up is not available right now",
                                        body: <p>You can still place a delivery order as a guest.</p>,
                                        confirmText: "OK",
                                        onConfirm: () => {
                                            this.props.dispatch(
                                                actions.globalModals.closeAlertModal()
                                            );
                                        }
                                    })
                                );
                            }}
                        >
                            <Button
                                disabled={this.props.isAccountCreationDisabled}
                                onClick={(): any => {
                                    GoogleAnalytics.logEvent({
                                        category: "LoginPanel",
                                        action: "Open",
                                        label: "SignUp"
                                    });
                                    this.handleButtonClick(replace, {
                                        pathname: Config.routes.register,
                                        state: { from: this.redirectLink }
                                    });
                                }}
                            >
                                Sign up with email
                            </Button>
                        </DisabledButtonClickWrapper>

                        <Button
                            styleType="clear"
                            onClick={(): any => {
                                GoogleAnalytics.logEvent({
                                    category: "LoginPanel",
                                    action: "Open",
                                    label: "Guest"
                                });
                                if (this.props.fromCoffeeLoyalty) {
                                    this.props.dispatch(
                                        actions.session.handleProductDeeplink(
                                            null,
                                            null,
                                            null,
                                            this.props.fromCoffeeLoyalty,
                                            true
                                        )
                                    );
                                } else {
                                    this.handleButtonClick(push, this.redirectLink);
                                }
                            }}
                        >
                            No thanks, continue as guest
                        </Button>
                    </div>

                    <div className="form-footer">
                        <p className="body-small">
                            By continuing you agree to our
                            <br />
                            <TextButton
                                styletype={"gray-underline"}
                                big
                                target="_blank"
                                to={this.props.terms_conditions_url}
                            >
                                T&C's
                            </TextButton>{" "}
                            and{" "}
                            <TextButton
                                styletype={"gray-underline"}
                                big
                                target="_blank"
                                to={this.props.privacy_policy_url}
                            >
                                Privacy Policy
                            </TextButton>
                        </p>
                    </div>
                </div>
                <div className="prompt-modal__side_bar">
                    <h3 className="heading-3">why sign up?</h3>
                    <p className="body-medium prompt-modal__side_bar__list_item">
                        <span className="prompt-modal__side_bar__icon">
                            <i className="icon icon-tick" />
                        </span>
                        Jack's Café Loyalty
                    </p>
                    <p className="body-medium prompt-modal__side_bar__list_item">
                        <span className="prompt-modal__side_bar__icon">
                            <i className="icon icon-tick" />
                        </span>
                        Exclusive Vouchers
                    </p>
                    <p className="body-medium prompt-modal__side_bar__list_item">
                        <span className="prompt-modal__side_bar__icon">
                            <i className="icon icon-tick" />
                        </span>
                        Reorder past orders
                    </p>
                    <p className="body-medium prompt-modal__side_bar__list_item">
                        <span className="prompt-modal__side_bar__icon">
                            <i className="icon icon-tick" />
                        </span>
                        Quicker checkout
                    </p>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any): any => {
    return {
        privacy_policy_url: state.cms.privacy_policy_url,
        terms_conditions_url: state.cms.terms_conditions_url,
        isAccountCreationDisabled:
            state.launchDarkly.flags[FeatureFlags.IS_ACCOUNT_CREATION_DISABLED]
    };
};

export default withRouter(connect(mapStateToProps)(LoginPrompt));
