// @flow

import _ from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withRouter } from "@utils/hooks/withRouter";
import { push } from "@lagunovsky/redux-react-router";
import ReCAPTCHA from "react-google-recaptcha";
import { cookie } from "@utils";

import MappedMetaTags from "@containers/MappedMetaTags";
import Config from "@Config";
import actions from "@actions";
import FacebookSignIn from "./FacebookSignIn";
import AppleSignIn from "./AppleSignIn";
import Button from "@components/atoms/Button";
import ServerErrorMessage from "@components/atoms/ServerErrorMessage";
import { ReactForm, ReactFormField } from "@adrenalin/react-form";
import useAsyncState from "../utils/hooks/useAsyncState";
import CheckoutHero from "@components/molecules/CheckoutHero";
import Footer from "@components/organisms/Footer";
import { type ProfileType } from "@SharedTypes";
import TextButton from "@components/atoms/TextButton";
// import { useEffect, useState } from "react";
// import GlobalModals from "@Modals";

type PropsType = {
    login: (formData: any) => Promise<any>,
    push: (path: string) => void,
    openGlobalModal: (modal: any) => void,
    globalModals: any,
    session: any,
    privacy_policy_url: string,
    terms_conditions_url: string
};

const Login = (props: PropsType): React.Node => {
    const [state, localActions] = useAsyncState();
    const recaptchaRef = React.useRef();
    const formRef = React.useRef();
    const referrer = _.get(props, "location.state.from", null);

    const handleFormSubmit = async (formData: any): any => {
        const skipReCaptcha = cookie.get("skipReCaptcha") === "true";
        let googleRecaptchaResponse;
        if (recaptchaRef.current && !skipReCaptcha) {
            googleRecaptchaResponse = await recaptchaRef.current.executeAsync();
        } else if (!skipReCaptcha) {
            return;
        }

        // Send data layer when user presses Log in with email
        // @To ask: submit only for when successful login?
        window.dataLayer.push({
            event: "login_email",
            event_category: "onboarding"
          });

        localActions.request();
        props
            .login({ ...formData, googleRecaptchaResponse, skipReCaptcha })
            .then((user: ProfileType) => {
                // if login prompt callback from globalModals run callback, otherwise redirect to verification/account
                // const { loginRedirect } = props.session;
                // if (loginRedirect) {
                //     props.push(loginRedirect);
                //     props.clearLoginRedirect();
                // } else {
                props.push(
                    user.unverified_email
                        ? Config.routes.emailVerification
                        : referrer || "/"
                );
                // }
                localActions.success();
            })
            .catch((error: any) => {
                // setResetRecaptcha(true);
                if (recaptchaRef.current) {
                    recaptchaRef.current.reset();
                }
                console.error(error);
                localActions.fail(
                    error.friendly_message || "Something went wrong"
                );
            });
    };

    const handleFacebookSuccess = () => {
        props.push(referrer || "/");
    };

    const handleFacebookError = (error: string) => {
        localActions.fail(error);
    };

    // const handleGoogleRecaptcha = (value: string) => {
    //     if (value) {
    //         setCaptchaResp(value);
    //     }
    // };

    return (
        <React.Fragment>
            <MappedMetaTags defaultTitle="Login" />

            <CheckoutHero>
                <div className="container-fluid-xsmall">
                    <CheckoutHero.Title button={<CheckoutHero.BackLink />}>
                        Login
                    </CheckoutHero.Title>
                </div>
            </CheckoutHero>

            <div className="container-fluid-xsmall">
                <div className="content-card">
                    <div className="account-utils-form">
                        <ServerErrorMessage>{state.error}</ServerErrorMessage>
                        <FacebookSignIn
                            text="Continue with Facebook"
                            onSuccess={handleFacebookSuccess}
                            onError={handleFacebookError}
                        />
                        <AppleSignIn
                            text="Continue with Apple"
                            onSuccess={handleFacebookSuccess}
                            onError={handleFacebookError}
                        />
                        <div className="or">or</div>
                        <ReactForm ref={formRef} onSuccess={handleFormSubmit}>
                            <ReactFormField
                                type="email"
                                autoComplete="username"
                                name="email"
                                required={true}
                                validators={["email"]}
                                errorMessages={{
                                    required: "Please enter your email address."
                                }}
                                label="Email"
                            />

                            <ReactFormField
                                type="password"
                                name="password"
                                required={true}
                                errorMessages={{
                                    required: "Please enter your password."
                                }}
                                label="Password"
                                autoComplete="current-password"
                            />

                            {/* <div style={{ display: "none" }}>
                                <ReactFormField
                                    type="text"
                                    value={captchaResp}
                                    name="googleRecaptchaResponse"
                                    id="googleRecaptchaResponse"
                                />
                            </div> */}

                            <Button type="submit" loading={state.loading}>
                                Log in with email
                            </Button>

                            <div className="form-footer">
                                <p className="body-medium">
                                    By continuing you agree to our{" "}
                                    <TextButton
                                        styletype={"gray-underline"}
                                        big
                                        target="_blank"
                                        to={props.terms_conditions_url}
                                    >
                                        T&C's
                                    </TextButton>{" "}
                                    and{" "}
                                    <TextButton
                                        styletype={"gray-underline"}
                                        big
                                        target="_blank"
                                        to={props.privacy_policy_url}
                                    >
                                        Privacy Policy
                                    </TextButton>
                                </p>
                            </div>

                            <div className="form-footer">
                                <p className="body-small">
                                    <Link to={Config.routes.forgotPassword}>
                                        Forgotten your password?
                                    </Link>{" "}
                                </p>
                                <p className="body-small">
                                    Don&#39;t have an account?{" "}
                                    <Link
                                        to={{
                                            pathname: Config.routes.register,
                                            state: { from: referrer }
                                        }}
                                    >
                                        Sign up
                                    </Link>
                                </p>
                            </div>
                        </ReactForm>
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            size="invisible"
                            sitekey={Config.googleRecaptchaKey}
                            badge="bottomright"
                        />
                    </div>
                </div>
            </div>
            <Footer />
        </React.Fragment>
    );
};

const mapStateToProps = (state: any): any => {
    return {
        globalModals: state.globalModals,
        session: state.session,
        privacy_policy_url: state.cms.privacy_policy_url,
        terms_conditions_url: state.cms.terms_conditions_url
    };
};

const mapDispatchToProps = {
    login: actions.user.login,
    openGlobalModal: actions.globalModals.openGlobalModal,
    push: push
    // clearLoginRedirect: actions.session.clearLoginRedirect
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Login)
);
