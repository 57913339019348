// @flow

import React, { useRef, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import Classes from "@Classes";
import { responsive } from "@utils";
import ProductImage from "@components/atoms/ProductImage";
import NumberIndicator from "@components/atoms/NumberIndicator";

gsap.registerPlugin(ScrollToPlugin);

type ItemType = {
    href: string,
    name: string,
    icon?: string,
    alertCount?: number,
    onClick?: Function,
    isHidden?: boolean
};

type PropsType = {
    className?: string,
    mobileSlider?: boolean,
    active?: boolean,
    disabled?: boolean,
    items: ItemType[]
};

const scrollToActive = (containerRef: any) => {
    if (containerRef.current) {
        const activeChild = containerRef.current.querySelector(".active");
        if (activeChild) {
            gsap.to(containerRef.current, {
                scrollTo: activeChild,
                duration: 0.5
            });
        }
    }
};

const SideNav = (props: PropsType): any => {
    // base css class
    const baseClassname = "side-nav";

    // container ref and scroll to active on route change hooks
    const containerRef = useRef(null);
    const location = useLocation();
    useEffect(() => {
        if (responsive.isMobileBreakpoint()) {
            scrollToActive(containerRef);
        }
    }, [location]);
    setTimeout(() => {
        if (responsive.isMobileBreakpoint()) {
            scrollToActive(containerRef);
        }
    }, 0);

    if (!props.disabled) {
        return (
            <div
                ref={containerRef}
                className={`${baseClassname} nav-primary-pad ${
                    props.active ? Classes.active : ""
                } ${
                    props.mobileSlider
                        ? `${baseClassname}--mobile-slider`
                        : "mobile-pane"
                } ${props.className ? props.className : ""}`}
            >
                <div className={`${baseClassname}__inner`}>
                    {props.items
                        ? props.items.map(
                              (item: ItemType, ctx: number): any => {
                                  if (!item.isHidden) {
                                      return (
                                          <NavLink
                                              className={`${baseClassname}__link ${baseClassname}__link--${
                                                  item.icon
                                                      ? "product"
                                                      : "navitem"
                                              }`}
                                              to={item.href}
                                              onClick={item.onClick}
                                              key={ctx}
                                              aria-label={item.name}
                                          >
                                              <div
                                                  className={`${baseClassname}__link__text`}
                                              >
                                                  {item.name}
                                                  {item.alertCount ? (
                                                      <NumberIndicator
                                                          count={
                                                              item.alertCount
                                                          }
                                                      />
                                                  ) : null}
                                              </div>

                                              {item.icon ? (
                                                  <ProductImage
                                                      className={`${baseClassname}__link__icon`}
                                                      src={item.icon}
                                                      alt={item.name}
                                                  />
                                              ) : (
                                                  <div
                                                      className={`${baseClassname}__link__icon icon icon-chevron-right`}
                                                  />
                                              )}
                                          </NavLink>
                                      );
                                  } else {
                                      return null;
                                  }
                              }
                          )
                        : null}
                </div>
            </div>
        );
    } else {
        return null;
    }
};

export default SideNav;
