// @flow

import * as React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import Strings from "@Strings";
import actions from "@actions";

// import actions from "@actions";
import Config from "@Config";
import Footer from "@components/organisms/Footer";
import CheckoutHero from "@components/molecules/CheckoutHero";
import SelectedOrderMethod from "@components/molecules/SelectedOrderMethod";
import CartItems from "@components/molecules/CartItems";
import CartTotals from "@components/molecules/CartTotals";
import OrderButtons from "@components/molecules/OrderButtons";
import Disclaimer from "@components/atoms/Disclaimer";
import MappedMetaTags from "@containers/MappedMetaTags";
import Icon from "@components/atoms/Icon";
import TextButton from "@components/atoms/TextButton";

import PoweredByMenulog from "@components/atoms/PoweredByMenulog";

import { type CartType } from "@SharedTypes";

const { Fragment, useEffect } = React;

type PropsType = {
    cart: CartType,
    session: any,
    showCompModal: actions.cart.showCompModal
};

const Cart = (props: PropsType): React.Node => {
    const storeId: ?number = _.get(props, "session.selectedStore.store_id");

    useEffect((): any => {
        window.dataLayer.push({
            cartValue:
                props.cart.total_amount === 0
                    ? 0
                    : props.cart.total_amount - (props.cart.delivery_cost || 0),
            productIds: props.cart.line_items.map((item: any): any => {
                return item.promo_id || item.pos_item_id;
            }),
            storeId: props.cart.store_id
        });
    }, []);

    return (
        <Fragment>
            <MappedMetaTags defaultTitle="Cart" />

            <CheckoutHero>
                <div className="container-fluid-xsmall">
                    <CheckoutHero.Title
                        button={
                            storeId ? (
                                <CheckoutHero.Link
                                    text="View Menu"
                                    to={`${Config.routes.menu}/${storeId}`}
                                />
                            ) : null
                        }
                    >
                        Cart
                    </CheckoutHero.Title>
                </div>
            </CheckoutHero>

            <div className="container-fluid-xsmall cart-page">
                <div className="content-card content-card--with-sections">
                    <div className="content-card__section">
                        <SelectedOrderMethod type="cart" />
                    </div>

                    <div className="content-card__section">
                        <CartItems editable={true} />

                        {/* <TastyAdditions /> */}

                        {!props.cart.compCode && (
                            <div className={`cart-page__comp_code__button`}>
                                <TextButton onClick={props.showCompModal}>
                                    <Icon name="voucher" />
                                    Got a promo code?
                                </TextButton>
                            </div>
                        )}

                        {props.cart.line_items.length > 0 ? (
                            <Fragment>
                                <CartTotals />
                                <OrderButtons />
                            </Fragment>
                        ) : null}

                        <div className="cart-page__footer">
                            <Disclaimer margin>
                                {Strings.cartDisclaimer}
                            </Disclaimer>
                            <PoweredByMenulog className="powered-by-menulog--light" />
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </Fragment>
    );
};

const mapStateToProps = (state: any): any => ({
    cart: state.cart,
    session: state.session
});

export default connect(
    mapStateToProps,
    {
        showCompModal: actions.cart.showCompModal
    }
)(Cart);
