// @flow

import { type ProfileType, type AdyenCardType } from "@SharedTypes";

import * as React from "react";
import { connect } from "react-redux";

import actions from "@actions";
import AccountPage from "@components/organisms/AccountPage";
// import AddButton from "@components/atoms/AddButton";
import PaymentMethodRow from "@components/atoms/PaymentMethodRow";
import useAsyncState from "@utils/hooks/useAsyncState";
// import Loading from "@components/atoms/Loading";
import ServerErrorMessage from "@components/atoms/ServerErrorMessage";
import AnimateHeight from "@components/atoms/AnimateHeight";
import LoaderCover from "@components/atoms/LoaderCover";

// import modalIds from "../../constants/GlobalModals";

const { useEffect } = React;

type PropsType = {
    user: ProfileType,
    adyen: { cards: AdyenCardType[] },
    getCards: typeof actions.adyen.getCards,
    getUserInfo: typeof actions.user.getInfo,
    openGlobalModal: typeof actions.globalModals.openGlobalModal
};

const PaymentMethods = (props: PropsType): React.Node => {
    const [state, actions] = useAsyncState();

    useEffect(() => {
        actions.request();
        props
            .getCards()
            .then(actions.success)
            .catch((error: any) => {
                console.error(error);
                actions.fail(error.friendly_message || "Something went wrong");
            });
    }, []);

    return (
        <AccountPage
            title="Payment Methods"
            intro={
                <React.Fragment>
                    <i className="icon icon-lock-with-hole" /> Payment methods
                    can only be added during Checkout. Click edit to delete
                    already saved cards.
                </React.Fragment>
            }
        >
            <ServerErrorMessage>{state.error}</ServerErrorMessage>

            <LoaderCover active={state.loading} solid={true} />

            {!state.loading || props.adyen.cards.length > 0 ? (
                <React.Fragment>
                    <div className="payment-methods">
                        <AnimateHeight duration={500} hideOverflow={true}>
                            {props.adyen.cards.length ? (
                                props.adyen.cards.map(
                                    (card: AdyenCardType): React.Node => (
                                        <PaymentMethodRow
                                            key={
                                                card.recurring_detail_reference
                                            }
                                            card={card}
                                        />
                                    )
                                )
                            ) : (
                                <div className="payment-methods__empty">
                                    You currently have no saved cards
                                </div>
                            )}
                        </AnimateHeight>
                    </div>
                </React.Fragment>
            ) : null}
        </AccountPage>
    );
};

const mapStateToProps = (state: any): any => {
    return {
        user: state.user,
        adyen: state.adyen
    };
};

export default connect(
    mapStateToProps,
    {
        getUserInfo: actions.user.getInfo,
        getCards: actions.adyen.getCards,
        openGlobalModal: actions.globalModals.openGlobalModal
    }
)(PaymentMethods);
