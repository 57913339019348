import _ from "lodash";
import ApiUtils from "@utils/ApiUtils";
import DateService from "@utils/DateService";
import moment from "moment";
import AWSSignature from "@utils/AWSSignature";
import actions from "@actions";
import store from "@Store";
import Config from "@Config";

const isPublic = path => {
    const publicEndpoints = [
        /\/api\/v1\/app/,
        /\/api\/v1\/stores/,
        /\/api\/v1\/menu\/\d+\//
    ];
    for (const pathRegex of publicEndpoints) {
        if (pathRegex.test(path)) {
            return true;
        }
    }
    return false;
};

export const AWS = (method = "get", url, headers, body = "") => {
    if (!store) return headers;
    const { user } = store.getState();
    const { host, pathname, search } = new URL(url);
    if (
        !_.get(
            user,
            "api_access_token_response.api_access_token.access_key_id"
        ) ||
        !url.includes(Config.apiGatewayHost()) ||
        isPublic(pathname)
    ) {
        return headers;
    }
    const tokens = user.api_access_token_response.api_access_token;
    const awsSignature = new AWSSignature();
    const credentials = {
        SecretKey: tokens.secret_key,
        AccessKeyId: tokens.access_key_id
    };
    const newHeaders = {
        ...headers,
        host,
        AccessKey: tokens.access_key_id,
        "X-Amz-Date": DateService.now().toISOString(),
        "X-Amz-Security-Token": window.disableAuthSession
            ? ""
            : tokens.security_token
    };
    const options = {
        path: pathname + `${search ?? ''}`,
        method,
        service: "execute-api",
        headers: newHeaders,
        region: "ap-southeast-2",
        body,
        credentials
    };
    awsSignature.setParams(options);
    return {
        ...newHeaders,
        host: Config.apiGatewayHost(),
        Authorization: awsSignature.getAuthorizationHeader().Authorization
    };
};

const API = {
    POST_FORM_URL_ENCODED: "application/x-www-form-urlencoded",
    POST_JSON: "application/json",
    POST_PLAIN: "text/plain",
    awsFetch: (url, option) => {
        const { session } = store.getState();
        const awsHeaders = AWS(
            option.method.toLowerCase(),
            url,
            option.headers,
            option.body
        );
        const newOption = {
            timeout: 30 * 1000,
            ...option,
            headers: {
                ...awsHeaders,
                "x-api-key": Config.apiGatewayKey(),
                "session-id": session.sessionId,
                "test-group": session.testGroup,
                "timezone-offset": moment().format("Z")
            }
        };
        const newUrl = url.replace(
            Config.apiGatewayHost(),
            Config.apiGatewayHost()
        );

        const corsProxyUrl = `${document.location.protocol}//${document.location.hostname}:${Config.corsProxyPort}/`;

        return fetch(
            `${process.env.CORS_PROXY ? corsProxyUrl : ""}${newUrl}`,
            newOption
        )
            .then(
                async res => {
                    if (res.status === 403) {
                        await DateService.triggerResolveTimeAsync();
                    }
                    return Promise.resolve(res);
                },
                err => {
                    return Promise.reject(err);
                }
            )
            .catch(err => {
                // console.log(err);
                throw err;
            });
    },

    retryFetch: (url, option) => {
        let retries = url.endsWith("/app") ? 3 : 0;
        let retryOn403 = true;
        const retryDelay = 2000;

        return new Promise((resolve, reject) => {
            const wrappedFetch = n => {
                API.awsFetch(url, option)
                    .then(
                        response => {
                            // const resDate = response.headers.get("date");
                            if (
                                (response.status >= 500 &&
                                    response.status < 600) ||
                                response.status === 429 ||
                                response.status === 403 ||
                                (url.includes("/confirm") &&
                                    response.status === 202)
                            ) {
                                throw response;
                            } else {
                                resolve(response);
                            }
                        },
                        error => {
                            throw error;
                        }
                    )
                    .catch(error => {
                        if (retryOn403 && _.get(error, "status") === 403) {
                            setTimeout(() => {
                                wrappedFetch(0);
                            }, retryDelay);
                            retryOn403 = false;
                        } else if (n > 0 || _.get(error, "status") === 202) {
                            setTimeout(() => {
                                wrappedFetch(
                                    n - (_.get(error, "status") === 202 ? 0 : 1)
                                );
                            }, retryDelay);
                        } else {
                            reject(error);
                        }
                    });
            };
            wrappedFetch(retries);
        });
    },

    autoFreshTokenFetch: (url, option, retry = true) =>
        API.retryFetch(url, option)
            .then(
                res => {
                    if (res.status >= 200 && res.status < 300) {
                        return res;
                    }
                    throw res;
                },
                res => {
                    throw res;
                }
            )
            .catch(res => {
                if (!retry) throw res;
                if (res.status === 403 || res.status === 401) {
                    return new Promise((resolve, reject) => {
                        ApiUtils.refreshToken(
                            () => {
                                API.retryFetch(url, option).then(
                                    async secondRes => {
                                        let responseBody = {};
                                        // This is for handling empty response
                                        try {
                                            responseBody = await secondRes.json();
                                        } catch (error) {}

                                        if (
                                            secondRes.status >= 200 &&
                                            secondRes.status < 300
                                        ) {
                                            resolve(secondRes);
                                        } else {
                                            if (responseBody.code === "9003") {
                                                resolve(secondRes);
                                            } else {
                                                reject(secondRes);
                                            }
                                        }
                                    }
                                );
                            },
                            err => {
                                const { user } = store.getState();
                                if (!_.isEmpty(user)) {
                                    // auth token no longer valid, logout
                                    store.dispatch(actions.user.logout(false));
                                }
                                reject(res);
                            }
                        );
                    });
                }
                throw res;
            })
};

const methods = ["get", "post", "put", "patch", "delete"];

methods.forEach(method => {
    API[method] = (url, headers, body, type, retry) => {
        // Append protocol and api gateway host to api route
        url = `https://${Config.apiGatewayHost()}${url}`;

        let stringBody = "";
        if (!type || type === API.POST_JSON) {
            stringBody = JSON.stringify(body);
        } else if (type === API.POST_FORM_URL_ENCODED) {
            stringBody = Object.keys(body)
                .map(
                    key =>
                        `${encodeURIComponent(key)}=${encodeURIComponent(
                            body[key]
                        )}`
                )
                .join("&");
        }
        const newHeaders = {
            ...headers
        };
        newHeaders["Content-Type"] = `${
            type ? type : "application/json"
        }; charset=utf-8`;
        const option = {
            method: method.toUpperCase(),
            headers: newHeaders
        };
        if (method !== "get") {
            option.body = stringBody;
        }
        return API.autoFreshTokenFetch(url, option, retry)
            .then(response => response.text())
            .then(content => {
                if (content) {
                    return JSON.parse(content);
                } else {
                    return {};
                }
            })
            .catch(err => {
                // console.log(err)
                if (err.status === 304) {
                    throw err;
                } else if (_.isFunction(err.json)) {
                    return err
                        .json()
                        .catch(() => {
                            throw new Error("Unknown error"); // error response is not json
                        })
                        .then(json => {
                            throw Object({
                                ...json,
                                message: json.message || "Unknown error", // error response is json, maybe doesn't have message
                                httpStatus: err.status || 999
                            });
                        });
                } else if (err.message) {
                    throw err; // an error object, possible timeout error
                }
                throw new Error("Unknown error"); // unknow error
            });
    };
});

export default API;
