// @flow

import ActionTypes from "@actionTypes";
import AddressAPI from "../apis/AddressAPI";

//
// Get user saved addresses
//
const getSavedAddressesRequest = (): any => ({
    type: ActionTypes.GET_SAVED_ADDRESSES_REQUEST
});

const getSavedAddressesSuccess = (addresses: [any]): any => ({
    type: ActionTypes.GET_SAVED_ADDRESSES_SUCCESS,
    addresses
});

const getSavedAddressesFail = (error: any): any => ({
    type: ActionTypes.GET_SAVED_ADDRESSES_FAIL,
    error
});

export const getSavedAddresses = (): any => (
    dispatch: any,
    getState: any
): any => {
    const user = { ...getState().user };

    dispatch(getSavedAddressesRequest());

    return AddressAPI.getSavedAddresses(user.key)
        .then((response: any): any => {
            dispatch(getSavedAddressesSuccess(response || []));
            return response;
        })
        .catch((err: any): any => {
            dispatch(getSavedAddressesFail(err));
            throw err;
        });
};

//
// Add new address
//
const addSavedAddressRequest = (): any => ({
    type: ActionTypes.ADD_SAVED_ADDRESS_REQUEST
});

const addSavedAddressSuccess = (): any => ({
    type: ActionTypes.ADD_SAVED_ADDRESS_SUCCESS
});

const addSavedAddressFail = (error: any): any => ({
    type: ActionTypes.ADD_SAVED_ADDRESS_FAIL,
    error
});

export const addSavedAddress = (
    locationRecordId: string,
    nickname: string,
    deliveryNotes?: string,
    unitNumber?: string
): any => (dispatch: any, getState: any): any => {
    const user = { ...getState().user };

    dispatch(addSavedAddressRequest());

    return AddressAPI.saveUserAddress(
        user.key,
        locationRecordId,
        nickname,
        deliveryNotes,
        unitNumber
    )
        .then((response: any): any => {
            dispatch(addSavedAddressSuccess());
            dispatch(getSavedAddresses());
            return response;
        })
        .catch((error: any): any => {
            dispatch(addSavedAddressFail(error));
            throw error;
        });
};

//
// Update address
//
const updateSavedAddressRequest = (): any => ({
    type: ActionTypes.UPDATE_SAVED_ADDRESS_REQUEST
});

const updateSavedAddressSuccess = (): any => ({
    type: ActionTypes.UPDATE_SAVED_ADDRESS_SUCCESS
});

const updateSavedAddressFail = (error: any): any => ({
    type: ActionTypes.UPDATE_SAVED_ADDRESS_FAIL,
    error
});

export const updateSavedAddress = (
    locationRecordId: string,
    nickname: string,
    deliveryNotes?: string,
    unitNumber?: string,
    address_id: string
): any => (dispatch: any, getState: any): any => {
    const user = { ...getState().user };

    dispatch(updateSavedAddressRequest());

    return AddressAPI.updateUserAddress(
        user.key,
        locationRecordId,
        nickname,
        deliveryNotes,
        unitNumber,
        address_id
    )
        .then((response: any): any => {
            dispatch(updateSavedAddressSuccess());
            dispatch(getSavedAddresses());
            return response;
        })
        .catch((error: any): any => {
            dispatch(updateSavedAddressFail(error));
            throw error;
        });
};

//
// Delete saved address
//
const deleteSavedAddressRequest = (): any => ({
    type: ActionTypes.DELETE_SAVED_ADDRESS_REQUEST
});

const deleteSavedAddressSuccess = (): any => ({
    type: ActionTypes.DELETE_SAVED_ADDRESS_SUCCESS
});

const deleteSavedAddressFail = (error: any): any => ({
    type: ActionTypes.DELETE_SAVED_ADDRESS_FAIL,
    error
});

export const deleteSavedAddress = (addressId: any): any => (
    dispatch: any,
    getState: any
): any => {
    const user = { ...getState().user };

    dispatch(deleteSavedAddressRequest());

    return AddressAPI.deleteUserAddress(user.key, addressId)
        .then((response: any): any => {
            dispatch(deleteSavedAddressSuccess());
            return dispatch(getSavedAddresses());
        })
        .catch((error: any): any => {
            return dispatch(deleteSavedAddressFail(error));
        });
};

//
// Edit saved address
//
const editSavedAddressRequest = (): any => ({
    type: ActionTypes.EDIT_SAVED_ADDRESS_REQUEST
});

const editSavedAddressSuccess = (): any => ({
    type: ActionTypes.EDIT_SAVED_ADDRESS_SUCCESS
});

export const editSavedAddress = (address: any): any => (
    dispatch: any,
    getState: any
): any => {
    const user = { ...getState().user };

    dispatch(editSavedAddressRequest());

    return AddressAPI.editUserAddress(user.key, address).then(
        (response: any): any => {
            dispatch(editSavedAddressSuccess());
            return dispatch(getSavedAddresses());
        }
    );
};
