// @flow

//import moment from "moment";
//import jstz from "jstz";
import Configs from "@Config";
import API from "./API";
import Config from "@Config";

// const DeviceInfo = {
//     getTimezone: (): string => {
//         const timezone = jstz.determine();
//         return timezone.name();
//     }
// };

export default class OrderApi {
    static createOrder(cart: any): Promise<Request> {
        return API.put(
            `${Configs.orderApi}/store/${cart.store_id}/user/me/checkout`,
            null,
            cart
        );
    }

    static confirmOrder(confirmPayload: any): Promise<Request> {
        return API.put(
            `${Configs.orderApiv3}/${confirmPayload.order_id}/user/me/confirm`,
            null,
            confirmPayload
        );
    }

    // static confirmOrder(cart: any): Promise<Request> {
    //     return API.put(
    //         `${Configs.orderApi}/${cart.order_id}/user/me/confirm`,
    //         null,
    //         cart.recurringReference
    //     );
    // }

    // static payOrder(
    //     orderId: string,
    //     userId: string,
    //     isInStore?: boolean = true
    // ): Promise<Request> {
    //     return API.post(`${Configs.orderApi}/${orderId}/user/me`, null, {
    //         order_type: isInStore ? "MCO" : "MDT",
    //         device_time: moment().add(Config.apiTimeDifference, "s").toISOString(true),
    //         timezone: DeviceInfo.getTimezone()
    //     });
    // }

    // static payOrder1(
    //     orderId: string,
    //     userId: string,
    //     isInStore?: boolean = true
    // ): Promise<Request> {
    //     return API.post(
    //         `${Configs.orderApi}/${orderId}/user/me/checkin1`,
    //         null,
    //         {
    //             order_type: isInStore ? "MCO" : "MDT",
    //             device_time: moment().add(Config.apiTimeDifference, "s").toISOString(true),
    //             timezone: DeviceInfo.getTimezone()
    //         }
    //     );
    // }

    // static payOrder2(
    //     orderId: string,
    //     userId: string,
    //     isInStore?: boolean = true
    // ): Promise<Request> {
    //     return API.post(
    //         `${Configs.orderApi}/${orderId}/user/me/checkin2`,
    //         null,
    //         {
    //             order_type: isInStore ? "MCO" : "MDT",
    //             device_time: moment().add(Config.apiTimeDifference, "s").toISOString(true),
    //             timezone: DeviceInfo.getTimezone()
    //         }
    //     );
    // }

    static getPastOrders(userKey: string): Promise<Request> {
        return API.get(
            `${Configs.orderApiv2}/history/user/me?completed=true`,
            null,
            null
        );
    }

    static getActiveOrders(userKey: string): Promise<Request> {
        return API.get(
            `${Configs.orderApiv2}/history/user/me?completed=false`,
            null,
            null
        );
    }

    static getOrderDetailById(orderId: string): Promise<any> {
        return API.get(`${Config.orderApi}/user/me/details/${orderId}`);
    }

    static getOrderDetailByToken(orderToken: string): Promise<any> {
        return API.get(`${Config.orderApi}/details/${orderToken}`);
    }

    static submitOrderFeedback(
        order: any,
        feedbackForm: {
            comments: ?string,
            ratings: {}
        },
        userId?: string = ""
    ): Promise<any> {
        let resultPromise = null;
        if (order.feedback_token) {
            resultPromise = API.post(
                `${Config.feedbackApi}/${order.feedback_token}/order/${order.order_id}`,
                null,
                feedbackForm
            );
        } else {
            resultPromise = API.post(`${Configs.feedbackApi}/order/me`, null, {
                order_id: order.order_id,
                ...feedbackForm
            });
        }
        return resultPromise;
    }

    // static rateOrder(feedback: any): Promise<Request> {
    //     return API.post(`${Configs.feedbackApi}/order/me`, null, feedback);
    // }

    static getSupportedPaymentMethods(
        store_id: number,
        payment_methods_constraints: any
    ): Promise<any> {
        return API.post(`${Config.orderApi}/payment/drop-in/methods`, null, {
            channel: "Web",
            store_id: store_id,
            payment_methods_constraints: payment_methods_constraints
        });
    }
}
