import ActionTypes from "@actionTypes";

const initialState = {};

const globalModalReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.OPEN_GLOBAL_MODAL:
            return {
                ...state,
                [action.modalId]: {
                    active: true,
                    onSuccess: action.onSuccess,
                    toPath: action.toPath,
                    fromCoffeeLoyalty: action.fromCoffeeLoyalty
                }
            };
        case ActionTypes.CLOSE_GLOBAL_MODAL:
            return {
                ...state,
                [action.modalId]: {
                    active: false,
                    onSuccess: action.keepOnSuccess
                        ? state[action.modalId].onSuccess
                        : null,
                    toPath: null,
                    fromCoffeeLoyalty: null
                }
            };
        case ActionTypes.OPEN_ALERT_MODAL:
            return {
                ...state,
                ALERT: {
                    active: true,
                    title: action.title,
                    body: action.body,
                    confirmText: action.confirmText,
                    cancelText: action.cancelText,
                    onConfirm: action.onConfirm,
                    onCancel: action.onCancel,
                    html: action.html,
                    size: action.size,
                    textAlign: action.textAlign,
                    terms: action.terms,
                    hideClose: action.hideClose
                }
            };
        case ActionTypes.CLOSE_ALERT_MODAL:
            return {
                ...state,
                ALERT: {
                    ...state.ALERT,
                    active: false
                }
            };
        case ActionTypes.CLOSE_ALL_MODALS:
            Object.keys(state).forEach(modalKey => {
                if (modalKey !== "ALERT") {
                    // only hide non alert modals
                    state[modalKey].active = false;
                }
            });
            return { ...state };
        case ActionTypes.OPEN_VOUCHER_MODAL:
            return {
                ...state,
                VOUCHER_MODAL: {
                    active: true,
                    voucher: action.voucher,
                    isComp: action.isComp
                }
            };
        case ActionTypes.CLOSE_VOUCHER_MODAL:
            return {
                ...state,
                VOUCHER_MODAL: {
                    active: false
                }
            };
        case ActionTypes.OPEN_BARCODE_OVERLAY:
            return {
                ...state,
                BARCODE_OVERLAY: {
                    active: true
                }
            };
        case ActionTypes.CLOSE_BARCODE_OVERLAY:
            return {
                ...state,
                BARCODE_OVERLAY: {
                    active: false
                }
            };
        default:
            return state;
    }
};

export default globalModalReducer;
