// @flow

import Configs from "@Config";
import API from "./API";

export default class VersionApi {
    static getVersion(): Promise<any> {
        return API.get(`${Configs.versionApi}`, null, null);
    }
}
