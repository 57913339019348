// @flow

import _ from "lodash";
import React from "react";
import { push } from "@lagunovsky/redux-react-router";
import ActionTypes from "@actionTypes";
import actions from "@actions";
import ApiFactory from "@apis/APIFactory";
import { initAppboy, appboyUpdateUser, appboyLogCustomEvent } from "@utils/AppBoy";
import { cookie, pubsub } from "@utils";
// import orderUtils from "@utils/orderUtils";
import { resetOrdering } from "./session.js";

import { type ProfileType, type LoginProfileType } from "@SharedTypes";

// Authenticate user (post login, register, first load)
// -------------

export const authenticateUser = (profile?: ProfileType, isLogin?: boolean): any => (
    dispatch: any,
    getState: any
): any => {
    const user = { ...getState().user, ...profile };

    if (profile) {
        // is a new login request or result of user update

        // get users coffee loyalty progress and cms data
        dispatch(actions.vouchers.getUserCoffeeLoyalty());

        // make other global user data requests (these currently require the user to be verified)
        if (user.is_email_verified || user.is_fb_user) {
            dispatch(actions.vouchers.getVouchers());
            dispatch(actions.savedAddresses.getSavedAddresses());
            dispatch(actions.orders.getAllOrders());
        }

        // start active order polling
        dispatch(actions.orders.startActiveOrdersPolling());
    }

    // launchdarkly user alias
    if (window.launchDarklyClient) {
        const previousUser = window.launchDarklyClient.getUser();
        window.launchDarklyClient.alias(
            {
                key: user.key,
                custom: previousUser.custom
            },
            previousUser
        );
    }

    // appboy
    initAppboy(window.braze_key, window.braze_sdk_base_url);
    appboyUpdateUser(user);

    if (isLogin) {
        // appboy/braze login event
        appboyLogCustomEvent("LoginDate");
    }

    // Dispatch CustomEvent containing user and profile details for marketing site
    const body = document.querySelector("body");
    if (body !== null) {
        const dispatchUser = new CustomEvent("dispatch-user", {
            detail: {
                user: getState().user
            }
        });
        body.dispatchEvent(dispatchUser);
    }

    // set user auth tokens in cookies
    const userTokens = user.api_access_token_response.api_access_token;
    const refreshToken = _.get(
        user,
        "api_access_token_response.email_user_token.refresh_token"
    );
    cookie.set("hjuser_key", user.key, 31);
    cookie.set("hjuser_secret_key", userTokens.secret_key, 31);
    cookie.set("hjuser_security_token", userTokens.security_token, 31);
    cookie.set("hjuser_access_key_id", userTokens.access_key_id, 31);
    cookie.set("hjuser_refresh_token", escape(refreshToken), 31);

    // set session auth status
    return dispatch({
        type: ActionTypes.AUTHENTICATED
    });
};

// Refresh user auth
// -----------------

export const refreshSuccess = (token: string): any => (
    dispatch: any,
    getState: any
): any => {
    if (_.isEmpty(getState().user)) return;

    dispatch({
        type: ActionTypes.REFRESH_SUCCESS,
        token
    });

    // re auth (set updated cookies with updated tokens from state)
    dispatch(authenticateUser());
};

// Expire user session token (for debugging)
// -----------------
export const expireUserToken: any = (): any => ({
    type: ActionTypes.EXPIRE_SESSION
});

// Logout
// -------------

export const logout = (makeCall: any = true): any => (
    dispatch: any,
    getState: any
): any => {
    // expire user cookies
    cookie.set("hjuser_key", "", 31);
    cookie.set("hjuser_secret_key", "", 31);
    cookie.set("hjuser_security_token", "", 31);
    cookie.set("hjuser_access_key_id", "", 31);
    cookie.set("hjuser_refresh_token", "", 31);

    if (makeCall) {
        // user inititiated logout, expire token on the server
        ApiFactory.UserApi.logout(getState().user.key).catch(() => {});

        // appboy/braze logout event
        appboyLogCustomEvent("LogoutDate");
    } else {
        // expired token on server triggered user logout
        dispatch(
            actions.globalModals.openAlertModal({
                title: "You have been logged out.",
                body: <React.Fragment></React.Fragment>,
                confirmText: "OK",
                onConfirm: () => {
                    dispatch(actions.globalModals.closeAlertModal());
                }
            })
        );
    }

    dispatch({
        type: ActionTypes.LOGOUT
    });

    // stop active orders polling
    dispatch(actions.orders.stopActiveOrderPolling());

    dispatch(resetOrdering());

    // get guest users coffee loyalty progress and cms data
    dispatch(actions.vouchers.getGuestCoffeeLoyalty());

    // redirect to landing page
    dispatch(push("/"));
};

// Get User Info
// -------------

const getInfoRequest: any = (): any => ({
    type: ActionTypes.GET_INFO_REQUEST
});

const getInfoSuccess: any = (profile: ProfileType): any => ({
    type: ActionTypes.GET_INFO_SUCCESS,
    profile
});

const getInfoFail: any = (): any => ({
    type: ActionTypes.GET_INFO_FAIL
});

const publishVerifiedUserLogin = (): any => (
    dispatch: any,
    getState: any
): any => {
    if (_.get(getState(), "user.is_email_verified", false)) {
        pubsub.publish("VERIFIED_USER_LOG_IN");
    }
};

export const getInfo = (loginAttempt: any): any => (
    dispatch: any,
    getState: any
): any => {
    return new Promise((resolve: any, reject: any): any => {
        const { user, session } = getState();

        if (
            _.get(
                user,
                "api_access_token_response.api_access_token.security_token"
            )
        ) {
            dispatch(getInfoRequest());

            return ApiFactory.UserApi.getInfo(user.key)
                .then((res: any): any => {
                    if (_.isEmpty(getState().user)) reject("No user found");

                    const profilePercentage = _.get(
                        res,
                        "profile_percent_complete"
                    );

                    if (
                        profilePercentage &&
                        profilePercentage === 100 &&
                        getState().user.profile_percent_complete !== 100
                    ) {
                        appboyLogCustomEvent("ProfileCompleted");
                    }

                    dispatch(getInfoSuccess(res));

                    if (session.isAuthenticated) {
                        // appboy
                        initAppboy(window.braze_key, window.braze_sdk_base_url);
                        appboyUpdateUser(res);
                    }

                    dispatch(publishVerifiedUserLogin());

                    // set auth status, make other user requests and save user in localstorage
                    if (loginAttempt) {
                        dispatch(authenticateUser(res));
                    } else {
                        // get users coffee loyalty progress and cms data
                        dispatch(actions.vouchers.getUserCoffeeLoyalty());
                    }

                    resolve(res);
                })
                .catch((err: any): any => {
                    console.log(err)
                    if (loginAttempt) {
                        dispatch(logout(false));
                    }
                    dispatch(getInfoFail());
                    resolve();
                });
        } else {
            resolve();

            // get guest users coffee loyalty progress and cms data
            dispatch(actions.vouchers.getGuestCoffeeLoyalty());
        }
    });
};

// Update Info
// -----------

const updateInfoRequest = (): any => ({
    type: ActionTypes.UPDATE_USER_REQUEST
});

const updateInfoSuccess = (): any => ({
    type: ActionTypes.UPDATE_USER_SUCCESS
});

const updateInfoFailure = (): any => ({
    type: ActionTypes.UPDATE_USER_FAILURE
});

export const updateInfo = (profile: any): any => (
    dispatch: any,
    getState: any
): any => {
    dispatch(updateInfoRequest());
    const { user } = getState();
    return ApiFactory.UserApi.updateInfo(user.key, profile)
        .then((res: any): any => {
            dispatch(updateInfoSuccess());
            dispatch(getInfo());
            return res;
        })
        .catch((error: any): any => {
            dispatch(updateInfoFailure());
            throw error;
        });
};

// Register
// --------

const registerRequest = (): any => ({
    type: ActionTypes.REGISTER_REQUEST
});

const registerSuccess = (profile: any, shouldLogin: any): any => (
    dispatch: any
): any => {
    if (shouldLogin) {
        dispatch({
            type: ActionTypes.REGISTER_SUCCESS,
            profile
        });
        // set auth status and save user
        dispatch(publishVerifiedUserLogin());
        dispatch(authenticateUser(profile));
    } else {
        dispatch({
            type: ActionTypes.REGISTER_SUCCESS
        });
    }
};

const registerFailure = (error: any = ""): any => ({
    type: ActionTypes.REGISTER_FAILURE,
    error
});

export const register = (
    profile: any,
    shouldLogin: any = true,
    googleRecaptchaResponse: string,
    skipReCaptcha: boolean
): any => (dispatch: any, getState: any): any => {
    dispatch(registerRequest());
    return ApiFactory.UserApi.register(
        profile,
        googleRecaptchaResponse,
        skipReCaptcha
    )
        .then((profile: any): any => {
            dispatch(registerSuccess(profile, shouldLogin));
            return profile;
        })
        .catch((error: any): any => {
            dispatch(registerFailure(error));
            throw error;
        });
};

// Guest Login/Register
//

export const guestLogin = (guestFormData: any): any => (
    dispatch: any,
    getState: any
): any => {
    dispatch({
        type: ActionTypes.GUEST_LOGIN_REQUEST
    });

    const marketingOptIn = guestFormData.marketingAgreement ? true : false;

    // check opt in for marketing
    guestFormData.notifications = {
        email: {
            voucher: marketingOptIn,
            news: marketingOptIn
        },
        on_screen: {
            voucher: marketingOptIn,
            news: marketingOptIn
        }
    };

    return ApiFactory.UserApi.guestLogin(guestFormData)
        .then((profile: any): any => {
            profile = {
                ...profile,
                ...guestFormData
            };

            dispatch({
                type: ActionTypes.GUEST_LOGIN_SUCCESS,
                profile
            });

            dispatch(actions.orders.startActiveOrdersPolling());

            return profile;
        })
        .catch((error: any) => {
            dispatch({
                type: ActionTypes.GUEST_LOGIN_FAILURE
            });
            throw error;
        });
};

// Reset Guest Login/Register
//

export const resetGuestUser = (): any => (
    dispatch: any,
    getState: any
): any => {
    dispatch(actions.orders.stopActiveOrderPolling());
    dispatch({
        type: ActionTypes.GUEST_LOGIN_RESET
    });
};

// export const resetGuestUser = (): any => ({
//     type: ActionTypes.GUEST_LOGIN_RESET
// });

// Login
// -----

const loginRequest = (): any => ({
    type: ActionTypes.LOGIN_REQUEST
});

const loginFailure = (): any => ({
    type: ActionTypes.LOGIN_FAILURE
});

const loginSuccess = (profile: ProfileType): any => (dispatch: any): any => {
    // login successful, set user object
    dispatch({
        type: ActionTypes.LOGIN_SUCCESS,
        profile
    });
    window.localStorage.removeItem("pastGuestOrder");

    dispatch(publishVerifiedUserLogin());
    // set auth status and save user
    return dispatch(authenticateUser(profile, true));
};

export const login = (profile: LoginProfileType): any => (
    dispatch: any,
    getState: any
): any => {
    dispatch(loginRequest());
    return ApiFactory.UserApi.login(profile)
        .then((profile: ProfileType): any => {
            dispatch(loginSuccess(profile));
            return profile;
        })
        .catch((err: any): any => {
            dispatch(loginFailure());
            throw err;
        });
};

// Facebook Login
// --------------

const fbLoginRequest = (): any => ({
    type: ActionTypes.FB_LOGIN_REQUEST
});

const fbLoginFailure = (error: any): any => ({
    type: ActionTypes.FB_LOGIN_FAILURE,
    error
});

const fbLoginSuccess = (profile: any): any => (dispatch: any): any => {
    dispatch({ type: ActionTypes.FB_LOGIN_SUCCESS, profile });
    dispatch(publishVerifiedUserLogin());
    window.localStorage.removeItem("pastGuestOrder");

    dispatch(authenticateUser(profile, true));
};

export const FBLogin = (token: string = "", tnc: any = {}): any => (
    dispatch: any
): any => {
    dispatch(fbLoginRequest());
    return ApiFactory.UserApi.FBlogin(token, tnc)
        .then((res: any): any => {
            return dispatch(fbLoginSuccess({ ...res, fb_token: token }));
        })
        .catch((err: any): any => {
            dispatch(fbLoginFailure(err));
            throw err;
        });
};

// Apple Login
// --------------

// const appleLoginRequest = (): any => ({
//     type: ActionTypes.FB_LOGIN_REQUEST
// });

// const appleLoginFailure = (error: any): any => ({
//     type: ActionTypes.FB_LOGIN_FAILURE,
//     error
// });

// const appleLoginSuccess = (profile: any): any => (dispatch: any): any => {
//     dispatch({ type: ActionTypes.FB_LOGIN_SUCCESS, profile });
//     dispatch(publishVerifiedUserLogin());
//     return dispatch(authenticateUser(profile));
// };

export const appleLogin = (
    token: string = "",
    user?: any,
    tnc?: boolean
): any => (dispatch: any): any => {
    dispatch(loginRequest());
    return ApiFactory.UserApi.applelogin(token, user, tnc)
        .then((res: any): any => {
            return dispatch(
                loginSuccess({
                    ...res,
                    api_access_token_response: {
                        api_access_token:
                            res.api_access_token_response.api_access_token,
                        email_user_token:
                            res.api_access_token_response.refresh_user_token
                    }
                })
            );
        })
        .catch((err: any): any => {
            dispatch(loginFailure());
            throw err;
        });
};

export const connectApple = (token: any = ""): any => (
    dispatch: any,
    getState: any
): any => {
    const {
        user: { key }
    } = getState();
    return ApiFactory.UserApi.connectApple(key, token)
        .then((res: any): any => {
            dispatch(
                loginSuccess({
                    ...getState().user,
                    api_access_token_response: {
                        api_access_token: res.api_access_token,
                        email_user_token: res.refresh_user_token
                    }
                })
            );
            return true;
        })
        .catch((error: any): any => {
            throw error;
        });
};

// Connect FB
// ----------

const connectFBRequest = (): any => ({ type: ActionTypes.FB_CONNECT_REQUEST });

const connectFBFail = (error: any): any => ({
    type: ActionTypes.FB_CONNECT_FAIL,
    error
});

const connectFBSuccess = (response): any => {
    const userTokens = _.get(response, "access_token_response", null);
    if (userTokens) {
        cookie.set("hjuser_secret_key", userTokens.secret_key, 31);
        cookie.set("hjuser_security_token", userTokens.security_token, 31);
        cookie.set("hjuser_access_key_id", userTokens.access_key_id, 31);
    }

    return { userTokens, type: ActionTypes.FB_CONNECT_SUCCESS };
};

export const connectFB = (token: any = ""): any => (
    dispatch: any,
    getState: any
): any => {
    const {
        user: { key }
    } = getState();
    dispatch(connectFBRequest());
    return ApiFactory.UserApi.connectFB(key, token)
        .then((res: any): any => {
            dispatch(connectFBSuccess(res));
            dispatch(getInfo());
            return true;
        })
        .catch((error: any): any => {
            dispatch(connectFBFail());
            throw error;
        });
};

// Resend Verification Email
// -------------------------

const resendVerificationRequest = (): any => ({
    type: ActionTypes.RESEND_VERIFICATION_REQUEST
});

const resendVerificationSuccess = (): any => ({
    type: ActionTypes.RESEND_VERIFICATION_SUCCESS
});

const resendVerificationFail = (): any => ({
    type: ActionTypes.RESEND_VERIFICATION_FAIL
});

export const resendVerification = (): any => (
    dispatch: any,
    getState: any
): any => {
    const {
        user: { key }
    } = getState();
    dispatch(resendVerificationRequest());
    return ApiFactory.UserApi.resendVerification(key)
        .then((res: any): any => {
            dispatch(resendVerificationSuccess());
            return res;
        })
        .catch((err: any): any => {
            dispatch(resendVerificationFail());
            throw err;
        });
};

// Verify Email
// ------------

const verifyEmailRequest = (): any => ({
    type: ActionTypes.VERIFY_EMAIL_REQUEST
});

const verifyEmailSuccess = (): any => ({
    type: ActionTypes.VERIFY_EMAIL_SUCCESS
});

const verifyEmailFail = (): any => ({
    type: ActionTypes.VERIFY_EMAIL_FAIL
});

export const verifyEmail = (token: any): any => (
    dispatch: any,
    getState: any
): any => {
    const {
        user: { key }
    } = getState();
    dispatch(verifyEmailRequest());
    return ApiFactory.UserApi.verifyEmail(key, token)
        .then((): any => {
            dispatch(verifyEmailSuccess());
            return dispatch(getInfo());
        })
        .catch((err: any): any => {
            dispatch(verifyEmailFail());
            throw err;
        });
};

// Change Password
// ---------------

const changePasswordRequest = (): any => ({
    type: ActionTypes.CHANGE_PASSWORD_REQUEST
});

const changePasswordSuccess = (): any => ({
    type: ActionTypes.CHANGE_PASSWORD_SUCCESS
});

const changePasswordFail = (): any => ({
    type: ActionTypes.CHANGE_PASSWORD_FAIL
});

export const changePassword = (
    currentPassword: string,
    newPassword: string
): any => (dispatch: any, getState: any): any => {
    const {
        user: { key }
    } = getState();
    dispatch(changePasswordRequest());
    return ApiFactory.UserApi.changePassword(key, currentPassword, newPassword)
        .then((res: any): any => {
            dispatch(changePasswordSuccess());
            dispatch(getInfo());
            return res;
        })
        .catch((err: any): any => {
            dispatch(changePasswordFail());
            throw err;
        });
};

// Request Password Reset
// ----------------------

const requestResetPasswordRequest = (): any => ({
    type: ActionTypes.REQUEST_RESET_PASSWORD_REQUEST
});

const requestResetPasswordSuccess = (): any => ({
    type: ActionTypes.REQUEST_RESET_PASSWORD_SUCCESS
});

const requestResetPasswordFail = (): any => ({
    type: ActionTypes.REQUEST_RESET_PASSWORD_FAIL
});

export const requestResetPassword = (
    email: any,
    googleRecaptchaResponse: string,
    skipReCaptcha: boolean
): any => (dispatch: any): any => {
    dispatch(requestResetPasswordRequest());
    return ApiFactory.UserApi.requestResetPassword(
        email,
        googleRecaptchaResponse,
        skipReCaptcha
    )
        .then((res: any): any => {
            dispatch(requestResetPasswordSuccess());
            return res;
        })
        .catch((err: any): any => {
            dispatch(requestResetPasswordFail());
            throw err;
        });
};

// Password Reset
// --------------

const resetPasswordRequest = (): any => ({
    type: ActionTypes.RESET_PASSWORD_REQUEST
});

const resetPasswordSuccess = (): any => ({
    type: ActionTypes.RESET_PASSWORD_SUCCESS
});

const resetPasswordFail = (): any => ({
    type: ActionTypes.RESET_PASSWORD_FAIL
});

export const resetPassword = (
    userKey: string,
    token: string,
    password: string
): any => (dispatch: any, getState: any): any => {
    dispatch(resetPasswordRequest());
    return ApiFactory.UserApi.resetPassword(userKey, token, password)
        .then((res: any): any => {
            dispatch(resetPasswordSuccess());
            return res;
        })
        .catch((err: any): any => {
            dispatch(resetPasswordFail());
            throw err;
        });
};

// Dispatch user event

const dispatchUserEvent = (): any => (
    dispatch: any,
    getState: any
): any => {
    const body = document.querySelector("body");

    const dispatchUser = new CustomEvent("dispatch-user")
    if (body !== null) {
        body.dispatchEvent(dispatchUser);
    }
};
