// @flow

import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "@utils/hooks/withRouter";
import AccountHero from "../molecules/AccountHero";
import MappedMetaTags from "@containers/MappedMetaTags";

type PropsType = {
    title: string,
    intro: string,
    postIntro?: any,
    children: React.Node
};

const MyVouchers = (props: PropsType): React.Node => {
    const baseClassName = "account-page";
    return (
        <div className={`${baseClassName}`}>
            <MappedMetaTags defaultTitle={props.title} />

            <AccountHero title={props.title} postIntro={props.postIntro}>
                <div className="page-intro">{props.intro}</div>
            </AccountHero>

            <div className={`${baseClassName}__container`}>
                <div className="container-fluid-small">
                    <div className={`${baseClassName}__content`}>
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state: any): any => {
    return {};
};

const mapDispatchToProps = (dispatch: any): any => {
    return {};
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(MyVouchers)
);
