import ActionTypes from "@actionTypes";

const initialState = {
    isFetching: false
};

export default function intitialFetchAllReducer(
    state = initialState,
    action = {}
) {
    switch (action.type) {
        case ActionTypes.INITIAL_FETCH_ALL_REQUEST:
            return {
                ...state,
                isFetching: true,
                loaded: false
            };
        case ActionTypes.INITIAL_FETCH_ALL_FAILURE:
            return {
                ...state,
                isFetching: false,
                loaded: false
            };
        case ActionTypes.INITIAL_FETCH_ALL_SUCCESS:
            return {
                ...state,
                isFetching: false,
                loaded: true
            };
        default:
            return state;
    }
}
