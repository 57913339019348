// @flow

import * as React from "react";
import _ from "lodash";

const baseClassname = "content-card";
const sectionDisplayName = "ContentCardSection";

const ContentCard = (props: {
    children: React.Node,
    maintainShadow?: boolean,
    className?: string
}): React.Node => {
    // Determine if we're using ContentCardSection's inside this component
    let hasSections = false;
    React.Children.forEach(props.children, (child: any) => {
        if (_.get(child, "type.displayName") === sectionDisplayName) {
            hasSections = true;
        }
    });

    const classes = [
        baseClassname,
        hasSections ? `${baseClassname}--with-sections` : null,
        props.maintainShadow ? `${baseClassname}--maintain-shadow` : null,
        props.className
    ];

    return <div className={classes.join(" ")}>{props.children}</div>;
};

ContentCard.defaultProps = {
    className: ""
};

export const ContentCardSection = (props: {
    children: React.Node,
    className?: string
}): React.Node => {
    const classes = [`${baseClassname}__section`, props.className];
    return <div className={classes.join(" ")}>{props.children}</div>;
};

// Set a displayName property so we can see when this
// component is being used inside the ContentCard component
ContentCardSection.displayName = sectionDisplayName;

ContentCardSection.defaultProps = {
    className: ""
};

ContentCard.Section = ContentCardSection;

export default ContentCard;
