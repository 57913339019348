// @flow

import * as React from "react";
import { connect } from "react-redux";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import actions from "@actions";
import LineItem from "@components/molecules/LineItem";
import OrderVoucher from "@components/molecules/OrderVoucher";
import OrderComp from "@components/molecules/OrderComp";
import AnimateHeight from "@components/atoms/AnimateHeight";

type PropsType = {
    small: boolean,
    editable: boolean,
    cart: any,
    removeCartVoucher: typeof actions.cart.removeCartVoucher
};

const defaultProps = {
    small: false,
    editable: false
};

const CartItems = (props: PropsType): any => {
    const baseClassname = "cart-items";

    return (
        <div className={`${baseClassname}`}>
            {/* Empty cart indicator */}
            <CSSTransition
                key={"cart-empty-state"}
                in={props.cart.line_items.length === 0}
                timeout={1000}
                classNames={"fade"}
                mountOnEnter
                unmountOnExit
            >
                <AnimateHeight
                    className={`${baseClassname}__empty`}
                    duration={300}
                    height={props.cart.line_items.length ? 0 : null}
                    hideOverflow={true}
                >
                    <div className={`${baseClassname}__empty__inner`}>
                        Your cart is empty?{" "}
                        <span role="img" aria-label="hidden">
                            🤔
                        </span>
                    </div>
                </AnimateHeight>
            </CSSTransition>

            {/* Cart items list*/}
            <TransitionGroup component={null}>
                {props.cart.line_items.map(
                    (lineItem: any, index: number): React.Node => (
                        <CSSTransition
                            key={lineItem.uuid || index}
                            timeout={500}
                            classNames="line-item-slide"
                        >
                            <AnimateHeight duration={300}>
                                <LineItem
                                    editable={props.editable}
                                    isCartItem={true}
                                    lineItem={lineItem}
                                    small={props.small}
                                />
                            </AnimateHeight>
                        </CSSTransition>
                    )
                )}
            </TransitionGroup>

            {/* Promo error */}
            {props.cart.promoError ? (
                <OrderComp
                    voucher_name={props.cart.promoError}
                    small={props.small}
                    error={true}
                />
            ) : null}

            {/* Applied cart vouchers */}
            {props.cart.voucher_id && props.cart.voucher_name ? (
                <OrderVoucher
                    voucher_name={props.cart.voucher_name}
                    editable={props.editable}
                />
            ) : null}

            {/* Applied comp code */}
            {props.cart.comp_code && (
                <OrderComp
                    voucher_name={props.cart.comp_code}
                    isCartItem={true}
                    small={props.small}
                    discount={props.cart.discounts_total}
                    editable={props.editable}
                />
            )}
        </div>
    );
};

CartItems.defaultProps = defaultProps;

const mapStateToProps = (state: any): any => {
    return {
        cart: state.cart
    };
};

export default connect(
    mapStateToProps,
    {
        removeCartVoucher: actions.cart.removeCartVoucher
    }
)(CartItems);
