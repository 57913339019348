// @flow

import * as React from "react";
import { connect } from "react-redux";
import AccountPage from "./AccountPage";
import { ReactForm, ReactFormField } from "@adrenalin/react-form";
import { Row, Col } from "../../containers/Grid";
import Button from "@components/atoms/Button";
import ServerErrorMessage from "@components/atoms/ServerErrorMessage";
import useAsyncState from "../../utils/hooks/useAsyncState";
import Actions from "@actions";

import { type ProfileType } from "@SharedTypes";

type PropsType = {
    user: ProfileType,
    changePassword: (
        currentPassword: string,
        newPassword: string
    ) => Promise<any>
};

type FormType = {
    oldPassword: string,
    newPassword: string
};

const ChangePassword = (props: PropsType): React.Node => {
    const [state, actions] = useAsyncState();

    const handleFormSubmit = ({ oldPassword, newPassword }: FormType) => {
        actions.request();
        props
            .changePassword(oldPassword, newPassword)
            .then((response: any) => {
                actions.success("Success!");
            })
            .catch((error: any) => {
                console.log(error);
                actions.fail(error.friendly_message || "Something went wrong");
            });
    };

    return (
        <AccountPage
            title={props.user.is_email_user ? "Password" : "Add a password"}
            intro={
                props.user.is_email_user
                    ? "Set a new password by first entering your old password."
                    : "Add a password to your account to allow logging in by email"
            }
        >
            <Row>
                <Col xs={12} lg={9} xl={6}>
                    <ServerErrorMessage>{state.error}</ServerErrorMessage>
                    <ReactForm
                        onSuccess={handleFormSubmit}
                        disableSubmitUntilValid={true}
                    >
                        {props.user.is_email_user && (
                            <ReactFormField
                                type="password"
                                name="oldPassword"
                                label="Old Password"
                                validators={["password"]}
                                required
                            />
                        )}
                        <ReactFormField
                            type="password"
                            name="newPassword"
                            label="New Password"
                            info="Your new password must contain at least; 8 characters, a mix of uppercase and lowercase letters, 1 number, and 1 special character."
                            validators={["password"]}
                            required
                            hideErrors
                        />
                        <div className="react-form__field react-form__field--submit">
                            <Button
                                type="submit"
                                styleType="inline"
                                success={!!state.success}
                                loading={state.loading}
                            >
                                Save New Password
                            </Button>
                        </div>
                    </ReactForm>
                </Col>
            </Row>
        </AccountPage>
    );
};

const mapStateToProps = (state: any): any => {
    return {
        user: state.user
    };
};

export default connect(
    mapStateToProps,
    { changePassword: Actions.user.changePassword }
)(ChangePassword);
