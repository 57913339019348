// @flow

import * as React from "react";

type PropsType = {
    value: string,
    label: string,
    inputElement: React.Node
};

const RadioButton = (props: PropsType): React.Node => {
    return (
        <label className="radio-button" key={props.value}>
            {props.inputElement}
            <div className="radio-button__button">{props.label}</div>
        </label>
    );
};

export default RadioButton;
