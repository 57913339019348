// @flow

import * as React from "react";

type PropsType = {
    className?: string,
    name: string,
    type?: string,
    circle?: boolean
};

const Icon = (props: PropsType): any => {
    return (
        <i
            {...props }
            className={`icon icon-${props.name} ${
                props.circle ? "icon--circle" : ""
            } ${props.className || ""}`}
        />
    );
};

export default Icon;
