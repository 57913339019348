// @flow

import * as React from "react";
import { connect } from "react-redux";
import AccountPage from "./AccountPage";
import { ReactForm, ReactFormField } from "@adrenalin/react-form";
import { Row, Col } from "../../containers/Grid";
import Button from "@components/atoms/Button";
import ServerErrorMessage from "@components/atoms/ServerErrorMessage";
import Actions from "@actions";

import { type ProfileType } from "@SharedTypes";
import useAsyncState from "../../utils/hooks/useAsyncState";

type FormType = {
    voucher: string | boolean,
    news: string | boolean
};

type PropsType = {
    user: ProfileType,
    updateUserInfo: (profile: any) => Promise<any>
};

const EmailPreferences = (props: PropsType): React.Node => {
    const [state, localActions] = useAsyncState();

    const handleFormSubmit = (data: FormType) => {
        const notifications = {
            email: {
                voucher: !!data.voucher,
                news: !!data.news
            },
            on_screen: { ...props.user.notifications.on_screen }
        };
        localActions.request();
        props
            .updateUserInfo({ notifications })
            .then(() => {
                localActions.success("Success!");
            })
            .catch((error: any) => {
                console.error(error);
                localActions.fail(
                    error.friendly_message || "Something went wrong"
                );
            });
    };

    return (
        <AccountPage
            title="Email Preferences"
            intro="Get the most out of your membership by selecting what you want to receive."
        >
            <Row>
                <Col xs={12} lg={6}>
                    <ServerErrorMessage>{state.error}</ServerErrorMessage>
                    <ReactForm
                        onSuccess={handleFormSubmit}
                        disableSubmitUntilValid={true}
                    >
                        <ReactFormField
                            type="checkbox"
                            label="Exclusive vouchers"
                            name="voucher"
                            defaultChecked={
                                props.user.notifications && !!props.user.notifications.email.voucher
                            }
                        />
                        <ReactFormField
                            type="checkbox"
                            label="News"
                            name="news"
                            defaultChecked={
                                props.user.notifications && !!props.user.notifications.email.news
                            }
                        />
                        <div className="react-form__field react-form__field--submit">
                            <Button
                                type="submit"
                                styleType="inline"
                                success={!!state.success}
                                loading={state.loading}
                            >
                                Save
                            </Button>
                        </div>
                    </ReactForm>
                </Col>
            </Row>
        </AccountPage>
    );
};

const mapStateToProps = (state: any): any => {
    return {
        user: state.user
    };
};

export default connect(
    mapStateToProps,
    {
        updateUserInfo: Actions.user.updateInfo
    }
)(EmailPreferences);
