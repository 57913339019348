// @flow

import { type OrderType } from "@SharedTypes";

import React, { Component } from "react";
import { createPortal } from "react-dom";
import { responsive } from "@utils";
import { connect } from "react-redux";
import { CSSTransition } from "react-transition-group";

import Config from "@Config";
import { MobileBreakpointProvider } from "@utils/hooks/useMobileBreakpoint";
import OrderDetails from "@components/molecules/OrderDetails";
import AnimateHeight from "@components/atoms/AnimateHeight";
import orderUtils from "@utils/orderUtils";

type PropsType = {
    orders: [OrderType],
    session: any,
    hideActiveOrder: boolean
};

type StateType = {
    viewport: {
        width: number
    },
    expanded: boolean
};

class ActiveOrder extends Component<PropsType, StateType> {
    state = {
        viewport: {
            width: window.innerWidth
        },
        expanded: false
    };

    baseClassname = "active-order";

    componentDidMount() {
        // set viewport sizes in state to trigger state updates (redirect) on resize
        window.addEventListener("resize", this.handleResizeEvent, false);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResizeEvent, false);
    }

    handleResizeEvent = (e: any) => {
        this.setState({
            viewport: {
                width: window.innerWidth
            }
        });
    };

    toggleActiveOrders = () => {
        this.setState({ expanded: !this.state.expanded });
    };

    renderSingleActiveOrder(): any {
        if (this.props.orders.length === 1) {
            return (
                <div
                    className={`${this.baseClassname}__order-status-container`}
                >
                    <OrderDetails
                        order={this.props.orders[0]}
                        isGuestUser={this.props.session.isGuestUser}
                    />
                </div>
            );
        } else {
            return null;
        }
    }

    renderMultipleActiveOrders(): any {
        if (this.props.orders.length > 1) {
            return (
                <React.Fragment>
                    {/* Desktop close button */}
                    {!responsive.isMobileBreakpoint() ? (
                        <div
                            className={`${
                                this.baseClassname
                            }__close-button-container ${
                                this.state.expanded ? "visible" : ""
                            }`}
                        >
                            <div
                                className={`${this.baseClassname}__close-button`}
                                onClick={this.toggleActiveOrders}
                                role={"button"}
                                aria-label={"Close popup"}
                            >
                                Close
                            </div>
                        </div>
                    ) : null}
                    <div
                        className={
                            !responsive.isMobileBreakpoint()
                                ? this.state.expanded
                                    ? `${this.baseClassname}__track-your-order__desktop hidden`
                                    : `${this.baseClassname}__track-your-order__desktop`
                                : `${this.baseClassname}__track-your-order__mobile`
                        }
                        onClick={this.toggleActiveOrders}
                    >
                        <div
                            className={`${this.baseClassname}__elements-container`}
                        >
                            <div className={`${this.baseClassname}__icon`}>
                                <div className="lds-ring">
                                    <div />
                                    <div />
                                    <div />
                                    <div />
                                </div>
                                <div className="burger-icon" />
                            </div>
                            <div
                                className={`${this.baseClassname}__text-button-container`}
                            >
                                <div className={`${this.baseClassname}__text`}>
                                    track your order
                                </div>
                                <div
                                    className={`${this.baseClassname}__button`}
                                >
                                    {!responsive.isMobileBreakpoint()
                                        ? `${this.props.orders.length} active orders `
                                        : `${this.props.orders.length}`}
                                </div>
                            </div>
                        </div>
                        {this.state.expanded ? (
                            <div
                                role={"button"}
                                aria-label={"Close popup"}
                                className={`${this.baseClassname}__button-icon--close`}
                            />
                        ) : (
                            <div
                                role={"button"}
                                aria-label={"Open popup"}
                                className={`${this.baseClassname}__button-icon--up`}
                            />
                        )}
                    </div>
                    <AnimateHeight
                        // hideOverflow
                        duration={500}
                        timingFunction="cubic-bezier(0.645, 0.045, 0.355, 1)"
                        height={this.state.expanded ? null : 0}
                    >
                        <div
                            style={{
                                opacity: !responsive.isMobileBreakpoint()
                                    ? this.state.expanded
                                        ? 1
                                        : 0
                                    : null
                            }}
                            className={`${this.baseClassname}__order-status-container`}
                        >
                            {this.props.orders.map(
                                (order: any, index: any): any => {
                                    return (
                                        <OrderDetails
                                            key={index}
                                            order={order}
                                            isGuestUser={
                                                this.props.session.isGuestUser
                                            }
                                        />
                                    );
                                }
                            )}
                        </div>
                    </AnimateHeight>
                </React.Fragment>
            );
        } else {
            return null;
        }
    }

    renderActiveOrderContents(): any {
        const shouldShow =
            this.props.orders.length > 0 && !this.props.session.hideActiveOrder;

        return (
            <CSSTransition
                in={shouldShow}
                timeout={Config.pageTransitionDuration}
                classNames="slide-up-down"
                unmountOnExit
            >
                {/* Overlay */}
                <div>
                    <CSSTransition
                        in={this.state.expanded}
                        timeout={1000}
                        classNames={"fade"}
                        mountOnEnter
                        unmountOnExit
                    >
                        <div
                            className={`${this.baseClassname}__overlay--expanded`}
                            onClick={this.toggleActiveOrders}
                        />
                    </CSSTransition>

                    {/* Wrapper */}
                    <div
                        className={
                            responsive.isMobileBreakpoint()
                                ? this.state.expanded
                                    ? `${this.baseClassname}__expanded`
                                    : `${this.baseClassname}`
                                : `${this.baseClassname}`
                        }
                    >
                        {/* Container */}
                        <div
                            className={`${this.baseClassname}__orders-container`}
                        >
                            {this.renderSingleActiveOrder()}
                            {this.renderMultipleActiveOrders()}
                        </div>
                    </div>
                </div>
            </CSSTransition>
        );
    }

    render(): any {
        const mobilePortal = document.querySelector(
            Config.ordersFixedFooterSelector
        );

        // Handle no order state
        // if (orders.length <= 0 || this.props.session.hideActiveOrder) {
        //     return null;
        // }

        return (
            <MobileBreakpointProvider>
                {(isMobile: boolean): any =>
                    isMobile
                        ? mobilePortal
                            ? createPortal(
                                  this.renderActiveOrderContents(),
                                  mobilePortal
                              )
                            : null
                        : this.renderActiveOrderContents()
                }
            </MobileBreakpointProvider>
        );
    }
}

const mapStateToProps = (state: any): any => ({
    orders: state.orders.active.filter(orderUtils.isDeliveryOrder),
    hideActiveOrder: state.session.hideActiveOrder,
    session: state.session
});

export default connect(
    mapStateToProps,
    {}
)(ActiveOrder);
