// @flow

import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "@utils/hooks/withRouter";
import { Helmet } from "react-helmet";

import Footer from "@components/organisms/Footer";
import TextButton from "@components/atoms/TextButton";
import MappedMetaTags from "@containers/MappedMetaTags";

const { Fragment } = React;

type LinkType = {
    title: string,
    href: string,
    target: ?string
};

type PropsType = {
    title: string,
    links?: LinkType[],
    hideFooter: boolean
};

const PageNotFound = (props: PropsType): React.Node => {
    const { links = [], hideFooter } = props;
    return (
        <div
            className={`not-found ${hideFooter ? "not-found--no-footer" : ""}`}
        >
            <div className="not-found__inner">
                <MappedMetaTags
                    defaultTitle={props.title || "Page not found"}
                />

                <Helmet>
                    <meta name="prerender-status-code" content="404" />
                </Helmet>

                <div className="nav-primary-pad">
                    <div className="container-fluid-xxsmall">
                        <h1 className="heading-3 not-found__code">Error 404</h1>
                        <h2 className="heading-1 not-found__title">
                            {props.title}
                        </h2>
                        <p className="page-intro">
                            You might have followed an old link, or maybe the
                            page was moved.
                            {links.length > 0 ? (
                                <Fragment>
                                    <br />
                                    Try one of these links instead:
                                </Fragment>
                            ) : null}
                        </p>
                        {links.length > 0 ? (
                            <nav className="not-found__links">
                                <ul>
                                    {links.map(
                                        (
                                            link: LinkType,
                                            index: number
                                        ): React.Node => (
                                            <li key={index}>
                                                <TextButton
                                                    styletype="big"
                                                    to={link.href}
                                                    target={link.target}
                                                >
                                                    {link.title}
                                                </TextButton>
                                            </li>
                                        )
                                    )}
                                </ul>
                            </nav>
                        ) : null}
                    </div>
                </div>
            </div>
            {!props.hideFooter ? <Footer /> : null}
        </div>
    );
};

const mapStateToProps = (state: any): any => {
    return {
        navigator: state.navigator
    };
};

const mapDispatchToProps = (dispatch: any): any => {
    return {};
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(PageNotFound)
);
