// @flow

import * as React from "react";
import moment from "moment";
import { connect } from "react-redux";
import JSBarcode from "jsbarcode";
import _ from "lodash";
import { push } from "@lagunovsky/redux-react-router";

import Config from "@Config";
import actions from "@actions";
import ApiFactory from "@apis/APIFactory";
import Button from "@components/atoms/Button";
import CompCountdown from "@components/atoms/CompCountdown";
import TextButton from "@components/atoms/TextButton";
import ProductImage from "@components/atoms/ProductImage";
import * as GoogleAnalytics from "@utils/GoogleAnalytics";
import FeatureFlags from "@FeatureFlags";

import { type VoucherType, type ProfileType } from "@SharedTypes";

const { useEffect, useState, useRef } = React;

type PropsType = {
    voucher: VoucherType,
    imageBasePath: string,
    user: ProfileType,
    active: boolean,
    // onAddToCart: () => void,
    onClose: () => void,
    isComp?: boolean,
    compImageBasePath: string,
    compCodeStores: any,
    paymentSupport: any,
    applyCompCode: any,
    addVoucher: any,
    push: any,
    storeId: any,
    openAlertModal: any,
    closeAlertModal: any,
    closeVoucherModal: any,
    coffeeLoyaltyCta?: any,
    showBarcodeCta?: any,
    isCMMiscEnabled?: boolean,
    isClutchEnabled?: boolean,
};

const VoucherDetail = ({
    voucher,
    imageBasePath,
    compImageBasePath,
    user,
    active,
    isComp,
    // onAddToCart = () => {},
    onClose = () => {},
    compCodeStores,
    paymentSupport,
    applyCompCode,
    addVoucher,
    push,
    storeId,
    openAlertModal,
    closeAlertModal,
    closeVoucherModal,
    coffeeLoyaltyCta,
    showBarcodeCta,
    isCMMiscEnabled,
    isClutchEnabled,
}: PropsType): React.Node => {
    const barcodeRef = useRef(null);
    const [showBarcode, setShowBarcode] = useState(false);

    // Render the barcode
    useEffect(() => {
        if (barcodeRef.current) {
            try {
                JSBarcode(barcodeRef.current, user.loyalty_card_no, {
                    width: 2,
                    height: 110,
                    font: "Roboto",
                    fontSize: 16,
                    margin: 0,
                    marginTop: 16,
                    marginBottom: 16,
                    textMargin: 10
                });
            } catch (error) {
                console.log("JSBarcode Error: ", error);
            }
        }
    }, [barcodeRef.current, user.loyalty_card_no]);

    // Auto close the barcode when the component is not active
    useEffect(() => {
        if (!active) {
            setShowBarcode(false);
        }
    }, [active]);

    const handleAddToCart = () => {
        GoogleAnalytics.logEvent({
            category: "Vouchers",
            action: "Redeem App",
            label: "Voucher_Details",
            value: voucher.id
        });

        // To do - add voucher title
        window.dataLayer.push({
            event: "offer_redeem_app",    // hardcoded value
            voucher_title: voucher.name   // dynamic value
          });

        // check if payment method constrain exists in support mapping
        let voucherConstraintsupported =
            voucher.payment_methods_constraints &&
            voucher.payment_methods_constraints.length
                ? false
                : true;

        if (voucher.payment_methods_constraints) {
            voucher.payment_methods_constraints.forEach(
                (paymentMethod: any) => {
                    if (paymentSupport[paymentMethod]) {
                        voucherConstraintsupported =
                            paymentSupport[paymentMethod];
                    }
                }
            );
        }

        // payment methods support check
        if (voucherConstraintsupported) {
            // voucher constraints supported

            if (isComp && voucher.code) {
                applyCompCode(voucher.code)
                    .then(result => {
                        if (result) {
                            push(`${Config.routes.menu}/${storeId}`);
                        }
                    })
                    .catch(error => {
                        openAlertModal({
                            title: error.title || "Unable to use promotion",
                            body: (
                                <p>
                                    {error.friendly_message ||
                                        "Invalid promo code"}
                                </p>
                            ),
                            onConfirm: () => {
                                closeAlertModal();
                            }
                        });
                    });
            } else {
                addVoucher(voucher);
            }
        } else {
            // no support for voucher payment constraints
            const unsupportedPaymentConstraints = _.filter(
                Config.PaymentOptions,
                (paymentOption: any): any => {
                    if (voucher && voucher.payment_methods_constraints) {
                        if (
                            voucher.payment_methods_constraints.includes(
                                paymentOption.constraint
                            )
                        ) {
                            return true;
                        } else {
                            return false;
                        }
                    } else {
                        return true;
                    }
                }
            );

            openAlertModal({
                title: (
                    <>
                        {unsupportedPaymentConstraints.map(
                            (paymentOption: any): any => {
                                return paymentOption.text + " ";
                            }
                        )}{" "}
                        not available
                    </>
                ),
                body: (
                    <p>
                        To be eligible for this promotion, you must pay with{" "}
                        {unsupportedPaymentConstraints.map(
                            (paymentOption: any): any => {
                                return paymentOption.text + " ";
                            }
                        )}
                        . Please use a device with{" "}
                        {unsupportedPaymentConstraints.map(
                            (paymentOption: any): any => {
                                return paymentOption.text + " ";
                            }
                        )}
                        to continue
                    </p>
                ),
                onConfirm: () => {
                    closeAlertModal();
                }
            });
        }

        // hide voucher modal
        closeVoucherModal();
    };

    const handleOpenBarcode = () => {
        GoogleAnalytics.logEvent({
            category: "Vouchers",
            action: "Redeem Store",
            label: "Voucher_Details",
            value: voucher.id
        });

        // To do - add voucher title
        window.dataLayer.push({
            event: "offer_redeem_store",    // hardcoded value
            voucher_title: voucher.name   // dynamic value
          });

        setShowBarcode(true);

        !isClutchEnabled &&
            ApiFactory.VoucherAPI.undeferVoucher(`${voucher.id}`).catch(err => {
                openAlertModal({
                    title: err.title || "Something went wrong",
                    body: err.friendly_message || (
                        <p>Sorry, you can't use this voucher at the moment.</p>
                    ),
                    confirmText: "OK",
                    onConfirm: () => {
                        handleCloseBarcode();
                        closeAlertModal();
                    }
                });
            });
    };

    const handleCloseBarcode = () => {
        setShowBarcode(false);
        !isClutchEnabled &&
            ApiFactory.VoucherAPI.deferVoucher(`${voucher.id}`);
    };

    const handleCMLoyalty = () => {
        openAlertModal({
            title: "Sorry, redemption is only available at cashier",
            body: <p>Please show your voucher barcode at the cashier to redeem</p>,
            confirmText: "OK",
            onConfirm: () => {
                closeAlertModal();
            }
        });
    }

    if (!voucher) {
        return null;
    }

    return (
        <div className="voucher-detail">
            <div className="voucher-detail__main">
                <div className="voucher-detail__image">
                    <ProductImage
                        src={`${
                            isComp
                                ? compImageBasePath
                                : imageBasePath && !voucher.coffeeLoyaltyCta
                                ? imageBasePath
                                : ""
                        }${voucher && voucher.image}`}
                        alt={`${voucher && voucher.name}`}
                    />
                    {isComp && voucher.code && (
                        <div className={`voucher-detail__comp`}>
                            <div className={`voucher-detail__comp__left`}>
                                <div>Online Exclusive</div>
                                <div className={`voucher-detail__comp__code`}>
                                    {voucher.code}
                                </div>
                            </div>
                            <CompCountdown
                                showCountdown={voucher.show_countdown}
                                expiry={
                                    voucher.expiry || voucher.expiration_date
                                }
                            />
                        </div>
                    )}
                </div>
                <div className="voucher-detail__content">
                    {!isComp && (
                        <p>
                            <strong>
                                {voucher.title ? (
                                    voucher.title
                                ) : voucher && voucher.expiration_date ? (
                                    <>
                                        Offer expires{" "}
                                        {moment(voucher.expiration_date).format(
                                            "Do MMM YYYY"
                                        )}{" "}
                                    </>
                                ) : null}
                            </strong>
                        </p>
                    )}

                    <p className="body-small">
                        {voucher.terms_conditions ||
                            `Not valid with any other offer. One voucher per
                                customer, per transaction only. Voucher needs to be
                                scanned at the time of purchase to be redeemed.
                                Valid only at participating Hungry Jack’s
                                restaurants.`}
                    </p>

                    {voucher.termsOnClick ? (
                        <p className="body-small">
                            <button
                                onClick={voucher.termsOnClick}
                                className="anchor"
                            >
                                View full terms & conditions
                            </button>
                        </p>
                    ) : null}

                    {_.isArray(compCodeStores) && (
                        <p className="body-small">
                            {`\n\nValid at following stores: ${compCodeStores
                                .map((store: any): any => store.name)
                                .join(", ")}`}
                        </p>
                    )}
                </div>
            </div>
            <div className="voucher-detail__footer">
                {voucher.showBarcodeCta && (
                    <>
                        <Button onClick={voucher.showBarcodeCta.onClick} className="hide-desktop">
                            {voucher.showBarcodeCta.text}
                        </Button>
                    </>
                )}
                {voucher.coffeeLoyaltyCta ? (
                    <>
                        <Button onClick={voucher.coffeeLoyaltyCta.onClick}>
                            {voucher.coffeeLoyaltyCta.text}
                        </Button>
                    </>
                ) : (
                    <>
                        {voucher.channels.includes("Web") && (
                            isCMMiscEnabled ? (
                                <Button onClick={handleCMLoyalty} styleType={"disabled"}>
                                    Add to cart
                                </Button>
                            ) : (
                                <Button onClick={handleAddToCart}>
                                    Add to cart
                                </Button>
                            )
                        )}
                        {voucher.channels.includes("Store") && !isComp && (
                            <Button onClick={handleOpenBarcode}>
                                Redeem at Cashier
                            </Button>
                        )}
                    </>
                )}
            </div>
            <div
                className={`voucher-detail__barcode ${
                    showBarcode ? "is-active" : ""
                }`}
            >
                <p className="voucher-detail__barcode__heading">
                    Scan barcode at cashier
                </p>
                <svg id="barcode" ref={barcodeRef} />
                <TextButton onClick={handleCloseBarcode}>Cancel</TextButton>
            </div>
        </div>
    );
};

const mapStateToProps = (state: any, ownProps: any): any => {
    return {
        user: state.user,
        imageBasePath: state.vouchers.image_base_path,
        compImageBasePath: state.vouchers.comps_image_base_path,
        compCodeStores: _.isArray(
            _.get(ownProps, "voucher.stores_ids_constraints")
        )
            ? _.compact(
                  ownProps.voucher.stores_ids_constraints.map((id: any): any =>
                      _.find(state.stores.list, { store_id: id })
                  )
              )
            : null,
        paymentSupport: state.device.paymentSupport,
        storeId: _.get(state, "session.selectedStore.store_id", 0),
        isCMMiscEnabled:
            state.launchDarkly.flags[FeatureFlags.IS_CM_MVP_MISC_ENABLED],
        isClutchEnabled:
            state.launchDarkly.flags[FeatureFlags.IS_CLUTCH_ENABLED],
    };
};

export default connect(mapStateToProps, {
    applyCompCode: actions.cart.applyCompCode,
    addVoucher: actions.vouchers.voucherDeeplink,
    openAlertModal: actions.globalModals.openAlertModal,
    closeAlertModal: actions.globalModals.closeAlertModal,
    closeVoucherModal: actions.globalModals.closeVoucherModal,
    push: push
})(VoucherDetail);
