// @flow

import React, { useState } from "react";
import { connect } from "react-redux";
import { push } from "@lagunovsky/redux-react-router";

import _ from "lodash";
import Config from "@Config";
import actions from "@actions";
import { formatMoney, responsive } from "@utils";
import { Row, Col } from "@containers/Grid";
import Button from "@components/atoms/Button";

type PropsType = {
    className?: string,
    product: any,
    addItemToCart: actions.cart.addItemToCart,
    push: push
};

const addToCartFlyoutAnimation = (addToCartButtonEl: any): any => {
    // add to cart flyout animation

    const animatedButtonEl = document.createElement("div");
    const bodySelector = document.body;

    // get correct destination container using breakpoint checks
    const cartContainerEl = responsive.isBreakpoint("sm")
        ? document.querySelector(".mobile-order-header__cart-status") // mobile header cart button
        : responsive.isBreakpoint("lg")
        ? document.querySelector(".cart-items") // desktop side cart
        : document.querySelector(".nav-primary__cart-status .rounded-button"); // tablet cart indicator;

    if (!cartContainerEl) {
        return false;
    }

    animatedButtonEl.classList.add("cart-flyout-animation");

    // set css attribute scoping for non jsx elements
    if (process.env.SCOPE_ATTRIBUTE) {
        animatedButtonEl.setAttribute(process.env.SCOPE_ATTRIBUTE, "");
    }

    // set intial positioning
    animatedButtonEl.style.top = `${addToCartButtonEl.getBoundingClientRect()
        .top +
        addToCartButtonEl.clientHeight / 2}px`;
    animatedButtonEl.style.left = `${addToCartButtonEl.getBoundingClientRect()
        .left +
        addToCartButtonEl.clientWidth / 2}px`;

    if (bodySelector) {
        // append animated cart indicator
        bodySelector.appendChild(animatedButtonEl);

        if (cartContainerEl) {
            setTimeout(() => {
                // set destination position
                animatedButtonEl.style.top = `${
                    cartContainerEl.getBoundingClientRect().bottom
                }px`;
                animatedButtonEl.style.left = `${cartContainerEl.getBoundingClientRect()
                    .left +
                    cartContainerEl.clientWidth / 2}px`;

                // set active class
                animatedButtonEl.classList.add("is-active");

                setTimeout(() => {
                    bodySelector.removeChild(animatedButtonEl);
                }, 1500);
            }, 0);
        }
    }
};

const AddToCartFooter = (props: PropsType): any => {
    // useState for addToCartSuccess
    const [success, setSuccess] = useState(false);

    const handleAddtoCartClick = (event: any, product: any) => {
        const addToCartButtonEl = event.target;
        props.addItemToCart(product).then(() => {
            // set/reset add to cart button success state
            setSuccess(true);
            setTimeout(() => {
                setSuccess(false);
            });

            if (responsive.isMobileBreakpoint()) {
                // redirect to cart for mobile
                setTimeout(() => {
                    props.push(
                        _.get(props, "product.parentCategory.href") ||
                            Config.routes.cart
                    );
                }, 750);
            } else {
                // trigger cart flyout animtion indicator
                addToCartFlyoutAnimation(addToCartButtonEl);
            }
        });
    };

    return (
        <div className={`mobile-product-footer ${props.className || ""}`}>
            <Row className="between-xs middle-xs">
                <Col xs={6}>
                    <div className="product-detail__totals">
                        {/*Product configuration totals*/}
                        {`${formatMoney(props.product.price)} / ${props.product.energy_kj}kJ`}
                    </div>
                </Col>
                <Col xs={6}>
                    {/*Add to cart/update cart button*/}
                    <Button
                        size="large"
                        success={success}
                        onClick={(e: any): any =>
                            handleAddtoCartClick(e, props.product)
                        }
                    >
                        {props.product.line_item_number
                            ? "Update Cart"
                            : "Add to cart"}
                    </Button>
                </Col>
            </Row>
        </div>
    );
};

const mapStateToProps = (state: any): any => {
    return {
        product: state.product
    };
};

const mapDispatchToProps = {
    addItemToCart: actions.cart.addItemToCart,
    push: push
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddToCartFooter);
