// @flow

import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { push } from "@lagunovsky/redux-react-router";
import { ReactForm, ReactFormField } from "@adrenalin/react-form";
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

import { createPortal } from "react-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { cookie } from "@utils";

import Config from "@Config";
import actions from "@actions";
import { pubsub } from "@utils";
import ApiFactory from "@apis/APIFactory";
// import GlobalModals from "../constants/GlobalModals";
import { Row, Col } from "@containers/Grid";
import GuestForm from "@components/organisms/GuestForm";
import Footer from "@components/organisms/Footer";
import CheckoutHero from "@components/molecules/CheckoutHero";
import RoundedButton from "@components/atoms/RoundedButton";
import OrderSummary from "@components/molecules/OrderSummary";
import Icon from "@components/atoms/Icon";
import LoaderCover from "@components/atoms/LoaderCover";
import StaticGoogleMap from "@components/atoms/StaticGoogleMap";
import ServerErrorMessage from "@components/atoms/ServerErrorMessage";
import AdyenCheckout from "@components/molecules/AdyenCheckout";
import MappedMetaTags from "@containers/MappedMetaTags";
// import * as GoogleAnalytics from "@utils/GoogleAnalytics";
import PoweredByMenulog from "@components/atoms/PoweredByMenulog";
import CheckoutSubmitButton from "@components/atoms/CheckoutSubmitButton";

import { type CartType, type ProfileType } from "@SharedTypes";

import FeatureFlags from "../constants/FeatureFlags";

gsap.registerPlugin(ScrollToPlugin);

type PropsType = {
    user: ProfileType,
    cart: CartType,
    session: any,
    adyen: any,
    dispatch: any,
    categories: any,
    menu: any,
    push: typeof push,
    createOrder: typeof actions.cart.createOrder,
    confirmOrder: typeof actions.orders.confirmOrder,
    openGlobalModal: typeof actions.globalModals.openGlobalModal,
    resetGuestUser: typeof actions.user.resetGuestUser,
    addSavedAddress: typeof actions.savedAddresses.addSavedAddress,
    updateSavedAddress: typeof actions.savedAddresses.updateSavedAddress,
    register: typeof actions.user.register,
    getInfo: typeof actions.user.getInfo,
    openAlertModal: typeof actions.user.openAlertModal,
    closeAlertModal: typeof actions.user.closeAlertModal,
    isAccountCreationDisabled?: boolean
};

type StateType = {
    loading?: boolean,
    error?: ?string,
    canPay: boolean,
    firstInvalidInput: any,
    adyenKey: number
};

class Checkout extends Component<PropsType, StateType> {
    state = {
        loading: false,
        error: null,
        canPay: false,
        firstInvalidInput: null,
        adyenKey: 0
    };
    hasTouchedDeliveryNotes = false;

    recaptchaRef = React.createRef<any>();
    checkoutFormRef = React.createRef<any>();
    adyenCheckoutRef = React.createRef<any>();
    isGuestCheckout;
    confirmObject;
    payPalElement;

    componentDidMount() {
        const localOrder = localStorage.getItem("tempOrder");
        if (window.location.search && localOrder) {
            const search = window.location.search.substring(1);
            const data = JSON.parse(
                '{"' +
                    decodeURIComponent(
                        search
                            .replace(/"/g, '\\"')
                            .replace(/&/g, '","')
                            .replace(/=/g, '":"')
                    ) +
                    '"}'
            );
            this.confirmObject = JSON.parse(localOrder);
            ApiFactory.CardAPI.provideDetails(this.confirmObject.order_id, {
                paymentData: localStorage.getItem("tempData"),
                details: data
            })
                .then((): any => {
                    this.submitOrder(this.confirmObject);
                    localStorage.removeItem("tempOrder");
                    localStorage.removeItem("tempData");
                })
                .catch(err => {
                    this.props.openAlertModal({
                        title: err.title || "Something went wrong",
                        body: (
                            <p>
                                {err.friendly_message ||
                                    err.message ||
                                    "Sorry, we couldn't proceed your payment"}
                            </p>
                        ),
                        confirmText: "OK",
                        onConfirm: () => {
                            this.props.closeAlertModal();
                        }
                    });
                    if (
                        this.props.session.isAuthenticated ||
                        this.props.session.isGuestUser
                    ) {
                        this.processCheckoutCart();
                    }

                    // subscribe to checkout form submit event to trigger submit method and react form ref
                    pubsub.subscribe("CHECKOUT_FORM_SUBMIT", () => {
                        if (this.checkoutFormRef.current) {
                            this.checkoutFormRef.current.submit();
                        }
                    });
                });
            return;
        } else {
            localStorage.removeItem("tempOrder");
            localStorage.removeItem("tempData");
        }
        // process order on mount if user or guest session
        if (
            this.props.session.isAuthenticated ||
            this.props.session.isGuestUser
        ) {
            this.processCheckoutCart();
        }

        // subscribe to checkout form submit event to trigger submit method and react form ref
        pubsub.subscribe("CHECKOUT_FORM_SUBMIT", () => {
            if (this.checkoutFormRef.current) {
                this.checkoutFormRef.current.submit();
            }
        });

        window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object
        window.dataLayer.push({
            event: "begin_checkout",
            ecommerce: {
                currency: "AUD", 
                value: this.props.cart.total_amount,
                coupon: this.props.cart.comp_code,
                discount: this.props.cart.discounts_total,
                items: 
                    this.props.cart.line_items.map((item, i): any => {
                        const parentCategory =
                            item.menuLink &&
                            _.find(this.props.categories, {
                                category_id: Number(
                                    item.menuLink.split("/")[3]
                                )
                            });
                        
                        let customised = false;
                        item.sales_items.forEach((line): any => {
                            if (!_.isEmpty(line.modifiers))
                                customised = true
                        })

                        const reorderItem = this.props.menu.menu_items.filter(m => m.menu_item_id === item.menu_item_id && m.combo_level === item.combo_level);

                        return {
                            item_id: (item.promo_id || item.pos_id || item.pos_item_id),
                            item_name: item.menu_item_name,
                            coupon: item.voucher_id ? "Reward Voucher" : item.comp_code ? item.comp_code : "",
                            discount: item.priceDiff || 0,
                            index: i,
                            item_category:  _.get(parentCategory, "name") || "",
                            item_category2: item.is_reorder ? "Reorder" : item.isUpsell ? "Upsell" : customised ? "Customised" : "",
                            item_variant: item.item_variant ? item.item_variant : _.isEmpty(reorderItem) ? "Item Only" : reorderItem[0].option_name,
                            line_items: [item.sales_items.map((line): any => {
                                return `${line.sales_item_name}${!_.isEmpty(line.modifiers) ?
                                    ` (${line.modifiers.map((mod, i): any => {
                                        return `+1 ${mod.modifier_name}${line.modifiers.length - 1 > i ? `, ` : ``}`
                                    })})` : ''
                                }`
                            })].join(`, `),
                            price: item.defaultComboPrice || item.price,
                            quantity: item.quantity
                        }
                    })
            }
        });

    }

    handleGuestCheckoutComplete() {
        // successful guest login, process and create the order (/checkout api)
        this.processCheckoutCart();
    }

    handleCheckoutFormSubmit(formData: any) {
        // Fires from ReactForm onSubmit
        // - Main checkout form submit handler for logged in and guest users on final checkout stage

        const orderID = _.get(this, "props.cart.order_id");

        // fill contact name and number from form or user/guest object
        formData.first_name =
            formData.first_name ||
            `${this.props.user.first_name} ${this.props.user.last_name}`;
        formData.mobile_number =
            formData.mobile_number || this.props.user.phone;

        // prepare confirm payload object
        this.confirmObject = {
            order_id: orderID,
            delivery_details: formData,
            order_mode: "delivery"
        };

        if (orderID) {
            if (this.adyenCheckoutRef.current) {
                this.adyenCheckoutRef.current.submit();
                this.setState({
                    loading: true
                });
            }
        }
    }

    handleAdyenOnLoad() {
        this.payPalElement = document.getElementsByClassName(
            "adyen-checkout__payment-method--paypal"
        )[0];
        if (this.checkoutFormRef.current) {
            this.checkoutFormRef.current.validateForm(false);
            const inputs = _.get(this.checkoutFormRef, "current.state.inputs");
            let firstInvalidInput = null;
            for (let input of inputs) {
                if (!input.valid) {
                    firstInvalidInput = input;
                    break;
                }
            }
            this.setState({
                firstInvalidInput
            });
        }
    }

    async handleAdyenOnSubmit(paymentData: any, dropin: any): any {
        if (!this.confirmObject) {
            if (this.checkoutFormRef.current) {
                const formRef = this.checkoutFormRef.current;
                formRef.validateForm(true);
                if (formRef.formValid) {
                    const formData = formRef.prepareFormData();
                    const orderID = _.get(this, "props.cart.order_id");
                    // fill contact name and number from form or user/guest object
                    formData.first_name =
                        formData.first_name ||
                        `${this.props.user.first_name} ${this.props.user.last_name}`;
                    formData.mobile_number =
                        formData.mobile_number || this.props.user.phone;

                    // prepare confirm payload object
                    this.confirmObject = {
                        order_id: orderID,
                        delivery_details: formData,
                        order_mode: "delivery"
                    };
                } else {
                    return;
                }
            }
        }

        const skipReCaptcha = cookie.get("skipReCaptcha") === "true";
        let adyenPaymentRecaptcha;
        let guestUserSignupRecaptcha;

        if (!skipReCaptcha) {
            if (this.recaptchaRef.current) {
                this.recaptchaRef.current.reset();
                adyenPaymentRecaptcha = await this.recaptchaRef.current.executeAsync();
                
                this.recaptchaRef.current.reset();
                guestUserSignupRecaptcha = await this.recaptchaRef.current.executeAsync();
            } else {
                return;
            }
        }

        if (!adyenPaymentRecaptcha && !skipReCaptcha) {
            return;
        }

        return ApiFactory.CardAPI.payOrder(
            this.confirmObject.order_id,
            this.props.cart.user_id,
            paymentData.data,
            adyenPaymentRecaptcha,
            skipReCaptcha
        )
            .then((res): any => {
                window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object
                window.dataLayer.push({
                    event: "add_payment_info",
                    ecommerce: {
                        currency: "AUD", 
                        value: this.props.cart.total_amount,
                        coupon: this.props.cart.comp_code,
                        payment_type: /\d/.test(this.props.session.paymentMethodName) ? "Credit Card" : this.props.session.paymentMethodName,
                        discount: this.props.cart.discounts_total,
                        items: 
                            this.props.cart.line_items.map((item, i): any => {
                                const parentCategory =
                                    item.menuLink &&
                                    _.find(this.props.categories, {
                                        category_id: Number(
                                            item.menuLink.split("/")[3]
                                        )
                                    });
                                
                                let customised = false;
                                item.sales_items.forEach((line): any => {
                                    if (!_.isEmpty(line.modifiers))
                                        customised = true
                                })

                                return {
                                    item_id: (item.promo_id || item.pos_id),
                                    item_name: item.menu_item_name,
                                    coupon: item.voucher_id ? "Reward Voucher" : item.comp_code ? item.comp_code : "",
                                    discount: item.priceDiff,
                                    index: i,
                                    item_category:  _.get(parentCategory, "name"),
                                    item_category2: item.is_reorder ? "Reorder" : item.isUpsell ? "Upsell" : customised ? "Customised" : "",
                                    item_variant: item.item_variant,
                                    line_items: [item.sales_items.map((line): any => {
                                        return `${line.sales_item_name}${!_.isEmpty(line.modifiers) ?
                                            ` (${line.modifiers.map((mod, i): any => {
                                                return `+1 ${mod.modifier_name}${line.modifiers.length - 1 > i ? `, ` : ``}`
                                            })})` : ''
                                        }`
                                    })].join(`, `),
                                    price: item.defaultComboPrice,
                                    quantity: item.quantity
                                }
                            })
                    }
                });

                if (res.action) {
                    if (res.action.type === "redirect") {
                        localStorage.setItem(
                            "tempOrder",
                            JSON.stringify(this.confirmObject)
                        );
                        localStorage.setItem(
                            "tempData",
                            res.action.paymentData
                        );
                        if (this.props.user.is_guest_user) {
                            localStorage.setItem(
                                "tempUser",
                                JSON.stringify(this.props.user)
                            );
                            localStorage.setItem(
                                "tempSession",
                                JSON.stringify(this.props.session)
                            );
                        }
                    } else if (res.action.type === "threeDS2Challenge") {
                        document.getElementsByClassName(
                            "adyen-checkout"
                        )[0].style.zIndex = "9999"; // user needs to interact with adyen iframe, bring it on top of overlay
                    }
                    dropin.handleAction(res.action);
                } else {
                    this.submitOrder(this.confirmObject, guestUserSignupRecaptcha, skipReCaptcha);
                }
            })
            .catch(error => {
                this.setState({
                    loading: false,
                    error: error.friendly_message || "Something went wrong"
                });
            });
    }

    async handleAdyenOnDetailProvide(data: any, dropin: any): any {

        const skipReCaptcha = cookie.get("skipReCaptcha") === "true";
        let guestUserSignupRecaptcha;

        if (!skipReCaptcha) {
            if (this.recaptchaRef.current) {                
                this.recaptchaRef.current.reset();
                guestUserSignupRecaptcha = await this.recaptchaRef.current.executeAsync();
            } else {
                return;
            }
        }

        if (!guestUserSignupRecaptcha && !skipReCaptcha) {
            return;
        }

        return ApiFactory.CardAPI.provideDetails(
            this.confirmObject.order_id,
            data.data
        )
            .then((res): any => {
                if (res.action) {
                    if (res.action.type === "redirect") {
                        localStorage.setItem(
                            "tempOrder",
                            JSON.stringify(this.confirmObject)
                        );
                        localStorage.setItem(
                            "tempData",
                            res.action.paymentData
                        );
                        if (this.props.user.is_guest_user) {
                            localStorage.setItem(
                                "tempUser",
                                JSON.stringify(this.props.user)
                            );
                            localStorage.setItem(
                                "tempSession",
                                JSON.stringify(this.props.session)
                            );
                        }
                    } else if (res.action.type === "threeDS2Challenge") {
                        document.getElementsByClassName(
                            "adyen-checkout"
                        )[0].style.zIndex = "9999"; // user needs to interact with adyen iframe, bring it on top of overlay
                    }
                    dropin.handleAction(res.action);
                } else {
                    // this.submitOrder(this.confirmObject);
                    this.submitOrder(this.confirmObject, guestUserSignupRecaptcha, skipReCaptcha);
                }
            })
            .catch(error => {
                this.setState({
                    loading: false,
                    error: error.friendly_message || "Something went wrong"
                });
                this.setState({ adyenKey: this.state.adyenKey + 1 });
            });
    }

    handleAdyenOnError(err: any) {
        gsap.to(window, {
            scrollTo: "#checkout-payment",
            duration: 0.5
        });
        this.setState({
            loading: false
        });
    }

    processCheckoutCart() {
        if (this.props.cart.line_items.length) {
            this.setState({
                loading: true
            });

            // get updated user info (used to get updated email verification status)
            this.props.getInfo().then(() => {
                // create order/call checkout api
                this.props
                    .createOrder()
                    .then(() => {
                        window.dataLayer.push({
                            event: "checkout",
                            ecommerce: {
                                checkout: {
                                    actionField: {
                                        step: 2,
                                        option: "Checkout Pageload"
                                    }
                                }
                            }
                        });
                        window.dataLayer.push({
                            cartValue:
                                this.props.cart.total_amount -
                                (this.props.cart.delivery_cost || 0),
                            productIds: this.props.cart.line_items.map(
                                (item: any): any => {
                                    return item.promo_id || item.pos_item_id;
                                }
                            ),
                            storeId: this.props.cart.store_id
                        });
                        this.setState({
                            loading: false,
                            canPay: true
                        });
                    })
                    .catch((error: any) => {
                        this.setState({
                            loading: false,
                            error:
                                error.friendly_message || "Something went wrong"
                        });
                    });
            });
        } else {
            // no cart items, redirect to landing page
            this.props.push("/");
        }
    }

    submitOrder(confirmPayload: any, guestUserSignupRecaptcha: any, skipReCaptcha: boolean): Promise<any> {
        this.setState({
            loading: true
        });

        const isForLater = !!this.props.cart.requested_delivery_datetime;

        return this.props
            .confirmOrder(confirmPayload)
            .then((res: any): any => {
                const deliveryLocation = _.get(
                    this,
                    "props.session.deliveryLocation"
                );

                if (confirmPayload.delivery_details.address_nickname) {
                    // save/update address to user

                    if (deliveryLocation.isSavedAddress) {
                        // update existing user saved delivery address
                        this.props.updateSavedAddress(
                            deliveryLocation.address.record_id,
                            confirmPayload.delivery_details.address_nickname,
                            confirmPayload.delivery_details.delivery_notes,
                            confirmPayload.delivery_details.unit_number,
                            deliveryLocation.address_id
                        );
                    } else {
                        // save new user delivery address
                        this.props.addSavedAddress(
                            deliveryLocation.record_id,
                            confirmPayload.delivery_details.address_nickname,
                            confirmPayload.delivery_details.delivery_notes,
                            confirmPayload.delivery_details.unit_number
                        );
                    }
                }
                // successful order
                // if password in user state object then register guest user
                if (this.props.user.password) {
                    // register as a HJ user as well
                    this.props
                        .register(
                            {
                                first_name: this.props.user.first_name,
                                last_name: this.props.user.last_name,
                                email:
                                    this.props.user.email ||
                                    this.props.user.guest_email,
                                phone: this.props.user.phone,
                                password: this.props.user.password
                            },
                            false,
                            guestUserSignupRecaptcha,
                            skipReCaptcha
                        )
                        .catch((error: any) => {
                            this.props.openAlertModal({
                                title: "Account not created",
                                body: (
                                    <p>
                                        This email is already registered. Why
                                        not login or recover your password for
                                        next time.
                                    </p>
                                ),
                                confirmText: "OK",
                                onConfirm: () => {
                                    this.props.closeAlertModal();
                                }
                            });
                        });
                } else {
                    // standard guest user with no registration
                    // call user.getInfo to get guest user data from VAR and update braze/appboy attributes
                    this.props.getInfo();
                }

                // redirect to order detail page with order id
                if (this.props.session.isGuestUser && res.order_token) {
                    // redirect guest user to public order detail route
                    this.props.push(
                        `${Config.routes.order}/guest/${res.order_token}${
                            isForLater ? "?thankyou=true" : ""
                        }`
                    );
                } else if (
                    this.props.session.isAuthenticated &&
                    confirmPayload.order_id
                ) {
                    // redirect account user to logged in order detail route
                    this.props.push(
                        `${Config.routes.order}/${confirmPayload.order_id}${
                            isForLater ? "?thankyou=true" : ""
                        }`
                    );
                } else {
                    // handle errors?
                    this.setState({
                        loading: false,
                        error:
                            "Something went wrong whilst confirming your order."
                    });
                }
            })
            .catch((error: any): any => {
                this.setState({
                    loading: false,
                    error:
                        error.friendly_message ||
                        "Something went wrong whilst confirming your order."
                });
            });
    }

    pushGTMEvent = () => {
        if (!this.hasTouchedDeliveryNotes) {
            this.hasTouchedDeliveryNotes = true;
            window.dataLayer.push({
                event: "checkout",
                ecommerce: {
                    checkout: {
                        actionField: {
                            step: 3,
                            option: "Delivery Details"
                        }
                    }
                }
            });
        }
    };

    //
    // Render Methods
    //

    renderDeliveryAddressDetails(): any {
        const isSavedAddress = _.get(
            this,
            "props.session.deliveryLocation.isSavedAddress"
        );
        const deliveryLocation = _.get(this, "props.session.deliveryLocation");

        return (
            <React.Fragment>
                <Row className="checkout__delivery-title">
                    <Col xs={7}>
                        <div className="heading-3">Delivery Details</div>
                    </Col>
                    <Col xs={5}>
                        <PoweredByMenulog />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6} className="">
                        <div className="selected-order-method__detail">
                            <Icon
                                className="selected-order-method__detail__icon"
                                name="map-pin-outline"
                            />
                            <div className="selected-order-method__detail__label">
                                {deliveryLocation.full_address}
                            </div>
                        </div>
                        <ReactFormField
                            type="text"
                            name="unit_number"
                            id="unit_number"
                            label="Unit number / level (Optional)"
                            placeholder="e.g. Unit 10"
                            defaultValue={
                                isSavedAddress
                                    ? deliveryLocation.unit_number
                                    : ""
                            }
                            onChange={this.pushGTMEvent}
                        />
                        <ReactFormField
                            type="textarea"
                            name="delivery_notes"
                            id="delivery_notes"
                            label="Driver Only Notes (Optional)"
                            placeholder="e.g. Beware of dogs"
                            defaultValue={
                                isSavedAddress
                                    ? deliveryLocation.delivery_note
                                    : ""
                            }
                            onChange={this.pushGTMEvent}
                        />
                        {!this.isGuestCheckout ? (
                            <ReactFormField
                                type="text"
                                name="address_nickname"
                                id="address_nickname"
                                label="Address Nickname (Optional)"
                                placeholder="e.g. Home"
                                defaultValue={
                                    isSavedAddress
                                        ? deliveryLocation.nickname
                                        : ""
                                }
                                onChange={this.pushGTMEvent}
                            />
                        ) : null}
                    </Col>
                    <Col xs={12} md={6} className="first-xs last-md">
                        <StaticGoogleMap
                            marker={true}
                            width={660}
                            height={540}
                            lat={_.get(
                                this,
                                "props.session.deliveryLocation.coords.lat"
                            )}
                            lng={_.get(
                                this,
                                "props.session.deliveryLocation.coords.lng"
                            )}
                        />
                    </Col>
                </Row>
            </React.Fragment>
        );
    }

    render(): any {
        this.isGuestCheckout = !this.props.session.isAuthenticated;

        return (
            <div className="checkout">
                <LoaderCover fixed={true} active={this.state.loading} />

                <MappedMetaTags defaultTitle="Checkout" />

                <CheckoutHero>
                    <div className="container-fluid">
                        <CheckoutHero.Title>
                            Review Your Order
                        </CheckoutHero.Title>
                    </div>
                </CheckoutHero>


                <div className="checkout-container">
                    <div className="container-fluid">
                        <Row className="row--no-pad">
                            <Col xs={12} sm={7} md={8}>
                                <div className="content-card content-card--with-sections">
                                    {this.isGuestCheckout &&
                                    !this.props.session.isGuestUser ? (
                                        <div className="content-card__section">
                                            {/* Guest Form */}

                                            <ServerErrorMessage>
                                                {this.state.error}
                                            </ServerErrorMessage>

                                            <div className="heading-3 title">
                                                Your Details
                                            </div>
                                            <p>
                                                So we can contact you about your
                                                order.
                                            </p>
                                            <GuestForm
                                                onComplete={this.handleGuestCheckoutComplete.bind(
                                                    this
                                                )}
                                            />
                                        </div>
                                    ) : (
                                        // Checkout Form (Registered and Guest users)
                                        <>
                                            <ReactForm
                                                ref={this.checkoutFormRef}
                                                onSuccess={this.handleCheckoutFormSubmit.bind(
                                                    this
                                                )}
                                                onUpdate={(_, inputs) => {
                                                    let firstInvalidInput = null;
                                                    for (let input of inputs) {
                                                        if (!input.valid) {
                                                            firstInvalidInput = input;
                                                            break;
                                                        }
                                                    }
                                                    this.setState({
                                                        firstInvalidInput
                                                    });
                                                }}
                                            >
                                                <div className="content-card__section">
                                                    <Row className="title between-xs">
                                                        <Col>
                                                            <div className="heading-3">
                                                                {this
                                                                    .isGuestCheckout
                                                                    ? "Your"
                                                                    : "Contact"}{" "}
                                                                Details
                                                            </div>
                                                        </Col>
                                                        {this.props.session
                                                            .isGuestUser ? (
                                                            <Col>
                                                                <RoundedButton
                                                                    onClick={
                                                                        this.props
                                                                            .resetGuestUser
                                                                    }
                                                                >
                                                                    Edit details
                                                                </RoundedButton>
                                                            </Col>
                                                        ) : null}
                                                    </Row>

                                                    {!this.isGuestCheckout ? (
                                                        <React.Fragment>
                                                            <p>
                                                                If we need to get in
                                                                touch, who should we
                                                                call?
                                                            </p>
                                                            <Row>
                                                                <Col xs={12} sm={6}>
                                                                    <ReactFormField
                                                                        type="text"
                                                                        name="first_name"
                                                                        required={
                                                                            true
                                                                        }
                                                                        validators={[
                                                                            "name"
                                                                        ]}
                                                                        errorMessages={{
                                                                            required:
                                                                                "Please enter your first name."
                                                                        }}
                                                                        label="First Name"
                                                                        defaultValue={
                                                                            this
                                                                                .props
                                                                                .user
                                                                                .first_name
                                                                        }
                                                                    />
                                                                </Col>
                                                                <Col xs={12} sm={6}>
                                                                    <ReactFormField
                                                                        type="tel"
                                                                        name="mobile_number"
                                                                        required={
                                                                            true
                                                                        }
                                                                        validators={[
                                                                            "mobile"
                                                                        ]}
                                                                        errorMessages={{
                                                                            required:
                                                                                "Please enter your mobile number."
                                                                        }}
                                                                        label="Mobile number"
                                                                        defaultValue={
                                                                            this
                                                                                .props
                                                                                .user
                                                                                .phone
                                                                        }
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </React.Fragment>
                                                    ) : (
                                                        <table className="list-table">
                                                            <tbody>
                                                                <tr>
                                                                    <td>Name</td>
                                                                    <td>
                                                                        {
                                                                            this
                                                                                .props
                                                                                .user
                                                                                .first_name
                                                                        }{" "}
                                                                        {
                                                                            this
                                                                                .props
                                                                                .user
                                                                                .last_name
                                                                        }
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Email</td>
                                                                    <td>
                                                                        {this.props
                                                                            .user
                                                                            .email ||
                                                                            this
                                                                                .props
                                                                                .user
                                                                                .unverified_email ||
                                                                            this
                                                                                .props
                                                                                .user
                                                                                .guest_email}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Phone</td>
                                                                    <td>
                                                                        {
                                                                            this
                                                                                .props
                                                                                .user
                                                                                .phone
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    )}
                                                </div>

                                                <div className="content-card__section">
                                                    {this.renderDeliveryAddressDetails()}
                                                </div>

                                                <div
                                                    className="content-card__section"
                                                    id="checkout-payment"
                                                >
                                                    <Row className="between-xs">
                                                        <Col>
                                                            <div className="heading-3 title">
                                                                Payment Details
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    <div className="checkout__payment-methods">
                                                        {this.props.cart.order_id &&
                                                        this.state.canPay ? (
                                                            <React.Fragment>
                                                                <p className="checkout__payment-text">
                                                                    <Icon name="lock-with-hole" />{" "}
                                                                    Your credit card
                                                                    details are
                                                                    securely
                                                                    processed by our
                                                                    payment partner.
                                                                </p>
                                                                <AdyenCheckout
                                                                    key={`${this.state.adyenKey}`}
                                                                    ref={
                                                                        this
                                                                            .adyenCheckoutRef
                                                                    }
                                                                    orderId={
                                                                        this.props
                                                                            .cart
                                                                            .order_id
                                                                    }
                                                                    userId={
                                                                        this.props
                                                                            .cart
                                                                            .user_id
                                                                    }
                                                                    orderAmount={
                                                                        this.props
                                                                            .cart
                                                                            .total_amount
                                                                    }
                                                                    onLoad={this.handleAdyenOnLoad.bind(
                                                                        this
                                                                    )}
                                                                    onSubmit={this.handleAdyenOnSubmit.bind(
                                                                        this
                                                                    )}
                                                                    onProvide={this.handleAdyenOnDetailProvide.bind(
                                                                        this
                                                                    )}
                                                                    onError={this.handleAdyenOnError.bind(
                                                                        this
                                                                    )}
                                                                />
                                                            </React.Fragment>
                                                        ) : null}
                                                    </div>

                                                    <ServerErrorMessage>
                                                        {this.state.error}
                                                    </ServerErrorMessage>

                                                    {/* Main submit/payment buttons */}
                                                    <div className="hide-sm">
                                                        {/* {this.renderSubmitButtons()} */}
                                                        <CheckoutSubmitButton />
                                                    </div>
                                                </div>
                                            </ReactForm>
                                        </>
                                    )}
                                    <ReCAPTCHA
                                        ref={this.recaptchaRef}
                                        size="invisible"
                                        sitekey={Config.googleRecaptchaKey}
                                        badge="bottomright"
                                    />
                                </div>
                            </Col>
                            <Col xs={12} sm={5} md={4}>
                                <OrderSummary />
                            </Col>
                        </Row>
                    </div>
                </div>

                <Footer />
                {this.state.firstInvalidInput &&
                    this.payPalElement &&
                    createPortal(
                        <div
                            className="paypal-overlay"
                            onClick={() => {
                                if (this.checkoutFormRef.current) {
                                    this.checkoutFormRef.current.validateForm(
                                        true
                                    );
                                }
                                const ref = _.get(
                                    this.state.firstInvalidInput,
                                    "ref.current"
                                );
                                const scrollTop =
                                    _.get(
                                        document,
                                        "documentElement.scrollTop"
                                    ) || _.get(document, "body.scrollTop");
                                if (ref && scrollTop) {
                                    const inputOffsetY =
                                        ref.getBoundingClientRect().top +
                                        scrollTop;
                                    window.scrollTo(
                                        0,
                                        inputOffsetY - ref.clientHeight
                                    );
                                }
                            }}
                        ></div>,
                        this.payPalElement
                    )}
            </div>
        );
    }
}

const mapStateToProps = (state: any): any => ({
    user: state.user,
    cart: state.cart,
    session: state.session,
    adyen: state.adyen,
    menu: state.menu,
    categories: state.menu.categories,
    isAccountCreationDisabled:
        state.launchDarkly.flags[FeatureFlags.IS_ACCOUNT_CREATION_DISABLED]
});

const mapDispatchToProps = {
    push: push,
    createOrder: actions.cart.createOrder,
    confirmOrder: actions.orders.confirmOrder,
    openGlobalModal: actions.globalModals.openGlobalModal,
    resetGuestUser: actions.user.resetGuestUser,
    addSavedAddress: actions.savedAddresses.addSavedAddress,
    updateSavedAddress: actions.savedAddresses.updateSavedAddress,
    register: actions.user.register,
    getInfo: actions.user.getInfo,
    openAlertModal: actions.globalModals.openAlertModal,
    closeAlertModal: actions.globalModals.closeAlertModal
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Checkout);
