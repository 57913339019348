// @flow

import _ from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import { responsive } from "@utils";

import Config from "@Config";
import actions from "@actions";

import GlobalModals from "@Modals";
import { Row, Col } from "@containers/Grid";
import Button from "@components/atoms/Button";

type OrderButtonsType = {
    className?: string,
    cart: any,
    menu: any,
    session: any,
    preCheckout: typeof actions.cart.preCheckout,
    openGlobalModal: typeof actions.globalModals.openGlobalModal
};

const OrderButtons = (props: OrderButtonsType): React.Node => {
    const firstCategory = _.find(props.menu.categories, { isHidden: false });
    const selectedStoreId = _.get(props, "session.selectedStore.store_id");

    const handleOrderMoreClick = () => {
        if (!_.get(props, "session.selectedStore.store_id")) {
            props.openGlobalModal({ modalId: GlobalModals.ADDRESS_LOCATOR });
        }
    };

    const handleCheckoutClick = () => {
        props.preCheckout();
    };

    return (
        <div className="order-buttons">
            <Row>
                <Col xs={6}>
                    <Button
                        {...(selectedStoreId
                            ? {
                                  to:
                                      firstCategory
                                          ? `${Config.routes.menu}/${selectedStoreId}/${firstCategory.category_id}`
                                          : `${Config.routes.menu}/${selectedStoreId}`
                              }
                            : { onClick: handleOrderMoreClick })}
                        // to={
                        //     !responsive.isMobileBreakpoint() && firstCategory
                        //         ? `${Config.routes.menu}/${selectedStoreId}/${firstCategory.category_id}`
                        //         : `${Config.routes.menu}/${selectedStoreId}`
                        // }
                        // onClick={handleOrderMoreClick}
                        styleType="clear"
                    >
                        Order More
                    </Button>
                </Col>
                <Col xs={6}>
                    <Button
                        styleType="green"
                        loading={props.cart.isFetching}
                        disabled={
                            !props.cart.line_items.length ||
                            !_.get(props, "session.selectedStore.store_id")
                        }
                        onClick={handleCheckoutClick}
                    >
                        Check out
                    </Button>
                </Col>
            </Row>
        </div>
    );
};

const mapStateToProps = (state: any): any => {
    return {
        cart: state.cart,
        session: state.session,
        menu: state.menu
    };
};

export default connect(
    mapStateToProps,
    {
        preCheckout: actions.cart.preCheckout,
        openGlobalModal: actions.globalModals.openGlobalModal
    }
)(OrderButtons);
