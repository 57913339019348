const home = "/";
const menu = "/menu";
const cart = "/cart";
const checkout = "/checkout";
const account = "/account";
const login = "/login";
const logout = "/logout";
const register = "/register";
const forgotPassword = "/forgot-password";
const resetPassword = "/reset-password";
const emailVerification = "/verify-email";
const emailVerificationEdit = "/verify-email/edit";
const productDeeplink = `/product`;
const editCartNumberQueryParam = `editCartItem`;
const order = "/order";
const orderFeedback = `${order}/feedback`;
const firstOrderFeedback = `/orderfeedback`;
const category = `/category/:id`;
const saveCompCode = "/save-comp-code/:code";
const dumbLink = "/dumb-deep-link";
const addItem = "/add-product/:type/:id";
const addItemOpenCart = "/add-product-open-cart/:type/:id";
const addItemOpenEdit = "/add-product-open-edit/:type/:id";

export default {
    // routes in which to show the side cart
    persistentCartRoutes: [menu, productDeeplink, account],
    // routes to hide the primary nav on
    hideNavRoutes: [
        login,
        register,
        forgotPassword,
        resetPassword,
        emailVerification,
        cart,
        checkout,
        order
    ],
    // routes to hide the active order component
    hideActiveOrderRoutes: [order],
    menuRoutes: [
        menu,
        productDeeplink
    ],
    home,
    menu,
    cart,
    checkout,
    account,
    login,
    logout,
    register,
    forgotPassword,
    resetPassword,
    emailVerification,
    productDeeplink,
    editCartNumberQueryParam,
    emailVerificationEdit,
    order,
    orderFeedback,
    deeplinks: [
        saveCompCode,
        addItem,
        addItemOpenCart,
        addItemOpenEdit,
        firstOrderFeedback,
        category,
        dumbLink
    ]
};
