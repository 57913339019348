export default {
    adyenSDKVersion: "3.8.0", // js web sdk version
    configurationObject: {
        autoFocusOnLoad: true,
        consolidateCards: true,
        context: "test",
        initialPMCount: 5, // Overwrites the initial amount of payment methods shown
        //translations: translationObject,
        //useDefaultCSS: false,
        //allowAddedLocales: false, // Allows you to add a locale
        paymentMethods: {
            card: {
                sfStyles: {
                    base: {
                        fontSize: "16px",
                        fontFamily: `Roboto, apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;`,
                        fontWeight: "normal"
                    },

                    error: {
                        color: "#940012"
                    },

                    placeholder: {
                        color: "#979797"
                    },

                    validated: {
                        color: "#62a744"
                    }
                }
                // separateDateInputs: false,
                // placeholders: {
                //     encryptedSecurityCode: "737",
                //     encryptedExpiryDate: "10/20"
                // }
            }
        }
    }
};
