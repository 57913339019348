// @flow

import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { formatMoney } from "@utils";
import Config from "@Config";
import Classes from "@Classes";
import actions from "@actions";
import GlobalModals from "@Modals";
import modalIds from "../../constants/GlobalModals";
import HJLogo from "@assets/images/HJLogo.svg";
import VoucherCount from "@components/atoms/VoucherCount";
import RoundedButton from "@components/atoms/RoundedButton";
import CounterDot from "@components/atoms/CounterDot";
import Modal from "@components/organisms/Modal";
import * as GoogleAnalytics from "@utils/GoogleAnalytics";
import FeatureFlags from "../../constants/FeatureFlags";

type PropsType = {
    modals: any,
    navigator: any,
    session: any,
    cart: any,
    cms: any,
    isRewardWalletDisabled?: boolean,
    displayTestUserBadge?: boolean,
    openGlobalModal: typeof actions.globalModals.openGlobalModal,
    closeGlobalModal: typeof actions.globalModals.closeGlobalModal
};

type StateType = {
    scrolledUp: boolean
};

class NavPrimary extends Component<PropsType, StateType> {
    state = {
        scrolledUp: false
    };

    baseClassname = "nav-primary";
    prevScrollPos = window.scrollY;
    mobileNavHeight = 62;

    componentDidMount() {
        this.getScrollerElement().addEventListener("scroll", this.handleScroll);
    }

    componentWillUnmount() {
        this.getScrollerElement().removeEventListener(
            "scroll",
            this.handleScroll
        );
    }

    componentDidUpdate(nextProps: PropsType) {
        if (
            nextProps.navigator.location.pathname !==
            this.props.navigator.location.pathname
        ) {
            this.closeMobileMenu();
        }
    }

    handleScroll = (event: any) => {
        if (this.getScrollerElement()) {
            const scrolledToBottom =
                this.getScrollPosition() + this.getScrollerHeight() >=
                this.getScrollerElement().scrollHeight;

            // If scrolled right to the bottom of the page, ensure we set scrolledUp to false.
            // This prevents momentum scroll bouncing from showing the menu even though the user didn't scroll up
            this.setState({
                scrolledUp: scrolledToBottom
                    ? false
                    : this.getScrollPosition() < this.prevScrollPos
            });

            this.prevScrollPos = this.getScrollPosition();
        }
    };

    getScrollerElement(): any {
        return document.body;
    }

    getScrollPosition(): number {
        return document.body ? document.body.scrollTop : 0;
    }

    getScrollerHeight(): number {
        return document.body ? document.body.clientHeight : 0;
    }

    handleMobileMenuClick() {
        this.openMobileMenu();
    }

    openMobileMenu() {
        this.props.openGlobalModal({ modalId: modalIds.MOBILE_MENU });
    }

    closeMobileMenu() {
        this.props.closeGlobalModal(modalIds.MOBILE_MENU);
    }

    handleOrderNowClick = () => {
        this.props.openGlobalModal({ modalId: GlobalModals.ADDRESS_LOCATOR });
    };

    renderMenuItems(): any {
        return (
            <React.Fragment>
                {_.get(this, "props.session.selectedStore.store_id") && (
                    <Link
                        to={`${Config.routes.menu}/${_.get(
                            this,
                            "props.session.selectedStore.store_id"
                        )}`}
                        className={`${this.baseClassname}__text-link`}
                    >
                        Delivery Menu
                    </Link>
                )}

                {_.get(this, "props.session.isAuthenticated") ? (
                    <React.Fragment>
                        <Link
                            to={Config.routes.account}
                            onClick={() => {
                                GoogleAnalytics.logEvent({
                                    category: "OrderHome",
                                    action: "Open",
                                    label: "MyAccount"
                                });
                            }}
                            className={`${this.baseClassname}__text-link`}
                        >
                            My Account
                            {!this.props.isRewardWalletDisabled && <VoucherCount />}
                        </Link>
                        <Link
                            to={Config.routes.logout}
                            className={`${this.baseClassname}__text-link`}
                        >
                            Logout
                        </Link>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <Link
                            to={Config.routes.login}
                            onClick={() => {
                                GoogleAnalytics.logEvent({
                                    category: "OrderHome",
                                    action: "Navigation",
                                    label: "LoginMenu"
                                });
                            }}
                            className={`${this.baseClassname}__text-link`}
                        >
                            Login
                        </Link>
                        <Link
                            to={Config.routes.register}
                            className={`${this.baseClassname}__text-link`}
                            onClick={() => {
                                GoogleAnalytics.logEvent({
                                    category: "OrderHome",
                                    action: "Navigation",
                                    label: "SignUpMenu"
                                });
                            }}
                        >
                            Sign Up
                        </Link>
                    </React.Fragment>
                )}

                <a
                    href={
                        this.props.cms.marketing_site_root_url ||
                        "https://www.hungryjacks.com.au"
                    }
                    onClick={() => {
                        GoogleAnalytics.logEvent({
                            category: "OrderHome",
                            action: "Navigation",
                            label: "HJsHomeMenu"
                        });
                    }}
                    className={`${this.baseClassname}__text-link`}
                >
                    HJ's Home
                </a>

                {_.get(this, "props.session.showPersistentCart") ? (
                    <div
                        className={`${this.baseClassname}__cart-status ${this.baseClassname}__text-link`}
                    >
                        <div
                            className={`${this.baseClassname}__cart-status__details`}
                        >
                            <div>
                                {" "}
                                {_.get(this, "props.session.orderMethod") ===
                                "pickup"
                                    ? "Pick Up "
                                    : "Delivery "}
                                Order
                            </div>
                            <div
                                className={`${this.baseClassname}__cart-status__cart-total`}
                            >
                                <span>
                                    {formatMoney(this.props.cart.total_amount)}
                                </span>{" "}
                                <span>/ {this.props.cart.total_kj}kJ</span>
                            </div>
                        </div>
                        <RoundedButton to={Config.routes.cart} color="red">
                            <CounterDot
                                count={this.props.cart.line_items.reduce(
                                    (sum: number, item: any): number =>
                                        sum + item.quantity,
                                    0
                                )}
                                color="green"
                                floating={true}
                            />
                            View cart
                        </RoundedButton>
                    </div>
                ) : null}
            </React.Fragment>
        );
    }

    render(): any {
        const testGroup = _.get(this, "props.session.testGroup");
        const hiddenClass = this.props.session.hideNav ? Classes.hidden : "";
        const scrolledUpClass =
            !this.state.scrolledUp && this.prevScrollPos > this.mobileNavHeight
                ? "is-scrolled-down"
                : "";
        const persistentCartClass = _.get(
            this,
            "props.session.showPersistentCart"
        )
            ? `${this.baseClassname}--persistent-card`
            : "";

        return (
            <React.Fragment>
                <nav
                    className={`${this.baseClassname} ${hiddenClass} ${scrolledUpClass} ${persistentCartClass}`}
                >
                    {/* Test user group launch darkly badge */}
                    {this.props.displayTestUserBadge ? (
                        <div
                            className={`${this.baseClassname}__test-badge-mobile show-sm`}
                        >
                            Beta {testGroup ? `(${testGroup})` : null}
                        </div>
                    ) : null}

                    <div className={`${this.baseClassname}__container`}>
                        {/* {!_.get(this, "props.session.isAuthenticated") ? (
                            <Tooltip
                                className="nav-primary__tooltip"
                                identifier="nav-login-signup"
                                title="Member Perks"
                                message={`Login or sign up to see your vouchers and promos here`}
                                position="bottom"
                                locked={true}
                            />
                        ) : null} */}

                        <div className={`${this.baseClassname}__inner`}>
                            <Link
                                to="/"
                                className={`${this.baseClassname}__logo`}
                            >
                                <img
                                    src={HJLogo}
                                    className={`${this.baseClassname}__logo__img`}
                                    alt="Hungry Jack's"
                                />
                            </Link>

                            <Link
                                to="/"
                                className={`${this.baseClassname}__text-link ${this.baseClassname}__headline`}
                            >
                                ORDER ONLINE
                                {/* Test user group launch darkly badge */}
                                {this.props.displayTestUserBadge ? (
                                    <div
                                        className={`${this.baseClassname}__test-badge hide-sm`}
                                    >
                                        Beta{" "}
                                        {testGroup ? `(${testGroup})` : null}
                                    </div>
                                ) : null}{" "}
                            </Link>

                            <div className={`${this.baseClassname}__items`}>
                                {this.renderMenuItems()}
                            </div>

                            {/* <Tooltip
                                identifier="nav-login-signup"
                                title="Member Perks"
                                message={`Login or sign up to see your vouchers and promos here`}
                                position="bottom"
                                locked={true}
                                disabled={
                                    _.get(
                                        this,
                                        "props.session.isAuthenticated"
                                    ) ||
                                    _.get(
                                        this,
                                        "props.session.showPersistentCart"
                                    )
                                }
                            > */}
                            <button
                                className={`${this.baseClassname}__button icon icon-menu show-sm`}
                                onClick={this.handleMobileMenuClick.bind(this)}
                            />
                            {/* </Tooltip> */}
                        </div>
                    </div>
                </nav>

                {/* Mobile menu modal */}
                <Modal
                    className={`${this.baseClassname}__mobile-menu`}
                    aside={true}
                    active={_.get(this.props.modals, [
                        modalIds.MOBILE_MENU,
                        "active"
                    ])}
                    onClose={() => {
                        this.closeMobileMenu();
                    }}
                >
                    <div
                        className={`${this.baseClassname}__mobile-menu__inner`}
                    >
                        {this.renderMenuItems()}
                    </div>
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: any): any => {
    return {
        modals: state.globalModals,
        session: state.session,
        navigator: state.navigator,
        cart: state.cart,
        cms: state.cms,
        vouchers: state.vouchers,
        displayTestUserBadge:
            state.launchDarkly.flags[FeatureFlags.DISPLAY_TEST_GROUP_BADGE],
        isRewardWalletDisabled:
            state.launchDarkly.flags[FeatureFlags.IS_REWARD_WALLET_DISABLED],

    };
};

export default connect(
    mapStateToProps,
    {
        openGlobalModal: actions.globalModals.openGlobalModal,
        closeGlobalModal: actions.globalModals.closeGlobalModal
    }
)(NavPrimary);
