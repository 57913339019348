// @flow

import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "@utils/hooks/withRouter";
import { Helmet } from "react-helmet";

type PropsType = {
    defaultTitle: string,
    defaultDescription: string,
    defaultKeywords: string,
    defaultImage: string,
    pageMetaData: any,
    navigator: any
};

const MappedMetaTags = (props: PropsType): any => {
    const currentPathname = _.get(props, "navigator.location.pathname");
    const matchedMetaData = _.find(props.pageMetaData, {
        relative_url: currentPathname
    });

    const title =
        (matchedMetaData && matchedMetaData.title) || props.defaultTitle;
    const description =
        (matchedMetaData && matchedMetaData.description) ||
        props.defaultDescription;
    const keywords =
        (matchedMetaData && matchedMetaData.keywords) || props.defaultKeywords;
    const image =
        (matchedMetaData && matchedMetaData.share_image) || props.defaultImage;

    return (
        <Helmet>
            {/*Title*/}
            {title ? <title>{title}</title> : null}
            {title ? <meta property="og:title" content={title} /> : null}

            {/*Description*/}
            {description ? (
                <meta name="”description”" content={description} />
            ) : null}
            {description ? (
                <meta itemprop="description" content={description} />
            ) : null}
            {description ? (
                <meta property="og:description" content={description} />
            ) : null}

            {/*Keywords*/}
            {keywords ? <meta name="keywords" content={keywords} /> : null}

            {/*Share Image*/}
            {image ? <meta itemprop="image" content={image} /> : null}
            {image ? <meta property="og:image" content={image} /> : null}

            {/*Url*/}
            <meta property="og:url" content={window.location.href} />
        </Helmet>
    );
};

const mapStateToProps = (state: any): any => {
    return {
        pageMetaData: state.cms.seo_data,
        navigator: state.navigator
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        null
    )(MappedMetaTags)
);
