// @flow

import Config from "@Config";
import ActionTypes from "@actionTypes";

// initMapApi - load google maps api in promise
export const initMapApi = (): any => (dispatch: any): any => {
    return new Promise((resolve: any, reject: any) => {
        // timeout reject
        let requestTimeout = setTimeout(() => {
            reject("Google Maps API timed out.");
        }, Config.fetchTimeout);

        // loaded resolve
        window.googleMapsLoaded = () => {
            clearTimeout(requestTimeout);
            resolve();
        };

        // async script include
        let gMapsJs = document.createElement("script");
        gMapsJs.type = "text/javascript";
        gMapsJs.async = true;
        gMapsJs.src = `https://maps.googleapis.com/maps/api/js?key=${Config.googleMapsKey}&libraries=places,geometry&callback=window.googleMapsLoaded`;
        if (document && document.body) document.body.appendChild(gMapsJs);
    });
};

// checkGooglePaySupport - load google pay api in promise
// Google pay API setup and support check below
// Reference - https://developers.google.com/pay/api/web/guides/tutorial
export const checkGooglePaySupport = (): any => (dispatch: any): any => {
    return new Promise((resolve: any, reject: any) => {
        // timeout reject
        let requestTimeout = setTimeout(() => {
            reject("Google Pay API timed out.");
        }, Config.fetchTimeout);

        // loaded resolve
        const googlePayLoaded = () => {
            clearTimeout(requestTimeout);

            const baseCardPaymentMethod = {
                type: "CARD",
                parameters: {
                    allowedAuthMethods: Config.googlePay.allowedCardAuthMethods,
                    allowedCardNetworks: Config.googlePay.allowedCardNetworks
                }
            };

            // $FlowFixMe - flow ignore fix
            // const cardPaymentMethod = Object.assign(
            //     {
            //         tokenizationSpecification:
            //             Config.googlePay.tokenizationSpecification
            //     },
            //     baseCardPaymentMethod
            // );

            const paymentsClient = new window.google.payments.api.PaymentsClient(
                {
                    environment: Config.googlePay.environment
                }
            );

            const isReadyToPayRequest = Object.assign(
                {},
                Config.googlePay.baseRequest
            );
            isReadyToPayRequest.allowedPaymentMethods = [baseCardPaymentMethod];

            // check if google payments client is ready to accept payments
            paymentsClient
                .isReadyToPay(isReadyToPayRequest)
                .then(function(response) {
                    if (response.result) {
                        // google payment supported
                        dispatch({
                            type: ActionTypes.SET_GOOGLEPAY_SUPPORT,
                            supported: true
                        });
                    } else {
                        // google payment unsupported
                        dispatch({
                            type: ActionTypes.SET_GOOGLEPAY_SUPPORT,
                            supported: false
                        });
                    }

                    resolve();
                })
                .catch(function(err) {
                    console.error("Google Pay error: ", err);

                    dispatch({
                        type: ActionTypes.SET_GOOGLEPAY_SUPPORT,
                        supported: false
                    });

                    resolve();
                });
        };

        // async script include
        let gPayJs = document.createElement("script");
        gPayJs.type = "text/javascript";
        gPayJs.async = true;
        gPayJs.onload = googlePayLoaded;
        gPayJs.src = `https://pay.google.com/gp/p/js/pay.js`;
        if (document && document.body) document.body.appendChild(gPayJs);
    });
};
