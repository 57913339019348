// @flow

import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "@utils/hooks/withRouter";
import moment from "moment";
import actions from "@actions";
import branch from "branch-sdk";
import _ from "lodash";

import LineItem from "@components/molecules/LineItem";
import CartTotals from "@components/molecules/CartTotals";
import OrderVoucher from "@components/molecules/OrderVoucher";
import Button from "@components/atoms/Button";
import OrderComp from "@components/molecules/OrderComp";

import orderUtils from "@utils/orderUtils";
import * as GoogleAnalytics from "@utils/GoogleAnalytics";

import { type OrderType } from "@SharedTypes";

type PropsType = {
    order: OrderType,
    addPastOrderToCart: typeof actions.cart.addPastOrderToCart,
    forceShowOrderedTime: boolean
};

const OrderDetail = (props: PropsType): React.Node => {
    const baseClassname = "order-detail";

    if (!props.order) return null;

    const { order, addPastOrderToCart } = props;
    const isDeliveryOrder = orderUtils.isDeliveryOrder(order);
    let branchLink = null;

    if (!isDeliveryOrder) {
        var linkData = {
            data: {
                $deeplink_path:
                    "hungryjacks://" +
                    (order.order_token
                        ? "track-order/" + order.order_token
                        : "track-order-by-id/" + order.order_id)
            }
        };

        branch.link(linkData, function(err: any, link: string) {
            branchLink = link;
        });
    }

    const handleReorder = () => {
        if (!isDeliveryOrder && branchLink) {
            window.open(branchLink);
            return;
        }
        GoogleAnalytics.logEvent({
            category: "Profile",
            action: "Reorder",
            label: "Past_Orders"
        });
        addPastOrderToCart(order);
    };
    const isPastOrder = orderUtils.isPastOrder(order);

    const address = orderUtils.getOrderAddress(order);
    const showReorderButton = orderUtils.isPastOrder(order);

    return (
        <div className={`${baseClassname}`}>
            <div className={`${baseClassname}__intro`}>
                <div className={`${baseClassname}__intro__col`}>
                    <h3
                        className={`heading-${
                            orderUtils.isActiveOrder(order) ? "3" : "2"
                        }`}
                    >
                        {!orderUtils.isDeliveryOrder(order)
                            ? "Your pickup order"
                            : orderUtils.isActiveOrder(order)
                            ? "Order Summary"
                            : "Your delivery order"}
                    </h3>
                    <p className={`${baseClassname}__item`}>
                        <i className="icon icon-clock" />
                        {isPastOrder ||
                        !isDeliveryOrder ||
                        props.forceShowOrderedTime ? (
                            <>
                                {`Ordered ${moment(
                                    order.created_time,
                                    "D MMM YYYY hh:mma"
                                ).format("D MMM YYYY, h:mma")}`}
                            </>
                        ) : (
                            <>
                                To be delivered{" "}
                                {moment(
                                    order.delivery_estimated_time_of_arrival ||
                                        order.requested_delivery_datetime
                                ).format("D MMM YYYY, h:mma")}
                            </>
                        )}
                    </p>
                    <p className={`${baseClassname}__item`}>
                        <i className="icon icon-map-pin-outline" />
                        {address}
                    </p>
                </div>
                <div className={`${baseClassname}__intro__col`}>
                    {showReorderButton ? (
                        <Button
                            disabled={_.get(order, "is_voucher_order", false)}
                            onClick={handleReorder}
                        >
                            Re-order
                        </Button>
                    ) : null}
                </div>
            </div>
            {order.friendly_order_id ? (
                <div className={`${baseClassname}__number`}>
                    Order number <span className={`${baseClassname}__number__bold`}>{order.friendly_order_id}</span>
                </div>
            ) : null}

            {/* Line items */}
            <div className={`${baseClassname}__line-items`}>
                {order.line_items.map(
                    (lineItem: any, index: number): React.Node => (
                        <LineItem key={index} lineItem={lineItem} />
                    )
                )}
            </div>
            {/* Order Voucher */}
            {order.voucher_id && order.voucher_name ? (
                <OrderVoucher voucher_name={order.voucher_name} />
            ) : null}
            {/* Totals */}
            {order.comp_code ? (
                <OrderComp
                    voucher_name={order.comp_code}
                    discount={order.discounts_total}
                />
            ) : null}
            {/* Totals */}
            <CartTotals cart={order} />
        </div>
    );
};

const mapStateToProps = (state: any, ownProps: PropsType): any => {
    return {};
};

export default withRouter(
    connect(
        mapStateToProps,
        {
            addPastOrderToCart: actions.cart.addPastOrderToCart
        }
    )(OrderDetail)
);
