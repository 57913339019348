// @flow

import React, { Component } from "react";
import _ from "lodash";
import actions from "@actions";
import { connect } from "react-redux";

// import Config from "@Config";
import ApiFactory from "@apis/APIFactory";
import LoaderCover from "@components/atoms/LoaderCover";
// import ServerErrorMessage from "@components/atoms/ServerErrorMessage";

// import adyenPaymentSession from "@assets/adyenPaymentSession.json"; // debugging

type PropsType = {
    dispatch: any,
    userId?: ?any,
    orderId?: ?any,
    mode?: ?string,
    onLoad: ?any,
    onSubmit?: ?any,
    session: any,
    onProvide?: ?any,
    onError: any,
    orderAmount: number,
    className: ?string
};

type StateType = {
    paymentSession: ?any,
    merchantAccount: ?string,
    paypalMerchant: ?string,
    appleMerchant: ?string,
    adyenLoaded: boolean,
    error: ?any,
    originKey: ?string
};

class AdyenCheckout extends Component<PropsType, StateType> {
    state = {
        paymentSession: null,
        merchantAccount: null,
        paypalMerchant: null,
        appleMerchant: null,
        originKey: null,
        adyenLoaded: false,
        error: null
    };

    _isMounted = false;
    adyenCheckout = window.AdyenCheckout;
    dropin: any = null;
    adyenCheckoutContainer = React.createRef<any>();

    componentDidMount() {
        this._isMounted = true;
        this.init();
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        if (!prevState.paymentSession && this.state.paymentSession) {
            this.initAdyenCheckout();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    // Public Methods
    submit(e: any) {
        if (this.dropin.activePaymentMethod.displayName === "PayPal") {
            console.error(
                "please use paypal button. TODO: hide this green button"
            );
        } else {
            this.dropin.submit();
        }
        // adyen sdk - submit form
        // if (!this.dropin.checkPaymentMethodIsValid()) {
        //     this.setState({
        //         error: "Payment method is not valid."
        //     });
        //     return;
        // }
    }

    init() {
        // clear any existing payment session from state and get new payment session payload
        this.setState(
            {
                paymentSession: null,
                merchantAccount: null,
                paypalMerchant: null,
                appleMerchant: null
            },
            () => {
                this.getOriginKey().then(this.getOrderPaymentSession);
            }
        );
    }

    getOriginKey = (): any => {
        return ApiFactory.CardAPI.getOriginKey().then((res: any): any => {
            return new Promise((resolve, reject) => {
                this.setState(
                    {
                        originKey: res.origin_key
                    },
                    resolve
                );
            });
        });
    };

    getOrderPaymentSession = () => {
        // get a standard checkout/order payment session token (standard checkout)
        if (this.props.orderId && this.props.userId) {
            ApiFactory.CardAPI.getOrderPaymentSession(this.props.orderId)
                .then((res: any) => {
                    if (this._isMounted) {
                        this.setState({
                            paymentSession: res.payment_methods,
                            merchantAccount: res.adyen_merchant_account,
                            paypalMerchant: res.paypal_merchant_id,
                            appleMerchant: res.apple_merchant_id
                        });
                    }
                })
                .catch((err: any) => {
                    if (this._isMounted) {
                        this.setState({
                            adyenLoaded: true,
                            error:
                                err.friendly_message ||
                                "Something went wrong whilst initialising the payment session."
                        });
                        if (this.props.onError) {
                            this.props.onError(err);
                        }
                    }
                });
        } else {
            console.error(
                "AdyenCheckout: userId and orderId props were not provided to the AdyenCheckout component."
            );
        }
    };

    initAdyenCheckout = async (): any => {
        // define checkout object from current adyen window object
        this.adyenCheckout = window.AdyenCheckout;

        // start interval check for loading state of adyen web sdk

        // set loading state and start interval loader check
        this.setState({ adyenLoaded: false });

        const configuration = {
            paymentMethodsResponse: this.state.paymentSession, // The `/paymentMethods` response from the server.
            originKey: this.state.originKey,
            locale: "en-AU",
            environment: window.hj_env === "prod" ? "live-au" : "test",
            showPayButton: false,
            onSubmit: (state, dropin) => {
                if (
                    state.data.paymentMethod.type === "applepay" &&
                    !state.data.paymentMethod["applepay.token"]
                ) {
                    return;
                }
                if (this._isMounted) {
                    if (this.props.onSubmit) {
                        this.props.onSubmit(state, dropin);
                    } else {
                        console.error(
                            "No onSubmit prop was provided, please include an onSubmit prop that will pass back the paymentData."
                        );
                    }
                }
            },
            onAdditionalDetails: (state, dropin) => {
                if (this._isMounted) {
                    if (this.props.onProvide) {
                        this.props.onProvide(state, dropin);
                    } else {
                        console.error(
                            "No onProvide prop was provided, please include an onProvide prop that will pass back the paymentData."
                        );
                    }
                }
            },
            onReady: () => {
                if (this._isMounted) {
                    // append tooltips into adyen fields
                    // this.appendTooltips();

                    this.setState({
                        adyenLoaded: true
                    });

                    // load callback prop
                    if (this.props.onLoad) {
                        this.props.onLoad();
                    }
                }
            },
            onError: error => {
                if (this._isMounted) {
                    this.setState({
                        error: _.get(error, "message", "some error")
                    });
                }

                // Log the error
                console.error("ADYEN ERROR:", {
                    error
                });

                // Call onError callback
                if (this.props.onError) {
                    this.props.onError(error);
                }

                // Wait for the next tick to
                // re init checkout/card inputs
                // requestAnimationFrame(() => {
                //     this.init();
                // });
            },
            onSelect: event => {
                this.props.dispatch(
                    actions.session.setPaymentMethod(event.displayName)
                );
                window.dataLayer.push({
                    event: "checkout",
                    ecommerce: {
                        checkout: {
                            actionField: {
                                step: 4,
                                option: "Payment Method Selected"
                            }
                        }
                    }
                });
            },
            paymentMethodsConfiguration: {
                paypal: {
                    merchantId: this.state.paypalMerchant,
                    environment: window.hj_env === "prod" ? "live" : "test",
                    countryCode: "AU",
                    amount: {
                        currency: "AUD",
                        value: Math.round(this.props.orderAmount * 100)
                    },
                    intent: "authorize",
                    onCancel: (data, dropin) => {
                        dropin.setStatus("ready");
                    }
                },
                card: {
                    enableStoreDetails: !this.props.session.isGuestUser,
                    hasHolderName: true,
                    holderNameRequired: false,
                    // enableStoreDetails: true
                },
                paywithgoogle: {
                    environment:
                        window.hj_env === "prod" ? "PRODUCTION" : "TEST", // Change this to PRODUCTION when you're ready to accept live Google Pay payments
                    amount: {
                        currency: "AUD",
                        value: Math.round(this.props.orderAmount * 100)
                    },
                    configuration: {
                        gatewayMerchantId: this.state.merchantAccount // Your Adyen merchant or company account name
                    }
                },
                applepay: {
                    // Required configuration for Apple Pay
                    amount: Math.round(this.props.orderAmount * 100),
                    currencyCode: "AUD",
                    countryCode: "AU",
                    totalPriceLabel: "Hungry Jack's",
                    configuration: {
                        merchantName: "ApplePay Hungry Jack's", // Name to be displayed on the form
                        merchantIdentifier: this.state.appleMerchant // Your Apple merchant identifier as described in https://developer.apple.com/documentation/apple_pay_on_the_web/applepayrequest/2951611-merchantidentifier
                    },
                    onValidateMerchant: (resolve, reject, validationURL) => {
                        ApiFactory.CardAPI.getApplePaySession(
                            validationURL
                        ).then(res => {
                            resolve(res.payment_session);
                        }, reject);
                    },
                    onSubmit: state => {
                        if (this._isMounted) {
                            if (this.props.onSubmit) {
                                this.props.onSubmit(state);
                            } else {
                                console.error(
                                    "No onSubmit prop was provided, please include an onSubmit prop that will pass back the paymentData."
                                );
                            }
                        }
                    }
                }
            }
        };
        const checkout = new this.adyenCheckout(configuration);
        this.dropin = checkout.create("dropin").mount("#adyen-checkout");
    };

    render(): any {
        return (
            <div
                className={
                    `adyen-checkout ` +
                    (this.props.className ? this.props.className : "")
                }
            >
                <LoaderCover solid={true} active={!this.state.adyenLoaded} />
                {/* <ServerErrorMessage>{this.state.error}</ServerErrorMessage> */}
                <div
                    ref={this.adyenCheckoutContainer}
                    id="adyen-checkout"
                    className={`adyen-checkout__wrapper ${
                        this.state.adyenLoaded ? "has-loaded" : ""
                    }`}
                />
            </div>
        );
    }
}

export default connect(
    (state: any): any => ({
        session: state.session
    }),
    null,
    null,
    {
        forwardRef: true
    }
)(AdyenCheckout);
