import * as initialFetchAll from "./initialFetchAll";
import * as google from "./google";
import * as adyen from "./adyen";
import * as device from "./device";
import * as session from "./session";
import * as cms from "./cms";
import * as stores from "./stores";
import * as menu from "./menu";
import * as product from "./product";
import * as cart from "./cart";
import * as user from "./user";
import * as globalModals from "./globalModals";
import * as vouchers from "./vouchers";
import * as savedAddresses from "./savedAddresses";
import * as orders from "./orders";
import * as config from "./config";
import * as launchDarkly from "./launchDarkly";

export default {
    ...initialFetchAll,
    google,
    adyen,
    device,
    session,
    cms,
    stores,
    menu,
    product,
    cart,
    user,
    globalModals,
    vouchers,
    savedAddresses,
    orders,
    config,
    launchDarkly
};
