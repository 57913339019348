// @flow

import * as React from "react";
import Icon from "@components/atoms/Icon";

type PropsType = {
    children: any,
    className?: string,
    icon?: string,
    margin?: boolean,
    ariaLabel?: string
};

const Disclaimer = ({ icon, margin, children, ariaLabel, ...props }: PropsType): any => {
    return (
        <div
            {...props}
            className={`disclaimer ${margin ? "disclaimer--margin" : ""}`}
        >
            {icon ? 
                <Icon name={icon} title={ariaLabel} role={"img"} aria-label={ariaLabel} className="disclaimer__icon" />
                : null
            }
            <p className={`disclaimer__text`}>{children}</p>
        </div>
    );
};

export default Disclaimer;
