// @flow

import React, { Component } from "react";
// import { connect } from "react-redux";

// import actions from "@actions";
import handleDeeplink from "@utils/deeplinkHelper";
import Landing from "@containers/Landing";
// import actions from "@actions";

type PropsType = {
    location: any
};

class DeeplinkHandler extends Component<PropsType> {
    componentDidMount() {
        handleDeeplink(window.location.href);
    }
    render(): any {
        return <Landing />;
    }
}

export default DeeplinkHandler;
