// @flow

import * as React from "react";
import Icon from "@components/atoms/Icon";
import LinkHandler from "@components/atoms/LinkHandler";

type PropsType = {
    children: React.Node,
    className?: string,
    type?: string,
    to?: string,
    styletype?: string,
    icon?: string,
    iconCircle?: boolean,
    big: boolean,
    small: boolean
};

const TextButton = (props: PropsType): React.Node => {
    const {
        big,
        className,
        styletype,
        icon,
        iconCircle,
        small,
        to,
        ...remainingProps
    } = props;

    const baseClassname = "text-button";

    const styleTypeClass = props.styletype
        ? `${baseClassname}--${props.styletype}`
        : "";

    return (
        <LinkHandler
            {...remainingProps}
            className={`${baseClassname} ${styleTypeClass} ${
                big ? `${baseClassname}--big` : ""
            }
            ${small ? `${baseClassname}--small` : ""} ${
                className ? className : ""
            }`}
            type={props.type || to ? "" : "button"}
            to={to}
        >
            {props.icon ? (
                <Icon name={props.icon} circle={props.iconCircle} />
            ) : null}
            <span className={`${baseClassname}__content`}>
                {props.children}
            </span>
        </LinkHandler>
    );
};

TextButton.defaultProps = {
    big: false,
    small: false
};

export default TextButton;
