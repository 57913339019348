// @flow

import * as React from "react";
import { connect } from "react-redux";
import { Outlet } from "react-router-dom";
import { withRouter } from "@utils/hooks/withRouter";

import MappedMetaTags from "@containers/MappedMetaTags";
import CheckoutHero from "@components/molecules/CheckoutHero";
import Footer from "@components/organisms/Footer";

const { Fragment } = React;

type PropsType = {
    router: any
};

const EmailVerification = (props: PropsType): React.Node => {
    return (
        <Fragment>
            <MappedMetaTags defaultTitle="Verify Email" />

            <CheckoutHero>
                <div className="container-fluid-xsmall">
                    <CheckoutHero.Title button={<CheckoutHero.BackLink />}>
                        Email Verification
                    </CheckoutHero.Title>
                </div>
            </CheckoutHero>

            <div className="container-fluid-xsmall">
                <div className="content-card">
                    <div className="account-utils-form">
                        <Outlet />
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
};

const mapStateToProps = (state: any): any => {
    return {};
};

export default withRouter(
    connect(
        mapStateToProps,
        {}
    )(EmailVerification)
);
