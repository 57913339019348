// @flow

import * as React from "react";
import { Link } from "react-router-dom";

import Button from "@components/atoms/Button";
import Icon from "@components/atoms/Icon";
import * as GoogleAnalytics from "@utils/GoogleAnalytics";
import Routes from "../../constants/Routes";

import { type OrderType } from "@SharedTypes";

const ThumbsUps = (props: { order: OrderType }): React.Node => {
    const baseClassName = "thumbs-ups";

    const renderThumb = (rating: number): React.Node => (
        <Link
            onClick={() => {
                GoogleAnalytics.logEvent({
                    category: "MyOrders",
                    action: "Open",
                    label: "RateOrder"
                });
            }}
            to={`${Routes.orderFeedback}/${props.order.order_token}?prefillFeedback=${rating}`}
            className={`${baseClassName}__button`}
        >
            <Icon name="rate-on" />
        </Link>
    );

    return (
        <div className={`${baseClassName}`}>
            {renderThumb(1)}
            {renderThumb(2)}
            {renderThumb(3)}
            {renderThumb(4)}
            {renderThumb(5)}
        </div>
    );
};

const RateOrder = (props: { order: OrderType }): React.Node => {
    const baseClassName = "rate-order";
    return (
        <div className={`${baseClassName}`}>
            <div className={`${baseClassName}__control`}>
                <p>
                    <strong>How was your online ordering experience?</strong>
                </p>
                <ThumbsUps order={props.order} />
            </div>
            <div className={`${baseClassName}__button`}>
                <Button
                    onClick={() => {
                        GoogleAnalytics.logEvent({
                            category: "MyOrders",
                            action: "Open",
                            label: "RateOrder"
                        });
                    }}
                    styleType={["clear"]}
                    to={`${Routes.orderFeedback}/${props.order.order_token}`}
                >
                    Rate your order
                </Button>
            </div>
        </div>
    );
};

export default RateOrder;
