// @flow

import * as React from "react";
import { connect } from "react-redux";

import actions from "@actions";
import Button from "@components/atoms/Button";
import ProductTile from "@components/molecules/ProductTile";

type PropsType = {
    dispatch: any,
    upsells: any,
    promoBasepath: string,
    itemBasepath: string,
    addMenuItemToCart: any,
    onSuccess?: () => void,
};

const UpsellsModal = (props: PropsType): any => {
    const handleAddUpsellsToCart = (upsellItem: any) => {
        props.addMenuItemToCart(
            upsellItem.menu_item_id,
            upsellItem.sales_item_id,
            true
        );

        props.onSuccess();
    };

    return (
        <div className="upsells-modal">
            <div className="upsells-modal__body">
                <h2 className="heading-3">
                    Make your meal
                    <br /> extra tasty
                </h2>
                <div>
                    <div className="upsells-modal__row row">
                        {props.upsells.map(
                            (upsell: any, index: number): React.Node => {
                                if (upsell) {
                                    return (<div    
                                        key={index}
                                        className="upsells-modal__col col-xs-6"
                                    >
                                        <ProductTile
                                            {...upsell}
                                            upsell={true}
                                            imageUrl={
                                                (upsell.promo_id !== null
                                                    ? props.promoBasepath
                                                    : props.itemBasepath) +
                                                upsell.image
                                            }
                                            onClick={(): any =>
                                                handleAddUpsellsToCart(upsell)
                                            }
                                        />
                                    </div>)
                                } else {
                                    return <></>
                                }
                        })}
                    </div>
                </div>
            </div>
            <div className="upsells-modal__buttons">
                <Button styleType="black" onClick={props.onSuccess}>
                    No thanks
                </Button>
            </div>
        </div>
    );
};

const mapStateToProps = (state: any): any => {
    return {
        upsells: state.upsells,
        itemBasepath: state.menu.images_item_basepath,
        promoBasepath: state.menu.images_promos_basepath
    };
};

export default connect(
    mapStateToProps,
    {
        addMenuItemToCart: actions.cart.addMenuItemToCart,
    }
)(UpsellsModal);
