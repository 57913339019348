// @flow

import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "@utils/hooks/withRouter";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { appboyLogCustomEvent } from "@utils/AppBoy";

import actions from "@actions";

import { formatMoney } from "@utils";
import { Row, Col } from "@containers/Grid";
import SalesItem from "@components/molecules/SalesItem";
import Button from "@components/atoms/Button";
import TextButton from "@components/atoms/TextButton";
import FormatSymbols from "@components/atoms/FormatSymbols";
import LoaderCover from "@components/atoms/LoaderCover";

import * as GoogleAnalytics from "@utils/GoogleAnalytics";

type PropsType = {
    dispatch: any,
    product: any,
    menu: any,
    salesItem: {
        name: string,
        image: string,
        sales_item_id: number,
        pos_item_id: number,
        diff: number,
        modifier_groups: [],
        item_modifiers: [],
        modifiers: [],
        per_option_max: number,
        combo_line_item_number: number
    },
    salesItemIndex: any,
    salesGroup: any,
    onConfirm: () => void
};
type StateType = {
    modifySalesItem: any,
    loaded: boolean
};

class EditSalesItem extends Component<PropsType, StateType> {
    state = {
        modifySalesItem: null,
        loaded: false
    };

    componentDidMount() {
        this.logOpenEvents();

        this.delayContentLoaded();
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        if (prevState.modifySalesItem !== this.state.modifySalesItem) {
            // the side/modifier switched
            this.logOpenEvents();
        }
    }

    static getDerivedStateFromProps(nextProps: any, prevState: any): any {
        const nextSalesGroupItems = _.get(
            nextProps,
            "salesGroup.sales_item_ids"
        );

        if (
            prevState.modifySalesItem ||
            !nextSalesGroupItems ||
            (nextSalesGroupItems && nextSalesGroupItems.length === 1)
        ) {
            // either no sales group items, or sales group was passed but it only has 1 sales item available

            return {
                ...prevState,
                modifySalesItem: _.find(nextProps.product.sales_items, {
                    combo_line_item_number: prevState.modifySalesItem
                        ? prevState.modifySalesItem.combo_line_item_number
                        : null
                        ? nextSalesGroupItems[0].combo_line_item_number
                        : nextProps.salesItem.combo_line_item_number,

                    sales_item_id: prevState.modifySalesItem
                        ? prevState.modifySalesItem.sales_item_id
                        : nextSalesGroupItems
                        ? nextSalesGroupItems[0].sales_item_id
                        : nextProps.salesItem.sales_item_id
                })
            };
        } else {
            return prevState;
        }
    }

    delayContentLoaded() {
        setTimeout(() => {
            this.setState({
                loaded: true
            });
        }, 750);
    }

    logOpenEvents() {
        if (this.state.modifySalesItem) {
            // modifier customisation modal opened
            GoogleAnalytics.logEvent({
                category: "Menu",
                action: "Open",
                label: "Customize"
            });
        } else {
            // change sales item modal opened
            GoogleAnalytics.logEvent({
                category: "Menu",
                action: "Open",
                label: "Side"
            });
        }
    }

    setItemToModify(comboLineItemNumber?: number): any {
        const salesItem = _.find(this.props.product.sales_items, {
            combo_line_item_number: comboLineItemNumber
        });

        if (salesItem) {
            this.setState({
                modifySalesItem: {
                    combo_line_item_number: comboLineItemNumber,
                    ...salesItem
                }
            });
        }
    }

    handleConfirmClick() {
        GoogleAnalytics.logEvent({
            category: "Menu",
            action: "Done",
            label: "Customize"
        });
        appboyLogCustomEvent("CustomizeItem");
        if (this.props.onConfirm) {
            this.props.onConfirm();
        }
    }

    handleResetModifiersClick() {
        this.props.dispatch(
            actions.product.resetSalesItemModifiers(this.props.salesItemIndex)
        );
    }

    renderSalesGroupSelection(): any {
        const salesGroupSaleItems = _.get(
            this,
            "props.salesGroup.sales_item_ids"
        );
        if (salesGroupSaleItems) {
            return (
                <React.Fragment>
                    {salesGroupSaleItems.map(
                        (salesItem: any, salesItemIndex: number): any => {
                            return (
                                <SalesItem
                                    key={salesItemIndex}
                                    name={salesItem.name}
                                    image={salesItem.thumb}
                                    salesItem={salesItem}
                                    salesItemIndex={this.props.salesItemIndex}
                                    salesGroup={this.props.salesGroup}
                                    selectionMode={true}
                                    comboLineItemNumber={
                                        this.props.salesItem
                                            .combo_line_item_number
                                    }
                                    onButtonClick={(): any => {
                                        this.setItemToModify(
                                            this.props.salesItem
                                                .combo_line_item_number
                                        );
                                    }}
                                    editMode={true}
                                />
                            );
                        }
                    )}
                </React.Fragment>
            );
        }
    }

    renderSalesItemModification(): any {
        if (this.state.modifySalesItem) {
            return (
                <React.Fragment>
                    <div className="edit-sales-item__name">
                        <FormatSymbols>
                            {this.state.modifySalesItem.name}
                        </FormatSymbols>
                        <TextButton
                            onClick={this.handleResetModifiersClick.bind(this)}
                        >
                            Reset
                        </TextButton>
                    </div>

                    {this.state.modifySalesItem
                        ? this.state.modifySalesItem.modifier_groups.map(
                              (
                                  modifierGroup: any,
                                  modifierGroupCtx: number
                              ): any => {
                                  return (
                                      <React.Fragment key={modifierGroupCtx}>
                                          <div className="edit-sales-item__group-name">
                                              {modifierGroup.name}
                                          </div>
                                          <div>
                                              {modifierGroup.modifiers.map(
                                                  (
                                                      modifierItem: any,
                                                      modifierItemCtx: number
                                                  ): any => {
                                                      if (
                                                          modifierItem.pos_item_id ===
                                                          5169
                                                      ) {
                                                          return null;
                                                      }
                                                      return (
                                                          <SalesItem
                                                              key={
                                                                  modifierItemCtx
                                                              }
                                                              name={`${modifierItem.name}`}
                                                              image={
                                                                  modifierItem.thumb
                                                              }
                                                              salesItem={
                                                                  this.state
                                                                      .modifySalesItem
                                                              }
                                                              modifierItem={
                                                                  modifierItem
                                                              }
                                                              modifierGroup={
                                                                  modifierGroup
                                                              }
                                                              editMode={true}
                                                              comboLineItemNumber={
                                                                  this.props
                                                                      .salesItem
                                                                      .combo_line_item_number
                                                              }
                                                          />
                                                      );
                                                  }
                                              )}
                                          </div>
                                      </React.Fragment>
                                  );
                              }
                          )
                        : null}
                </React.Fragment>
            );
        } else {
            return null;
        }
    }

    render(): any {
        return (
            <>
                <LoaderCover solid={true} active={!this.state.loaded} />
                {this.state.loaded ? (
                    <div className="edit-sales-item">
                        <div className="edit-sales-item__content">
                            <TransitionGroup component={null}>
                                <CSSTransition
                                    key={this.state.modifySalesItem}
                                    timeout={100000000}
                                    classNames="slide-in-out"
                                >
                                    <div className="edit-sales-item__inner">
                                        <div className="edit-sales-item__heading heading-2">
                                            {this.state.modifySalesItem
                                                ? "Customise "
                                                : "Choose a "}
                                            {_.get(
                                                this,
                                                "props.salesGroup.name",
                                                "side"
                                            )}
                                        </div>

                                        <div className="edit-sales-item--items">
                                            {this.state.modifySalesItem
                                                ? this.renderSalesItemModification()
                                                : this.renderSalesGroupSelection()}
                                        </div>
                                    </div>
                                </CSSTransition>
                            </TransitionGroup>
                        </div>

                        <div className="edit-sales-item__footer">
                            <Row className="between-xs middle-xs">
                                <Col xs={6}>
                                    <div className="product-detail__totals">
                                        {formatMoney(this.props.product.price)}{" "}
                                        /{this.props.product.energy_kj}kJ
                                    </div>
                                </Col>
                                <Col xs={6}>
                                    <Button
                                        onClick={this.handleConfirmClick.bind(
                                            this
                                        )}
                                    >
                                        Done
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </div>
                ) : null}
            </>
        );
    }
}

const mapStateToProps = (state: any): any => {
    return {
        product: state.product,
        menu: state.menu
    };
};

export default withRouter(connect(mapStateToProps)(EditSalesItem));
