// @flow

import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import actions from "@actions";

import Config from "@Config";
import Classes from "@Classes";
import Strings from "@Strings";
import SelectedOrderMethod from "@components/molecules/SelectedOrderMethod";
import CartItems from "@components/molecules/CartItems";
import CartTotals from "@components/molecules/CartTotals";
import Disclaimer from "@components/atoms/Disclaimer";
import Icon from "@components/atoms/Icon";
import TextButton from "@components/atoms/TextButton";

type PropsType = {
    showCompModal: actions.cart.showCompModal,
    hasCompCode: boolean
};

type StateType = {};

class PersistentCart extends Component<PropsType, StateType> {
    state = {};

    render(): any {
        const baseClassname = "persistent-cart";
        const activeClass = _.get(this, "props.session.showPersistentCart")
            ? Classes.active
            : "";

        return (
            <div className={`${baseClassname} ${activeClass}`}>
                <div className={`${baseClassname}__inner`}>
                    <div className={`${baseClassname}__section`}>
                        <SelectedOrderMethod tooltips={true} />
                    </div>

                    <div className={`${baseClassname}__divider`} />

                    <div className={`${baseClassname}__section`}>
                        <CartItems small={true} editable={true} />
                        <CartTotals small={true} narrow />
                    </div>

                    {/* <TastyAdditions /> */}

                    <div className={`${baseClassname}__small__divider`} />
                    {this.props.hasCompCode || (
                        <div className={`${baseClassname}__small__section`}>
                            <TextButton onClick={this.props.showCompModal}>
                                <Icon name="voucher" />
                                Got a promo code?
                            </TextButton>
                        </div>
                    )}

                    <div className={`${baseClassname}__small__section`}>
                        <TextButton styletype="black" to={Config.routes.cart}>
                            Expand cart <Icon name="chevron-right" />
                        </TextButton>
                    </div>

                    <div className={`${baseClassname}__divider`} />

                    <div className={`${baseClassname}__section`}>
                        <Disclaimer>{Strings.cartDisclaimer}</Disclaimer>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any): any => {
    return {
        session: state.session,
        navigator: state.navigator,
        hasCompCode: !!state.cart.comp_code
    };
};

export default connect(
    mapStateToProps,
    {
        showCompModal: actions.cart.showCompModal
    }
)(PersistentCart);
