// @flow

import * as React from "react";
//import "@scss/base/flexboxgrid.scss";

// Col
type ColNumbersType = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | null;

type ColPropsType = {
    children?: any,
    className?: string,
    xs?: ColNumbersType,
    sm?: ColNumbersType,
    md?: ColNumbersType,
    lg?: ColNumbersType,
    xl?: ColNumbersType,
    xsOffset?: ColNumbersType,
    smOffset?: ColNumbersType,
    mdOffset?: ColNumbersType,
    lgOffset?: ColNumbersType,
    xlOffset?: ColNumbersType
};

export const Col = (props: ColPropsType): any => {
    const colPrefix = "col";

    const xsCls = props.xs ? `${colPrefix}-xs-${props.xs}` : "";
    const smCls = props.sm ? `${colPrefix}-sm-${props.sm}` : "";
    const mdCls = props.md ? `${colPrefix}-md-${props.md}` : "";
    const lgCls = props.lg ? `${colPrefix}-lg-${props.lg}` : "";
    const xlCls = props.xl ? `${colPrefix}-xl-${props.xl}` : "";

    const xsOffsetCls = props.xsOffset
        ? `${colPrefix}-xs-offset-${props.xsOffset}`
        : "";
    const smOffsetCls = props.smOffset
        ? `${colPrefix}-sm-offset-${props.smOffset}`
        : "";
    const mdOffsetCls = props.mdOffset
        ? `${colPrefix}-md-offset-${props.mdOffset}`
        : "";
    const lgOffsetCls = props.lgOffset
        ? `${colPrefix}-lg-offset-${props.lgOffset}`
        : "";
    const xlOffsetCls = props.xlOffset
        ? `${colPrefix}-xl-offset-${props.xlOffset}`
        : "";

    return (
        <div
            className={`${props.className ||
                ""} ${xsCls} ${smCls} ${mdCls} ${lgCls} ${xlCls} ${xsOffsetCls} ${smOffsetCls} ${mdOffsetCls} ${lgOffsetCls} ${xlOffsetCls}`}
        >
            {props.children}
        </div>
    );
};

// Row

type RowPropsType = {
    children: any, // React.ChildrenArray<React.Element<typeof Col>>,
    className?: string,
    noPad?: boolean
};

export const Row = (props: RowPropsType): any => {
    return (
        <div
            className={`row ${props.className || ""} ${
                props.noPad ? "row--no-pad" : ""
            }`}
        >
            {props.children}
        </div>
    );
};
