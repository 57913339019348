// @flow

import ActionTypes from "@actionTypes";
import ApiFactory from "@apis/APIFactory";

export const getStoresRequest = (): any => ({
    type: ActionTypes.GET_STORES_REQUEST
});

export const getStoresSuccess = (newStores: any): any => (
    dispatch: any,
    getState: any
): any => {
    dispatch({
        type: ActionTypes.GET_STORES_SUCCESS,
        stores: newStores
    });
};

export const getStoresFailure = (): any => ({
    type: ActionTypes.GET_STORES_FAILURE
});

export const getStores = (
    lastUpdated: any,
    enableTestStores?: boolean = false
): any => (dispatch: any): any => {
    dispatch(getStoresRequest());
    return new Promise((resolve: any, reject: any) => {
        ApiFactory.StoresAPI.getStores(lastUpdated, enableTestStores)
            .then(
                (response: any) => {
                    dispatch(getStoresSuccess(response));
                    resolve();
                },
                (res: any) => {
                    if (res.status !== 304) {
                        throw res;
                    }
                    dispatch(getStoresSuccess(null));
                    reject(res);
                }
            )
            .catch((err: any) => {
                dispatch(getStoresFailure());
                reject(err);
            });
    });
};
