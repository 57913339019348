// @flow

import _ from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { push } from "@lagunovsky/redux-react-router";
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

import ReCAPTCHA from "react-google-recaptcha";
import { cookie } from "@utils";

import { ReactForm, ReactFormField } from "@adrenalin/react-form";

import Config from "@Config";
import Actions from "@actions";
// import GlobalModals from "../constants/GlobalModals";
import FacebookSignIn from "./FacebookSignIn";
import AppleSignIn from "./AppleSignIn";

import MappedMetaTags from "@containers/MappedMetaTags";
import Button from "@components/atoms/Button";
import ServerErrorMessage from "@components/atoms/ServerErrorMessage";
import CheckoutHero from "@components/molecules/CheckoutHero";
import Footer from "@components/organisms/Footer";
import useAsyncState from "@utils/hooks/useAsyncState";
import * as GoogleAnalytics from "@utils/GoogleAnalytics";
import TextButton from "@components/atoms/TextButton";

import FeatureFlags from "../constants/FeatureFlags";

gsap.registerPlugin(ScrollToPlugin);

type PropsType = {
    register: Actions.user.register,
    openGlobalModal: (modal: any) => void,
    push: (path: string) => any,
    privacy_policy_url: string,
    terms_conditions_url: string,
    isAccountCreationDisabled?: boolean
};

const Register = (props: PropsType): React.Node => {
    const [state, localActions] = useAsyncState();
    const recaptchaRef = React.useRef();

    const referrer = _.get(props, "location.state.from", null);

    // Saves form data into state, triggers T&C lightbox
    const handleFormSubmit = (formData: any) => {
        GoogleAnalytics.logEvent({
            category: "OnBoarding - done",
            action: "Open",
            label: "Terms"
        });

        const openTCModal = () => {
            // open terms modal
            handleTermsConfirmed(formData);
            // props.openGlobalModal({
            //     modalId: GlobalModals.TNC,
            //     onSuccess: (): any => handleTermsConfirmed(formData)
            // });
        };

        if (window.Modernizr) {
            if (window.Modernizr.touch) {
                // Make sure the keyboard goes away on submit, this fixes an edge
                // case where the user submits on iOS by hitting 'Enter' on the
                // keyboard rather than using the submit button
                if (document.activeElement) {
                    document.activeElement.blur();
                }
                // Opening a global modal whilst the keyboard is animating away
                // causes render issues on mobile browsers (Particularly iOS Chrome
                // & FireFox). Wrapping in a 100ms setTimeout to ensure the keyboard
                // has closed beofre opening the modal fixes the issue.
                setTimeout(openTCModal, 100);
            } else {
                openTCModal();
            }
        } else {
            openTCModal();
        }
    };

    const handleFacebookSuccess = () => {
        props.push(referrer || "/");
    };

    const handleFacebookError = (error: string) => {
        localActions.fail(error);
        gsap.to(window, {
            scrollTo: {
                y: 0,
                autoKill: false,
                duration: 1
            }
        });
    };

    // Triggered after T&C Agreed to
    // Trigger the register request
    const handleTermsConfirmed = async (formData: any): any => {
        if (formData) {
            localActions.request();

            const skipReCaptcha = cookie.get("skipReCaptcha") === "true";
            let googleRecaptchaResponse;
            if (recaptchaRef.current && !skipReCaptcha) {
                googleRecaptchaResponse = await recaptchaRef.current.executeAsync();
            } else if (!skipReCaptcha) {
                return;
            }

            props
                .register(
                    formData,
                    true,
                    googleRecaptchaResponse,
                    skipReCaptcha
                )
                .then(() => {
                    localActions.success();
                    props.push(Config.routes.emailVerification);
                })
                .catch((error: any) => {
                    if (recaptchaRef.current) {
                        recaptchaRef.current.reset();
                    }

                    localActions.fail(
                        error.friendly_message || "Something went wrong"
                    );

                    gsap.to(window, {
                        scrollTo: {
                            y: 0,
                            autoKill: false,
                            duration: 1
                        }
                    });
                });
        }
    };

    return (
        <React.Fragment>
            <MappedMetaTags defaultTitle="Sign Up" />

            <CheckoutHero>
                <div className="container-fluid-xsmall">
                    <CheckoutHero.Title button={<CheckoutHero.BackLink />}>
                        Sign Up
                    </CheckoutHero.Title>
                </div>
            </CheckoutHero>

            <div className="container-fluid-xsmall">
                <div className="content-card">
                    <div className="account-utils-form">
                        {props.isAccountCreationDisabled ? (
                            <ServerErrorMessage>
                                    <p style={{
                                    fontWeight: "bold",
                                    margin: "0 0 4px",
                                    lineHeight: "18px"
                                }}>Signing up is not available right now.</p>
                                <p style={{
                                    fontWeight: "400",
                                    margin: "0",
                                    lineHeight: "18px"
                                }}>If you already have an account, you can continue to log in.</p>
                            </ServerErrorMessage>
                        ) : (
                            <React.Fragment>
                                <ServerErrorMessage>{state.error}</ServerErrorMessage>
                                <p className="page-intro">
                                    Checkout faster, receive exclusive rewards and be
                                    the first to find out about new menu items and
                                    promotions.
                                </p>
        
                                <FacebookSignIn
                                    text="signup with Facebook"
                                    onSuccess={handleFacebookSuccess}
                                    onError={handleFacebookError}
                                />
        
                                <AppleSignIn
                                    text="Signup with Apple"
                                    onSuccess={handleFacebookSuccess}
                                    onError={handleFacebookError}
                                />
        
                                <div className="or">or</div>
        
                                <ReactForm
                                    onSuccess={handleFormSubmit}
                                    disableSubmitUntilValid
                                >
                                    <ReactFormField
                                        type="text"
                                        name="first_name"
                                        required={true}
                                        validators={["name"]}
                                        errorMessages={{
                                            required: "Please enter your first name."
                                        }}
                                        label="First Name"
                                    />
        
                                    <ReactFormField
                                        type="text"
                                        name="last_name"
                                        required={true}
                                        validators={["name"]}
                                        errorMessages={{
                                            required: "Please enter your last name."
                                        }}
                                        label="Last Name"
                                    />
        
                                    <ReactFormField
                                        type="email"
                                        name="email"
                                        autoComplete="username"
                                        required={true}
                                        validators={["email"]}
                                        errorMessages={{
                                            required: "Please enter your email address."
                                        }}
                                        label="Email"
                                        info="We will send you an email to verify your account"
                                    />
        
                                    <ReactFormField
                                        type="tel"
                                        name="phone"
                                        required={true}
                                        validators={["mobile"]}
                                        errorMessages={{
                                            required: "Please enter your mobile number."
                                        }}
                                        label="Mobile number"
                                    />
        
                                    <ReactFormField
                                        type="password"
                                        info="Your password must be at least 8 characters long and should include 1 uppercase letter, 1 lower case letter, 1 number and 1 special character"
                                        hideErrors
                                        passwordVisibility={true}
                                        name="password"
                                        required={true}
                                        showErrors={false}
                                        validators={["password"]}
                                        label="Create password"
                                        autoComplete="new-password"
                                    />
        
                                    <Button type="submit" loading={state.loading}>
                                        Sign Up
                                    </Button>
        
                                    <div className="form-footer">
                                        <p className="body-medium">
                                            By continuing you agree to our{" "}
                                            <TextButton
                                                styletype={"gray-underline"}
                                                big
                                                target="_blank"
                                                to={props.terms_conditions_url}
                                            >
                                                T&C's
                                            </TextButton>{" "}
                                            and{" "}
                                            <TextButton
                                                styletype={"gray-underline"}
                                                big
                                                target="_blank"
                                                to={props.privacy_policy_url}
                                            >
                                                Privacy Policy
                                            </TextButton>
                                        </p>
                                    </div>
        
                                    <div className="form-footer">
                                        <p className="body-small">
                                            Already have an account?{" "}
                                            <Link
                                                to={{
                                                    pathname: Config.routes.login,
                                                    state: { from: referrer }
                                                }}
                                            >
                                                Login
                                            </Link>
                                        </p>
                                    </div>
                                </ReactForm>
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    size="invisible"
                                    sitekey={Config.googleRecaptchaKey}
                                    badge="bottomright"
                                />
                            </React.Fragment>
                        )
                        }
                    </div>
                </div>
            </div>
            <Footer />
        </React.Fragment>
    );
};

const mapStateToProps = (state: any): any => {
    return {
        privacy_policy_url: state.cms.privacy_policy_url,
        terms_conditions_url: state.cms.terms_conditions_url,
        isAccountCreationDisabled:
            state.launchDarkly.flags[FeatureFlags.IS_ACCOUNT_CREATION_DISABLED]
    };
};

export default connect(
    mapStateToProps,
    {
        register: Actions.user.register,
        push,
        openGlobalModal: Actions.globalModals.openGlobalModal
    }
)(Register);
