import visaSVG from "@assets/images/visa.svg";
import mastercardSVG from "@assets/images/mastercard.svg";
import paypalSVG from "@assets/images/paypal.svg";
import applepaySVG from "@assets/images/applepay.svg";
import googlepaySVG from "@assets/images/googlepay.svg";
import amexSVG from "@assets/images/amex.svg";

const PaymentOptions = [
    {
        id: "visa",
        constraint: "credit-card",
        img: visaSVG,
        text: "Visa"
    },
    {
        id: "mastercard",
        constraint: "credit-card",
        img: mastercardSVG,
        text: "Mastercard"
    },
    {
        id: "amex",
        constraint: "credit-card",
        img: amexSVG,
        text: "Amex"
    },
    {
        id: "paypal",
        constraint: "paypal",
        img: paypalSVG,
        text: "Paypal"
    },
    {
        id: "applepay",
        constraint: "applepay",
        img: applepaySVG,
        text: "Apple Pay"
    },
    {
        id: "googlepay",
        constraint: "googlepay",
        img: googlepaySVG,
        text: "Google Pay"
    }
];

export default PaymentOptions;
