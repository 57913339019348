// @flow

import * as React from "react";

import Icon from "@components/atoms/Icon";

type PropsType = {
    children: React.Node,
    className: string,
    onClick: () => void
};

const AddButton = ({
    children,
    onClick,
    className,
    ...props
}: PropsType): React.Node => {
    return (
        <button
            className={`add-button ${className}`}
            onClick={onClick}
            {...props}
        >
            <Icon name="add" className="add-button__icon" />
            <span className="add-button__text">{children}</span>
        </button>
    );
};

AddButton.defaultProps = {
    className: "",
    onClick: () => {}
};

export default AddButton;
