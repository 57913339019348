// @flow

import React from "react";
import { connect } from "react-redux";

import { pubsub } from "@utils";
import Button from "@components/atoms/Button";

type PropsType = {
    className?: string,
    session: any,
    adyen: any
};

const CheckoutSubmitButton = (props: PropsType): any => {
    if (props.session.paymentMethodName === "PayPal") return null;
    return (
        <Button
            className="button--pay"
            styleType="green"
            onClick={(e: any): any => {
                pubsub.publish("CHECKOUT_FORM_SUBMIT");
            }}
        >
            <i className="button__lock-icon icon icon-lock-with-hole"></i>
            Confirm and pay now
        </Button>
    );
};

const mapStateToProps = (state: any): any => {
    return {
        session: state.session,
        adyen: state.adyen
    };
};

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CheckoutSubmitButton);
