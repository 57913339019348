// @flow

import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "@utils/hooks/withRouter";
import * as GoogleAnalytics from "@utils/GoogleAnalytics";
import _ from "lodash";

import Config from "@Config";
import OrderComp from "@components/molecules/OrderComp";
import RoundedButton from "@components/atoms/RoundedButton";
import LineItem from "@components/molecules/LineItem";
import CartTotals from "@components/molecules/CartTotals";
import Strings from "@Strings";
import FeatureFlags from "@FeatureFlags";

type PropsType = {
    cart: any,
    session: any,
    isAuthenticated: boolean,
    defaultTime: string,
    vouchers: any,
    isCMJCLEnabled?: boolean
};

const OrderSummary = (props: PropsType): React.Node => {
    const baseClassname = "order-summary";
    let coffeeLoyaltyEligibilityMessage;

    console.log(props.vouchers.coffeeLoyaltyEligibility)
    console.log(props.vouchers.coffeeLoyalty)

    if (props.vouchers.coffeeLoyalty && props.vouchers.coffeeLoyaltyEligibility) {
        if (props.vouchers.coffeeLoyalty.has_free_coffee_reward && (props.vouchers.coffeeLoyaltyEligibility.is_eligible_to_voucher || props.vouchers.coffeeLoyaltyEligibility.eligible_credits > 0)) {
            if (props.isCMJCLEnabled) {
                coffeeLoyaltyEligibilityMessage = "You have a FREE coffee, but you can't redeem it in the app right now. Please scan your coffee card at the cashier instead."
            }
        }
    }

    return (
        <div className={`${baseClassname}`}>
            <div className={`${baseClassname}__header`}>
                <div className={`${baseClassname}__header__info`}>
                    <h3 className="heading-3">Order Summary</h3>
                    {_.get(props, "session.deliveryTime") === "asap" && (
                        <p className="body-small body-small--subdued">
                            {Strings.asapDeliveryEstimate + props.defaultTime}
                        </p>
                    )}
                </div>
                <div className={`${baseClassname}__header__button`}>
                    <RoundedButton
                        to={Config.routes.cart}
                        onClick={() => {
                            if (!props.isAuthenticated) {
                                GoogleAnalytics.logEvent({
                                    category: "GuestContactDetails",
                                    action: "Change",
                                    label: "EditOrder"
                                });
                            }
                        }}
                    >
                        Edit order
                    </RoundedButton>
                </div>
            </div>

            {coffeeLoyaltyEligibilityMessage ? (
                <div className={`${baseClassname}__header__stamps`}>
                    {coffeeLoyaltyEligibilityMessage}
                </div>
            ) : null}

            <div className={`${baseClassname}__items`}>
                {props.cart.line_items.map(
                    (lineItem: any, index: number): React.Node => (
                        <LineItem
                            key={index}
                            editable={false}
                            isCartItem={false}
                            lineItem={lineItem}
                            small={true}
                            summary={true}
                        />
                    )
                )}
            </div>

            {props.cart.comp_code && (
                <div className={`${baseClassname}__items`}>
                    <OrderComp
                        voucher_name={props.cart.comp_code}
                        discount={props.cart.discounts_total}
                        editable={false}
                    />
                </div>
            )}

            <div className={`${baseClassname}__totals`}>
                <CartTotals small={true} />
            </div>
        </div>
    );
};

const mapStateToProps = (state: any): any => {
    return {
        cart: state.cart,
        isAuthenticated: state.session.isAuthenticated,
        defaultTime:
            state.config.order_delivery.default_expected_time_display.text,
        session: state.session,
        vouchers: state.vouchers,
        isCMJCLEnabled:
            state.launchDarkly.flags[FeatureFlags.IS_CM_MVP_JCL_ENABLED]
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        {}
    )(OrderSummary)
);
