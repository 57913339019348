// @flow

import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "@utils/hooks/withRouter";
import Config from "@Config";

import API from "../../apis/APIFactory";
import useAsyncState from "@utils/hooks/useAsyncState";
import Button from "@components/atoms/Button";
import ServerErrorMessage from "@components/atoms/ServerErrorMessage";
import actions from "@actions";

const { useEffect, Fragment } = React;

type PropsType = {
    userKey: string,
    token: string,
    getInfo: () => Promise<any>
};

const VerifyUserEmail = (props: PropsType): React.Node => {
    const { userKey, token } = props.router.params;

    const [state, actions] = useAsyncState();

    useEffect(() => {
        actions.request();
        API.UserApi.verifyEmail(userKey, token)
            .then(props.getInfo)
            .then(() => {
                actions.success("Email verified.");
            })
            .catch((error: any) => {
                console.error(error);
                actions.fail(error.friendly_message || "Something went wrong");
            });
    }, [userKey, token]);

    return (
        <Fragment>
            {state.loading ? (
                <div className="loader-container">
                    <div className="loader" />
                </div>
            ) : state.error ? (
                <ServerErrorMessage>{state.error}</ServerErrorMessage>
            ) : (
                <p className="page-intro">Email successfully verified</p>
            )}

            {!state.loading && !state.error ? (
                <Button
                    styleType={"inline"}
                    to={Config.routes.account}
                    disabled={state.loading}
                >
                    Continue To Account
                </Button>
            ) : null}

            {!state.loading && state.error ? (
                <Button
                    styleType={"inline"}
                    to={Config.routes.home}
                    disabled={state.loading}
                >
                    Continue To Ordering
                </Button>
            ) : null}
        </Fragment>
    );
};

const mapStateToProps = (state: any): any => {
    return {};
};

export default withRouter(
    connect(
        mapStateToProps,
        { getInfo: actions.user.getInfo }
    )(VerifyUserEmail)
);
