export const cookie = (() => {
    const get = name => {
        return _getAll()[name] || null;
    };

    function set(name, value, days) {
        var domain, domainParts, date, expires, host;

        if (days) {
            date = new Date();
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
            expires = "; expires=" + date.toGMTString();
        } else {
            expires = "";
        }

        host = document.location.host.split(":")[0];
        if (host.split(".").length === 1) {
            // no "." in a domain - it's localhost or something similar
            document.cookie = name + "=" + value + expires + "; path=/";
        } else {
            // Remember the cookie on all subdomains.
            //
            // Start with trying to set cookie to the top domain.
            // (example: if user is on foo.com, try to set
            //  cookie to domain ".com")
            //
            // If the cookie will not be set, it means ".com"
            // is a top level domain and we need to
            // set the cookie to ".foo.com"
            domainParts = host.split(".");
            domainParts.shift();
            domain = "." + domainParts.join(".");

            document.cookie =
                name + "=" + value + expires + "; path=/; domain=" + domain;

            // check if cookie was successfuly set to the given domain
            // (otherwise it was a Top-Level Domain)
            if (cookie.get(name) == null || cookie.get(name) !== value) {
                // append "." to current domain
                domain = "." + host;
                document.cookie =
                    name + "=" + value + expires + "; path=/; domain=" + domain;
            }
        }
    }

    function remove(name) {
        document.cookie = `${name}"=; expires=${new Date(0).toUTCString()};`;
    }

    // function _createExpiresString(days) {
    //     const date = new Date();
    //     date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    //     // $FlowFixMe
    //     return `;expires=${date.toGMTString()}`;
    // }

    const _getAll = () => {
        return document.cookie
            .split(";") // Separate into individual cookies
            .map(cookie => cookie.trim()) // Remove surrounding whitespace
            .map(cookie => cookie.split("=")) // Split cookie into key value pairs where [0] = key and [1] = value
            .reduce(
                (prev, cookiePair) => ({
                    ...prev,
                    [cookiePair[0]]: cookiePair[1]
                }),
                {}
            ); // Convert to real key value object
    };

    return {
        get,
        set,
        remove
    };
})();

export default cookie;
