import React, { Component } from "react";
import DateService from "@utils/DateService";
import moment from "moment";

export default class CompCountdown extends Component {
    constructor(props) {
        super(props);
        this.expiry = moment(this.props.expiry);
        this.state = {
            secondLeft: this.expiry.diff(DateService.now(), "seconds")
        };
        if (this.props.showCountdown) {
            this.timer = setInterval(() => {
                this.setState({
                    secondLeft: this.expiry.diff(DateService.now(), "seconds")
                });
            }, 200);
        }
    }

    componentWillUnmount() {
        if (this.timer) {
            clearInterval(this.timer);
        }
    }

    render() {
        return this.props.showCountdown && this.state.secondLeft < 86400 ? (
            <div className="comp-countdown counting">
                <div className="comp-countdown__title">
                    {"Expires in".toUpperCase()}
                </div>
                <div className="comp-countdown__detail">
                    {`${(this.state.secondLeft / 3600).toFixed()}h `.padStart(
                        4,
                        "0"
                    ) +
                        `${(this.state.secondLeft / 60).toFixed() %
                            60}m `.padStart(4, "0") +
                        `${this.state.secondLeft % 60}s`.padStart(3, "0")}
                </div>
            </div>
        ) : (
            <div className="comp-countdown">
                <div className="comp-countdown__title">
                    {`Expires ${this.expiry.format("h:mmA")}`.toUpperCase()}
                </div>
                <div className="comp-countdown__detail">
                    {this.expiry.format("DD/MM/YYYY")}
                </div>
            </div>
        );
    }
}
