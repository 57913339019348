import ActionTypes from "@actionTypes";

const initialState = {
    flags: {}
};

export default function deviceReducer(state = initialState, action = {}) {
    switch (action.type) {
        case ActionTypes.SET_LAUNCH_DARKLY_FLAGS:
            return {
                ...state,
                flags: action.flags
            };
        default:
            return state;
    }
}
