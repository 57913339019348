// @flow

import _ from "lodash";
import * as React from "react";

type PropsType = {
    className?: ?string,
    children: ?any,
    duration?: ?number,
    delayShrink?: ?number,
    delayExpand?: ?number,
    height?: ?number,
    hideOverflow?: ?boolean,
    timingFunction?: ?string,
    disabled?: boolean
};

function AnimateHeight(props: PropsType) {
    const [height, setHeight] = React.useState(0);
    const [delay, setDelay] = React.useState(0);

    const outerRef = React.useRef(null);
    const innerRef = React.useRef(null);

    React.useEffect((): any => {
        const animate = () => {
            if (innerRef.current && outerRef.current) {
                let innerRefHeight = _.get(innerRef, "current.clientHeight", 0);
                let outerRefHeight = _.get(outerRef, "current.clientHeight", 0);
                const innerHeight = !isNaN(parseInt(props.height))
                    ? parseInt(props.height)
                    : innerRefHeight;
    
                if (!height || outerRefHeight !== innerHeight) {
                    setDelay(
                        innerRef.current.clientHeight <
                        outerRef.current.clientHeight
                            ? props.delayShrink || 0
                            : props.delayExpand || 0
                    )

                    setHeight(
                        !isNaN(parseInt(innerHeight))
                        ? parseInt(innerHeight)
                        : "auto"
                    );

                    requestAnimationFrame(() => {
                        animate()
                    })
                }
            }
        }

        animate()
    }, [height, props.delayExpand, props.delayShrink, props.height])

    let styleObject = {
        height: height,
        transition: `all ${props.duration || 500}ms`,
        transitionDelay: `${delay || 0}ms`,
        overflow: props.hideOverflow ? "hidden" : "visible",
        transitionTimingFunction: props.timingFunction || null
    };

    if (height === 0) {
        styleObject = {
            ...styleObject,
            borderTopWidth: "0",
            borderBottomWidth: "0"
        };
    }

    if (props.disabled) {
        return props.children;
    } else {
        return (
            <div
                ref={outerRef}
                className={props.className}
                style={styleObject}
            >
                <div ref={innerRef}>{props.children}</div>
            </div>
        );
    }
}

export default AnimateHeight;
