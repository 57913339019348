// @flow

import ActionTypes from "@actionTypes";
import * as GoogleAnalytics from "@utils/GoogleAnalytics";

export type GlobalModalPropsType = {
    modalId: string,
    onSuccess?: () => void,
    toPath?: string,
    fromCoffeeLoyalty?: number
};

export const openGlobalModal = ({
    modalId,
    onSuccess,
    toPath,
    fromCoffeeLoyalty
}: GlobalModalPropsType): any => ({
    type: ActionTypes.OPEN_GLOBAL_MODAL,
    modalId,
    onSuccess,
    toPath,
    fromCoffeeLoyalty
});

export const closeGlobalModal = (modalId: string, keepOnSuccess: any): any => ({
    type: ActionTypes.CLOSE_GLOBAL_MODAL,
    modalId,
    keepOnSuccess
});

export const openAlertModal = (alertConfig: any): any => (
    dispatch: any
): any => {
    dispatch({
        type: ActionTypes.OPEN_ALERT_MODAL,
        title: alertConfig.title,
        body: alertConfig.body,
        confirmText: alertConfig.confirmText,
        cancelText: alertConfig.cancelText,
        onConfirm: alertConfig.onConfirm,
        onCancel: alertConfig.onCancel,
        html: alertConfig.html,
        size: alertConfig.size,
        textAlign: alertConfig.textAlign,
        terms: alertConfig.terms,
        hideClose: alertConfig.hideClose
    });
};

export const closeAlertModal = (): any => ({
    type: ActionTypes.CLOSE_ALERT_MODAL
});

export const closeAllModals = (): any => ({
    type: ActionTypes.CLOSE_ALL_MODALS
});

export const openVoucherModal = (voucher: any, isComp?: boolean): any => (
    dispatch: any
): any => {
    GoogleAnalytics.logEvent({
        category: "Vouchers",
        action: "Open",
        label: "Voucher_Details",
        value: voucher.id
    });

    dispatch({
        type: ActionTypes.OPEN_VOUCHER_MODAL,
        voucher: voucher,
        isComp: isComp
    });
};

export const closeVoucherModal = (): any => ({
    type: ActionTypes.CLOSE_VOUCHER_MODAL
});

export const openBarcodeOverlay = (voucher: any, isComp?: boolean): any => (
    dispatch: any
): any => {
    dispatch({
        type: ActionTypes.OPEN_BARCODE_OVERLAY
    });
};

export const closeBarcodeOverlay = (): any => ({
    type: ActionTypes.CLOSE_BARCODE_OVERLAY
});
