// @flow

const pubSub = ((): { subscribe: any, publish: any } => {
    const events = {};

    const subscribe = (
        name: string,
        callback: any,
        initialTrigger: boolean
    ): any => {
        if (!events[name]) {
            events[name] = [];
        }

        const index = events[name].push(callback) - 1;

        if (initialTrigger) {
            pubSub.publish(name);
        }

        return {
            remove: () => {
                delete events[name][index];
            }
        };
    };

    const publish = (name: string, data: any) => {
        if (!events[name]) {
            return;
        }

        events[name].forEach((event: any) => {
            if (typeof event === "function") {
                event(data || {});
            }
        });
    };

    return {
        subscribe,
        publish
    };
})();

export default pubSub;
