// @flow

import * as React from "react";
import { connect } from "react-redux";
import { push } from "@lagunovsky/redux-react-router";
import _ from "lodash";
import * as GoogleAnalytics from "@utils/GoogleAnalytics";
import PoweredByMenulog from "@components/atoms/PoweredByMenulog";
import { appboyLogCustomEvent } from "@utils/AppBoy";

import actions from "@actions";
import Button from "@components/atoms/Button";
import GlobalModals from "@Modals";
import Config from "@Config";

type PropsType = {
    openGlobalModal: typeof actions.globalModals.openGlobalModal,
    closeGlobalModal: typeof actions.globalModals.closeGlobalModal,
    push: typeof push,
    selectedStoreId: number,
    appDeeplink: string
};

const StartOrder = (props: PropsType): React.Node => {
    const handleDeliveryCTAClick = () => {
        appboyLogCustomEvent("DeliveryCTA");
        GoogleAnalytics.logEvent({
            category: "OrderHome",
            action: "StartOrder",
            label: "Delivery"
        });
        props.openGlobalModal({
            modalId: GlobalModals.ADDRESS_LOCATOR,
            toPath:
                window.location.pathname.indexOf(Config.routes.cart) > -1
                    ? Config.routes.cart
                    : null
        });
    };

    return (
        <section className="start-order">
            <div className="container-fluid">
                <div className="start-order__inner">
                    <h3 className="start-order__title heading-3">
                        Start a new order
                    </h3>
                    <div className="start-order__buttons">
                        <div className="start-order__button">
                            {props.selectedStoreId ? (
                                <Button
                                    styleType="green"
                                    to={`${Config.routes.menu}/${props.selectedStoreId}`}
                                    onClick={() => {
                                        appboyLogCustomEvent("DeliveryCTA");
                                        GoogleAnalytics.logEvent({
                                            category: "OrderHome",
                                            action: "StartOrder",
                                            label: "Delivery"
                                        });
                                    }}
                                >
                                    <i className={`icon icon-delivery`} />
                                    Delivery
                                </Button>
                            ) : (
                                <Button
                                    styleType="green"
                                    onClick={handleDeliveryCTAClick}
                                >
                                    <i className={`icon icon-delivery`} />
                                    Delivery
                                </Button>
                            )}
                            <PoweredByMenulog className="start-order__button_info" />
                        </div>
                        <Button
                            to={props.appDeeplink}
                            styleType="green"
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() => {
                                appboyLogCustomEvent("PickupCTA");
                                GoogleAnalytics.logEvent({
                                    category: "OrderHome",
                                    action: "StartOrder",
                                    label: "Pickup"
                                });
                            }}
                        >
                            <i className={`icon icon-pickup`} />
                            Pick-up on app
                        </Button>
                    </div>
                </div>
            </div>
        </section>
    );
};

const mapStateToProps = (state: any): any => ({
    selectedStoreId: _.get(state, "session.selectedStore.store_id", false),
    appDeeplink: state.config.app_branch_deeplink_url
});

export default connect(
    mapStateToProps,
    {
        openGlobalModal: actions.globalModals.openGlobalModal,
        closeGlobalModal: actions.globalModals.closeGlobalModal,
        push
    }
)(StartOrder);
