// @flow

// Essentially a setInterval but for functions that return a promise
// Only queues the next interval after the promise finishes, this guarantees
// there always being the minmum amount of time between subsequent responses
const promiseSetInterval = (
    promiseReturningFunction: () => Promise<any>,
    interval: number,
    skipInitialExecution?: boolean = true
): { remove: () => void } => {
    let isIntervalActive = true;

    // Queues the next iteration of the passed in callback
    const queueNext = () => {
        if (isIntervalActive) {
            setTimeout(() => {
                // We are doing an additional check for isIntervalActive here
                // because it is possible that isIntervalActive has changed
                // since the setTimeout was originally queued
                if (isIntervalActive) {
                    main(false);
                }
            }, interval);
        }
    };

    // Encapsulate main logic into a worker function so we can
    // capture the isIntervalActive variable above
    const main = (skip: boolean) => {
        if (skip) {
            queueNext();
        } else {
            promiseReturningFunction()
                .catch(console.error)
                .finally(queueNext);
        }
    };

    // Run the main function once, immediately
    main(skipInitialExecution);

    return {
        // Include a remove function that can be used for cleanup
        // Will prevent any future timeouts from firing
        remove: () => {
            isIntervalActive = false;
        }
    };
};

export default promiseSetInterval;
