// @flow

import * as React from "react";
import { connect } from "react-redux";
import _ from "lodash";

import actions from "@actions";
import ProductImage from "@components/atoms/ProductImage";
import CompCountdown from "./CompCountdown";

const { Fragment } = React;

type PropsType = {
    useDraggableLinkHandlers?: boolean,
    voucher: {
        id: number,
        thumb: string,
        code?: string,
        show_countdown?: boolean,
        expiry?: string,
        expiration_date?: string,
        payment_methods_constraints?: any
    },
    isComp?: boolean,
    image_base_path: string,
    comps_image_base_path: string,
    openVoucherModal: any,
    storeId: number,
    paymentSupport: any
};

const VoucherTile = (props: PropsType): React.Node => {
    const baseClassname = "voucher-tile";

    const handleVoucherClick = (): any => {
        props.openVoucherModal(props.voucher, props.isComp);
    };

    return (
        <Fragment>
            <a
                className={`${baseClassname}`}
                data-expiration-date={
                    props.voucher ? props.voucher.expiration_date : ""
                }
                data-expiry={props.voucher ? props.voucher.expiry : ""}
                {...{ onClick: handleVoucherClick }}
            >
                <div className={`${baseClassname}__inner`}>
                    <ProductImage
                        className={`${baseClassname}__img`}
                        src={`${
                            props.isComp
                                ? props.comps_image_base_path
                                : props.image_base_path
                        }${props.voucher.thumb}`}
                        alt={props.voucher.name}
                    />
                    {props.isComp && (
                        <div className={`${baseClassname}__comp`}>
                            <div className={`${baseClassname}__comp__left`}>
                                <div>Online Exclusive</div>
                                <div className={`${baseClassname}__comp__code`}>
                                    {props.voucher.code}
                                </div>
                            </div>
                            <CompCountdown
                                showCountdown={props.voucher.show_countdown}
                                expiry={props.voucher.expiry}
                            />
                        </div>
                    )}
                </div>
            </a>
        </Fragment>
    );
};

VoucherTile.defaultProps = {
    useDraggableLinkHandlers: false
};

export const PlaceholderVoucher = (): React.Node => (
    <div className="voucher-placeholder">
        <div className="voucher-placeholder__inner" />
    </div>
);

export const CheckBackSoonVoucher = (): React.Node => (
    <div className="voucher-check-back">
        <div className="voucher-check-back__inner">
            <div className="voucher-check-back__content">
                <i className="icon icon-voucher" />
                <p className="voucher-check-back__text">
                    Check back soon for more offers
                </p>
            </div>
        </div>
    </div>
);

const mapStateToProps = (state: any): any => ({
    paymentSupport: state.device.paymentSupport,
    image_base_path: state.vouchers.image_base_path,
    comps_image_base_path: state.vouchers.comps_image_base_path,
    storeId: _.get(state, "session.selectedStore.store_id", 0)
});

export default connect(
    mapStateToProps,
    {
        openVoucherModal: actions.globalModals.openVoucherModal
    }
)(VoucherTile);
