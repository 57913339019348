// @flow

import * as React from "react";
import { connect } from "react-redux";
import AccountPage from "./AccountPage";

import Actions from "@actions";
import Modal from "@components/organisms/Modal";
import AddressLocator from "@components/organisms/AddressLocator";
import DeliveryAddressItem from "@components/molecules/DeliveryAddressItem";
//import TextButton from "@components/atoms/TextButton";
import ServerErrorMessage from "@components/atoms/ServerErrorMessage";
import AddButton from "@components/atoms/AddButton";
import AnimateHeight from "@components/atoms/AnimateHeight";

import { type SavedAddressType, type ProfileType } from "@SharedTypes";

const { useState } = React;

type PropsType = {
    user: ProfileType,
    savedAddresses: {
        addresses: SavedAddressType[],
        isFetching: boolean,
        error: any
    },
    addSavedAddress: typeof Actions.savedAddresses.addSavedAddress
};

const DeliveryAddresses = (props: PropsType): React.Node => {
    const [addressFinderActive, setAddressFinderActive] = useState(false);
    const [error, setError] = useState(null);

    const handleAddAddressClick = () => {
        setAddressFinderActive(true);
    };

    const handleAddressFinderSubmit = ({
        selectedRecordId,
        addressNickname,
        deliveryNotes,
        unitNumber
    }: any) => {
        setAddressFinderActive(false);
        props
            .addSavedAddress(
                selectedRecordId,
                addressNickname,
                deliveryNotes,
                unitNumber
            )
            .catch((err: any) => {
                setError(err.friendly_message || "Something went wrong");
                console.error(err);
            });
    };

    const addresses = props.savedAddresses.addresses;

    return (
        <AccountPage
            title="Delivery Addresses"
            intro="Save new addresses for faster checkout."
        >
            <ServerErrorMessage>{error}</ServerErrorMessage>
            <div className="delivery-address-list">
                <div className="delivery-address-list__items">
                    <AnimateHeight duration={500} hideOverflow={true}>
                        {props.savedAddresses.isFetching &&
                        addresses.length === 0 ? (
                            <p>Loading...</p>
                        ) : (
                            addresses.map(
                                (address: SavedAddressType): React.Node => (
                                    <DeliveryAddressItem
                                        key={address.address_id}
                                        address={address}
                                    />
                                )
                            )
                        )}
                    </AnimateHeight>
                </div>
                <AddButton onClick={handleAddAddressClick}>
                    Add new address
                </AddButton>
            </div>
            <Modal
                className="address-locator-modal"
                active={addressFinderActive}
                onClose={() => {
                    setAddressFinderActive(false);
                }}
            >
                <AddressLocator
                    addSavedAddress={true}
                    onSuccess={handleAddressFinderSubmit}
                />
            </Modal>
        </AccountPage>
    );
};

const mapStateToProps = (state: any): any => {
    return {
        user: state.user,
        savedAddresses: state.savedAddresses
    };
};

export default connect(
    mapStateToProps,
    {
        addSavedAddress: Actions.savedAddresses.addSavedAddress
    }
)(DeliveryAddresses);
