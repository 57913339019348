// @flow
import { legacy_createStore as createStore, applyMiddleware, compose } from 'redux'
import {
    createRouterMiddleware
} from '@lagunovsky/redux-react-router'
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import { createBrowserHistory } from "history";
import reducers from "@reducers";
import ErrorReporter from "@utils/ErrorReporter";
import RouteChange from "@utils/RouteChange";

// History object for redux
export const history = createBrowserHistory();

// Redux crash reporter
const crashReporter = (store: any): any => (next: any): any => (
    action: any
): any => {
    try {
        return next(action);
    } catch (error) {
        ErrorReporter({
            msg: error,
            data: action
        });
    }
};

// Create single array for all middlewares and add logger when needed only
const middlewares = [
    createRouterMiddleware(history),
    thunk,
    crashReporter,
    RouteChange
    // ModalChange
];

// Include redux logger middleware only for local development
if (process.env.NODE_ENV === "development") {
    const loggerMiddleware = createLogger({
        collapsed: true
    });
    middlewares.push(loggerMiddleware);
}

const initialState = {};

export const routerSelector = (state) => state.navigator

export const store = createStore(
    reducers(history),
    initialState,
    compose(applyMiddleware(...middlewares))
);

export default store;
