// @flow

import * as React from "react";
import { connect } from "react-redux";

import AccountPage from "./AccountPage";
import { Row, Col } from "../../containers/Grid";
import Button from "@components/atoms/Button";
import PastOrder from "@components/molecules/PastOrder";
import GlobalModals from "@Modals";
import actions from "@actions";

import { type ProfileType, type OrderType } from "@SharedTypes";

type PropsType = {
    user: ProfileType,
    orders: {
        isFetching: boolean,
        past: Array<OrderType>,
        upcoming: Array<OrderType>,
        actve: Array<OrderType>
    },
    openGlobalModal: typeof actions.globalModals.openGlobalModal
};

const MyOrders = (props: PropsType): React.Node => {
    const baseClassname = "my-orders";
    const handleDeliveryCTAClick = () => {
        props.openGlobalModal({ modalId: GlobalModals.ADDRESS_LOCATOR });
    };
    return (
        <AccountPage
            title="My Orders"
            intro="Re-order a past delivery order and these items will be sent to your cart for review."
        >
            <Row>
                <Col xs={12}>
                    <div className={`${baseClassname}__upcoming`}>
                        <h3 className="heading-3">Upcoming orders</h3>
                        <hr style={{ marginBottom: 0 }} />
                        {props.orders.upcoming.length > 0 ? (
                            props.orders.upcoming.map(
                                (order: any, index: number): React.Node => (
                                    <PastOrder order={order} key={index} />
                                )
                            )
                        ) : (
                            <>
                                <p>
                                    You don't have any upcoming orders right
                                    now. Place an order for later and it will be
                                    shown here.
                                </p>
                                <Button
                                    onClick={handleDeliveryCTAClick}
                                    styleType={["inline", "clear"]}
                                    style={{ marginBottom: 40, marginTop: 25 }}
                                >
                                    Start a new order
                                </Button>
                            </>
                        )}
                    </div>
                    {props.orders.past.length > 0 ? (
                        <div className={`${baseClassname}__past`}>
                            <h3 className="heading-3">Past orders</h3>
                            <hr style={{ marginBottom: 0 }} />
                            {props.orders.past.map(
                                (order: any, index: number): React.Node => (
                                    <PastOrder order={order} key={index} />
                                )
                            )}
                        </div>
                    ) : null}
                </Col>
            </Row>
        </AccountPage>
    );
};

const mapStateToProps = (state: any): any => {
    return {
        user: state.user,
        orders: state.orders
    };
};

export default connect(
    mapStateToProps,
    {
        openGlobalModal: actions.globalModals.openGlobalModal
    }
)(MyOrders);
