// @flow

import * as React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import moment from "moment";
import AccountPage from "./AccountPage";
import { ReactForm, ReactFormField } from "@adrenalin/react-form";
import { Row, Col } from "../../containers/Grid";
import Button from "@components/atoms/Button";
import ServerErrorMessage from "@components/atoms/ServerErrorMessage";
import Actions from "@actions";

import { type ProfileType } from "@SharedTypes";
import RadioGroup from "../atoms/RadioGroup";
import { maskDate } from "../../utils/maskInput";
import useAsyncState from "../../utils/hooks/useAsyncState";
import CompleteProfile from "../molecules/CompleteProfile";
import Config from "@Config";
import TextButton from "@components/atoms/TextButton";

import FeatureFlags from "../../constants/FeatureFlags";

const { useState, Fragment } = React;

const customValidators = [
    {
        ID: "au-date",
        validate: (input: { value: string }): boolean => {
            var pattern = /^(0[1-9]|[1-2][0-9]|3[0-1])(\/)(0[1-9]|1[0-2])(\/)(\d{4})$/gi;
            return pattern.test(input.value);
        },
        error: "Please make sure the date is DD/MM/YYYY"
    },
    {
        ID: "au-postcode",
        validate: (input: { value: string }): boolean => {
            var pattern = /^(\d{4})$/;
            return pattern.test(input.value);
        },
        error: "Please enter a valid postcode"
    }
];

type FormType = {
    first_name: string,
    last_name: string,
    email: string,
    birth_date: string,
    phone: string,
    postcode: string,
    gender: string
};

type PropsType = {
    user: ProfileType,
    updateUserInfo: (profile: ProfileType) => Promise<any>,
    isAccountCreationDisabled?: boolean
};

const Profile = (props: PropsType): React.Node => {
    const hasInitialDate = !!props.user.birth_date;
    const [state, localActions] = useAsyncState();
    const [birthDate, setBirthDate] = useState(
        hasInitialDate ? moment(props.user.birth_date).format("DD/MM/YYYY") : ""
    );
    const disableEmail = props.user.email && props.user.unverified_email;

    const handleFormSubmit = (data: FormType) => {
        const filteredData = _.pickBy(data, (a: any): boolean => !!a);

        // Don't send email if the email field is in a locked state
        if (disableEmail) {
            delete filteredData.email;
        }

        if (hasInitialDate) {
            delete filteredData.birth_date;
        } else {
            // Add formatted date
            const formattedBirthDate = moment(birthDate, "DD/MM/YYYY").format(
                "YYYY-MM-DD"
            );
            if (birthDate) {
                filteredData.birth_date = formattedBirthDate;
            }
        }

        localActions.request();
        props
            .updateUserInfo(filteredData)
            .then((response: any) => {
                localActions.success("Success!");
            })
            .catch((error: any) => {
                console.error(error);
                localActions.fail(
                    error.friendly_message || "Something went wrong"
                );
            });
    };

    const genderOptions = [
        { label: "Female", value: "Female", id: "gender--female" },
        { label: "Male", value: "Male", id: "gender--male" },
        { label: "Other", value: "Other", id: "gender--other" },
        {
            label: "Prefer not to say",
            value: "PreferNotToSay",
            id: "gender--prefer_not_to_say"
        }
    ];

    return (
        <AccountPage
            title="Profile"
            intro="Keep your profile up to date to receive your exclusive rewards and have a seamless ordering experience."
        >
            <Row>
                <Col xs={12}>
                    {props.isAccountCreationDisabled &&
                        <ServerErrorMessage>
                            <p style={{
                                fontWeight: "bold",
                                margin: "0 0 4px",
                                lineHeight: "18px"
                            }}>You cannot edit your name or birthday right now.</p>
                            <p style={{
                                fontWeight: "400",
                                margin: "0",
                                lineHeight: "18px"
                            }}>We're working on some updates, please try again later.</p>
                        </ServerErrorMessage>
                    }
                </Col>
                <Col xs={12} lg={9} xl={6} className="last-xl">
                    {props.user.profile_percent_complete < 100 && (
                        <CompleteProfile
                            percent={props.user.profile_percent_complete}
                        />
                    )}
                </Col>
                <Col xs={12} lg={9} xl={6}>
                    <ServerErrorMessage>{state.error}</ServerErrorMessage>
                    <ReactForm
                        onSuccess={handleFormSubmit}
                        validators={customValidators}
                    >
                        <ReactFormField
                            type="text"
                            name="first_name"
                            label="First Name"
                            validators={["name"]}
                            defaultValue={props.user.first_name}
                            required
                            disabled={props.isAccountCreationDisabled}
                        />
                        <ReactFormField
                            type="text"
                            name="last_name"
                            label="Last Name"
                            validators={["name"]}
                            defaultValue={props.user.last_name}
                            required
                            disabled={props.isAccountCreationDisabled}
                        />
                        <ReactFormField
                            type="email"
                            name="email"
                            label="Email"
                            validators={["email"]}
                            defaultValue={
                                props.user.email || props.user.unverified_email
                            }
                            disabled={disableEmail}
                            required
                            info={
                                props.user.email &&
                                props.user.unverified_email ? (
                                    <Fragment>
                                        You requested to change your email to{" "}
                                        <strong>
                                            {props.user.unverified_email}
                                        </strong>
                                        . Please verify that email to complete
                                        the change.{" "}
                                        <TextButton
                                            to={
                                                Config.routes
                                                    .emailVerificationEdit
                                            }
                                            small
                                        >
                                            Edit email
                                        </TextButton>
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        If you change your email, you will need
                                        to verify it to complete the change.
                                    </Fragment>
                                )
                            }
                        />
                        <ReactFormField
                            type="text"
                            name="birth_date"
                            label="Date of birth (optional)"
                            placeholder="DD / MM / YYYY"
                            value={birthDate}
                            validators={["au-date"]}
                            disabled={hasInitialDate || props.isAccountCreationDisabled}
                            onChange={(
                                event: SyntheticEvent<HTMLInputElement>
                            ) => {
                                const { value } = event.currentTarget;
                                const maskedDate = maskDate(value);
                                setBirthDate(maskedDate);
                            }}
                            info={
                                hasInitialDate
                                    ? "You can't change your birthday, sorry!"
                                    : "Enter your date of birth carefully as this cannot be changed."
                            }
                        />
                        <ReactFormField
                            type="text"
                            name="phone"
                            label="Mobile Number"
                            validators={["mobile"]}
                            required
                            defaultValue={props.user.phone || null}
                        />
                        <ReactFormField
                            type="text"
                            name="postcode"
                            label="Postcode (optional)"
                            defaultValue={props.user.postcode}
                            validators={["au-postcode"]}
                        />
                        <RadioGroup label="Gender (optional)">
                            {genderOptions.map(
                                (option: any, index: number): React.Node => {
                                    return (
                                        <ReactFormField
                                            key={index}
                                            type="radio"
                                            name="gender"
                                            defaultChecked={
                                                props.user.gender ===
                                                option.value
                                            }
                                            {...option}
                                        />
                                    );
                                }
                            )}
                        </RadioGroup>
                        <div className="react-form__field react-form__field--submit">
                            <Button
                                type="submit"
                                styleType="inline"
                                success={!!state.success}
                                loading={state.loading}
                            >
                                Save
                            </Button>
                        </div>
                    </ReactForm>
                </Col>
            </Row>
        </AccountPage>
    );
};

const mapStateToProps = (state: any): any => {
    return {
        user: state.user,
        isAccountCreationDisabled:
            state.launchDarkly.flags[FeatureFlags.IS_ACCOUNT_CREATION_DISABLED]
    };
};

export default connect(
    mapStateToProps,
    {
        updateUserInfo: Actions.user.updateInfo
    }
)(Profile);
