// @flow

import * as React from "react";
import _ from "lodash";

type PropsType = {
    label: string,
    children: React.Node
};

const RadioGroup = (props: PropsType): React.Node => {
    const name = React.Children.map(
        props.children,
        (child: React.Node): any => {
            return _.get(child, "props.name", "");
        }
    )[0];
    return (
        <div className="radio-group">
            <label htmlFor={name} className="radio-group__label">
                {props.label}
            </label>
            {props.children}
        </div>
    );
};

export default RadioGroup;
