import ActionTypes from "@actionTypes";

const initialState = {
    isFetching: false,
    error: null,
    addresses: []
};

const savedAddressesReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case ActionTypes.GET_SAVED_ADDRESSES_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case ActionTypes.LOGOUT:
            return initialState;
        case ActionTypes.GET_SAVED_ADDRESSES_SUCCESS:
            return {
                ...state,
                isFetching: false,
                addresses: action.addresses
            };
        case ActionTypes.GET_SAVED_ADDRESSES_FAIL:
            return {
                ...state,
                isFetching: false,
                error: action.error
            };
        default:
            return state;
    }
};

export default savedAddressesReducer;
