// @flow

import * as React from "react";
import Config from "@Config";
import BreadcrumbLink from "../atoms/BreadcrumbLink";

type PropsType = {
    title: string,
    postIntro?: any,
    children: React.Node
};

const AccountHero = (props: PropsType): React.Node => {
    return (
        <div className={`account-hero nav-primary-pad offset-next-xs`}>
            <div className="container-fluid-small">
                <div className="account-hero__inner">
                    <BreadcrumbLink
                        to={`${Config.routes.account}`}
                        className="show-sm"
                    >
                        <span className="icon icon-arrow-left" /> My Account
                    </BreadcrumbLink>
                    <h1 className="heading-1" data-text={props.title}>
                        {props.title}
                    </h1>
                    {props.children}
                </div>

                {props.postIntro ? props.postIntro : null}
            </div>
        </div>
    );
};

export default AccountHero;
