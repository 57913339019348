export default {
    DISPLAY_TEST_GROUP_BADGE: "display-test-group-badge-operational",
    ENABLE_TEST_STORES: "enable-test-stores-operational",
    ENABLE_JS_EARN_AT_COUNTER: "enable-jc-earn-at-counter",
    IS_JC_LOYALTY_DISABLED: "is-jc-loyalty-disabled",
    IS_ACCOUNT_CREATION_DISABLED: "is-account-creation-disabled",
    IS_REWARD_WALLET_DISABLED: "is-reward-wallet-disabled",
    IS_CM_MVP_JCL_ENABLED: "is-cmmvp-jcl-enabled",
    IS_CM_MVP_MISC_ENABLED: "is-cmmvp-misc-enabled",
    IS_CLUTCH_ENABLED: "is-clutch-enabled",
};
