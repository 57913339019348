// @flow

import * as React from "react";
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

import Slider from "@components/atoms/Slider";

gsap.registerPlugin(ScrollToPlugin);

type PropsType = {
    className: string,
    gaps?: boolean,
    children?: React.Node
};

type StateType = {
    contentHeight: string,
    percent: number,
    showSlider: boolean,
    touchDown: boolean
};

class ImageSlider extends React.Component<PropsType, StateType> {
    static defaultProps = {
        className: ""
    };

    state = {
        contentHeight: "auto",
        percent: 0,
        showSlider: true,
        touchDown: false
    };

    wrapperRef = React.createRef<HTMLDivElement>();
    scrollableRef = React.createRef<HTMLDivElement>();
    childrenContainerRef = React.createRef<HTMLDivElement>();
    clickTarget: any = null;
    touchStartX = 0;
    startScrollX = 0;
    movementThreshold = 10; // The amount of movement required to cancel a link click in the slider
    unmounting = false;

    componentDidMount() {
        this.listenForScrollableAreaWidthChange(this.showHideScrollBar);
        window.addEventListener("mouseup", this.handleMouseUp);
        window.addEventListener("mousemove", this.handleMouseMove);
        if (this.scrollableRef.current) {
            this.scrollableRef.current.addEventListener(
                "scroll",
                this.handleSliderScroll
            );
        }
        if (this.wrapperRef.current) {
            this.wrapperRef.current.addEventListener(
                "mousedown",
                this.handleMouseDown
            );
        }
    }

    componentWillUnmount() {
        this.unmounting = true;
        window.removeEventListener("mouseup", this.handleMouseUp);
        window.removeEventListener("mousemove", this.handleMouseMove);
        if (this.scrollableRef.current) {
            this.scrollableRef.current.removeEventListener(
                "scroll",
                this.handleSliderScroll
            );
        }
        if (this.wrapperRef.current) {
            this.wrapperRef.current.removeEventListener(
                "mousedown",
                this.handleMouseDown
            );
        }
    }

    handleSliderScroll: EventListener = (event: Event) => {
        if (this.scrollableRef.current) {
            const scrollAmount =
                this.scrollableRef.current.scrollWidth -
                this.scrollableRef.current.clientWidth;
            this.setState({
                percent:
                    (this.scrollableRef.current.scrollLeft / scrollAmount) * 100
            });
        }
    };

    handleMouseDown: MouseEventListener = (event: MouseEvent) => {
        if (!this.scrollableRef.current) {
            return;
        }
        // Save details about the mouse down
        this.touchStartX = event.clientX;
        this.startScrollX = this.scrollableRef.current.scrollLeft;
        this.clickTarget = event.target;
        // This prevents the user being able to 'pick up' the link
        // Allows for smooth draggability
        event.preventDefault();
        this.setState({ touchDown: true });
    };

    handleMouseUp = (event: MouseEvent) => {
        this.setState({ touchDown: false });
        // Fake a click on the touched element if the user has moved the
        // mouse less than the movement threshold
        const movementDelta = Math.abs(this.touchStartX - event.clientX);
        if (movementDelta <= this.movementThreshold) {
            if (this.clickTarget && this.clickTarget.click) {
                this.clickTarget.click();
            }
            this.clickTarget = null;
        }
    };

    handleMouseMove = (event: SyntheticMouseEvent<>) => {
        if (this.state.touchDown) {
            const dragAmount = this.touchStartX - event.clientX;
            gsap.to(this.scrollableRef.current, {
                scrollTo: {
                    x: this.startScrollX + dragAmount,
                    duration: 0.3
                }
            });
        }
    };

    listenForScrollableAreaWidthChange = (callback: () => void) => {
        let measuredWidth = 0;
        let measuredScrollWidth = 0;
        const run = () => {
            if (this.scrollableRef.current) {
                if (
                    measuredWidth !== this.scrollableRef.current.clientWidth ||
                    measuredScrollWidth !==
                        this.scrollableRef.current.scrollWidth
                ) {
                    measuredScrollWidth = this.scrollableRef.current
                        .scrollWidth;
                    measuredWidth = this.scrollableRef.current.clientWidth;
                    callback();
                }
            }
            // Make sure to stop once the component has been marked for unmounting
            if (!this.unmounting) {
                window.requestAnimationFrame(run);
            }
        };
        window.requestAnimationFrame(run);
    };

    showHideScrollBar = () => {
        if (this.scrollableRef && this.scrollableRef.current) {
            if (
                this.scrollableRef.current.scrollWidth <=
                this.scrollableRef.current.clientWidth
            ) {
                this.setState({ showSlider: false });
            } else {
                this.setState({ showSlider: true });
            }
        }
    };

    setScrollPosition = (percent: number) => {
        if (this.scrollableRef.current) {
            const scrollAmount =
                this.scrollableRef.current.scrollWidth -
                this.scrollableRef.current.clientWidth;
            const scrollXPos = (percent / 100) * scrollAmount;
            gsap.set(this.scrollableRef.current, {
                scrollTo: {
                    x: scrollXPos
                }
            });
        }
    };

    render(): React.Node {
        const { gaps = true } = this.props;
        return (
            <div
                className={`image-slider ${gaps ? "image-slider--gaps" : ""} ${
                    this.props.className
                }`}
            >
                <div
                    className={`image-slider__link-blocker ${
                        this.state.touchDown ? "is-visible" : ""
                    }`}
                />
                <div ref={this.wrapperRef} className="image-slider__wrapper">
                    <div
                        ref={this.scrollableRef}
                        className="image-slider__scrollable"
                    >
                        <div className="container-fluid">
                            <div
                                className="image-slider__nowrap"
                                ref={this.childrenContainerRef}
                            >
                                {this.props.children}
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.showSlider ? (
                    <div className="container-fluid">
                        <Slider
                            onSlide={(percent: number) => {
                                this.setScrollPosition(percent);
                            }}
                            percent={this.state.percent}
                        />
                    </div>
                ) : null}
            </div>
        );
    }
}

export default ImageSlider;
