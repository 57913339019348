// @flow

import * as React from "react";
import { connect } from "react-redux";
import { ReactForm, ReactFormField } from "@adrenalin/react-form";
import { withRouter } from "@utils/hooks/withRouter";

import Config from "@Config";
import Actions from "@actions";
import useAsyncState from "../utils/hooks/useAsyncState";
import CheckoutHero from "@components/molecules/CheckoutHero";
import Footer from "@components/organisms/Footer";
import Button from "@components/atoms/Button";
import ServerErrorMessage from "@components/atoms/ServerErrorMessage";
import MappedMetaTags from "@containers/MappedMetaTags";

const { Fragment } = React;

type FormType = {
    password: string
};

type PropsType = {
    match: any,
    resetPassword: typeof Actions.user.resetPassword
};

const ResetPassword = (props: PropsType): React.Node => {
    const { userKey = "", token = "" } = props.router.params;

    const [state, actions] = useAsyncState();

    const handleFormSubmit = (formData: FormType) => {
        actions.request();
        props
            .resetPassword(userKey, token, formData.password)
            .then(() => {
                actions.success("Password reset successfully.");
            })
            .catch((error: any) => {
                console.error(error);
                actions.fail(error.friendly_message || "Something went wrong");
            });
    };

    return (
        <React.Fragment>
            <MappedMetaTags defaultTitle="Reset Password" />

            <CheckoutHero>
                <div className="container-fluid-xsmall">
                    <CheckoutHero.Title button={<CheckoutHero.BackLink />}>
                        {state.success ? "Success!" : "Reset your password"}
                    </CheckoutHero.Title>
                </div>
            </CheckoutHero>

            <div className="container-fluid-xsmall">
                <div className="content-card">
                    <div className="account-utils-form">
                        {state.success ? (
                            <Fragment>
                                <p className="page-intro">
                                    We've saved your new password.
                                </p>
                                <Button
                                    to={Config.routes.login}
                                    style={{ margin: "20px 0" }}
                                >
                                    Continue to login
                                </Button>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <ServerErrorMessage>
                                    {state.error}
                                </ServerErrorMessage>
                                <p className="page-intro">
                                    Enter a new password for your account
                                </p>
                                <ReactForm
                                    onSuccess={handleFormSubmit}
                                    disableSubmitUntilValid
                                >
                                    <ReactFormField
                                        type="password"
                                        name="password"
                                        passwordVisibility={true}
                                        required={true}
                                        validators={["password"]}
                                        errorMessages={{
                                            required:
                                                "Please enter a new password."
                                        }}
                                        label="Create password"
                                        info="Your password must be at least 8 characters long and should include 1 uppercase letter, 1 lower case letter, 1 number and 1 special character"
                                        hideErrors
                                    />
                                    <Button
                                        type="submit"
                                        loading={state.loading}
                                        success={!!state.success}
                                    >
                                        Save Password
                                    </Button>
                                </ReactForm>
                            </Fragment>
                        )}
                    </div>
                </div>
            </div>
            <Footer />
        </React.Fragment>
    );
};

const mapStateToProps = (state: any): any => {
    return {};
};

export default withRouter(
    connect(
        mapStateToProps,
        { resetPassword: Actions.user.resetPassword }
    )(ResetPassword)
);
