// @flow

import * as React from "react";

type PropsType = {
    percent: number,
    onSlide: (percent: number) => any
};

class Slider extends React.Component<PropsType> {
    sliderRef = React.createRef<HTMLDivElement>();
    handleRef = React.createRef<HTMLDivElement>();

    touchDown = false;

    componentDidMount() {
        // mouse bindings
        if (this.sliderRef.current) {
            this.sliderRef.current.addEventListener(
                "mousedown",
                this.startTouch
            );
        }
        window.addEventListener("mouseup", this.endTouch);
        window.addEventListener("mousemove", this.handleMoved);
        // touch bindings
        if (this.sliderRef.current) {
            this.sliderRef.current.addEventListener(
                "touchstart",
                this.startTouch
            );
        }
        window.addEventListener("touchend", this.endTouch);
        window.addEventListener("touchmove", this.handleMoved);
    }

    componentWillUnmount() {
        // mouse bindings
        if (this.sliderRef.current) {
            this.sliderRef.current.removeEventListener(
                "mousedown",
                this.startTouch
            );
        }
        window.removeEventListener("mouseup", this.endTouch);
        window.removeEventListener("mousemove", this.handleMoved);
        // touch bindings
        if (this.sliderRef.current) {
            this.sliderRef.current.removeEventListener(
                "touchstart",
                this.startTouch
            );
        }
        window.removeEventListener("touchend", this.endTouch);
        window.removeEventListener("touchmove", this.handleMoved);
    }

    componentDidUpdate(nextProps: PropsType) {
        if (nextProps.percent) {
            this.updateUI(nextProps.percent);
        }
    }

    startTouch: EventListener = (event: Event) => {
        this.touchDown = true;
        this.handleMoved(event);
    };

    endTouch = () => {
        this.touchDown = false;
    };

    handleMoved = (e: any) => {
        if (this.touchDown) {
            if (this.sliderRef.current) {
                const slider = this.sliderRef.current;
                const rect = slider.getBoundingClientRect();
                const eventX = e.clientX
                    ? e.clientX
                    : e.changedTouches[0].clientX;
                const xOffset = eventX - Number(rect.left);
                const sliderWidth = slider.clientWidth;
                const pixelAmount = sliderWidth - (sliderWidth - xOffset);
                let percent = (pixelAmount / sliderWidth) * 100;
                if (percent < 0) {
                    percent = 0;
                } else if (percent > 100) {
                    percent = 100;
                }

                // update ui if no props provided
                if (!this.props.percent) {
                    this.updateUI(percent);
                }

                // return onSlide prop function with percent
                this.props.onSlide(percent);
            }
        }
    };

    updateUI = (percent: number) => {
        if (this.handleRef.current) {
            this.handleRef.current.style.left = `${percent}%`;
        }
    };

    render(): React.Node {
        return (
            <div ref={this.sliderRef} className={`slider`}>
                <div ref={this.handleRef} className="slider__handle" />
                <div className="slider__line" />
            </div>
        );
    }
}

export default Slider;
