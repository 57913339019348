// @flow

import * as React from "react";
import { connect } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { withRouter } from "@utils/hooks/withRouter";
import { push } from "@lagunovsky/redux-react-router";
import { ReactForm, ReactFormField } from "@adrenalin/react-form";
import _ from "lodash";

import Config from "@Config";
import useAsyncState from "@utils/hooks/useAsyncState";
import Button from "@components/atoms/Button";
import TextButton from "@components/atoms/TextButton";
import ServerErrorMessage from "@components/atoms/ServerErrorMessage";

import actions from "@actions";

import { type ProfileType } from "@SharedTypes";

const { Fragment } = React;

type PropsType = {
    user: ProfileType,
    getInfo: () => Promise<any>,
    resendVerification: () => Promise<any>,
    updateUserInfo: (newUserFields: { email: string }) => Promise<any>,
    push: (route: string) => void
};

const VerifyEmailPrompt = (props: PropsType): React.Node => {
    const [state, actions] = useAsyncState();

    const handleError = (error: any) => {
        console.error(error);
        actions.fail(error.friendly_message || "Unknown error");
    };

    const handleIHaveVerified = () => {
        // Check verification
        actions.request();
        props
            .getInfo()
            .then(() => {
                if (props.user.unverified_email === null) {
                    // Redirect to account
                    actions.success();
                    props.push(Config.routes.account);
                } else {
                    actions.fail(
                        "We were unable to verify your account. Please find your verification email and try again."
                    );
                }
            })
            .catch(handleError);
    };

    const handleResendEmail = () => {
        // Resend verification email
        actions.request();
        props
            .resendVerification()
            .then(() => {
                // This API is very quick - add a half second delay so loading is percieved
                setTimeout(() => {
                    actions.success();
                }, 500);
            })
            .catch(handleError);
    };

    const handleUpdateEmailSuccess = ({ email }: any) => {
        actions.request();
        props
            .updateUserInfo({ email })
            .then(() => {
                actions.success();
                props.push(Config.routes.emailVerification);
            })
            .catch(handleError);
    };

    // Render loading
    if (state.loading) {
        return (
            <div className="loader-container">
                <div className="loader" />
            </div>
        );
    }

    // Render logged out state
    if (_.isEmpty(props.user)) {
        return (
            <Fragment>
                <p className="page-intro">
                    There is no logged in user. Cannot verify.
                </p>
                <Button to={Config.routes.login}>Login</Button>
            </Fragment>
        );
    }

    // Redirect user to account if there is not email to verify
    if (props.user.unverified_email === null) {
        return <Navigate to={Config.routes.account} />;
    }

    return (
        <Fragment>
            <ServerErrorMessage>{state.error}</ServerErrorMessage>
            <Routes location={props.router.location}>
                <Route
                    path={`edit`}
                    element={
                        <Fragment>
                            <ReactForm
                                style={{ margin: "20px 0" }}
                                onSuccess={handleUpdateEmailSuccess}
                            >
                                <ReactFormField
                                    type="text"
                                    label="New Email"
                                    name="email"
                                    defaultValue={props.user.unverified_email}
                                    validators={["email"]}
                                    required
                                />
                                <Button type="submit">Update email</Button>
                            </ReactForm>
                            <TextButton
                                className="text-button--block"
                                to={Config.routes.emailVerification}
                            >
                                Cancel
                            </TextButton>
                        </Fragment>
                    }
                />
                <Route
                    index
                    element={
                        <Fragment>
                            <p className="page-intro">
                                One last step, we’ve sent an email to{" "}
                                <strong>{props.user.unverified_email}</strong>{" "}
                                to verify your account. Open it up, click the
                                link and you’re all done!
                            </p>
                            <Button onClick={handleIHaveVerified}>
                                I have verified
                            </Button>
                            <p
                                className="body-small center-xs"
                                style={{ color: "#4A4A4A" }}
                            >
                                Need help?{" "}
                                <TextButton onClick={handleResendEmail}>
                                    Resend email
                                </TextButton>{" "}
                                or{" "}
                                <TextButton
                                    to={Config.routes.emailVerificationEdit}
                                >
                                    edit email address
                                </TextButton>
                            </p>
                        </Fragment>
                    }
                />
            </Routes>
        </Fragment>
    );
};

const mapStateToProps = (state: any): any => {
    return {
        user: state.user
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        {
            getInfo: actions.user.getInfo,
            resendVerification: actions.user.resendVerification,
            updateUserInfo: actions.user.updateInfo,
            push: push
        }
    )(VerifyEmailPrompt)
);
