// @flow
import store from "@Store";
import _ from "lodash";
import actions from "@actions";
import { push } from "@lagunovsky/redux-react-router";
import Config from "@Config";
import orderUtils from "@utils/orderUtils";

const handleDeeplink = async (href: string): any => {
    const url = new URL(href);
    const paths = _.compact(url.pathname.split("/"));
    const querys = url.search
        .slice(1)
        .split("&")
        .reduce((accumulator: any, currentValue: string): any => {
            const [key, value] = currentValue.split("=");
            return Object.assign(accumulator, {
                [key]: decodeURIComponent(value)
            });
        }, {});
    if (querys.compCode) {
        try {
            await store.dispatch(actions.cart.applyCompCode(querys.compCode));
        } catch (error) {
            store.dispatch(
                actions.globalModals.openAlertModal({
                    title: "Unable to use promotion",
                    body: error.friendly_message || "Invalid promo code",
                    onConfirm: () => {
                        store.dispatch(actions.globalModals.closeAlertModal());
                    }
                })
            );
        }
    }
    if (paths[0] === "save-comp-code") {
        store.dispatch(actions.session.handleSaveCompCode(paths[1]));
    } else if (paths[0] === "add-product") {
        const isPromo = paths[1] === "promo";
        const id = parseInt(paths[2]);
        const comboLevel = paths[2].split(".")[1];
        store.dispatch(
            actions.session.handleAddProduct(
                !isPromo && id,
                isPromo && id,
                url.search.includes("uneditable=true"),
                comboLevel && parseInt(comboLevel)
            )
        );
    } else if (paths[0] === "add-product-open-cart") {
        const isPromo = paths[1] === "promo";
        const id = parseInt(paths[2]);
        const comboLevel = paths[2].split(".")[1];
        store
            .dispatch(
                actions.session.handleAddProduct(
                    !isPromo && id,
                    isPromo && id,
                    url.search.includes("uneditable=true"),
                    comboLevel && parseInt(comboLevel)
                )
            )
            .then((): any => {
                return store.dispatch(push(Config.routes.cart));
            });
    } else if (paths[0] === "add-product-open-edit") {
        const isPromo = paths[1] === "promo";
        const id = parseInt(paths[2]);
        const comboLevel = paths[2].split(".")[1];
        store
            .dispatch(
                actions.session.handleAddProduct(
                    !isPromo && id,
                    isPromo && id,
                    url.search.includes("uneditable=true"),
                    comboLevel && parseInt(comboLevel)
                )
            )
            .then((): any => {
                const newItem = _.last(store.getState().cart.line_items);
                if (newItem) {
                    return store.dispatch(
                        push(
                            `${newItem.menuLink}?${Config.routes.editCartNumberQueryParam}=${newItem.uuid}`
                        )
                    );
                }
            });
    } else if (paths[0] === "orderfeedback") {
        const { orders } = store.getState();
        const order = _.head(orders.past);
        if (order && orderUtils.canGiveFeedback(order)) {
            return store.dispatch(
                push(`${Config.routes.orderFeedback}/${order.order_token}`)
            );
        } else {
            push(`/`);
        }
    } else if (paths[0] === "category") {
        const id = parseInt(paths[1]);
        store.dispatch(actions.session.handleOpenCategory(id));
    }
};

export default handleDeeplink;
