// @flow

import React, { Component } from "react";

import RoundedButton from "@components/atoms/RoundedButton";
import Icon from "@components/atoms/Icon";

type PropsType = {
    quantity?: number,
    max?: ?number,
    min?: number,
    disabled?: boolean,
    onChange?: (amount: number) => void,
    onDecrement?: () => void,
    onIncrement?: () => void
};

class QuantityControl extends Component<PropsType> {
    static defaultProps = {
        min: 0
    };

    handleIncrementClick() {
        this.setNewIncrement(1);

        if (this.props.onIncrement) {
            this.props.onIncrement();
        }
    }

    handleDecrementClick() {
        this.setNewIncrement(-1);

        if (this.props.onDecrement) {
            this.props.onDecrement();
        }
    }

    setNewIncrement(amount: number) {
        if (this.props.onChange) {
            this.props.onChange(this.props.quantity + amount);

            const reader = document.getElementById("sr-quantity-reader");
            reader.textContent = `1 item ${amount > 0 ? 'added' : 'removed'}. ${this.props.quantity + amount} item${this.props.quantity + amount > 1 ? 's': ''} total.`
        }
    }

    render(): any {
        return (
            <div className="quantity-control">
                <RoundedButton
                    onClick={this.handleDecrementClick.bind(this)}
                    aria-label={"Remove"}
                    disabled={
                        this.props.quantity === this.props.min ||
                        this.props.disabled
                    }
                >
                    <Icon name="minus" aria-hidden={true} />
                </RoundedButton>

                <span>{this.props.quantity}</span>
                <div aria-live="polite" aria-atomic="true" className="visually-hidden" id="sr-quantity-reader"></div>

                <RoundedButton
                    onClick={this.handleIncrementClick.bind(this)}
                    aria-label={"Add"}
                    disabled={
                        (this.props.quantity &&
                            this.props.max &&
                            this.props.quantity >= this.props.max) ||
                        this.props.disabled
                    }
                >
                    <Icon name="plus" aria-hidden={true} />
                </RoundedButton>
            </div>
        );
    }
}

export default QuantityControl;
