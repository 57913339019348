// @flow

import * as React from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { withRouter } from "@utils/hooks/withRouter";

import Config from "@Config";
import actions from "@actions";
import ActiveOrder from "@components/molecules/ActiveOrder";

const { useEffect } = React;

// Types
type PropsType = {
    session: any,
    user: any,
    fixedFooterHeight: number,
    getUserInfo: typeof actions.user.getInfo,
    getAppConfig: typeof actions.config.getAppConfig
};

const PortalFactory = (querySelectorString: string): any => (props: {
    children: any
}): any => {
    const el = document.querySelector(querySelectorString);
    if (el) {
        return ReactDOM.createPortal(props.children, el);
    }
    return null;
};

const loginSignUpPortalSelector = ".login-signup-portal";
const loginSignUpMobilePortalSelector = ".login-signup-portal--mobile";

const LoginSignupPortal = PortalFactory(loginSignUpPortalSelector);
const LoginSignupMobilePortal = PortalFactory(loginSignUpMobilePortalSelector);

const Kentico = (props: PropsType): React.Node => {
    useEffect(() => {
        props.getUserInfo(true);
        props.getAppConfig();
    }, []);

    useEffect(() => {
        // Update whatever we need to when the fixed footer height changes
    }, [props.fixedFooterHeight]);

    const onlineOrderingUrl =
        window.onlineOrderingURL || "http://localhost:3000";

    const loginSignupEl = document.querySelector(loginSignUpPortalSelector);

    if (!props.user.isFetching || !props.user.key) {
        if (loginSignupEl) {
            loginSignupEl.classList.add("has-loaded");
        }

        return (
            <div className="hj-kentico-react">
                {props.session.isAuthenticated ? (
                    <React.Fragment>
                        <LoginSignupPortal>
                            <a
                                href={`${onlineOrderingUrl}${Config.routes.account}`}
                            >
                                My Account
                            </a>
                        </LoginSignupPortal>

                        <LoginSignupMobilePortal>
                            <a
                                href={`${onlineOrderingUrl}${Config.routes.account}`}
                            >
                                My Account
                            </a>
                        </LoginSignupMobilePortal>

                        {/* ActiveOrder Widget */}
                        <ActiveOrder />
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <LoginSignupPortal>
                            <a
                                href={`${onlineOrderingUrl}${Config.routes.login}`}
                            >
                                Login
                            </a>
                            <div className="divider-sub-menu divider-sub-menu__desktop">
                                |
                            </div>
                            <a
                                href={`${onlineOrderingUrl}${Config.routes.register}`}
                            >
                                Sign up
                            </a>
                        </LoginSignupPortal>
                        <LoginSignupMobilePortal>
                            <div className="nav-sub-menu-icon-text">
                                <a
                                    className="mobile-sub-menu-link mobile-sub-menu-link__login"
                                    href={`${onlineOrderingUrl}${Config.routes.login}`}
                                >
                                    Login
                                </a>
                                <div className="divider-sub-menu">|</div>
                                <a
                                    className="mobile-sub-menu-link mobile-sub-menu-link__login"
                                    href={`${onlineOrderingUrl}${Config.routes.register}`}
                                >
                                    Sign up
                                </a>
                            </div>
                        </LoginSignupMobilePortal>
                    </React.Fragment>
                )}
            </div>
        );
    } else {
        return null;
    }
};

const mapStateToProps = (state: any): any => {
    return {
        session: state.session,
        device: state.device,
        navigator: state.navigator,
        page: state.page,
        user: state.user
    };
};

const mapDispatchToProps = {
    initialFetchAll: actions.initialFetchAll,
    getUserInfo: actions.user.getInfo,
    getGeoStatus: actions.device.getGeoStatus,
    productDeeplink: actions.session.handleProductDeeplink,
    getAppConfig: actions.config.getAppConfig
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Kentico)
);
