// @flow

import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "@utils/hooks/withRouter";

import { Row, Col } from "@containers/Grid";
import ProductHero from "@components/organisms/ProductHero";
import ProductTile from "@components/molecules/ProductTile";
import CoverBanner from "@components/atoms/CoverBanner";
import PageNotFound from "@containers/PageNotFound";
import { logCustomEventWithDelivery } from "@utils/AppBoy";
import * as GoogleAnalytics from "@utils/GoogleAnalytics";

type PropsType = {
    children: any,
    className?: string,
    router: any,
    session: any,
    menu: any,
    category: any
};

const baseClassname = "category-list";

class CategoryList extends Component<PropsType> {
    componentDidMount() {
        if (Number(this.props.router.params.category)) {
            if (window.localStorage.getItem("newSession")) {
                logCustomEventWithDelivery("FirstViewCategoryPage", {
                    categoryID: Number(this.props.router.params.category)
                });
                window.localStorage.removeItem("newSession");
            }
            logCustomEventWithDelivery("SelectACategory", {
                categoryID: Number(this.props.router.params.category)
            });
            GoogleAnalytics.logEvent({
                category: "Menu",
                action: "Open",
                label: "Categories",
                value: Number(this.props.router.params.category)
            });
        }
    }

    componentDidUpdate(prevProps: PropsType) {
        if (
            Number(prevProps.router.params.category) &&
            Number(this.props.router.params.category) &&
            Number(prevProps.router.params.category) !==
                Number(this.props.router.params.category)
        ) {
            logCustomEventWithDelivery("SelectACategory", {
                categoryID: Number(this.props.router.params.category)
            });
        }
    }

    render(): any {
        // const storeID =
        //     _.get(this, "props.session.selectedStore.store_id") ||
        //     _.get(this, "props.match.params.storeID");

        const category = _.find(this.props.menu.categories, {
            category_id: Number(this.props.router.params.category)
        });

        if (!this.props.router.params.category) {
            return null;
        } else if (category && category.menu_items.length) {
            // if banner link then slice menu items to space around banner
            const categoryMenuItems = category.banner_link
                ? category.menu_items.slice(1, category.menu_items.length)
                : category.menu_items;

            return (
                <div className={`${baseClassname}`}>
                    <ProductHero
                        className="nav-primary-pad"
                        title={category.name}
                        productImgSrc={`${_.get(
                            this,
                            "props.menu.images_category_basepath"
                        )}${category.image}`}
                        hideProductOnMobile={true}
                        category={true}
                    />

                    <div className={`${baseClassname}__products`}>
                        <div className="container-fluid-small">
                            <Row className="row--tile-pad">
                                {category.banner_link ? (
                                    <React.Fragment>
                                        <Col xs={6}>
                                            {/*First product tile (large)*/}
                                            <ProductTile
                                                large
                                                {...category.menu_items[0]}
                                            />
                                        </Col>
                                        <Col xs={6}>
                                            <CoverBanner
                                                ariaLabel={`Promo item for ${category.banner_text}`}
                                                to={category.banner_link}
                                                text={category.banner_text}
                                                background={`${_.get(
                                                    this,
                                                    "props.menu.images_category_basepath"
                                                )}${
                                                    category.banner_image_large
                                                }`}
                                            />
                                        </Col>
                                    </React.Fragment>
                                ) : null}

                                {/*Start rest of product tiles after first*/}
                                {categoryMenuItems.map(
                                    (menuItem: any, index: number): any => {
                                        return (
                                            <Col xs={6} md={4} key={index}>
                                                <ProductTile {...menuItem} />
                                            </Col>
                                        );
                                    }
                                )}
                            </Row>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <PageNotFound title="Category not found" hideFooter={true} />
            );
        }
    }
}

const mapStateToProps = (state: any): any => {
    return {
        session: state.session,
        menu: state.menu
    };
};

export default withRouter(connect(mapStateToProps)(CategoryList));
