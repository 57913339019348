// @flow

import React, { Component } from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import { withRouter } from "@utils/hooks/withRouter";

// import Config from "@Config";
import actions from "@actions";

type PropsType = {
    handleLogout: () => void
};
type StateType = {};

class Logout extends Component<PropsType, StateType> {
    state = {};

    componentDidMount() {
        this.props.handleLogout();
    }

    render(): any {
        return <Navigate replace to="/" />;
    }
}

const mapStateToProps = (state: any): any => {
    return {};
};

const mapDispatchToProps = (dispatch: any): any => {
    return {
        handleLogout: (): any => {
            dispatch(actions.user.logout(true));
        }
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Logout)
);
