// @flow

import * as React from "react";
import { connect } from "react-redux";
import { ReactForm, ReactFormField } from "@adrenalin/react-form";

import CMSAPI from "@apis/CMSAPI";
import useAsyncState from "@utils/hooks/useAsyncState";
import LoaderCover from "@components/atoms/LoaderCover";
import Button from "@components/atoms/Button";
// import Config from "@Config";

const { useEffect } = React;

type PropsType = {
    onClose: () => void,
    onConfirm: () => void
};

const defaultProps = {
    onConfirm: null,
    onClose: null
};

const TermsAgreement = (props: PropsType): React.Node => {
    const baseClassname = "terms-agreement";
    const [state, actions] = useAsyncState();

    // Fetches the T&C Content
    useEffect(() => {
        actions.request();
        CMSAPI.getTermsAndConditions()
            .then((data: any) => {
                actions.success(data.value);
            })
            .catch((error: any) => {
                console.error(error);
                actions.fail(
                    "Something went wrong loading the terms and conditions."
                );
            });
    }, []);

    const handleFormValidated = (formData: any) => {
        props.onConfirm();
    };

    return (
        <div className={`${baseClassname}`}>
            <h1 className={`${baseClassname}__title heading-3`}>
                Terms and Conditions
            </h1>
            <div className={`${baseClassname}__content`}>
                <LoaderCover active={state.loading} />

                {state.loading ? null : state.error ? (
                    state.error
                ) : (
                    <div dangerouslySetInnerHTML={{ __html: state.success }} />
                )}
            </div>
            {props.onConfirm ? (
                <ReactForm
                    onSuccess={handleFormValidated}
                    className={`${baseClassname}__form`}
                    disableSubmitUntilValid
                >
                    <ReactFormField
                        type="checkbox"
                        label="I agree to Hungry Jack’s Terms & Conditions and Privacy Policy"
                        name="termsAgree"
                        required={true}
                        errorMessages={{
                            required:
                                "You must agree to the terms and conditions."
                        }}
                        // Ensures the user can't submit the form until the T&C have loaded properly
                        //disabled={state.loading || state.error}
                    />

                    <button type="submit" className="button">
                        I agree
                    </button>
                </ReactForm>
            ) : (
                <Button onClick={props.onClose}>Close</Button>
            )}
        </div>
    );
};

TermsAgreement.defaultProps = defaultProps;

const mapStateToProps = (state: any): any => {
    return {};
};

export default connect(
    mapStateToProps,
    {}
)(TermsAgreement);
