// @flow

import Config from "@Config";

/**
 * Logic for logging a screen view is as follows:
 * - If the page title or pathName has changed, log the event immediately
 * - If not, start a requestAnimationFrame loop to wait until either the page title or pathname changes
 * - If the elapsed timeout passes (2000ms), stop the loop and send the page view to google analytics immediately. This acts as a fallback.
 *
 * This helps to catch the page title being updated by React Helmet - as this doesn't happen as soon as the route
 * changes, but rather, once the Helmet component is mounted.
 */
let previousTitle = "";
let previousPath = "";
let gaPageViewLogTimeout;

export const logPageView = (): any => {
    clearTimeout(gaPageViewLogTimeout);

    gaPageViewLogTimeout = setTimeout(() => {
        // timeout allows for helmet to correctly set any nested meta data

        let stopPolling = false;
        const getPath = (): string => {
            return `${window.location.pathname}${window.location.search}`;
        };

        const isReadyToLog = (): boolean =>
            previousTitle !== document.title && previousPath !== getPath();

        const log = () => {
            previousTitle = document.title;
            previousPath = getPath();
            const pageView = {
                page_title: document.title,
                page_path: getPath()
            };
            //console.log("LOGGING GA SCREEN VIEW", pageView);
            if (window.gtag) {
                window.gtag("config", Config.googleAnalyticsId, pageView);
            }
        };

        const pollForTitleChange = () => {
            if (isReadyToLog()) {
                log();
            } else if (!stopPolling) {
                requestAnimationFrame(pollForTitleChange);
            } else {
                log();
            }
        };

        if (isReadyToLog()) {
            log();
        } else {
            // Start RAFing for the document title chage
            requestAnimationFrame(pollForTitleChange);
            setTimeout(() => {
                stopPolling = true;
            }, 2000);
        }
    }, 500);
};

type EventType = {
    category: string,
    action: string,
    label: string,
    value?: number
};

export const logEvent = (event: EventType) => {
    // console.log("LOGGING GA EVENT", event);
    if (window.gtag) {
        window.gtag("event", event.action, {
            event_category: event.category,
            event_label: event.label,
            value: event.value
        });
    }
};

export default {
    logEvent,
    logPageView
};
