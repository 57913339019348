// @flow

import * as React from "react";
// import { connect } from "react-redux";

import ImageSliderBrazeFeed from "@components/molecules/ImageSliderBrazeFeed";

type PropsType = {
    // user: any
};

const MoreFromHJs = (props: PropsType): React.Node => {
    const baseClassname = "landing-page-section";

    return (
        <section className={`${baseClassname} ${baseClassname}--more-from-hjs`}>
            <div className="container-fluid">
                <h2 className={`${baseClassname}__title heading-2`}>
                    More from Hungry Jack's
                </h2>
            </div>
            <ImageSliderBrazeFeed />
        </section>
    );
};

// const mapStateToProps = (state: any): any => ({
//     user: state.user
// });

export default MoreFromHJs;
