import routes from "./Routes";
import adyen from "./Adyen";
import strings from "./Strings";
import PaymentOptions from "./PaymentOptions";

export default {
    apiGatewayKey: () => {
        return (
            window.api_gateway_key ||
            "LwY9OoETLM2961IEOEypKaA5mS6JhycQ8QbKAXdsR"
        );
    },
    apiGatewayHost: () => {
        return (
            window.api_gateway_url ||
            "5sjli9uoqb.execute-api.ap-southeast-2.amazonaws.com/int"
        );
    },
    routes,
    adyen,
    strings,
    PaymentOptions,
    // constants
    apiTimeDifference: 0,
    webviewQuery: "webview", // query string to check for webview mode
    fetchTimeout: 20000, // (ms) allowed time for fetch requests
    pageTransitionDuration: 1000, // (ms) standard page transition duration
    quickTypeDelay: 200,
    corsProxyPort: 8080,
    // keys
    facebookAppId: "280359175406551",
    googleMapsKey: "AIzaSyAE1I7ODPm1d1d1Q769J9HJaSGhJcnvkkA",
    googleAnalyticsId: "UA-62919310-1",
    // google recaptcha
    googleRecaptchaKey: "6LdoaFMUAAAAAD1l4E2Q_NfAyNnDRVZmLp7xr_-p",
    // endpoints
    userApi: `/api/v1/user`,
    tokenApi: `/api/v1/token`,
    storeApi: `/api/v1/stores`,
    menuApi: `/api/v1/menu`,
    menuApiv2: `/api/v2/menu`,
    orderApi: `/api/v1/order`,
    orderApiv2: `/api/v2/order`,
    orderApiv3: `/api/v3/order`,
    feedbackApi: `/api/v1/feedback`,
    shakeApi: `/api/v1/shakenwin`,
    voucherApi: `/api/v1/rewards`,
    versionApi: `/api/v1/app`,
    addressApi: `/api/v1/address`,
    contentApi: `/api/v1/content/general`,
    tncApi: `/api/v1/content/registrationtermsandconditions`,
    // urls/images
    paymentLogoUrl:
        "https://s3-ap-southeast-2.amazonaws.com/hjdev/image/payment",
    shareImageUrl: "/assets/images/hj-og-share.png",
    appFixedFooterSelector: "#app-fixed-footer",
    ordersFixedFooterSelector: "#orders-fixed-footer",
    // apple pay config
    applePay: {
        merchantIdentifier: "merchant.com.adyen.HungryJacksCOMDEV.test"
    },
    // google pay config
    googlePay: {
        environment: window.hj_env === "prod" ? "PRODUCTION" : "TEST", // "PRODUCTION"
        baseRequest: {
            apiVersion: 2,
            apiVersionMinor: 0
        },
        tokenizationSpecification: {
            type: "PAYMENT_GATEWAY",
            parameters: {
                gateway: "example",
                gatewayMerchantId: "exampleGatewayMerchantId"
            }
        },
        allowedCardNetworks: ["AMEX", "MASTERCARD", "VISA"],
        allowedCardAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"]
    }
};
