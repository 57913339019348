import React from "react";
import ActionTypes from "@actionTypes";
import ErrorReporter from "@utils/ErrorReporter";
import actions from "@actions";
import * as GoogleAnalytics from "@utils/GoogleAnalytics";
import Config from "@Config";
import { cookie } from "@utils";
import DateService from "@utils/DateService";
import FeatureFlags from "../constants/FeatureFlags";
import { onLocationChanged } from "@lagunovsky/redux-react-router";

// First load multi fetch/promisesp

// Request
export const initialFetchAllRequest = (): any => ({
    type: ActionTypes.INITIAL_FETCH_ALL_REQUEST
});

// Success
export const initialFetchAllSuccess = (result: any): any => ({
    type: ActionTypes.INITIAL_FETCH_ALL_SUCCESS
});

// Error
export const initialFetchAllFailure = (error: string): any => ({
    type: ActionTypes.INITIAL_FETCH_ALL_FAILURE,
    error
});

// FetchAll
export const initialFetchAll = (router: any): any => (
    dispatch: any,
    getState: any
): any => {
    if (router) {
        dispatch(onLocationChanged(router.location));
    }

    dispatch(initialFetchAllRequest());

    if (!cookie.get("hjuser_access_key_id")) {
        const value = window.localStorage.getItem("pastGuestOrder");
        if (value) {
            const json = JSON.parse(value);
            dispatch({
                type: ActionTypes.GET_PAST_ORDERS_SUCCESS,
                orders: [json]
            });
        }
    }

    window.AppleID.auth.init({
        clientId: "au.com.hungryjacks",
        scope: "name email",
        redirectURI: window.location.origin,
        state: "",
        usePopup: true
    });

    const useTestStores = getState().launchDarkly.flags[
        FeatureFlags.ENABLE_TEST_STORES
    ];

    DateService.initSyncTime();

    return (
        Promise.all([
            dispatch(actions.google.initMapApi()), // google maps
            dispatch(actions.adyen.initAdyenSDK()), // adyen web sdk
            dispatch(actions.config.getAppConfig()), // app settings/config
            dispatch(actions.cms.getCMSContent()), // get global cms content/meta data
            // dispatch(actions.vouchers.getGuestCoffeeLoyalty()), // get guest coffee loyalty cms content
            dispatch(actions.cms.getSpecialCategories()), // menu special categories
            dispatch(actions.stores.getStores(null, useTestStores)), // hj stores
            dispatch(actions.device.checkApplePaySupport()), // apple pay device support check
            dispatch(actions.google.checkGooglePaySupport()) // google pay device support check
        ])
            // login user check
            .then(
                (): any => {
                    // if (cookie.get("hjuser_access_key_id")) {
                    return dispatch(actions.user.getInfo(true));
                    // }
                } // attempt to get user info (log in check that will set isAuthenticated in session state)
            )
            // complete
            .then((): any => {
                // INITIALISE GOOGLE ANALYTICS
                const script = document.createElement("script");
                script.async = true;
                script.src = `https://www.googletagmanager.com/gtag/js?id=${Config.googleAnalyticsId}`;
                if (document.body) {
                    document.body.appendChild(script);
                }
                window.dataLayer = window.dataLayer || [];
                window.gtag = function(...args: any) {
                    window.dataLayer.push(arguments);
                };
                window.gtag("js", new Date());

                // Log GA Page View
                GoogleAnalytics.logPageView();
                // window.gtag("config", "UA-143250978-1");

                return dispatch(initialFetchAllSuccess());
            })
            .catch((error: string): any => {
                // show alert to user
                dispatch(
                    actions.globalModals.openAlertModal({
                        title: "Oops... There was an issue loading site.",
                        body: (
                            <React.Fragment>
                                <p>Please check back again soon.</p>
                                {typeof error === "string" ? (
                                    <p>{error}</p>
                                ) : null}
                            </React.Fragment>
                        ),
                        confirmText: "OK",
                        onConfirm: () => {
                            dispatch(actions.globalModals.closeAlertModal());
                        }
                    })
                );

                ErrorReporter({
                    msg: error
                });
                return dispatch(initialFetchAllFailure(error));
            })
            .finally(() => {
                // flag prerender ready for prerender service ssr
                window.prerenderReady = true;
            })
    );
};
