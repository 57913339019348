// @flow

import * as React from "react";
import { connect, useSelector } from "react-redux";
import { withRouter } from "@utils/hooks/withRouter";
import _ from "lodash";
import { CSSTransition } from "react-transition-group";
import * as GoogleAnalytics from "@utils/GoogleAnalytics";

import Config from "@Config";
import actions from "@actions";
import Footer from "@components/organisms/Footer";
import CheckoutHero from "@components/molecules/CheckoutHero";
import OrderDetail from "@components/molecules/OrderDetail";
import OrderProgress from "@components/molecules/OrderProgress";
import RateOrder from "@components/molecules/RateOrder";
import ThankYouFutureOrder from "@components/molecules/ThankYouFutureOrder";
import ImageSliderBrazeFeed from "@components/molecules/ImageSliderBrazeFeed";
import TextButton from "@components/atoms/TextButton";
import ContentCard from "@components/atoms/ContentCard";
import LoaderCover from "@components/atoms/LoaderCover";
import { Row, Col } from "@containers/Grid";
import MappedMetaTags from "@containers/MappedMetaTags";
import orderUtils from "@utils/orderUtils";

import useOrderStatusPolling, {
    mapToOrderWithStatusInfo
} from "@utils/hooks/useOrderStatusPolling";

import { type OrderType } from "@SharedTypes";
import useOrderStatuses from "@utils/hooks/useOrderStatuses";

type OrderPropsType = {
    order: any,
    loading: boolean,
    isAuthenticated: boolean
};

const Order = (props: OrderPropsType): React.Node => {
    const { order, loading } = props;

    const { selectedStoreId, cms } = useSelector((state: any): any => ({
        user: state.user,
        selectedStoreId: _.get(state, "session.selectedStore.store_id", null),
        cms: state.cms
    }));

    const statuses = useOrderStatuses();

    const orderType = loading ? "" : order && order.frontend_order_type;
    const isActiveOrder = orderType === "active";
    const isDeliveryOrder =
        _.has(order, "order_mode") && orderUtils.isDeliveryOrder(order);
    const isPastOrder = orderType === "past";
    const isUpcomingOrder = orderType === "upcoming";

    // Determine the page title
    let pageTitle: string;
    if (isActiveOrder) {
        pageTitle = `${isDeliveryOrder ? "Your delivery" : "Upcoming"} order`;
    } else if (isPastOrder) {
        pageTitle = "Past Order";
    } else if (isUpcomingOrder) {
        pageTitle = "Upcoming Order";
    } else {
        pageTitle = loading ? "" : "Order not found";
    }

    // Determine the container size to use
    // .container-fluid is used when we need to house a multi column layout
    const containerClass = isActiveOrder
        ? "container-fluid"
        : "container-fluid-xsmall";

    const renderOrderContents = (order: any): React.Node => {
        // Determine whether or not show the rating component
        const showRatingComponent = ((): boolean => {
            if (!orderUtils.canGiveFeedback(order)) return false;
            if (isPastOrder) return true;
            if (
                isActiveOrder &&
                statuses[statuses.length - 1].status_keys.includes(
                    order.order_status_key
                )
            ) {
                return true;
            }
            return false;
        })();

        return (
            <>
                <div className={containerClass}>
                    <Row noPad>
                        <Col xs={12} lg={isActiveOrder ? 8 : null}>
                            <ContentCard maintainShadow>
                                {window.location.search.includes(
                                    "thankyou=true"
                                ) && (
                                    <ContentCard.Section>
                                        <ThankYouFutureOrder />
                                    </ContentCard.Section>
                                )}

                                <CSSTransition
                                    in={isActiveOrder && isDeliveryOrder}
                                    timeout={1000}
                                    classNames={"fade"}
                                    mountOnEnter
                                    unmountOnExit
                                >
                                    <ContentCard.Section>
                                        <OrderProgress order={order} />
                                    </ContentCard.Section>
                                </CSSTransition>
                                {showRatingComponent ? (
                                    <ContentCard.Section>
                                        <RateOrder order={order} />
                                    </ContentCard.Section>
                                ) : null}
                                <ContentCard.Section>
                                    <OrderDetail
                                        order={order}
                                        forceShowOrderedTime={isActiveOrder}
                                    />
                                </ContentCard.Section>
                            </ContentCard>
                        </Col>
                        <CSSTransition
                            in={isActiveOrder}
                            timeout={1000}
                            classNames={"fade"}
                            mountOnEnter
                            unmountOnExit
                        >
                            <Col xs={12} lg={4}>
                                <ImageSliderBrazeFeed
                                    className="order-braze-feed-container"
                                    cardCategories={["ADVERTISING"]}
                                />
                            </Col>
                        </CSSTransition>
                    </Row>
                </div>
                <div className={containerClass}>
                    <div className="order-footer">
                        <p>
                            <strong>
                                {isUpcomingOrder
                                    ? "Need to cancel or need help with this order?"
                                    : "Need help with this order?"}
                            </strong>
                        </p>

                        <p>
                            <TextButton
                                onClick={() => {
                                    if (order.order_mode === "Delivery") {
                                        GoogleAnalytics.logEvent({
                                            category: `DeliveryTracker${order.step}`,
                                            action: "Open",
                                            label: "FAQs"
                                        });
                                    }
                                }}
                                to={cms.faq}
                                styletype="tertiary"
                                target="_blank"
                            >
                                View our FAQs
                            </TextButton>
                        </p>
                    </div>
                </div>
            </>
        );
    };

    return (
        <>
            <LoaderCover fixed active={loading}></LoaderCover>

            <MappedMetaTags defaultTitle={pageTitle} />

            <CheckoutHero>
                <div className={containerClass}>
                    <CheckoutHero.Title
                        button={
                            props.isAuthenticated ? (
                                <CheckoutHero.Link
                                    text="My orders"
                                    to={`${Config.routes.account}/my-orders`}
                                />
                            ) : selectedStoreId ? (
                                <CheckoutHero.Link
                                    text="Back to menu"
                                    to={`${Config.routes.menu}/${selectedStoreId}`}
                                />
                            ) : (
                                <CheckoutHero.Link
                                    text="Order online home"
                                    to={Config.routes.home}
                                />
                            )
                        }
                    >
                        {pageTitle}
                    </CheckoutHero.Title>
                </div>
            </CheckoutHero>

            {!loading && order ? renderOrderContents(order) : null}

            <Footer />
        </>
    );
};

const OrderContainer = connect((state: any): any => ({
    isAuthenticated: state.session.isAuthenticated
}))(Order);

// Takes an orderId, finds it in redux state, passes it to the Order component
const SimpleOrderWrapper = (props: { orderId: string }): React.Node => {
    const allOrders = useSelector((state: any): any => {
        return [
            ...state.orders.active,
            ...state.orders.past,
            ...state.orders.upcoming
        ];
    });
    let foundOrder: ?OrderType = allOrders.find(
        (o: OrderType): boolean => o.order_id === props.orderId
    );
    const order = mapToOrderWithStatusInfo(foundOrder);

    // const [retainedOrder, setRetainedOrder] = React.useState(null);

    // if (order && !retainedOrder) {
    //     // there was no order but now there
    //     // store order in local state
    //     setRetainedOrder(order);
    // } else if (!order && retainedOrder) {
    //     // there was an order but now its completed
    //     // re fetch past orders (completed orders)
    //     actions.orders.getAllOrders();
    // }

    return <OrderContainer order={order} loading={false}></OrderContainer>;
};

// Takes an order token, starts polling the order and passes to the Order component
const PolledOrderWrapper = (props: {
    orderToken?: string,
    orderId?: string
}): React.Node => {
    const [order, loading] = useOrderStatusPolling(
        props.orderId,
        props.orderToken
    );
    return (
        <OrderContainer
            order={mapToOrderWithStatusInfo(order)}
            loading={loading}
        ></OrderContainer>
    );
};

// Determine if we should be polling API for order status, or relying on order found in redux state
const OrderPollCheck = (props: {
    orderId: ?string,
    orderToken: ?string
}): React.Node => {
    if (props.orderId) {
        return (
            <SimpleOrderWrapper orderId={props.orderId}></SimpleOrderWrapper>
        );
    } else if (props.orderToken) {
        return (
            <PolledOrderWrapper
                orderToken={props.orderToken}
            ></PolledOrderWrapper>
        );
    } else {
        return <OrderContainer order={null} loading={false}></OrderContainer>;
    }
};

const mapStateToProps = (state: any, ownProps: any): any => {
    const { orderId = null, orderToken = null } = ownProps.router.params;
    return {
        orderId,
        orderToken
    };
};

const mapDispatchToProps = {
    getAllOrders: actions.orders.getAllOrders
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(OrderPollCheck)
);
