// @flow

import _ from "lodash";
import * as React from "react";
import { formatMoney } from "@utils";
import moment from "moment";
import { connect } from "react-redux";
import branch from "branch-sdk";

import Config from "@Config";

import Button from "@components/atoms/Button";
import TextButton from "@components/atoms/TextButton";
import actions from "@actions";
import orderUtils from "@utils/orderUtils";
import * as GoogleAnalytics from "@utils/GoogleAnalytics";

import { type OrderType } from "@SharedTypes";

import ProductDefaultImg from "../../../assets/images/product-default.png";

const { Fragment } = React;

type PropsType = {
    order: OrderType,
    orderStore: any,
    tile: boolean,
    summary: ?boolean,
    addPastOrderToCart: typeof actions.cart.addPastOrderToCart,
    imagePath: any,
    onReorder: ?Function
};

const PastOrder = ({
    order,
    orderStore,
    tile,
    summary,
    addPastOrderToCart,
    imagePath,
    onReorder
}: PropsType): React.Node => {
    const baseClassname = "past-order";
    const isPastOrder = orderUtils.isPastOrder(order);
    const isDeliveryOrder = orderUtils.isDeliveryOrder(order);
    const address = orderUtils.getOrderAddress(order);
    const tileClass = tile ? `${baseClassname}--tile` : "";
    let branchLink = null;

    if (!isDeliveryOrder) {
        var linkData = {
            data: {
                $deeplink_path:
                    "hungryjacks://" +
                    (order.order_token
                        ? "track-order/" + order.order_token
                        : "track-order-by-id/" + order.order_id)
            }
        };

        branch.link(linkData, function(err: any, link: string) {
            branchLink = link;
        });
    }

    const handleReorder = () => {
        if (!isDeliveryOrder && branchLink) {
            window.open(branchLink);
            return;
        }
        GoogleAnalytics.logEvent({
            category: "Profile",
            action: "Reorder",
            label: "Past_Orders"
        });
        addPastOrderToCart(order);
        onReorder && onReorder();
    };

    const totalNumber = order.line_items.reduce(
        (sum: number, item: any): number => sum + item.quantity,
        0
    );

    const totalSummary = (
        <>
            {tile && `${totalNumber} item${totalNumber > 1 ? "s" : ""} / `}
            {formatMoney(order.total_amount)}
            {!tile && " / " + order.total_kj + "kJ"}
        </>
    );

    return (
        <div className={`${baseClassname} ${tileClass}`}>
            <div className={`${baseClassname}__type`}>
                <div className={`${baseClassname}__type__label`}>
                    {isDeliveryOrder ? (
                        <Fragment>
                            <i className={`icon icon-delivery`} />
                            <span>Delivery</span>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <i className={`icon icon-pickup`} />
                            <span>Pickup</span>
                        </Fragment>
                    )}
                </div>
                <div
                    className={`${
                        tile ? "visible-sm" : "invisible-sm"
                    } body-large end-xs ${baseClassname}__summary`}
                >
                    {totalSummary}
                </div>
            </div>
            <div className={`${baseClassname}__detail`}>
                <p>
                    <i className="icon icon-clock" />
                    {isPastOrder || !isDeliveryOrder ? (
                        <>
                            Ordered{" "}
                            {moment(
                                order.created_time,
                                "D MMM YYYY hh:mma"
                            ).format("D MMM YYYY, h:mma")}
                        </>
                    ) : (
                        <>
                            To be delivered{" "}
                            {moment(
                                order.delivery_estimated_time_of_arrival ||
                                    order.requested_delivery_datetime
                            ).format("D MMM YYYY, h:mma")}
                        </>
                    )}
                </p>
                {isDeliveryOrder && order.delivery_details && (
                    <p>
                        <i className="icon icon-map-pin-outline" />
                        {address}
                    </p>
                )}
                {!isDeliveryOrder && orderStore && (
                    <p>
                        <i className="icon icon-map-pin-outline" />
                        {orderStore.name}
                    </p>
                )}

                {imagePath &&
                _.get(imagePath, "fallback_promo_items_base_image_path") ? (
                    <div className={`${baseClassname}__images`}>
                        {order.line_items.map(
                            (item: any, index: number): any => {
                                if (index > 4) {
                                    return null;
                                }
                                if (
                                    index === 4 &&
                                    order.line_items.length > 5
                                ) {
                                    return (
                                        <div
                                            className={`${baseClassname}__image`}
                                            key={item.line_item_number}
                                        >
                                            <div
                                                className={`${baseClassname}__image__heading`}
                                            >
                                                +{order.line_items.length - 4}
                                            </div>
                                            <div>more</div>
                                        </div>
                                    );
                                }
                                const path = item.promo_id
                                    ? `${imagePath.fallback_promo_items_base_image_path}${item.promo_id}/${item.combo_level}_thumb.png`
                                    : `${imagePath.fallback_sale_items_base_image_path}${item.pos_item_id}_thumb.png`;
                                return (
                                    <img
                                        className={`${baseClassname}__image`}
                                        key={item.line_item_number}
                                        alt={item.menu_item_name}
                                        onError={function(e: any) {
                                            e.currentTarget.onerror = null;
                                            e.currentTarget.src = ProductDefaultImg;
                                        }}
                                        src={path}
                                    />
                                );
                            }
                        )}
                        {tile && isPastOrder ? (
                            <Button
                                className={"inline-button"}
                                disabled={_.get(
                                    order,
                                    "is_voucher_order",
                                    false
                                )}
                                onClick={handleReorder}
                                styleType={tile ? ["clear", "red"] : null}
                            >
                                Re-order
                            </Button>
                        ) : null}
                    </div>
                ) : null}

                <p
                    className={`${
                        tile ? "invisible-sm" : "visible-sm"
                    } body-large ${baseClassname}__summary`}
                >
                    {totalSummary}
                </p>
            </div>
            {!summary ? (
                <div className={`${baseClassname}__actions`}>
                    {isPastOrder ? (
                        <Button
                            disabled={_.get(order, "is_voucher_order", false)}
                            onClick={handleReorder}
                            styleType={tile ? ["clear", "red"] : null}
                        >
                            Re-order
                        </Button>
                    ) : null}

                    {!tile ? (
                        <TextButton
                            to={`${Config.routes.order}/${order.order_id}`}
                        >
                            View details{" "}
                            <i
                                className="icon icon-arrow-right"
                                style={{ marginLeft: 5 }}
                            />
                        </TextButton>
                    ) : null}
                </div>
            ) : null}
        </div>
    );
};

const mapStateToProps = (state: any, ownProps: any): any => {
    return {
        imagePath: state.config.image_paths,
        orderStore: _.find(state.stores.list, {
            store_id: ownProps.order.store_id
        })
    };
};

export default connect(
    mapStateToProps,
    {
        addPastOrderToCart: actions.cart.addPastOrderToCart
    }
)(PastOrder);
