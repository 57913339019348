// @flow

import * as React from "react";

import Config from "@Config";

type PropsType = {
    inline: boolean,
    card: {
        variant: string,
        payment_method_variant: string,
        card: {
            expiry_month: string,
            expiry_year: string,
            holder_name: ?string,
            number: string
        }
    }
};

const SavedCard = (props: PropsType): React.Node => {
    return (
        <div
            className={`saved-card ${props.inline ? "saved-card--inline" : ""}`}
        >
            <div className="saved-card__image">
                <img
                    alt={props.card.variant}
                    src={`${Config.paymentLogoUrl}/${props.card.variant ||
                        props.card.payment_method_variant}@3x.png`}
                />
            </div>
            <div className="saved-card__details">
                <div className="saved-card__details__number">
                    {props.card.card
                        ? `• • • • ${"\u00a0"}• • • • ${"\u00a0"}• • • • ${"\u00a0"}${
                              props.card.card.number
                          }`
                        : `PayPal account: ${props.card.email}`}
                </div>
                {props.card.card && (
                    <div className="saved-card__details__expiry">
                        Expires: {props.card.card.expiry_month}/
                        {props.card.card.expiry_year}
                    </div>
                )}
            </div>
        </div>
    );
};

SavedCard.defaultProps = {
    inline: false
};

export default SavedCard;
