// @flow

import Configs from "@Config";
import moment from "moment";
import API from "./API";

export default class StoresAPI {
    static getStores(
        lastUpdated: string,
        enableTestStores: boolean
    ): Promise<any> {
        const addOneSecond = moment(lastUpdated).add(1, "s");
        return API.get(
            `/api/v2/stores?includeTestEntities=${
                enableTestStores ? "true" : ""
            }${
                lastUpdated ? `&lastUpdated=${addOneSecond.toISOString()}` : ""
            }`,
            null,
            null
        ).catch((error: any) => {
            console.error(error);
            throw error;
        });
    }

    static getMenu(
        storeID: string | number,
        orderMethod: ?string,
        lastUpdated: string
    ): Promise<any> {
        const addOneSecond = moment(lastUpdated).add(1, "s");

        return API.get(
            `${Configs.menuApiv2}/${storeID}/${
                !orderMethod
                    ? "delivery"
                    : orderMethod === "delivery"
                    ? "delivery"
                    : ""
            }${
                lastUpdated ? `?lastUpdated=${addOneSecond.toISOString()}` : ""
            }`,
            null,
            null
        ).catch((error: any) => {
            console.error(error);
            throw error;
        });
    }

    static getUpsell(
        storeID: string | number,
        itemIds: any[],
        menuID: ?string | ?number
    ): Promise<any> {
        const url = `${
            Configs.menuApi
        }/store/${storeID}/upsells?menuId=${menuID || ""}`;
        // const url = itemIds.reduce((sum, id, idx) => `${sum}salesItemIds[${idx}]=${id}&`,
        //   `${Configs.menuApi}/store/${storeID}/upsells/?`);
        return API.put(url, null, itemIds.map((id: any): string => `${id}`));
    }

    static checkStoreAvailability(storeID: string | number): Promise<any> {
        return API.get(`${Configs.storeApi}/status/${storeID}`, null, null);
    }
}
