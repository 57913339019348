// @flow

import * as React from "react";

import Button from "@components/atoms/Button";
import TextButton from "@components/atoms/TextButton";
import Config from "@Config";

type PropsType = { className?: string };

const SignupLogin = (props: PropsType): React.Node => {
    return (
        <section className="landing-page-section landing-page-section--signup-login">
            <div className="container-fluid">
                <div
                    className={`signup-or-login ${
                        props.className ? props.className : ""
                    }`}
                >
                    <div className="signup-or-login__content">
                        <h3 className="signup-or-login__title heading-3">
                            MAKE ORDERING EASIER
                        </h3>
                        <p className="body-large">
                            Sign-up or login to get access to exclusive rewards
                            and a faster checkout process with saved address and
                            quick re-order.
                        </p>
                        <div className="signup-or-login__actions">
                            <Button
                                styleType="inline"
                                minWidth={200}
                                to={Config.routes.register}
                            >
                                Sign Up
                            </Button>
                            <p>
                                Already registered?{" "}
                                <TextButton big to={Config.routes.login}>
                                    Login
                                </TextButton>
                            </p>
                        </div>
                    </div>
                    <div className="signup-or-login__image">
                        <div className="signup-or-login__image__inner" />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SignupLogin;
