// @flow

import * as React from "react";
import TextButton from "@components/atoms/TextButton";
import { connect } from "react-redux";

import Actions from "@actions";
import { type SavedAddressType } from "@SharedTypes";
import { formatAddressComponents } from "@utils";
import useAsyncState from "@utils/hooks/useAsyncState";
import Modal from "@components/organisms/Modal";
import AddressLocator from "@components/organisms/AddressLocator";

import { enableBodyScroll, disableBodyScroll } from "@utils/toggleBodyScroll";

const { useState, useEffect } = React;

type PropsType = {
    address: SavedAddressType,
    deleteSavedAddress: typeof Actions.savedAddresses.deleteSavedAddress,
    editSavedAddress: typeof Actions.savedAddresses.editSavedAddress
};

const DeliveryAddressItem = (props: PropsType): React.Node => {
    const baseClassname = "delivery-address-item";
    const [localState, actions] = useAsyncState();
    const [addressFinderActive, setAddressFinderActive] = useState(false);

    useEffect(() => {
        return () => {
            enableBodyScroll();
        }
    }, [])

    const {
        address_id,
        nickname,
        delivery_note,
        address: { address_line, locality, state, postcode }
    } = props.address;

    const formattedAddress = formatAddressComponents([
        [address_line],
        [locality, state, postcode]
    ]);

    const handleEditAddressClick = () => {
        setAddressFinderActive(true);
    };

    const handleDelete = () => {
        actions.request();
        props.deleteSavedAddress(address_id)
            .then(() => {
                actions.success();
            }).catch((err: any) => {
                console.error(err);
                actions.fail(err.friendly_message || "Something went wrong");
            });
    };

    const handleEdit = (data: any) => {
        actions.request();
        setAddressFinderActive(false);
        props
            .editSavedAddress({
                address_id: data.selectedAddressId,
                unit_number: data.unitNumber,
                delivery_note: data.deliveryNotes,
                nickname: data.addressNickname
            })
            .then(() => {
                actions.success();
            })
            .catch((err: any) => {
                console.error(err);
                actions.fail(err.friendly_message || "Something went wrong");
            });
    };

    const classes = [
        baseClassname,
        localState.loading ? `${baseClassname}--deleting` : null
    ];

    return (
        <div className={classes.join(" ")}>
            <div className={`${baseClassname}__detail`}>
                <p className={`${baseClassname}__nickname`}>
                    <strong>{nickname}</strong>
                </p>
                <p className={`${baseClassname}__address`}>
                    {formattedAddress}
                </p>
                <p className={`${baseClassname}__notes body-small`}>
                    Driver Only Notes: {delivery_note || "N/A"}
                </p>
            </div>
            <div className={`${baseClassname}__button`}>
                <TextButton onClick={handleEditAddressClick}>Edit</TextButton>
            </div>
            <Modal
                className="address-locator-modal"
                active={addressFinderActive}
                onClose={() => {
                    setAddressFinderActive(false);
                }}
            >
                <AddressLocator
                    editSavedAddress={true}
                    preloadedAddress={{
                        ...props.address.address,
                        ...props.address,
                        full_address: `${props.address.address.address_line} ${props.address.address.locality} ${props.address.address.state} ${props.address.address.postcode}`,
                        coords: {
                            lat: Number(props.address.address.latitude),
                            lng: Number(props.address.address.longitude)
                        }
                    }}
                    onSuccess={handleEdit}
                    onDelete={handleDelete}
                />
            </Modal>
        </div>
    );
};

const mapStateToProps = (state: any): any => ({});

export default connect(
    mapStateToProps,
    {
        deleteSavedAddress: Actions.savedAddresses.deleteSavedAddress,
        editSavedAddress: Actions.savedAddresses.editSavedAddress
    }
)(DeliveryAddressItem);
