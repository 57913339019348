// @flow

import * as React from "react";
import Config from "@Config";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

type PropsType = {
    isAuthenticated: boolean
};

const ThankYouFutureOrder = (props: PropsType): React.Node => {
    const baseClassname = "thank-you-future-order";

    return (
        <div className={`${baseClassname}`}>
            <div className={`${baseClassname}__header`}>
                <h2
                    className="heading-2"
                    style={{
                        position: "relative",
                        width: "100%"
                    }}
                >
                    <span>Thank you!</span>
                </h2>
            </div>
            <div className={`${baseClassname}__copy`}>
                <div className="body-large">
                    <span>
                        <p>
                            Your order is confirmed. We've emailed you a
                            receipt.
                        </p>
                        {props.isAuthenticated && (
                            <p>
                                You can find all of your order details in your
                                account under{" "}
                                <Link to={`${Config.routes.account}/my-orders`}>
                                    My Order History
                                </Link>
                            </p>
                        )}
                    </span>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state: any): any => {
    return {
        isAuthenticated: state.session.isAuthenticated
    };
};

export default connect(
    mapStateToProps,
    {}
)(ThankYouFutureOrder);
