// @flow

import _ from "lodash";

//
// Initial Product Reducer/Product object state
//
export const intialProductState = {
    menu_item_id: null,
    combo_level: 1,
    pos_item_id: null,
    isUpsell: false, // add if added through 'tasty addons'
    menu_item_name: "",
    quantity: 1,
    promo_id: null,
    voucher_id: null,
    is_available: true,
    price: 0,
    energy_kj: 0,
    allergens: [],
    sales_items: [],
    quantityUneditable: false
};

//
// Set Combo Level
// - configure a product and its sub items based off a combo level
//
export const setComboLevel = (
    menuItems: any,
    salesItems: any,
    combo_level: number
): any => {
    // get active/default menu item from combo_level
    const activeComboMenuItem =
        _.find(menuItems, {
            combo_level: combo_level
        }) || menuItems[0];

    // calculate total default kJ
    let totalEnergyKJ = 0;
    activeComboMenuItem.default_sales_item_ids.forEach((salesItem: any) => {
        if (salesItem.energy_kj) {
            totalEnergyKJ = totalEnergyKJ + salesItem.energy_kj;
        }
    });

    return {
        menuItems: menuItems,
        menu_item_id: activeComboMenuItem.menu_item_id,
        combo_level: combo_level,
        pos_item_id: activeComboMenuItem.pos_item_id,
        promo_id: activeComboMenuItem.promo_id,
        menu_item_name: activeComboMenuItem.name,
        image: activeComboMenuItem.image,
        defaultComboPrice: activeComboMenuItem.default_price,
        sales_groups: activeComboMenuItem.sales_groups,
        // set default sales items
        sales_items: activeComboMenuItem.default_sales_item_ids.map(
            (salesItem: any, idx: number): any => {
                // if sales item existed in previous combo size (e.g burgers), retain its settings
                let existingSalesItem = _.find(salesItems, {
                    sales_item_id: salesItem.sales_item_id
                });

                return {
                    ...salesItem,
                    sales_item_name: salesItem.name,
                    menu_item_id: activeComboMenuItem.menu_item_id,
                    sales_item_id: salesItem.sales_item_id,
                    pos_item_id: salesItem.pos_item_id,
                    is_visible: salesItem.is_visible,
                    modifiers: [],
                    combo_line_item_number:
                        activeComboMenuItem.menu_item_id + idx,
                    ...existingSalesItem
                };
            }
        )
    };
};

//
// Calculate Product Totals
// - recalculate total price, kj and modifiers
//
export const calculateProductTotals = (product: any): any => {
    let salesItemsPriceDiff = product.defaultComboPrice;
    let salesItemsKJ = 0;
    let mergedAllergens = {};
    let allergenLabels = [];

    // process each sales items price and energy diff
    product.sales_items.forEach((salesItem: any) => {
        if (salesItem.energy_kj) {
            salesItemsKJ = salesItemsKJ + salesItem.energy_kj;
        }
        if (salesItem.diff) {
            salesItemsPriceDiff = salesItemsPriceDiff + salesItem.diff;
        }

        // merge sales item allergens
        mergedAllergens = _.mergeWith(
            {},
            mergedAllergens,
            salesItem.allergens,
            (objValue: any, srcValue: any): boolean => {
                return srcValue || objValue;
            }
        );

        // process applied sales item modifiers
        if (salesItem.modifiers) {
            salesItem.modifiers.forEach((modifier: any) => {
                salesItemsKJ =
                    salesItemsKJ + modifier.energy_kj * modifier.quantity;
                salesItemsPriceDiff =
                    salesItemsPriceDiff + modifier.price * modifier.quantity;

                // merge modifier allergens
                if (modifier.allergens) {
                    mergedAllergens = _.mergeWith(
                        {},
                        mergedAllergens,
                        modifier.allergens,
                        (objValue: any, srcValue: any): boolean => {
                            return srcValue || objValue;
                        }
                    );
                }
            });
        }
    });

    // create a formatted array of applicable allergens
    Object.keys(mergedAllergens).forEach((allergen: any): any => {
        if (mergedAllergens[allergen]) {
            allergenLabels.push(
                allergen.charAt(0).toUpperCase() +
                    allergen.slice(1).replace(/_/g, " ")
            );
        }
    });

    return {
        price: salesItemsPriceDiff * product.quantity,
        energy_kj: salesItemsKJ * product.quantity,
        allergens: allergenLabels
    };
};
