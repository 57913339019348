// @flow

import Configs from "@Config";
import API from "./API";

import {
    type ProfileType,
    type RegisterProfileType,
    type LoginProfileType
} from "@SharedTypes";

export default class UserApi {
    static register(
        profile: RegisterProfileType,
        googleRecaptchaResponse: string,
        skipReCaptcha: boolean
    ): Promise<any> {
        let url = `${Configs.userApi}/email`;
        if (googleRecaptchaResponse) {
            url += `?captchaResponse=${googleRecaptchaResponse}`;
        } else if (skipReCaptcha) {
            url += `?skipRecaptcha=true`;
        }

        return API.post(url, null, {
            first_name: profile.first_name,
            last_name: profile.last_name,
            email: profile.email,
            phone: profile.phone,
            password: profile.password
        });
    }

    static login(profile: LoginProfileType): Promise<any> {
        let url = `${Configs.userApi}/email/login`;
        if (profile.googleRecaptchaResponse) {
            url += `?captchaResponse=${profile.googleRecaptchaResponse}`;
        } else if (profile.skipReCaptcha) {
            url += `?skipRecaptcha=true`;
        }
        return API.post(url, null, {
            username: profile.email,
            password: profile.password
        });
    }

    static FBlogin(
        token: string,
        tnc: {
            terms_and_conditions?: boolean,
            over_14?: boolean,
            email?: string
        } = {}
    ): Promise<any> {
        return API.put(`${Configs.userApi}/facebook`, null, {
            access_token: token,
            ...tnc
        });
    }

    static applelogin(
        token: string,
        user?: {
            first_name?: string,
            last_name?: string
        },
        tnc?: boolean
    ): Promise<any> {
        return API.put(`${Configs.userApi}/apple`, null, {
            id_token: token,
            accepted_terms_and_conditions: true,
            user
        });
    }

    static guestLogin(profile: RegisterProfileType): Promise<any> {
        return API.post(`${Configs.userApi}/guest/login`, null, profile);
    }

    static logout(userKey: string): Promise<any> {
        return API.get(`${Configs.userApi}/me/logout`, null, {}, false);
    }

    static getInfo(userKey: string): Promise<any> {
        return API.get(`${Configs.userApi}/me`, null, {});
    }

    static updateInfo(userkey: string, newProfile: ProfileType): Promise<any> {
        return API.patch(`${Configs.userApi}/me`, null, newProfile);
    }

    static resendVerification(userkey: string): Promise<any> {
        return API.put(`${Configs.userApi}/me/email/verification`, null, {});
    }

    static verifyEmail(userkey: string, token: string): Promise<any> {
        return API.put(
            `${Configs.userApi}/email/verification/${userkey}/${token}`,
            null,
            {}
        );
    }

    static connectFB(userkey: string, token: string): Promise<any> {
        return API.put(`${Configs.userApi}/me/facebook`, null, {
            access_token: token
        });
    }

    static connectApple(userkey: string, token: string): Promise<any> {
        return API.put(`${Configs.userApi}/me/apple`, null, {
            id_token: token
        });
    }

    static changePassword(
        userkey: string,
        current_password: string = "",
        new_password: string
    ): Promise<any> {
        return API.put(
            `${Configs.userApi}/me/password`,
            null,
            current_password.length > 0
                ? { current_password, new_password }
                : { new_password }
        );
    }

    static requestResetPassword(
        email: string,
        googleRecaptchaResponse: string,
        skipReCaptcha: boolean
    ): Promise<any> {
        let url = `${Configs.userApi}/password_reset`;
        if (googleRecaptchaResponse) {
            url += `?captchaResponse=${googleRecaptchaResponse}`;
        } else if (skipReCaptcha) {
            url += `?skipRecaptcha=true`;
        }
        return API.post(url, null, {
            email
        });
    }

    static resetPassword(
        userkey: string,
        token: string,
        new_password: string
    ): Promise<any> {
        return API.post(`${Configs.userApi}/${userkey}/password`, null, {
            code: token,
            new_password
        });
    }

    static refreshToken(
        refreshToken: string,
        userkey: string,
        isFB: boolean
    ): Promise<any> {
        return fetch(
            `https://${Configs.apiGatewayHost()}${
                Configs.tokenApi
            }/${userkey}/api-access-token`,
            {
                method: "POST",
                body: JSON.stringify({
                    access_token: refreshToken,
                    provider: isFB ? "facebook" : "custom"
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "x-api-key": Configs.apiGatewayKey()
                }
            }
        ).then(
            (res: any): Promise<any> => Promise.resolve(res),
            (err: any): any => {
                throw err;
            }
        );
    }
}
