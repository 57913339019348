// @flow

import _ from "lodash";
import Config from "@Config";
import ActionTypes from "@actionTypes";
import { createUniqueID } from "@utils";
import { checkCategories } from "@utils/timeRestrictions";
// import moment from "moment";

//import testCartObject from "@assets/hj-order-object.json";

const defaultState = {
    store_id: null,
    order_type: "MCO",
    //token: "",
    mobile_channel: "web",
    line_items: [],
    total_amount: 0,
    //recurringReference: null,
    voucher_id: null,
    hasDiscount: false,
    hasDiscountedItem: false,
    delivery_cost: null,
    isFetching: false,
    order_mode: "delivery",
    compCode: null,
    comp_code: null
};

let retainOrderId = !!localStorage.getItem("tempData"); // order id will be used in handling adyen redirction, so we need to retain it here

// prepare initial cart state form localstorage
let localStorageCart;
try {
    let serialisedCart = localStorage.getItem("cart");
    if (serialisedCart) {
        localStorageCart = JSON.parse(serialisedCart);
        localStorageCart.isFetching = false;
        if (!retainOrderId) {
            delete localStorageCart.aoo_order_id;
            delete localStorageCart.order_id;
        }
    } else {
        //localStorageCart = testCartObject || {}; // debug cart object
        localStorageCart = {};
    }
} catch (error) {}

const initialState = {
    ...defaultState,
    ...localStorageCart
};

const sumOfArrayItemKeys = (array: [], key: string): number => {
    return _.reduce(
        array,
        (sum: any, item: any): number => {
            return sum + item[key];
        },
        0
    );
};

const getLineItemBase = (promoID: number): number => {
    return promoID ? 100 : 200;
};

const cartReducer = (state: any = initialState, action: any = {}): any => {
    let cartPriceDifferences = false;
    let cartItemsUnavailable = false;

    switch (action.type) {
        case ActionTypes.GET_MENU_SUCCESS:
            return {
                ...state,
                store_id: action.menu.store_id,
                delivery_cost: _.get(
                    action,
                    "menu.additional_fees.delivery",
                    null
                )
            };
        // case ActionTypes.SET_DELIVERY_LOCATION:
        //     return {
        //         ...state,
        //         order_mode: "delivery",
        //         address_record_id: action.location.record_id,
        //         total_amount:
        //             (state.delivery_cost || 0) +
        //             sumOfArrayItemKeys(state.line_items, "price")
        //     };
        // case ActionTypes.SET_DELIVERY_TIME:
        //     return {
        //         ...state,
        //         requested_delivery_datetime:
        //             action.time === "asap"
        //                 ? null
        //                 : moment(action.time).format("D MMMM YYYY  h:mma")
        //     };
        case ActionTypes.RESET_ORDERING:
            return defaultState;
        case ActionTypes.APPLY_CART_VOUCHER:
            return {
                ...state,
                voucher_id: action.voucher.id,
                hasDiscount: "voucher",
                voucher_name: action.voucher.name
            };
        case ActionTypes.REMOVE_CART_VOUCHER:
            return {
                ...state,
                hasDiscount: false,
                voucher_id: null,
                voucher_name: null,
                promoError: null
            };
        case ActionTypes.ADD_ITEM_TO_CART:
            let updatedLineItems;

            // reset price difference flag
            action.line_item.hasPriceDifference = false;

            // set voucher flag on cart item if voucher id passed
            if (action.voucher) {
                action.line_item.voucher_id = action.voucher.id;
                action.line_item.voucher_name = action.voucher.name;
                action.line_item.payment_methods_constraints =
                    action.voucher.payment_methods_constraints;
            }

            // remove unused cart/checkout data
            delete action.line_item.parentCategory;
            delete action.line_item.menuItems;
            delete action.line_item.sales_groups;
            // action.line_item.sales_items.forEach((salesItem: any) => {
            //     delete salesItem.allergens;
            //     delete salesItem.modifier_groups;
            // });

            // get the matching cart line item index
            const existingCartItemIndex = _.findIndex(state.line_items, {
                uuid: action.line_item.uuid
            });

            if (existingCartItemIndex > -1) {
                // update existing cart item
                updatedLineItems = state.line_items;
                updatedLineItems.splice(existingCartItemIndex, 1, {
                    ...action.line_item
                });
            } else {
                // new product to cart
                const newItem = {
                    ...action.line_item,
                    line_item_number:
                        getLineItemBase(action.line_item.promo_id) +
                        (state.line_items.length + 1),
                    uuid: createUniqueID()
                };
                // merge existing cart items with new item
                updatedLineItems = state.line_items.concat(newItem);
            }

            return {
                ...state,
                line_items: updatedLineItems,
                hasDiscount: action.voucher ? "voucher" : state.hasDiscount,
                voucher_id: action.voucher
                    ? action.voucher.id
                    : state.voucher_id
            };
        case ActionTypes.REMOVE_ITEM_FROM_CART:
            return {
                ...state,
                line_items: _.filter(
                    state.line_items,
                    (item: any): any => item.uuid !== action.itemId
                ).map((item: any, index: number): any => ({
                    ...item,
                    line_item_number:
                        getLineItemBase(item.promo_id) + (index + 1)
                })),
                hasDiscount:
                    state.voucher_id === action.voucher_id
                        ? false
                        : state.hasDiscount,
                voucher_id:
                    state.voucher_id === action.voucher_id
                        ? null
                        : state.voucher_id
            };
        case ActionTypes.RECALCULATE_CART_TOTALS:
            return {
                ...state,
                total_amount:
                    (typeof state.delivery_cost === "number" &&
                    state.line_items.length > 0
                        ? state.delivery_cost
                        : 0) + sumOfArrayItemKeys(state.line_items, "price"),
                total_kj: sumOfArrayItemKeys(state.line_items, "energy_kj"),
                cartPriceDifferences: _.find(state.line_items, {
                    hasPriceDifference: true
                })
                    ? true
                    : false,
                cartItemsUnavailable: _.find(state.line_items, {
                    is_available: false
                })
                    ? true
                    : false
            };
        case ActionTypes.CHECK_CART_AVAILABILITY:
            cartPriceDifferences = false;
            cartItemsUnavailable = false;

            // if menu object is not set with a store then return without any changes
            if (!action.menu.store_id) {
                return {
                    ...state
                };
            }

            return {
                ...state,
                line_items: state.line_items.map((menuItem: any): any => {
                    let initialItemPrice = menuItem.price.toFixed(2);
                    let parentCategories;
                    let menuLink;

                    const newMenuItem = _.find(
                        action.menu.menu_items,
                        (item: any): any => {
                            if (menuItem.promo_id) {
                                return (
                                    item.promo_id === menuItem.promo_id &&
                                    item.combo_level === menuItem.combo_level
                                );
                            }
                            return item.pos_item_id === menuItem.pos_item_id;
                        }
                    );

                    // if menu item can not be found in menu, return with not available flag
                    if (!newMenuItem) {
                        cartItemsUnavailable = true;
                        return {
                            ...menuItem,
                            is_available: false
                        };
                    } else {
                        // create new cart item edit link if dosnt exist

                        // find menuItem with same menu item base id
                        const baseMenuItem = _.find(action.menu.menu_items, {
                            menu_item_id: newMenuItem.menu_item_id
                        });

                        parentCategories = _.filter(
                            action.menu.categories,
                            (category: any): boolean => {
                                return !!_.find(category.menu_items, {
                                    base_menu_item_id: Number(
                                        baseMenuItem.base_menu_item_id
                                    )
                                });
                            }
                        );

                        // find parent category from base menu item
                        // action.menu.categories.forEach((category: any) => {
                        //     parentCategory = _.find(category.menu_items, {
                        //         base_menu_item_id: Number(
                        //             baseMenuItem.base_menu_item_id
                        //         )
                        //     })
                        //         ? category
                        //         : parentCategory;
                        // });

                        // if parent category found build edit link
                        if (parentCategories && parentCategories.length > 0) {
                            menuLink = `${Config.routes.menu}/${
                                action.menu.store_id
                            }/${
                                parentCategories[0].category_id
                            }/${menuItem.pos_item_id ||
                                `promo/${menuItem.promo_id}`}`;
                        } else {
                            menuLink = `${Config.routes.menu}/${
                                action.menu.store_id
                            }/category/${menuItem.pos_item_id ||
                                `promo/${menuItem.promo_id}`}`;
                        }
                    }

                    // Time restrictions/parent category check
                    if (
                        parentCategories &&
                        !checkCategories(parentCategories, action.orderTime)
                    ) {
                        // no parent category found, or parent category is outside of time restrictions
                        return {
                            ...menuItem,
                            menuLink,
                            is_available: false
                        };
                    }

                    // create new line item
                    const newLineItem = {
                        ...menuItem,
                        menuLink,
                        menu_item_id: newMenuItem.menu_item_id,
                        is_available: true,
                        price: newMenuItem.default_price
                    };

                    try {
                        // look for the cart items sales items in the menu
                        newLineItem.sales_items = newLineItem.sales_items.map(
                            (salesItem: any, idx: any): any => {
                                const newSalesItem =
                                    _.find(action.menu.sales_items, {
                                        pos_item_id: salesItem.pos_item_id,
                                        sales_item_id: salesItem.sales_item_id
                                    }) ||
                                    _.find(action.menu.sales_items, {
                                        pos_item_id: salesItem.pos_item_id
                                    });

                                if (newMenuItem.sales_groups)
                                    newLineItem.price += _.find(
                                        newMenuItem.sales_groups[idx]
                                            .sales_item_ids,
                                        { id: newSalesItem.sales_item_id }
                                    ).diff;

                                const itemModifiers = [];

                                newSalesItem.modifier_groups.forEach(
                                    (modifierGroup: any): any => {
                                        modifierGroup.modifiers.forEach(
                                            (modifier: any): any => {
                                                const existingModifier =
                                                    _.find(
                                                        salesItem.modifiers,
                                                        {
                                                            modifier_id:
                                                                modifier.modifier_id
                                                        }
                                                    ) || {};

                                                const itemModifier = _.find(
                                                    action.menu.modifiers[
                                                        modifier.modifier_id
                                                    ].item_modifiers,
                                                    {
                                                        modifier_group_id:
                                                            modifierGroup.modifier_group_id,
                                                        sales_item_pos_item_id:
                                                            newSalesItem.pos_item_id
                                                    }
                                                );

                                                _.forEach(
                                                    itemModifier.actions,
                                                    (value: any, key: any) => {
                                                        value = value.modifier_id
                                                            ? value.modifier_id
                                                            : value;

                                                        itemModifiers.push({
                                                            ...existingModifier,
                                                            modifier_id:
                                                                action.menu
                                                                    .modifiers[
                                                                    modifier
                                                                        .modifier_id
                                                                ].modifier_id,
                                                            action_group_id:
                                                                itemModifier.action_group_id,
                                                            action: parseInt(
                                                                key,
                                                                10
                                                            ),
                                                            modifier_group_id:
                                                                modifierGroup.modifier_group_id,
                                                            action_id:
                                                                action.menu
                                                                    .modifiers[
                                                                    value
                                                                ].modifier_id,
                                                            price:
                                                                action.menu
                                                                    .modifiers[
                                                                    value
                                                                ].price,
                                                            modifier_name:
                                                                action.menu
                                                                    .modifiers[
                                                                    modifier
                                                                        .modifier_id
                                                                ].name,
                                                            pos_item_id:
                                                                action.menu
                                                                    .modifiers[
                                                                    value
                                                                ].pos_item_id
                                                        });
                                                    }
                                                );
                                            }
                                        );
                                    }
                                );

                                const modifiers = [];

                                _.forEach(
                                    salesItem.modifiers,
                                    (modifier: any) => {
                                        const newModifier = _.find(
                                            itemModifiers,
                                            {
                                                action: modifier.action,
                                                pos_item_id:
                                                    modifier.pos_item_id
                                            }
                                        );

                                        modifiers.push({
                                            quantity: modifier.quantity,
                                            ...newModifier
                                        });

                                        if (
                                            (newModifier.action === 1 ||
                                                newModifier.action === 4 ||
                                                newModifier.action === 16) &&
                                            newModifier.price
                                        ) {
                                            newLineItem.price +=
                                                newModifier.price.toFixed(2) *
                                                modifier.quantity;
                                        }
                                    }
                                );

                                return {
                                    ...salesItem,
                                    modifiers,
                                    menu_item_id: newLineItem.menu_item_id,
                                    sales_item_id: newSalesItem.sales_item_id
                                };
                            }
                        );
                    } catch (e) {
                        // there was an error trying to rebuild the cart item from the menu
                        cartItemsUnavailable = true;
                        return {
                            ...menuItem,
                            is_available: false
                        };
                    }

                    // set updated price
                    newLineItem.price *= newLineItem.quantity;
                    newLineItem.price.toFixed(2);

                    // if action passed showPriceDifference, set flag in cart item for ui
                    if (action.showPriceDifference) {
                        newLineItem.hasPriceDifference =
                            newLineItem.price.toFixed(2) ===
                            initialItemPrice.toFixed(2)
                                ? false
                                : true;
                    } else {
                        newLineItem.hasPriceDifference = false;
                    }

                    // set price difference value for any entire cart
                    cartPriceDifferences =
                        cartPriceDifferences || newLineItem.hasPriceDifference;

                    return newLineItem;
                }),
                cartPriceDifferences: cartPriceDifferences,
                cartItemsUnavailable: cartItemsUnavailable
            };
        case ActionTypes.ADD_PAST_ORDER_TO_CART:
            return {
                ...state,
                line_items: action.lineItems.map((item: any): any => ({
                    ...item,
                    is_reorder: true,
                    uuid: createUniqueID()
                }))
            };
        case ActionTypes.CREATE_ORDER_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case ActionTypes.CREATE_ORDER_SUCCESS:
            cartPriceDifferences = false;
            cartItemsUnavailable = false;

            return {
                ...action.order,
                compCode: state.compCode,
                delivery_cost: _.get(
                    action,
                    "order.additional_fees.delivery",
                    null
                ),
                isFetching: false,
                recurring_details: null,
                recurringReference: state.recurringReference
                    ? state.recurringReference
                    : _.get(
                          action.order.recurring_details,
                          "lastUsedReference"
                      ),
                line_items: action.order.line_items.map(
                    (item: any, idx: number): any => {
                        const existingLineItem = _.find(state.line_items, {
                            line_item_number: item.line_item_number
                        });

                        const itemHasPriceDifference =
                            item.price.toFixed(2) !==
                            existingLineItem.price.toFixed(2)
                                ? true
                                : false;

                        // if item previously had a voucher applied to it
                        if (state.line_items[idx].voucher_id) {
                            // mark item as unavailable if item was was a voucher item and order voucher failed
                            item.is_available = action.order.is_voucher_order;
                        }

                        cartPriceDifferences =
                            cartPriceDifferences || itemHasPriceDifference;
                        cartItemsUnavailable =
                            cartItemsUnavailable || !item.is_available;

                        // merge existing sales items
                        item.sales_items = _.merge(
                            [],
                            existingLineItem.sales_items,
                            item.sales_items
                        );

                        return {
                            ...existingLineItem,
                            ...item,
                            menuLink: _.find(state.line_items, {
                                line_item_number: item.line_item_number
                            }).menuLink,
                            uuid: createUniqueID(),
                            voucher_id: state.line_items[idx].voucher_id,
                            voucher_name: state.line_items[idx].voucher_name,
                            hasPriceDifference: itemHasPriceDifference,
                            priceDiff:
                                item.price -
                                _.find(state.line_items, {
                                    line_item_number: item.line_item_number
                                }).price
                        };
                    }
                ),
                cartPriceDifferences: cartPriceDifferences,
                hasDiscount: action.order.is_voucher_order
                    ? state.hasDiscount
                    : false,
                cartItemsUnavailable: cartItemsUnavailable,
                voucher_id: !action.order.is_voucher_order
                    ? null
                    : state.voucher_id
            };
        case ActionTypes.CREATE_ORDER_FAILURE:
            return _.omit(
                {
                    ...state,
                    ...action.order,
                    isFetching: false,
                    line_items: action.order.line_items.map(
                        (item: any, idx: number): any => ({
                            ...item,
                            uuid: createUniqueID(),
                            voucher_id: state.line_items[idx].voucher_id,
                            voucher_name: state.line_items[idx].voucher_name
                        })
                    )
                },
                "order_id"
            );
        case ActionTypes.GUEST_LOGIN_RESET:
            delete state.aoo_order_id;
            delete state.order_id;
            return {
                ...state
            };
        case ActionTypes.LOGOUT:
            delete state.aoo_order_id;
            delete state.order_id;
            return {
                ...state
            };
        case ActionTypes.AUTHENTICATED:
            if (!retainOrderId) {
                delete state.aoo_order_id;
                delete state.order_id;
                retainOrderId = false;
            }
            return {
                ...state
            };
        case ActionTypes.CONFIRM_ORDER_SUCCESS:
            return {
                ...defaultState,
                store_id: state.store_id
                // address_record_id: state.address_record_id,
                // order_mode: state.order_mode
            };
        case ActionTypes.GET_COMP_DETAIL_SUCCESS:
            return {
                ...state,
                discounts_total: 0,
                compCode: action.compCode,
                hasDiscount: "promo code",
                comp_code: action.compCode.comp_code
            };
        case ActionTypes.REMOVE_COMP_CODE:
            return {
                ...state,
                discounts_total: 0,
                compCode: null,
                hasDiscount: false,
                comp_code: null,
                promoError: null
            };
        case ActionTypes.SET_PROMO_ERROR:
            return {
                ...state,
                promoError: action.error
            };
        case ActionTypes.CLEAR_PROMO_ERROR:
            return {
                ...state,
                promoError: null
            };
        case ActionTypes.CHECK_DISCOUNTED_ITEM: {
            if (
                _.intersection(
                    action.discountedMenuItems,
                    state.line_items.map((item): any => item.menu_item_id)
                ).length > 0
            ) {
                return {
                    ...state,
                    hasDiscountedItem: "discounted item"
                };
            }
            return {
                ...state,
                hasDiscountedItem: false
            };
        }
        case ActionTypes.UPDATE_APP_CONFIG: {
            if (
                _.intersection(
                    _.get(action, "config.menu.discounted_menu_items", []),
                    state.line_items.map((item): any => item.menu_item_id)
                ).length > 0
            ) {
                return {
                    ...state,
                    hasDiscountedItem: "discounted item"
                };
            }
            return {
                ...state,
                hasDiscountedItem: false
            };
        }
        default:
            return state;
    }
};

const debouncedSaveCartToLocalStorage = _.debounce((newState: any) => {
    try {
        localStorage.setItem("cart", JSON.stringify(newState));
    } catch (error) {}
}, 100);

// Intercepts all actions sent to the cartReducer and saves the
// returned state into localStorage
const cartReducerMiddleMan = (
    state: any = initialState,
    action: any = {}
): any => {
    const newState = cartReducer(state, action);
    debouncedSaveCartToLocalStorage(newState);
    return newState;
};

export default cartReducerMiddleMan;
