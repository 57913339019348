// @flow

import StoresAPI from "./StoresAPI";
import UserApi from "./UserApi";
import OrderApi from "./OrderApi";
import VoucherAPI from "./VoucherAPI";
import CardAPI from "./CardAPI";
import CommonAPI from "./CommonAPI";
import AddressAPI from "./AddressAPI";
import CMSAPI from "./CMSAPI";

export default {
    AddressAPI,
    StoresAPI,
    VoucherAPI,
    CardAPI,
    UserApi,
    OrderApi,
    CommonAPI,
    CMSAPI
};
