export default {
    MOBILE_MENU: "MOBILE_MENU",
    ALERT: "ALERT",
    LOGIN_PROMPT: "LOGIN_PROMPT",
    ADDRESS_LOCATOR: "ADDRESS_LOCATOR",
    NEW_SAVED_ADDRESS: "NEW_SAVED_ADDRESS",
    ADD_PAYMENT_METHOD: "ADD_PAYMENT_METHOD",
    CHANGE_PAYMENT_METHOD: "CHANGE_PAYMENT_METHOD",
    COMP: "COMP",
    NAME: "NAME",
    EMAIL: "EMAIL",
    TNC: "TNC",
    VOUCHER_MODAL: "VOUCHER_MODAL",
    UPSELLS: "UPSELLS",
    BARCODE_OVERLAY: "BARCODE_OVERLAY"
};
