// @flow

import _ from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import * as GoogleAnalytics from "@utils/GoogleAnalytics";

//import { Row, Col } from "@containers/Grid";
import ProductTile from "@components/molecules/ProductTile";

type PropsType = {
    children: React.Node,
    className?: string,
    menu: any,
    categoryID: number,
    currentMenuItemID?: number,
    limit?: number
};

const RelatedProducts = (props: PropsType): React.Node => {
    const categoryItems = _.find(props.menu.categories, {
        category_id: Number(props.categoryID)
    });
    if (categoryItems && categoryItems.menu_items) {
        // shuffle category items and reduce to 3

        // remove current menu item
        let shuffledItems = _.filter(categoryItems.menu_items, function(
            menuItem: any
        ): boolean {
            return menuItem.base_menu_item_id !== props.currentMenuItemID;
        });
        // shuffle remaining items and limit to 3
        shuffledItems = _.shuffle(shuffledItems).slice(
            0,
            props.limit || shuffledItems.length
        );

        return (
            <div
                className={`related-products ${
                    props.className ? props.className : ""
                }`}
            >
                <div className="heading-3">You might also like</div>

                <div className="related-products__scroller">
                    <div className="related-products__row">
                        {shuffledItems.map(
                            (menuItem: any, index: number): any => {
                                return (
                                    <div
                                        className="related-products__item"
                                        key={index}
                                    >
                                        <ProductTile
                                            {...menuItem}
                                            onClick={() => {
                                                GoogleAnalytics.logEvent({
                                                    category: "Menu",
                                                    action: "OtherProducts",
                                                    label: "Alternatives",
                                                    value: Number(
                                                        menuItem.promo_id ||
                                                            menuItem.pos_item_id
                                                    )
                                                });
                                            }}
                                        />
                                    </div>
                                );
                            }
                        )}
                    </div>
                </div>
            </div>
        );
    } else {
        return null;
    }
};

const mapStateToProps = (state: any): any => ({
    menu: state.menu
});

export default connect(mapStateToProps)(React.memo(RelatedProducts));
