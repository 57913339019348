// @flow

import * as React from "react";
import { connect } from "react-redux";
import _ from "lodash";

import { appboyLogCustomEvent } from "@utils/AppBoy";
import GlobalModals from "../../constants/GlobalModals";
import actions from "@actions";
import Icon from "@components/atoms/Icon";
import Button from "@components/atoms/Button";
import { Row, Col } from "@containers/Grid";

import FeatureFlags from "../../constants/FeatureFlags";

type PropsType = {
    isLanding?: boolean,
    coffeeLoyalty: {
        credits_required: number,
        credits_earned: number,
        content_settings: any,
        has_voucher_assigned: boolean,
        voucher: any,
        barista_coffee_category_id_delivery: number
    },
    coffeeLoyaltyGuest: any,
    handleProductDeeplink: any,
    openAlertModal: any,
    closeAlertModal: any,
    openSignUpModal: any,
    openVoucherModal: any,
    closeVoucherModal: any
};

const LegacyCoffeeLoyaltyTracker = (props: PropsType): React.Node => {
    const isAuthenticated = _.get(props, "session.isAuthenticated", false);
    const totalPointsRequired =
        props.coffeeLoyalty && props.coffeeLoyalty.credits_required
            ? props.coffeeLoyalty.credits_required
            : 4;
    const totalPointsEarned =
        props.coffeeLoyalty && props.coffeeLoyalty.credits_earned
            ? props.coffeeLoyalty.credits_earned
            : 0;
    const freeCoffeeEarnt =
        props.coffeeLoyalty &&
        props.coffeeLoyalty.has_voucher_assigned &&
        props.coffeeLoyalty.voucher;
    const coffeeLoyaltyContent =
        props.coffeeLoyalty && props.coffeeLoyalty.content_settings
            ? props.coffeeLoyalty.content_settings
            : props.coffeeLoyaltyGuest;

    const handleClick = (earnStamps?: boolean) => {
        if (!isAuthenticated) {
            // trigger signup prompt
            props.openSignUpModal();
            // close voucher modal
            props.closeVoucherModal();
        } else if (freeCoffeeEarnt && !earnStamps) {
            // open voucher modal
            props.openVoucherModal(props.coffeeLoyalty.voucher);
        } else if (
            props.coffeeLoyaltyGuest &&
            props.coffeeLoyaltyGuest.barista_coffee_category_id_delivery
        ) {
            props.handleProductDeeplink(
                null,
                null,
                null,
                props.coffeeLoyaltyGuest.barista_coffee_category_id_delivery,
                true
            );

            // close voucher modal
            props.closeVoucherModal();
        }

        // log braze/appboy attribute
        appboyLogCustomEvent("CoffeeLoyaltyTileTap");
    };

    const handleTermsClick = () => {
        // launch loyalty modal (pass custom loyalty detail props to voucher modal)
        props.openVoucherModal({
            image: _.get(
                coffeeLoyaltyContent,
                "loyalty_detail_tile_image.path"
            ),
            title:
                coffeeLoyaltyContent.loyalty_detail_tile_title ||
                "Jack's Cafe Loyalty",
            terms_conditions:
                coffeeLoyaltyContent.loyalty_detail_tile_body ||
                "Not valid with any other offer. One voucher per customer, per transaction. Only available at participating Jack's Cafe restaurants.",
            termsOnClick: (): any => {
                // close voucher modal
                props.closeVoucherModal();

                // open terms modal
                props.openAlertModal({
                    title: "Terms & Conditions",
                    body: coffeeLoyaltyContent.loyalty_detail_tile_terms || "",
                    onConfirm: props.closeAlertModal,
                    html: true
                });
            },
            coffeeLoyaltyCta: {
                text: freeCoffeeEarnt
                    ? "Order Barista Coffee"
                    : "Earn Stamps Now",
                onClick: (): any => handleClick(true)
            }
        });
    };

    const termsLink = (): React.Node => (
        <button onClick={handleTermsClick}>
            <span>View T&Cs</span> <Icon name="arrow-right" />
        </button>
    );

    return (
        <section className="coffee-loyalty-tracker">
            <div className={`${props.isLanding ? "container-fluid" : ""}`}>
                {!props.isLanding ? (
                    <h2 className="heading-2">Jack's Cafe Loyalty</h2>
                ) : null}

                <Row className="middle-xs coffee-loyalty-tracker__row">
                    {/* Landing aside */}
                    {props.isLanding ? (
                        <Col
                            xs={12}
                            md={3}
                            className="coffee-loyalty-tracker__left-section"
                        >
                            <h2 className="heading-2">Jack's Cafe Loyalty</h2>

                            <p className="visible-md">{termsLink()}</p>
                        </Col>
                    ) : null}

                    {/* Main banner */}
                    <div className="coffee-loyalty-tracker__banner">
                        <div
                            onClick={(): any => handleClick()}
                            className="coffee-loyalty-tracker__banner__inner"
                        >
                            {/* mobile image */}
                            <img
                                className="coffee-loyalty-tracker__banner__image invisible-sm"
                                src={
                                    !isAuthenticated
                                        ? _.get(
                                              props,
                                              "coffeeLoyaltyGuest.mobile_home_page_tile_guests_image.path"
                                          )
                                        : freeCoffeeEarnt
                                        ? _.get(
                                              props,
                                              "coffeeLoyalty.content_settings.mobile_home_page_tile_earned_image.path"
                                          )
                                        : _.get(
                                              props,
                                              "coffeeLoyalty.content_settings.mobile_home_page_tile_earning_image.path"
                                          )
                                }
                                alt={
                                    freeCoffeeEarnt
                                        ? _.get(
                                              props,
                                              "coffeeLoyalty.content_settings.mobile_home_page_tile_earned_image.alt_text"
                                          )
                                        : _.get(
                                              props,
                                              "coffeeLoyalty.content_settings.mobile_home_page_tile_earning_image.alt_text"
                                          )
                                }
                            />

                            {/* // desktop images */}
                            <img
                                className="coffee-loyalty-tracker__banner__image visible-sm"
                                src={
                                    // "assets/images/coffee-loyalty-desktop-fallback.png"
                                    !isAuthenticated
                                        ? _.get(
                                              props,
                                              "coffeeLoyaltyGuest.desktop_home_page_tile_guests_image.path"
                                          )
                                        : freeCoffeeEarnt
                                        ? _.get(
                                              props,
                                              "coffeeLoyalty.content_settings.desktop_home_page_tile_earned_image.path"
                                          )
                                        : _.get(
                                              props,
                                              "coffeeLoyalty.content_settings.desktop_home_page_tile_earning_image.path"
                                          )
                                }
                                alt={
                                    freeCoffeeEarnt
                                        ? _.get(
                                              props,
                                              "coffeeLoyalty.content_settings.desktop_home_page_tile_earned_image.alt_text"
                                          )
                                        : _.get(
                                              props,
                                              "coffeeLoyalty.content_settings.desktop_home_page_tile_earning_image.alt_text"
                                          )
                                }
                            />

                            {/* Progress/Redemption */}
                            <div className="coffee-loyalty-tracker__progress-stamps">
                                {freeCoffeeEarnt && isAuthenticated ? (
                                    <Button
                                        onClick={handleClick}
                                        className="coffee-loyalty-tracker__redeem-cta invisible-sm"
                                    >
                                        Redeem your free Coffee
                                    </Button>
                                ) : (
                                    <div className="coffee-loyalty-tracker__progress__markers">
                                        {[...Array(totalPointsRequired)].map(
                                            (value, index): any => {
                                                const pointEarnt =
                                                    index < totalPointsEarned;

                                                return (
                                                    <div
                                                        key={index}
                                                        className={`coffee-loyalty-tracker__progress__markers__item ${
                                                            pointEarnt
                                                                ? "is-active"
                                                                : ""
                                                        }`}
                                                    >
                                                        {pointEarnt ? (
                                                            <Icon
                                                                className="coffee-loyalty-tracker__progress__markers__item__icon"
                                                                name="bean"
                                                            />
                                                        ) : (
                                                            <span className="coffee-loyalty-tracker__progress__markers__item__text">
                                                                {index + 1}
                                                            </span>
                                                        )}
                                                    </div>
                                                );
                                            }
                                        )}

                                        <div className="coffee-loyalty-tracker__progress__markers__item coffee-loyalty-tracker__progress__markers__item--large">
                                            <Icon
                                                className="coffee-loyalty-tracker__progress__markers__item__icon"
                                                name="voucher"
                                            />
                                            <span className="coffee-loyalty-tracker__progress__markers__item__text">
                                                Free
                                            </span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </Row>
                {!props.isJacksCafeLoyaltyDisabled &&
                    <p className={`${props.isLanding ? "invisible-md" : ""}`}>
                        {termsLink()}
                    </p>
                }
            </div>
        </section>
    );
};

const mapStateToProps = (state: any): any => {
    return {
        session: state.session,
        coffeeLoyalty: state.vouchers.coffeeLoyalty,
        coffeeLoyaltyGuest: state.vouchers.coffeeLoyaltyGuest,
        isJacksCafeLoyaltyDisabled:
            state.launchDarkly.flags[FeatureFlags.IS_JC_LOYALTY_DISABLED]
    };
};

export default connect(
    mapStateToProps,
    {
        openVoucherModal: actions.globalModals.openVoucherModal,
        closeVoucherModal: actions.globalModals.closeVoucherModal,
        handleProductDeeplink: actions.session.handleProductDeeplink,
        openAlertModal: actions.globalModals.openAlertModal,
        closeAlertModal: actions.globalModals.closeAlertModal,
        openSignUpModal: (): any =>
            actions.globalModals.openGlobalModal({
                modalId: GlobalModals.LOGIN_PROMPT,
                toPath: "/"
            })
    }
)(LegacyCoffeeLoyaltyTracker);
