// @flow

import * as React from "react";
//import { Link } from "react-router-dom";

import MapPinTicked from "@assets/images/map-pin-ticked.svg";

type PropsType = {
    className?: string,
    lat: number,
    lng: number,
    width: number,
    height?: number,
    zoom?: number,
    marker?: boolean
};

class StaticGoogleMap extends React.Component<PropsType> {
    mapRef = React.createRef<HTMLDivElement>();

    componentDidMount() {
        new window.google.maps.Map(this.mapRef.current, {
            center: {
                lat: this.props.lat,
                lng: this.props.lng
            },
            zoom: this.props.zoom || 16,
            mapTypeControl: false,
            zoomControl: false,
            fullscreenControl: false,
            streetViewControl: false,
            gestureHandling: "none",
            draggableCursor: "default",
            styles: [
                {
                    featureType: "poi",
                    stylers: [{ visibility: "off" }]
                }
            ]
        });
    }

    render(): any {
        return (
            <div className="static-google-map">
                {/*<img
                    alt="Google Map"
                    className="static-google-map__map"
                    src={`https://maps.googleapis.com/maps/api/staticmap?center=${
                        props.lat
                    },${props.lng}&zoom=${props.zoom || 18}&scale=1&size=${
                        props.width
                    }x${props.height ||
                        props.width}&maptype=roadmap&format=png&visual_refresh=true&key=${
                        Config.googleMapsKey
                    }&style=feature:poi|visibility:off`}
                />*/}
                <div ref={this.mapRef} className="static-google-map__map" />
                {this.props.marker ? (
                    <img
                        src={MapPinTicked}
                        alt="Google Map Pin"
                        className="static-google-map__marker"
                    />
                ) : null}
            </div>
        );
    }
}

export default StaticGoogleMap;
