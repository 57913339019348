// @flow

import _ from "lodash";
// import moment from "moment";
import Config from "@Config";
import timeRestrictions from "@utils/timeRestrictions";
import ActionTypes from "@actionTypes";
// import fakeMenu from 'menu_sample.json';

const initialState = {
    preOrderMetersAllowed: 150,
    isFetching: true,
    categories: [],
    images_category_basepath: "",
    images_item_basepath: "",
    menu_id: null,
    menu_items: [],
    sales_items: [],
    promos: [],
    modifiers: {},
    modifier_groups: [],
    menu_items_categories: {}
};

const getCategoryMenuItem = (
    menuItemId: number,
    category: any,
    menu: any
): any => {
    let baseMenuItem = _.find(menu.menu_items, (item: any): any => {
        return item.menu_item_id === menuItemId;
    });

    if (baseMenuItem) {
        let baseMenuItemId = baseMenuItem.base_menu_item_id;

        const menuItems = _.filter(menu.menu_items, (item: any): any => {
            return item.base_menu_item_id === baseMenuItemId;
        });

        const menuItem = _.minBy(menuItems, (item: any): number => {
            return item.combo_level === null ? -1 : item.combo_level;
        });

        // have found pos or promo menu item so add format additional data
        if (menuItem) {
            // find base sales item
            const baseSalesItem = _.find(menu.sales_items, {
                sales_item_id: menuItem.sales_item_ids[0]
            });
            // set lowest sales item as from price/kj
            // build image url
            // build link

            return {
                ...menuItem,
                fromPrice: menuItem.default_price,
                fromKj: baseSalesItem.energy_kj,
                imageUrl: menuItem.promo_id
                    ? menu.images_promos_basepath + menuItem.image
                    : menu.images_item_basepath + menuItem.image,
                link: menuItem.pos_item_id
                    ? `${Config.routes.menu}/${menu.store_id}/${category.category_id}/${menuItem.pos_item_id}`
                    : `${Config.routes.menu}/${menu.store_id}/${category.category_id}/promo/${menuItem.promo_id}`
            };
        }
    }
};

export default function menuReducer(
    state: any = initialState,
    action: any = {}
): any {
    switch (action.type) {
        case ActionTypes.GET_MENU_REQUEST:
            return {
                ...state,
                isFetching: true,
                menuNotFound: false
            };
        case ActionTypes.UPDATE_APP_CONFIG:
            return {
                ...state,
                preOrderMetersAllowed: action.config.pre_order_meters_allowed
            };
        case ActionTypes.GET_MENU_SUCCESS:
            if (action.menu === null) {
                return {
                    ...state,
                    isFetching: false
                };
            }
            return {
                preOrderMetersAllowed: state.preOrderMetersAllowed,
                ...action.menu,
                menu_items_categories: _.reduce(
                    _.flatMap(action.menu.categories, (category: any): any =>
                        _.map(category.menu_items, (item: any): any => [
                            item,
                            category.category_id
                        ])
                    ),
                    (result: any, item: any): any => {
                        (result[item[0]] || (result[item[0]] = [])).push(
                            item[1]
                        );
                        return result;
                    },
                    {}
                ),
                // extend category list items
                categories: action.menu.categories.map((category: any): any => {
                    // return all menu item objects in the category menu_items array
                    category.menu_items = category.menu_items
                        .map((categoryMenuItem: number): any => {
                            return getCategoryMenuItem(
                                categoryMenuItem,
                                category,
                                action.menu
                            );
                        })
                        .filter((categoryMenuItem: any): any => {
                            if (!categoryMenuItem) {
                                return false;
                            }
                            return true;
                        });

                    return category;
                }),
                // extend modifer groups with modifer items
                modifier_groups: action.menu.modifier_groups.map(
                    (modiferGroup: any): any => {
                        modiferGroup.modifiers = modiferGroup.modifiers.map(
                            (modiferID: any): any => {
                                return _.find(action.menu.modifiers, {
                                    modifier_id: modiferID
                                });
                            }
                        );
                        return modiferGroup;
                    }
                ),
                // extend sales items with modifer groups
                sales_items: action.menu.sales_items.map(
                    (salesItem: any): any => {
                        salesItem.modifier_groups = salesItem.modifier_groups.map(
                            (modiferGroupID: any): any => {
                                return _.find(action.menu.modifier_groups, {
                                    modifier_group_id: modiferGroupID
                                });
                            }
                        );
                        return salesItem;
                    }
                ),
                // extend menu item with sales items
                menu_items: action.menu.menu_items.map((menuItem: any): any => {
                    // default sales items
                    const ids = menuItem.sales_groups
                        ? menuItem.sales_groups.map(
                              (group: any): string =>
                                  group.default_sales_item_id
                          )
                        : menuItem.default_sales_item_ids;
                    menuItem.default_sales_item_ids = ids
                        .filter((defaultSalesItemID: any): any => {
                            if (!defaultSalesItemID) {
                                return false;
                            }
                            return true;
                        })
                        .map((defaultSalesItemID: number): any => {
                            return _.find(action.menu.sales_items, {
                                sales_item_id: defaultSalesItemID
                            });
                        });
                    // available sales items
                    if (menuItem.sales_groups) {
                        menuItem.sales_groups = menuItem.sales_groups.map(
                            (salesGroup: any): any => {
                                salesGroup.sales_item_ids = salesGroup.sales_item_ids.map(
                                    (salesGroupItem: any): any => {
                                        return {
                                            ..._.find(action.menu.sales_items, {
                                                sales_item_id: salesGroupItem.id
                                            }),
                                            ...salesGroupItem
                                        };
                                    }
                                );
                                return salesGroup;
                            }
                        );
                    }
                    return menuItem;
                }),
                isFetching: false,
                menuNotFound: false
            };
        case ActionTypes.GET_MENU_FAILURE:
            return {
                ...state,
                isFetching: false,
                menuNotFound: true
            };
        case ActionTypes.SET_DAYPART_MENU:
            return {
                ...state,
                categories: _.filter(state.categories, (category: any): any => {
                    if (
                        !category.restrictions ||
                        timeRestrictions(
                            category.restrictions,
                            action.orderTime
                        )
                    ) {
                        category.isHidden = false;
                        return category;
                    } else {
                        category.isHidden = true;
                        return category;
                    }
                    //return null;
                })
            };
        default:
            return state;
    }
}
