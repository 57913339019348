import ActionTypes from "@actionTypes";
import { cookie } from "@utils";

let userLocalStorage = {};
try {
    // userLocalStorage = JSON.parse(window.localStorage.getItem("user")); // old - local storage user save
    userLocalStorage = {
        api_access_token_response: {
            api_access_token: {
                access_key_id: cookie.get("hjuser_access_key_id") || "",
                secret_key: cookie.get("hjuser_secret_key") || "",
                security_token: cookie.get("hjuser_security_token") || ""
            },
            email_user_token: {
                refresh_token:
                    unescape(cookie.get("hjuser_refresh_token")) || ""
            }
        },

        key: cookie.get("hjuser_key") || ""
    };
} catch (e) {}

const localGuestuser = localStorage.getItem("tempUser");

if (
    window.location.pathname === "/checkout" &&
    window.location.search &&
    localGuestuser
) {
    userLocalStorage = JSON.parse(localGuestuser);
}

localStorage.removeItem("tempUser");

const initialState = {
    ...userLocalStorage,
    isFetching: true
};

export default function userReducer(state = initialState, action = {}) {
    switch (action.type) {
        case ActionTypes.FB_CONNECT_SUCCESS:
            return {
                ...state,
                api_access_token_response: {
                    ...state.api_access_token_response,
                    api_access_token: {
                        ...action.userTokens
                    }
                }
            };
        case ActionTypes.REGISTER_SUCCESS:
            if (action.profile) {
                return action.profile;
            } else {
                return state;
            }
        case ActionTypes.LOGIN_SUCCESS:
            return action.profile;
        case ActionTypes.GUEST_LOGIN_REQUEST:
            return {
                ...state,
                guestLoginFetching: true
            };
        case ActionTypes.GUEST_LOGIN_FAILURE:
            return {
                ...state,
                guestLoginFetching: false
            };
        case ActionTypes.GUEST_LOGIN_SUCCESS:
            return {
                ...action.profile,
                guestLoginFetching: false
            };
        case ActionTypes.FB_LOGIN_SUCCESS:
            return action.profile;
        case ActionTypes.LOGOUT:
            return {};
        case ActionTypes.GET_INFO_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case ActionTypes.GET_INFO_FAIL:
            return {
                ...state,
                isFetching: false
            };
        case ActionTypes.GET_INFO_SUCCESS:
            return {
                ...state,
                ...action.profile,
                isFetching: false
            };
        case ActionTypes.REFRESH_SUCCESS:
            return {
                ...state,
                api_access_token_response: {
                    ...state.api_access_token_response,
                    api_access_token: action.token
                }
            };
        case ActionTypes.CREATE_ORDER_SUCCESS:
            return {
                ...state,
                key: action.order.user_id
            };
        case ActionTypes.EXPIRE_SESSION:
            return {
                ...state,
                api_access_token_response: {
                    ...state.api_access_token_response,
                    api_access_token: {
                        ...state.api_access_token_response.api_access_token,
                        security_token: ""
                    }
                }
            };
        default:
            return state;
    }
}
