// @flow

/**
 * Notes:
 *
 * Uses this seemingly strange compositional structure to allow for
 * different sizes container elements inside the hero.
 *
 * Example usage:
 *
 * <CheckoutHero>
 *      <div className="container-fluid-xsmall">
 *          <CheckoutHero.Title button={<CheckoutHero.BackLink />}>
 *              Reset your password
 *          </CheckoutHero.Title>
 *      </div>
 *  </CheckoutHero>
 *
 */

import * as React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "@utils/hooks/withRouter";
// import { useSelector } from "react-redux";

import BreadcrumbLink from "@components/atoms/BreadcrumbLink";
import HJLogo from "@assets/images/HJLogo.svg";

type PropsType = {
    children: React.Node
};

const CheckoutHero = (props: PropsType): React.Node => {
    return <div className="checkout-hero">{props.children}</div>;
};

type TitlePropsType = {
    children: React.Node,
    button?: React.Node
};

export const Title = (props: TitlePropsType): React.Node => {
    if (props.children === "") return null;
    return (
        <div className="checkout-hero-title">
            <div className="checkout-hero-title__inner">
                <Link to="/" className="checkout-hero-title__img-link">
                    <img
                        className="checkout-hero-title__img"
                        src={HJLogo}
                        alt="Hungry Jacks Logo"
                    />
                </Link>
                <h1 className="checkout-hero-title__text heading-1">
                    {props.children}
                </h1>
            </div>
            {props.button ? (
                <div className="checkout-hero-title__link">{props.button}</div>
            ) : null}
        </div>
    );
};

export const CheckoutHeroLink = ({
    text,
    to = "/",
    ...props
}: any): React.Node => {
    return (
        <BreadcrumbLink {...props} to={to}>
            <i className="icon icon-arrow-left" />
            {text}
        </BreadcrumbLink>
    );
};

export const CheckoutHeroBackLink = ({
    children,
    router,
    ...props
}: any): React.Node => {
    // const prevRoute = useSelector(
    //     (state: any): string => state.session.prevRoute
    // );
    
    const { navigate, location } = router;

    const handleClick = () => {
        navigate(-1);
    };

    return (
        <button {...props} onClick={handleClick}>
            <i className="icon icon-arrow-left" />
            Back
        </button>
    );
};

CheckoutHero.Title = Title;
CheckoutHero.Link = CheckoutHeroLink;
CheckoutHero.BackLink = withRouter(CheckoutHeroBackLink);

export default CheckoutHero;
