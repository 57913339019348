// @flow

import _ from "lodash";
import React from "react";
import { connect } from "react-redux";

type FooterType = {
    footerLinks: any,
    device: any
};

const Footer = (props: FooterType): any => {
    if (!_.get(props, "device.webview")) {
        return (
            <footer className="footer">
                <div className="container-fluid-small center-xs">
                    <div className="footer__links">
                        <div className="footer__links__inner">
                            {props.footerLinks.map(
                                (link: any, i: number): any => {
                                    return (
                                        <a
                                            key={i}
                                            href={link.url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="footer__link"
                                        >
                                            {link.text}
                                        </a>
                                    );
                                }
                            )}
                        </div>
                    </div>
                    <p className="footer__statement">
                        Delivery powered by Menulog&nbsp;&nbsp;&nbsp;
                        <span className="footer__statement__separator">|</span>
                        &nbsp;&nbsp;&nbsp;Copyright © {new Date().getFullYear()}{" "}
                        Hungry Jack's Australia
                    </p>
                </div>
            </footer>
        );
    } else {
        return null;
    }
};

const mapStateToProps = (state: any): any => {
    return {
        footerLinks: state.cms.footer_links[0].links,
        device: state.device
    };
};

export default connect(mapStateToProps)(Footer);
