import moment from "moment-timezone";
import DateService from "@utils/DateService";
import { getSelectedStore } from "@actions/session.js";

const timeZoneMap = {
    "AUS Eastern Standard Time": "Australia/Sydney",
    "W. Australia Standard Time": "Australia/Perth",
    "AUS Central Standard Time": "Australia/Darwin",
    "E. Australia Standard Time": "Australia/Brisbane",
    "Cen. Australia Standard Time": "Australia/Adelaide"
};

export default class StoreUtils {
    static BuildStoreOpeningHoursRange(storeDay) {
        let storeNow = StoreUtils.selectedStoreNow();
        let storeOpenTime = null;
        let storeCloseTime = null;
        if (storeDay) {
            if (storeDay.open) {
                const openHourParts = storeDay.open.split(":");
                const openHours = parseInt(openHourParts[0]);
                const openMinutes = parseInt(openHourParts[1]);
                storeOpenTime = moment(storeNow)
                    .set("hour", openHours)
                    .set("minute", openMinutes)
                    .set("second", 0);
            }

            if (storeDay.close) {
                const closeHourParts = storeDay.close.split(":");
                const closeHours = parseInt(closeHourParts[0]);
                const closeMinutes = parseInt(closeHourParts[1]);
                storeCloseTime = moment(storeNow)
                    .set("hour", closeHours)
                    .set("minute", closeMinutes)
                    .set("second", 0);

                // add one day for the closing hours if the close time is before 5:00AM
                if (closeHours < 5) {
                    storeCloseTime.add("d", 1);
                }
            }
        }

        if (!storeOpenTime || !storeCloseTime) {
            return null;
        }

        return {
            storeOpenTime,
            storeCloseTime
        };
    }

    static BuildRestrictionCategoryTimeRange(restriction) {
        let storeNow = StoreUtils.selectedStoreNow();
        let startTime = null;
        let endTime = null;
        if (restriction && restriction.startTime && restriction.endTime) {
            const rawStartTime = moment(restriction.startTime);
            startTime = moment(storeNow)
                .set("hour", rawStartTime.hour())
                .set("minute", rawStartTime.minute())
                .set("second", 0);

            const rawEndTime = moment(restriction.endTime);
            endTime = moment(storeNow)
                .set("hour", rawEndTime.hour())
                .set("minute", rawEndTime.minute())
                .set("second", 0);

            // add one day for the end time if the start time is greater than end time. e.g.
            // Start: "2011-01-01T10:55:00"
            // End: "2011-01-01T05:00:00"
            if (startTime > endTime) {
                endTime.add("d", 1);
            }

            return {
                startTime,
                endTime
            };
        }

        return null;
    }

    static getStoreCurrentDayOfWeek() {
        let storeNow = StoreUtils.selectedStoreNow();

        // if 5AM or earlier, use the previous day trading hours
        if (storeNow.hour() < 5) {
            storeNow.subtract(1, "d");
        }

        return storeNow.day();
    }

    static selectedStoreNow() {
        const selectedStore = getSelectedStore();
        if (!selectedStore) {
            return DateService.now();
        }

        const storeNow = moment.tz(
            DateService.now(),
            StoreUtils.selectedStoreTimezone()
        );
        return storeNow;
    }

    static selectedStoreTimezone() {
        const selectedStore = getSelectedStore();
        const timezone = timeZoneMap[selectedStore.store_timezone_id]
            ? timeZoneMap[selectedStore.store_timezone_id]
            : "Australia/Sydney";
        return timezone;
    }

    static convertStoreTimezone(datetime) {
        const storeMoment = moment.tz(
            datetime,
            StoreUtils.selectedStoreTimezone()
        );
        return storeMoment;
    }
}
