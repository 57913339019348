// @flow

import * as React from "react";
import { connect } from "react-redux";

import { formatMoney } from "@utils";
import { type CartType } from "@SharedTypes";
import Icon from "@components/atoms/Icon";
import DeliveryFee from "@components/molecules/DeliveryFee";
import _ from "lodash";

type CartTotalsItemsType = "total";

type PropsType = {
    small?: boolean,
    hideNotifications?: boolean,
    cart: CartType,
    items: CartTotalsItemsType[],
    divider?: boolean,
    narrow?: boolean
};

const CartTotals = (props: PropsType): any => {
    const baseClassname = "cart-totals";

    const { cart } = props;
    const dividerClass =
        props.divider === false ? `${baseClassname}--hide-divider` : "";

    if (!cart) {
        return null;
    }

    const totalNumber = cart.line_items.reduce(
        (sum: number, item: any): number => sum + item.quantity,
        0
    );

    const deliveryFee =
        _.get(cart, "delivery_cost") || _.get(cart, "additional_fees.delivery");

    return (
        <div
            className={`${baseClassname} ${
                props.small
                    ? `${baseClassname}--small`
                    : `${baseClassname}--regular`
            } ${dividerClass}`}
        >
            {/* Price difference notification */}
            {!props.hideNotifications && cart.cartPriceDifferences ? (
                <div className={`${baseClassname}__prices-warning`}>
                    <Icon
                        name="alert"
                        className={`${baseClassname}__prices-warning__icon`}
                    />
                    One or more items have updated prices. Your cart total may
                    have changed.
                </div>
            ) : null}

            {props.items.includes("delivery_cost") &&
                cart.line_items.length > 0 &&
                typeof deliveryFee === "number" && (
                    <div
                        className={`${baseClassname}__item ${baseClassname}__item--bold ${baseClassname}__item--total`}
                    >
                        <DeliveryFee price={deliveryFee} />
                    </div>
                )}

            {props.items.includes("total") ? (
                <div
                    className={`${baseClassname}__item ${baseClassname}__item--bold ${baseClassname}__item--total`}
                >
                    <div className={`${baseClassname}__item__label`}>
                        Total ({totalNumber} item{totalNumber !== 1 && "s"})
                    </div>
                    <div className={`${baseClassname}__item__value`}>
                        {props.narrow ? (
                            <>
                                <div>{formatMoney(cart.total_amount)}</div>
                                <div>{cart.total_kj}kJ</div>
                            </>
                        ) : (
                            <div>
                                {cart.total_kj}kJ /{" "}
                                {formatMoney(cart.total_amount)}
                            </div>
                        )}
                    </div>
                </div>
            ) : null}
        </div>
    );
};

CartTotals.defaultProps = {
    items: ["total", "delivery_cost"],
    narrow: false
};

const mapStateToProps = (state: any, ownProps: any): any => {
    // If cart object is passed in, use that instead of the
    // cart object found in redux store
    // This allows us to reuse this component for any order
    // object without altering the original functionality
    const cart = ownProps.cart || state.cart;
    return {
        cart
    };
};

export default connect(mapStateToProps)(CartTotals);
