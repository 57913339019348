// @flow

import * as React from "react";

type PropsType = {
    children: any,
    className?: string
};

const InfoText = (props: PropsType): any => {
    return (
        <div {...props} className={`info-text`}>
            {props.children}
        </div>
    );
};

export default InfoText;
