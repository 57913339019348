// @flow

import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { withRouter } from "@utils/hooks/withRouter";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import Config from "@Config";
import Classes from "@Classes";

import actions from "@actions";
import MappedMetaTags from "@containers/MappedMetaTags";
import { responsive } from "@utils";
import PageNotFound from "@containers/PageNotFound";
import Footer from "@components/organisms/Footer";
import SideNav from "@components/molecules/SideNav";
import LoaderCover from "@components/atoms/LoaderCover";

type PropsType = {
    session: any,
    stores: any,
    menu: any,
    navigator: any,
    match: any,
    device: any,
    setStoreFromParam: number => void,
    getStoreMenu: number => void
};
type StateType = {
    storeID: number,
    viewport: any,
    loading: boolean
};

class Menu extends Component<PropsType, StateType> {
    state = {
        storeID: 0,
        viewport: {
            width: window.innerWidth,
            height: window.innerHeight
        },
        loading: true,
        firstCategory: null,
    };
    handleResizeEvent = this.handleResizeEvent.bind(this);

    componentDidMount() {
        // load store menu from storeID
        this.getStoreFromStoreID();
        // set viewport sizes in state to trigger state updates (redirect) on resize
        window.addEventListener("resize", this.handleResizeEvent, false);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResizeEvent, false);
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        if (prevState.storeID !== this.state.storeID) {
            this.getStoreFromStoreID();
        }
    }

    handleResizeEvent(e: any) {
        this.setState({
            viewport: {
                width: window.innerWidth,
                height: window.innerHeight
            }
        });
    }

    static getDerivedStateFromProps(nextProps: any, prevState: any): any {
        // update storeID in state if new storeID from route props
        return {
            storeID: Number(_.get(nextProps, "router.params.storeID")) || null,
            firstCategory: _.find(nextProps.menu.categories, { isHidden: false }) || null,
            loading: _.get(nextProps, "menu.isFetching")
        };
    }

    getStoreFromStoreID() {
        // find store from stores list by storeID from route
        // const selectedStore = _.find(this.props.stores, {
        //     store_id: this.state.storeID
        // });


        // fetch the store menu
        if (this.state.storeID) {
            this.props.getStoreMenu(this.state.storeID);
        }

        // if (selectedStore) {
        //     // found matching store for storeID from route param
        //     // set the selected store
        //     this.props.setStoreFromParam(selectedStore);
        // }
    }

    getMenuCategories(): any {
        // prepare a formated array of menu category link items (for SideNav)
        const formatedCategoryList = _.get(this, "props.menu.categories").map(
            (item: any): any => {
                item.href = `${Config.routes.menu}/${this.state.storeID}/${item.category_id}`;
                item.icon =
                    _.get(this, "props.menu.images_category_basepath") +
                    item.image;
                return item;
            }
        );
        return formatedCategoryList;
    }

    getSubMenuViewSwitchTimeout(): number {
        // timeout is calculated set to hold internal menu views when trasnitioning away from the menu with the base page transition
        return Config.routes.menu.indexOf(this.props.router.location.pathname) >
            -1
            ? 0
            : Config.pageTransitionDuration / 2;
    }

    render(): any {
        return (
            <React.Fragment>
                {/* Menu loading cover */}
                <LoaderCover absolute={true} active={this.state.loading} />

                {/* If no store id or menu fetched and returned nothing - 404 */}
                {!this.state.storeID ||
                _.get(this, "props.menu.menuNotFound") ? (
                    <PageNotFound title="Store menu not found" />
                ) : null}

                <MappedMetaTags defaultTitle="Menu" />

                {!this.state.loading &&
                _.get(this, "props.menu.menu_items") &&
                _.get(this, "props.menu.menu_items").length ? (
                    <div
                        className={`view-flex-row ${
                            responsive.isMobileBreakpoint() &&
                            !_.get(this, "props.router.params.category", false)
                                ? "gradient-bg"
                                : ""
                        }`}
                    >
                        <MappedMetaTags
                            defaultTitle={`${_.get(
                                this,
                                "props.menu.store_name"
                            )} Menu`}
                        />

                        {/* Categories List */}
                        {(responsive.isMobileBreakpoint() &&
                            !this.props.router.params.product) ||
                        !responsive.isMobileBreakpoint() ? (
                            <SideNav
                                className="gradient-bg"
                                mobileSlider={true}
                                items={this.getMenuCategories()}
                            />
                        ) : null}

                        {/* Product area */}
                        <div className="center-content">
                            <div
                                className={`mobile-pane ${
                                    this.props.router.params.category
                                        ? Classes.active
                                        : ""
                                }`}
                            >
                                {this.state.firstCategory && _.isUndefined(this.props.router.params.category) &&
                                    <Navigate to={`${this.props.router.params.storeID}/${this.state.firstCategory.category_id}`} replace={true} />
                                }
                                <TransitionGroup component={null}>
                                    <CSSTransition
                                        key={this.props.router.location.pathname}
                                        timeout={this.getSubMenuViewSwitchTimeout()}
                                        classNames="page"
                                        unmountOnExit
                                    >
                                        <Outlet />
                                    </CSSTransition>
                                </TransitionGroup>
                            </div>

                            {/* Footer */}
                            <Footer />
                        </div>
                    </div>
                ) : null}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: any): any => {
    return {
        session: state.session,
        stores: state.stores.list,
        menu: state.menu,
        product: state.product,
        navigator: state.navigator,
        device: state.device
    };
};

const mapDispatchToProps = (dispatch: any): any => {
    return {
        setStoreFromParam: (store: {}): any => {
            // set selected store via storeID
            dispatch(actions.session.setSelectedStore(store));
        },
        getStoreMenu: (storeID: {}): any => {
            // get store menu by store id
            dispatch(actions.menu.getMenu(storeID));
        }
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Menu)
);
