// @flow

/**
 * Masks a string to the passed in format
 * This is the base masking function
 *
 * @param {string} value Input value
 * @param {string} character Character to separate
 * @param {[number]} locations Index locations of where to pur character
 * @param {number} maxLength Max length of string
 */
export const mask = (
    value: string,
    character: string,
    locations: Array<number>,
    maxLength?: ?number
): string => {
    // First, replace all spacer chars
    let charPattern = new RegExp(`${character}`, "g");
    value = value.replace(charPattern, "");

    // Insert characters at each location
    locations.forEach((location: number) => {
        if (value.length >= location) {
            if (value[location - 1] !== character) {
                value =
                    value.slice(0, location - 1) +
                    character +
                    value.slice(location - 1);
            }
        }
    });

    // Limit character length
    if (maxLength) {
        if (value.length > maxLength) {
            value = value.slice(0, maxLength);
        }
    }
    return value;
};

/**
 * Accepts a pattern of X characters with a separating character
 * E.g. 'XX/XX/XXXX' or 'XXXX XXXX XXXX XXXX' or 'XX-XX'
 *
 * @param {string} pattern
 * @param {boolean} useMaxLength
 */
export const visualMask = (
    pattern: string,
    useMaxLength: boolean = true
): ((input: string) => any) => {
    const patternArray = [...pattern];
    const character =
        patternArray.find((x: string): boolean => x !== "X") || "";
    const locations = patternArray.reduce(
        (acc: Array<number>, current: string, index: number): Array<number> => {
            if (current === character) {
                acc.push(index + 1);
            }
            return acc;
        },
        []
    );
    const maxLength = patternArray.length;
    return (input: string): string => {
        return mask(
            input,
            character,
            locations,
            useMaxLength ? maxLength : null
        );
    };
};

/**
 * Masks a string to the format of 'XX/XX/XXXX'
 * @param {string} input
 */
export const maskDate = visualMask("XX/XX/XXXX");

/**
 * Masks a string to the format of 'XXXX XXXX XXXX XXXX'
 * @param {string} input
 */
export const maskCC = visualMask("XXXX XXXX XXXX XXXX");

export default mask;
