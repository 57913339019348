// @flow

import { useSelector } from "react-redux";

const useOrderStatuses = (): any => {
    // Get data from redux store
    const status_progress_steps = useSelector(
        (state: any): any => state.config.order_delivery.status_progress_steps
    );

    // Map the status_progress_steps into something more easily consumable by
    // the UI code - this adds a new key: 'barPercent'
    const statuses = status_progress_steps.reduce(
        (total: any, status: any): any => [
            ...total,
            {
                ...status,
                // Bar percent is equal to the previousStatus.barPercent + currentStatus.weight
                barPercent:
                    (total.length ? total[total.length - 1].barPercent : 0) +
                    status.weight
            }
        ],
        []
    );

    return statuses;
};

export default useOrderStatuses;
