// @flow

import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "@utils/hooks/withRouter";

import actions from "@actions";
import LimitedTimeProduct from "./LimitedTimeProduct";
import ProductTile from "./ProductTile";
import * as GoogleAnalytics from "@utils/GoogleAnalytics";

type PropsType = {
    items: any[],
    type: "standard" | "limited",
    handleProductDeeplink: any,
    hasStore: boolean,
    dispatch: any
};

const ProductLockup = (props: PropsType): React.Node => {
    const baseClassname = "product-lockup";

    // Ensure there are 5 items
    // if (props.items.length !== 5) {
    //     console.error(
    //         "5 items must be passed to the ProductLockup component. You passed:",
    //         props
    //     );
    //     return null;
    // }

    const renderProduct = (
        product: any,
        large?: boolean = false
    ): React.Node => {
        let content = null;
        if (props.type === "limited") {
            content = (
                <LimitedTimeProduct
                    large={large}
                    name={product.name}
                    image={product.banner_image}
                    delivery={product.delivery}
                    hasOverlay={!props.hasStore}
                    pickup={product.pickup}
                    onClick={(): any => {
                        GoogleAnalytics.logEvent({
                            category: "OrderHome",
                            action: "LTOStartOrder",
                            label: "LTO",
                            value: Number(product.promo_id || product.pos_id)
                        });
                        props.dispatch(
                            actions.session.handleProductDeeplink(
                                `${product.pos_id}`,
                                `${product.promo_id}`
                            )
                        );
                    }}
                />
            );
        } else {
            content = (
                <ProductTile
                    large={large}
                    name={product.name}
                    imageUrl={product.image}
                    hasOverlay={!props.hasStore}
                    onClick={(): any => {
                        GoogleAnalytics.logEvent({
                            category: "OrderHome",
                            action: "PopularStartOrder",
                            label: "PopularProducts",
                            value: Number(product.promo_id || product.pos_id)
                        });
                        props.dispatch(
                            actions.session.handleProductDeeplink(
                                `${product.pos_id}`,
                                `${product.promo_id}`
                            )
                        );
                    }}
                />
            );
        }
        return <div className={`${baseClassname}__item`}>{content}</div>;
    };

    // start items at second item and trim to 4 total
    const rightSideItems = props.items.slice(1, props.items.length).slice(0, 4);

    if (props.items.length) {
        return (
            <div className={`${baseClassname}`}>
                <div className={`${baseClassname}__left`}>
                    {renderProduct(props.items[0], true)}
                </div>
                <div className={`${baseClassname}__right`}>
                    {rightSideItems.map((item: any, index: any): any => {
                        return (
                            <React.Fragment key={index}>
                                {renderProduct(item)}
                            </React.Fragment>
                        );
                    })}
                </div>
            </div>
        );
    } else {
        return null;
    }
};

const mapStateToProps = (state: any): any => ({
    hasStore: !!state.session.selectedStore
});

export default withRouter(
    connect(
        mapStateToProps,
        null
    )(ProductLockup)
);
