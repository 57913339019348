// @flow

import * as React from "react";
import LinkHandler from "@components/atoms/LinkHandler";

type ButtonType = {
    children?: any,
    className?: string,
    to: string,
    href?: ?string,
    background: string,
    ariaLabel?: string
};

const CoverBanner = (props: ButtonType): any => {
    return (
        <LinkHandler
            aria-label={props.ariaLabel}
            className={`cover-banner ${props.className ? props.className : ""}`}
            style={{ backgroundImage: `url(${props.background})` }}
            to={props.to}
        >
            {props.children}
        </LinkHandler>
    );
};

export default CoverBanner;
