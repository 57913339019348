// @flow

import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "@utils/hooks/withRouter";
import ReCAPTCHA from "react-google-recaptcha";
import { ReactForm, ReactFormField } from "@adrenalin/react-form";
import { cookie } from "@utils";

import Actions from "@actions";
import useAsyncState from "../utils/hooks/useAsyncState";
import CheckoutHero from "@components/molecules/CheckoutHero";
import Footer from "@components/organisms/Footer";
import Button from "@components/atoms/Button";
import ServerErrorMessage from "../components/atoms/ServerErrorMessage";
import MappedMetaTags from "@containers/MappedMetaTags";
import Config from "@Config";

import { type ProfileType } from "@SharedTypes";

type PropsType = {
    user: ProfileType,
    requestResetPassword: Actions.user.requestResetPassword
};

type FormType = {
    email: string
};

const ForgotPassword = (props: PropsType): React.Node => {
    const [state, actions] = useAsyncState();
    const recaptchaRef = React.useRef();

    const handleFormValidated = async (formData: FormType): any => {
        const skipReCaptcha = cookie.get("skipReCaptcha") === "true";
        let googleRecaptchaResponse;
        if (recaptchaRef.current && !skipReCaptcha) {
            googleRecaptchaResponse = await recaptchaRef.current.executeAsync();
        } else if (!skipReCaptcha) {
            return;
        }

        actions.request();
        props
            .requestResetPassword(
                formData.email,
                googleRecaptchaResponse,
                skipReCaptcha
            )
            .then(() => {
                actions.success("Check your email for a password reset link.");
            })
            .catch((error: any) => {
                if (recaptchaRef.current) {
                    recaptchaRef.current.reset();
                }
                console.error(error);
                actions.fail(error.friendly_message || "Unexpected error");
            });
    };

    return (
        <React.Fragment>
            <MappedMetaTags defaultTitle="Forgot Password" />

            <CheckoutHero>
                <div className="container-fluid-xsmall">
                    <CheckoutHero.Title button={<CheckoutHero.BackLink />}>
                        Forgot Password
                    </CheckoutHero.Title>
                </div>
            </CheckoutHero>
            <div className="container-fluid-xsmall">
                <div className="content-card">
                    <div className="account-utils-form">
                        <ServerErrorMessage>{state.error}</ServerErrorMessage>
                        <p className="page-intro">
                            {state.success
                                ? "Email sent"
                                : "Forgotten your password? That's ok, just tell us your email to reset your password."}
                        </p>
                        {state.success ? (
                            <>
                                <p>{state.success}</p>
                                <Button to={Config.routes.login}>
                                    Continue to login
                                </Button>
                            </>
                        ) : (
                            <>
                                <ReactForm
                                    onSuccess={handleFormValidated}
                                    disableSubmitUntilValid
                                >
                                    <ReactFormField
                                        type="email"
                                        name="email"
                                        passwordVisibility={true}
                                        required={true}
                                        validators={["email"]}
                                        errorMessages={{
                                            required: "Please enter your email."
                                        }}
                                        label="Email"
                                    />
                                    <Button
                                        type="submit"
                                        loading={state.loading}
                                        success={state.success}
                                    >
                                        Send email
                                    </Button>
                                </ReactForm>
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    size="invisible"
                                    sitekey={Config.googleRecaptchaKey}
                                    badge="bottomright"
                                />
                            </>
                        )}
                    </div>
                </div>
            </div>
            <Footer />
        </React.Fragment>
    );
};

const mapStateToProps = (state: any): any => {
    return {
        user: state.user
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        { requestResetPassword: Actions.user.requestResetPassword }
    )(ForgotPassword)
);
