// @flow

import * as React from "react";
import { connect } from "react-redux";
import AccountPage from "./AccountPage";
import { Row, Col } from "../../containers/Grid";
import actions from "@actions";
import FacebookSignIn from "../../containers/FacebookSignIn";
import Disclaimer from "@components/atoms/Disclaimer";
import ServerErrorMessage from "@components/atoms/ServerErrorMessage";

import { type ProfileType } from "@SharedTypes";

import useAsyncState from "../../utils/hooks/useAsyncState";

const { Fragment } = React;

type PropsType = {
    user: ProfileType,
    getUserInfo: () => void
};

const FacebookConnect = (props: PropsType): React.Node => {
    const [state, actions] = useAsyncState();

    return (
        <AccountPage
            title="Connect to Facebook"
            intro="Connect your profile to your Facebook account to speed up logging in."
        >
            <Row>
                <Col xs={12} lg={8} xl={6}>
                    <ServerErrorMessage>{state.error}</ServerErrorMessage>
                    {props.user.is_fb_user ? (
                        <p>
                            This profile has already been connected to Facebook
                        </p>
                    ) : state.success ? (
                        <p>Successfully connected to Facebook</p>
                    ) : (
                        <Fragment>
                            <FacebookSignIn
                                inline="true"
                                text="Continue with Facebook"
                                mode="connect"
                                onSuccess={actions.success}
                                onError={actions.fail}
                            />
                            <Disclaimer>
                                We don't post anything on Facebook.
                            </Disclaimer>
                        </Fragment>
                    )}
                </Col>
            </Row>
        </AccountPage>
    );
};

const mapStateToProps = (state: any): any => {
    return {
        user: state.user
    };
};

export default connect(
    mapStateToProps,
    { getUserInfo: actions.user.getInfo }
)(FacebookConnect);
