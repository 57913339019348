import ActionTypes from "@actionTypes";

const initialState = {
    isFetching: true,
    isCoffeeFetching: false,
    image_base_path: null,
    comps_image_base_path: null,
    vouchers: [],
    comps: []
};

export default function voucherReducer(state = initialState, action = {}) {
    switch (action.type) {
        case ActionTypes.GET_VOUCHERS_REQUEST:
            return {
                ...state
            };
        case ActionTypes.GET_VOUCHERS_FAILURE:
            return {
                ...state,
                vouchers: [],
                comps: [],
                isFetching: false
            };
        case ActionTypes.GET_VOUCHERS_SUCCESS:
            return {
                ...state,
                ...action.result,
                isFetching: false
            };
        case ActionTypes.GET_COFFEE_LOYALTY_REQUEST:
            return {
                ...state,
                isCoffeeFetching: true,
            };
        case ActionTypes.GET_COFFEE_LOYALTY_FAILURE:
            return {
                ...state,
                coffeeLoyalty: null,
                isFetching: false,
                isCoffeeFetching: false,
            };
        case ActionTypes.GET_COFFEE_LOYALTY_SUCCESS:
            return {
                ...state,
                coffeeLoyalty: {
                    ...action.result,
                    voucher: action.result.voucher
                        ? { ...action.result.voucher, editProduct: true }
                        : null
                },
                isCoffeeFetching: false,
                isFetching: false
            };
        case ActionTypes.GET_COFFEE_LOYALTY_ELIGABLE_REQUEST:
            return {
                ...state
            };
        case ActionTypes.GET_COFFEE_LOYALTY_ELIGABLE_FAILURE:
            return {
                ...state,
                coffeeLoyaltyEligibility: null,
                isFetching: false
            };
        case ActionTypes.GET_COFFEE_LOYALTY_ELIGABLE_SUCCESS:
            return {
                ...state,
                coffeeLoyaltyEligibility: action.result,
                isFetching: false
            };
        case ActionTypes.GET_GUEST_COFFEE_LOYALTY_REQUEST:
            return {
                ...state
            };
        case ActionTypes.GET_GUEST_COFFEE_LOYALTY_FAILURE:
            return {
                ...state,
                coffeeLoyaltyGuest: null,
                isFetching: false
            };
        case ActionTypes.GET_GUEST_COFFEE_LOYALTY_SUCCESS:
            return {
                ...state,
                coffeeLoyaltyGuest: action.result,
                isFetching: false
            };
        case ActionTypes.REGISTER_SUCCESS:
        case ActionTypes.LOGIN_SUCCESS:
        case ActionTypes.LOGOUT: {
            return {
                ...state,
                vouchers: [],
                comps: [],
                coffeeLoyalty: null,
                coffeeLoyaltyEligibility: null,
                isFetching: true
            };
        }

        default:
            return state;
    }
}
