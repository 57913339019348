// @flow
import ActionTypes from "@actionTypes";
//import Config from "@Config";
// import CMSAPI from "../apis/CMSAPI";
import ApiFactory from "@apis/APIFactory";

//
// Get CMSable global product links
//
export const getSpecialCategories = (): any => (dispatch: any): any => {
    return new Promise((resolve: any, reject: any) => {
        ApiFactory.CMSAPI.getMenuSpecialCategories()
            .then((categories: any) => {
                dispatch({
                    type: ActionTypes.SET_MENU_SPECIAL_CATEGORIES,
                    categories: categories
                });
                resolve();
            })
            .catch((err: any) => {
                resolve();
            });
    });
};

//
// Gets and sets glboal cms content/meta data
//

const getCMSContentRequest = (): any => ({
    type: ActionTypes.GET_CMS_CONTENT_REQUEST
});

const getCMSContentSuccess = (content: any): any => ({
    type: ActionTypes.GET_CMS_CONTENT_SUCCESS,
    content
});

const getCMSContentFail = (error: any): any => ({
    type: ActionTypes.GET_CMS_CONTENT_FAILURE,
    error
});

export const getCMSContent = (lastUpdated: any): any => (
    dispatch: any
): any => {
    dispatch(getCMSContentRequest());
    return new Promise((resolve: any, reject: any) => {
        ApiFactory.CMSAPI.getCMSContent()
            .then((response: any) => {
                dispatch(getCMSContentSuccess(response));
                resolve();
            })
            .catch((err: any) => {
                dispatch(getCMSContentFail());
                reject(err);
            });
    });
};
