// @flow

import _ from "lodash";
import queryString from "query-string";
import * as React from "react";
import { connect } from "react-redux";
import { ReactForm, ReactFormField } from "@adrenalin/react-form";
import { withRouter } from "@utils/hooks/withRouter";

import Config from "@Config";
import actions from "@actions";
import * as GoogleAnalytics from "@utils/GoogleAnalytics";
import { Row, Col } from "@containers/Grid";
import ProductHero from "@components/organisms/ProductHero";
import Modal from "@components/organisms/Modal";
import EditSalesItem from "@components/organisms/EditSalesItem";
import RelatedProducts from "@components/organisms/RelatedProducts";
import SalesItem from "@components/molecules/SalesItem";
import QuantityControl from "@components/molecules/QuantityControl";
import BreadcrumbLink from "@components/atoms/BreadcrumbLink";
import RadioButton from "@components/atoms/RadioButton";
import Disclaimer from "@components/atoms/Disclaimer";
import PageNotFound from "@containers/PageNotFound";
import { logCustomEventWithDelivery } from "@utils/AppBoy";
import AddToCartFooter from "../molecules/AddToCartFooter";

type ProductDetailType = {
    product: string,
    match: any,
    menu: any,
    product: any,
    cart: any,
    dispatch: any,
    history: any
};

type StateType = {
    posID?: string,
    promoID?: string,
    editCartItemID?: string,
    menuItems: any,
    initialProductSettings: any,
    showEditModal: boolean,
    editSalesItem: any,
    editSalesItemIndex: any,
    editSalesGroup: any
};

class ProductDetail extends React.Component<ProductDetailType, StateType> {
    state = {
        posID: "0",
        promoID: "0",
        editCartItemID: "",
        menuItems: [],
        initialProductSettings: {},
        showEditModal: false,
        editSalesItem: null,
        editSalesItemIndex: null,
        editSalesGroup: null
    };
    cartSnapshot = _.cloneDeep(this.props.cart);

    //
    // Lifecycle
    //

    componentDidMount() {
        this.setProduct();
        // this.props.history.block((location, action) => {
        //     if (!/\/menu\/\d+\/\d+\/\d+/.test(location.pathname)) {
        //         const product = _.get(this, "props.product");
        //         const cartWasModified = !_.isEqual(
        //             this.cartSnapshot,
        //             this.props.cart
        //         );

        //         if (product && !cartWasModified && !this.state.editCartItemID) {
        //             logCustomEventWithDelivery("NonPurchase", {
        //                 productID: product.promo_id || product.pos_item_id
        //             });
        //         }
        //     }
        // });
    }

    componentWillUnmount() {
        if (
            !/\/menu\/\d+\/\d+\/.+/.test(this.props.navigator.location.pathname)
        ) {
            const product = _.get(this, "props.product");
            const cartWasModified = !_.isEqual(
                this.cartSnapshot,
                this.props.cart
            );

            if (product && !cartWasModified && !this.state.editCartItemID) {
                logCustomEventWithDelivery("NonPurchase", {
                    productID: product.promo_id || product.pos_item_id
                });
            }
        }
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        if (
            prevState.editCartItemID !== this.state.editCartItemID ||
            prevState.posID !== this.state.posID ||
            prevState.promoID !== this.state.promoID
        ) {
            this.setProduct();
        }

        if (
            prevProps.product.promo_id !== this.props.product.promo_id ||
            prevProps.product.pos_item_id !== this.props.product.pos_item_id
        ) {
            const parentCategory = _.find(this.props.menu.categories, {
                category_id: Number(this.props.router.params.category)
            });

            window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object
            window.dataLayer.push({
                event: "view_item",
                ecommerce: {
                    currency: "AUD", 
                    value: this.props.product.price,
                    items: [{
                        item_id: (this.props.product.promo_id || this.props.product.pos_id),
                        item_name: this.props.product.menu_item_name,
                        coupon: "",
                        discount: 0,
                        index: 0,
                        item_category: parentCategory ? parentCategory.name : null,
                        item_category2: this.props.product.isUpsell ? "Upsell" : "",
                        item_variant: this.props.product.menuItems && this.props.product.menuItems[(this.props.product.combo_level || 0)].option_name,
                        line_items: [this.props.product.sales_items.map((line): any => {
                            return `${line.sales_item_name}${!_.isEmpty(line.modifiers) ?
                                ` (${line.modifiers.map((mod, i): any => {
                                    return `+1 ${mod.modifier_name}${line.modifiers.length - 1 > i ? `, ` : ``}`
                                })})` : ''
                            }`
                        })].join(`, `),
                        price: this.props.product.defaultComboPrice,
                        quantity: this.props.product.quantity
                    }]
                }
            });
        }
    }

    static getDerivedStateFromProps(nextProps: any, prevState: any): any {
        // get cart line item edit mode from query param
        let editCartItemID = queryString.parse(nextProps.navigator.location.search)[
            Config.routes.editCartNumberQueryParam
        ];

        return {
            editCartItemID: editCartItemID ? editCartItemID : null,
            posID:
                !nextProps.router.params.promoID &&
                nextProps.router.params.product
                    ? nextProps.router.params.product
                    : null,
            promoID:
                nextProps.router.params.promoID && nextProps.router.params.product
                    ? nextProps.router.params.promoID
                    : null
        };
    }

    //
    // Methods
    //

    setProduct() {
        const uneditable = _.get(
            this.props,
            "location.state.uneditable",
            false
        );
        if (this.state.promoID) {
            const [id, combo] = this.state.promoID.split(".");
            this.props.dispatch(
                actions.product.setProductFromPromoID(
                    Number(id),
                    combo && Number(combo)
                )
            );
        } else if (this.state.posID) {
            const [id, combo] = this.state.posID.split(".");
            this.props.dispatch(
                actions.product.setProductFromPosID(
                    Number(id),
                    combo && Number(combo)
                )
            );
        }
        if (uneditable) {
            this.props.dispatch(actions.product.setProductUneditable());
        }

        // if edit cart item id then pass matching cart item item to product state
        if (this.state.editCartItemID) {
            this.props.dispatch(
                actions.product.editCartItem(this.state.editCartItemID)
            );
        }
        GoogleAnalytics.logEvent({
            category: "Menu",
            action: "Open",
            label: "Product_Details",
            value: Number(this.state.promoID || this.state.posID)
        });
    }

    productNotFound(): boolean {
        const atLeastOneMenuItem = _.get(this, "props.product.menuItems[0]");
        const productNotFound = _.get(this, "props.product.productNotFound");

        if (!productNotFound && atLeastOneMenuItem) {
            return false;
            // const routeCategory = _.find(this.props.menu.categories, {
            //     category_id: Number(this.props.match.params.category)
            // });
            // if (routeCategory) {
            //     const foundInRouteCategory = _.find(routeCategory.menu_items, {
            //         base_menu_item_id: atLeastOneMenuItem.base_menu_item_id
            //     });
            //     if (foundInRouteCategory) {
            //         return false;
            //     }
            // }
        }

        return true;
    }

    getMenuItemImageUrl(menuItem: any): any {
        if (menuItem) {
            return (
                (menuItem.promo_id
                    ? _.get(this.props, "menu.images_promos_basepath")
                    : _.get(this.props, "menu.images_item_basepath")) +
                menuItem.image
            );
        }
    }

    //
    // Event Handling
    //

    handleMealSizeChange(event: any) {
        this.props.dispatch(
            actions.product.setComboLevel(
                event.target.value === "" ? null : Number(event.target.value)
            )
        );
    }

    handleQuantityChange(quantity: number) {
        this.props.dispatch(actions.product.setQuantity(quantity));
    }

    handleSalesItemChangeClick(
        salesItem: any,
        salesItemIndex: number,
        salesGroup: any,
        comboLineItemNumber: number
    ) {
        this.setState({
            showEditModal: true,
            initialProductSettings: _.cloneDeep(this.props.product), // clone current product settings for reset later
            editSalesItem: salesItem,
            editSalesItemIndex: salesItemIndex,
            editSalesGroup: salesGroup
        });
    }

    handleSalesItemCancel() {
        // reset product back to pre modified changes
        this.props.dispatch(
            actions.product.resetProductChanges(
                this.state.initialProductSettings
            )
        );
        this.setState({
            showEditModal: false
        });
    }

    handleSalesItemUpdate() {
        this.setState({
            showEditModal: false
        });
    }

    //
    // Render Methods
    //

    renderMealSizeHandling(): React.Node {
        const menuItems = _.uniqBy(
            _.get(this, "props.product.menuItems"),
            "option_name"
        );

        if (menuItems && menuItems.length > 1) {
            return (
                <div className="product-detail__meal-size-handling">
                    <ReactForm className="radio-buttons center-xs">
                        {menuItems.map(
                            (menuItem: any, menuItemCTX: number): any => {
                                return (
                                    <ReactFormField
                                        key={menuItemCTX}
                                        name="product-meal-size"
                                        type="radio"
                                        label={menuItem.option_name}
                                        value={
                                            menuItem.combo_level === null
                                                ? ""
                                                : String(menuItem.combo_level)
                                        }
                                        fieldComponent={RadioButton}
                                        onChange={this.handleMealSizeChange.bind(
                                            this
                                        )}
                                        checked={
                                            menuItem.combo_level ===
                                            this.props.product.combo_level
                                        }
                                    />
                                );
                            }
                        )}
                    </ReactForm>
                </div>
            );
        } else {
            return null;
        }
    }

    renderMealSalesItems(): any {
        const product = _.get(this, "props.product");

        if (product.sales_items) {
            return (
                <div className="product-detail__sales-items">
                    {/* loop through sales items */}
                    {product.sales_items.map(
                        (salesItem: any, salesItemIndex: number): any => {
                            // try to find the menu item sales group that has the set sales item
                            const parentSalesGroup = _.get(
                                product.sales_groups,
                                `[${salesItemIndex}]`
                            );

                            if (salesItem.is_visible) {
                                return (
                                    <SalesItem
                                        key={salesItemIndex}
                                        name={salesItem.name}
                                        image={salesItem.thumb}
                                        salesItem={salesItem}
                                        salesGroup={parentSalesGroup}
                                        comboLineItemNumber={
                                            salesItem.combo_line_item_number
                                        }
                                        onButtonClick={(): any => {
                                            this.handleSalesItemChangeClick(
                                                salesItem,
                                                salesItemIndex,
                                                parentSalesGroup,
                                                salesItem.combo_line_item_number
                                            );
                                        }}
                                    />
                                );
                            } else {
                                return null;
                            }
                        }
                    )}
                </div>
            );
        } else {
            return null;
        }
    }

    renderAllergens(): any {
        if (this.props.product.allergens.length) {
            return (
                <>
                    {`${this.props.product.menu_item_name} contains ${this.props.product.allergens.map((allergen: string): any => {
                        return (`${allergen}`)
                    }).join(`, `)}`}
                    <br />
                </>
            );
        } else {
            return null;
        }
    }

    render(): React.Node {
        const product = _.get(this, "props.product");

        if (product && !this.productNotFound()) {
            const selectedMenuItemByCombo =
                _.find(product.menuItems, {
                    combo_level: this.props.product.combo_level
                }) || product.menuItems[0];

            return (
                <React.Fragment>
                    <div className="product-detail">
                        <ProductHero
                            className="nav-primary-pad offset-next-md"
                            title={product.menu_item_name}
                            description={selectedMenuItemByCombo.description}
                            productImgSrc={this.getMenuItemImageUrl(product)}
                            showProductOnMobile={true}
                        >
                            <BreadcrumbLink
                                to={`${Config.routes.menu}/${this.props.router.params.storeID}/${this.props.router.params.category}`}
                            >
                                <span className="icon icon-arrow-left" /> Back
                            </BreadcrumbLink>
                        </ProductHero>

                        <div className="container-fluid-small">
                            <div className="product-detail__content">
                                <div className="product-detail__section">
                                    {/*Meal size handling*/}
                                    {this.renderMealSizeHandling()}

                                    {/*Sales item handling*/}
                                    {this.renderMealSalesItems()}

                                    <Row className="product-detail__disclaimer-quantity between-xs">
                                        <Col>
                                            <Disclaimer icon="alert" ariaLabel={"Product information"}>
                                                {/*Active allergens*/}
                                                {this.renderAllergens()}
                                                Kilojoules are not updated when
                                                menu items are substituted
                                            </Disclaimer>
                                        </Col>
                                        <Col>
                                            {/*Product quantity control*/}
                                            <QuantityControl
                                                min={1}
                                                quantity={
                                                    this.props.product.quantity
                                                }
                                                disabled={
                                                    this.props.product
                                                        .quantityUneditable
                                                }
                                                onChange={this.handleQuantityChange.bind(
                                                    this
                                                )}
                                            />
                                        </Col>
                                    </Row>
                                </div>

                                {/* Product totals/add to cart footer */}
                                <div className="product-detail__section product-detail__footer">
                                    <AddToCartFooter className="hide-mobile" />
                                </div>
                            </div>

                            {/* Related products */}
                            <RelatedProducts
                                className="product-detail__related-products"
                                currentMenuItemID={
                                    this.props.product.menuItems
                                        ? this.props.product.menuItems[0]
                                              .base_menu_item_id
                                        : null
                                }
                                categoryID={this.props.router.params.category}
                                limit={3}
                            />
                        </div>
                    </div>

                    {/* Edit Sales item modal */}
                    <Modal
                        aside={true}
                        active={this.state.showEditModal}
                        onClose={this.handleSalesItemCancel.bind(this)}
                    >
                        <EditSalesItem
                            salesItem={this.state.editSalesItem}
                            salesItemIndex={this.state.editSalesItemIndex}
                            salesGroup={this.state.editSalesGroup}
                            onConfirm={this.handleSalesItemUpdate.bind(this)}
                        />
                    </Modal>
                </React.Fragment>
            );
        } else {
            return <PageNotFound title="Product not found" hideFooter={true} />;
        }
    }
}

const mapStateToProps = (state: any): any => {
    return {
        menu: state.menu,
        product: state.product,
        cart: state.cart,
        navigator: state.navigator
    };
};

export default withRouter(connect(mapStateToProps)(ProductDetail));
