// @flow

import * as React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { connect } from "react-redux";
import moment from "moment";
import useOrderStatuses from "@utils/hooks/useOrderStatuses";
import orderUtils from "@utils/orderUtils";

import PoweredByMenulog from "@components/atoms/PoweredByMenulog";

type PropsType = {
    order: any
};

const OrderProgress = (props: PropsType): React.Node => {
    const baseClassname = "order-progress";

    const allStatusSteps = useOrderStatuses();

    // Determine the text used for the delivery time
    const deliveryTime = ((): React.Node => {
        const formattedETA = moment(
            props.order.delivery_estimated_time_of_arrival
        ).format("h:mma");
        if (props.order.isDelivered) {
            return <span>Delivered {formattedETA}</span>;
        }
        if (props.order.delivery_estimated_time_of_arrival) {
            return <span>Estimated arrival {formattedETA}</span>;
        }
        return null;
    })();

    const isDeliveryOrder = orderUtils.isDeliveryOrder(props.order);

    return (
        <div className={`${baseClassname}`}>
            <div className={`${baseClassname}__header`}>
                {isDeliveryOrder ? (
                    <div className={`${baseClassname}__live`}>
                        <div className={`${baseClassname}__live__indicator`} />
                        <div className={`${baseClassname}__live__text`}>
                            LIVE
                        </div>
                    </div>
                ) : null}

                <h2
                    className="heading-2"
                    style={{
                        position: "relative",
                        width: "100%"
                    }}
                >
                    <TransitionGroup>
                        <CSSTransition
                            key={props.order.title}
                            timeout={700}
                            classNames="text-slide"
                            mountOnEnter
                            unmountOnExit
                        >
                            <span key={props.order.title}>
                                {props.order.title}
                            </span>
                        </CSSTransition>
                    </TransitionGroup>
                </h2>
            </div>

            {isDeliveryOrder ? (
                <>
                    <div className={`${baseClassname}__copy`}>
                        <div className="body-large">
                            <span>{props.order.subtitle}</span>
                        </div>
                        <div className="body-large hide-sm">{deliveryTime}</div>
                    </div>
                    <div className={`${baseClassname}__tracker`}>
                        <div
                            className={`${baseClassname}__tracker__fill`}
                            style={{
                                transform: `scaleX(${props.order.barPercent})`
                            }}
                        />
                        <div className={`${baseClassname}__tracker__segments`}>
                            {allStatusSteps.map(
                                (status: any, index: number): React.Node => (
                                    <div
                                        key={index}
                                        style={{
                                            left: `${status.barPercent * 100}%`
                                        }}
                                        className={`${baseClassname}__tracker__segment`}
                                    />
                                )
                            )}
                        </div>
                    </div>
                    <div className={`${baseClassname}__copy-sm show-sm`}>
                        <div className="body-large">{deliveryTime}</div>
                    </div>
                    <PoweredByMenulog
                        className={`${baseClassname}__menulog-copy`}
                    />
                </>
            ) : (
                <div className={`${baseClassname}__copy`}>
                    <div className="body-large">
                        <span>For assistance please contact Hungry Jacks</span>
                    </div>
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state: any): any => ({});

export default connect(
    mapStateToProps,
    {}
)(OrderProgress);
