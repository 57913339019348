import Bowser from "bowser";
import Config from "@Config";
import ActionTypes from "@actionTypes";
import { getQueryString } from "@utils";

const browser = Bowser.getParser(window.navigator.userAgent);

const initialState = {
    webview: getQueryString(Config.webviewQuery) ? true : false,
    browser: browser,
    isMobile: browser.getPlatform().type === "mobile" ? true : false,
    geolocation: {
        supported: "geolocation" in navigator ? true : false,
        access: false
    },
    paymentSupport: {
        "credit-card": true, // credit card supported by default
        paypal: true, // paypal supported by default
        googlepay: false,
        applepay: false
    }
};

export default function deviceReducer(state = initialState, action = {}) {
    switch (action.type) {
        case ActionTypes.SET_GEOLOCATION_STATUS:
            return {
                ...state,
                geolocation: {
                    ...state.geolocation,
                    access: action.access,
                    position: action.position
                }
            };
        case ActionTypes.SET_APPLEPAY_SUPPORT:
            return {
                ...state,
                paymentSupport: {
                    ...state.paymentSupport,
                    applepay: action.supported
                }
            };
        case ActionTypes.SET_GOOGLEPAY_SUPPORT:
            return {
                ...state,
                paymentSupport: {
                    ...state.paymentSupport,
                    googlepay: action.supported
                }
            };
        default:
            return state;
    }
}
