// @flow

import ActionTypes from "@actionTypes";
import ApiFactory from "@apis/APIFactory";

const getAppConfigRequest = (): any => ({
    type: ActionTypes.GET_APP_CONFIG_REQUEST
});

const getAppConfigFail = (error: any): any => ({
    type: ActionTypes.GET_APP_CONFIG_FAIL,
    error
});

const getAppConfigSuccess = (config: any): any => ({
    type: ActionTypes.GET_APP_CONFIG_SUCCESS,
    config
});

export const getAppConfig = (): any => (dispatch: any, getState: any): any => {
    dispatch(getAppConfigRequest());
    return new Promise((resolve: any, reject: any) => {
        ApiFactory.CommonAPI.getVersion()
            .then((res: any) => {
                dispatch(getAppConfigSuccess(res));
                resolve();
            })
            .catch((err: any) => {
                dispatch(getAppConfigFail(err));
                reject(err);
            });
    });
};
