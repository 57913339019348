import _ from "lodash";
import ActionTypes from "@actionTypes";

const initialState = {
    cards: [],
    activeCard: null,
    lastCardReference: null,
    checkCards: false,
    isFetching: false
};

export default function adyenReducer(state = initialState, action = {}) {
    switch (action.type) {
        case ActionTypes.GET_CARDS_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case ActionTypes.GET_CARDS_FAILURE:
            return {
                ...state,
                cards: [],
                lastCardReference: null,
                isFetching: false
            };
        case ActionTypes.GET_CARDS_SUCCESS:
            return {
                ...state,
                cards: _.get(action.result, "details") || [],
                // if last used card then set as active otherwise use last card in cards array
                activeCard:
                    action.result.details && action.result.details.length
                        ? _.get(action.result, "lastUsedReference")
                            ? _.find(action.result.details, card => {
                                  return (
                                      card.recurringDetail
                                          .recurringDetailReference ===
                                      _.get(action.result, "lastUsedReference")
                                  );
                              })
                            : action.result.details[
                                  action.result.details.length - 1
                              ]
                        : null,
                lastCardReference: _.get(action.result, "lastUsedReference"),
                isFetching: false
            };
        case ActionTypes.SET_SELECTED_CARD:
            return {
                ...state,
                activeCard: _.find(state.cards, card => {
                    return (
                        card.recurringDetail.recurringDetailReference ===
                        action.recurringDetailReference
                    );
                })
            };
        // case ActionTypes.ADD_CARD_REQUEST:
        //     return {
        //         ...state
        //     };
        // case ActionTypes.ADD_CARD_FAILURE:
        //     return {
        //         ...state,
        //         checkCards: false
        //     };
        // case ActionTypes.ADD_CARD_VERIFY:
        //     return {
        //         ...state,
        //         checkCards: true
        //     };
        // case ActionTypes.ADD_CARD_SUCCESS:
        //     return {
        //         ...state,
        //         cards: _.get(action.result, "details") || [],
        //         lastCardReference: _.get(action.result, "lastUsedReference"),
        //         checkCards: false
        //     };
        case ActionTypes.VERIFY_ADDED_CARD_REQUEST:
            return { ...state };
        case ActionTypes.VERIFY_ADDED_CARD_FAIL:
            return { ...state };
        case ActionTypes.VERIFY_ADDED_CARD_SUCCESS:
            return {
                ...state,
                cards: _.get(action.result, "details") || [],
                lastCardReference: _.get(action.result, "lastUsedReference"),
                checkCards: false,
                activeCard:
                    action.result.details && action.result.details.length
                        ? _.get(action.result, "lastUsedReference")
                            ? _.find(action.result.details, card => {
                                  return (
                                      card.recurringDetail
                                          .recurringDetailReference ===
                                      _.get(action.result, "lastUsedReference")
                                  );
                              })
                            : action.result.details[
                                  action.result.details.length - 1
                              ]
                        : null
            };
        case ActionTypes.CREATE_ORDER_SUCCESS:
            return {
                ...state,
                cards: _.get(action.order.recurring_details, "details") || [],
                lastCardReference: _.get(
                    action.order.recurring_details,
                    "lastUsedReference"
                )
            };
        case ActionTypes.REGISTER_SUCCESS:
        case ActionTypes.LOGIN_SUCCESS:
        case ActionTypes.LOGOUT: {
            return {
                ...state,
                cards: [],
                activeCard: null,
                lastCardReference: null,
                checkCards: false,
                isFetching: false
            };
        }
        default:
            return state;
    }
}
