// @flow

import * as React from "react";

// import MenulogLogo from "@assets/images/menulog-logo.svg";

type PropsType = {
    className: string
};

const PoweredByMenuLog = (props: PropsType): React.Node => {
    return (
        <div className={`powered-by-menulog ${props.className}`}>
            Delivery powered by
            <div className={`powered-by-menulog__logo`} />
        </div>
    );
};

PoweredByMenuLog.defaultProps = {
    className: ""
};

export default PoweredByMenuLog;
