// @flow

import * as React from "react";
import { Link } from "react-router-dom";
import Button from "@components/atoms/Button";

import { formatMoney } from "@utils";
import ProductImage from "@components/atoms/ProductImage";
import FormatSymbols from "@components/atoms/FormatSymbols";
import Icon from "@components/atoms/Icon";

const { useState, useRef } = React;
const doc: any = document.documentElement;

type PropsType = {
    className?: string,
    link?: ?string,
    name: string,
    fromPrice?: number,
    default_price?: number,
    fromKj?: number,
    imageUrl?: string,
    onClick?: ?any,
    large?: boolean,
    upsell?: boolean,
    hasOverlay?: boolean
};

const ProductTile = (props: PropsType): any => {
    const baseClassname = "product-tile";

    const [showOverlay, setShowOverlay] = useState(false);
    const ref = useRef(null);

    const hideOverlay = (e: any) => {
        doc.removeEventListener("click", hideOverlay);
        if (ref.current && !ref.current.contains(e.target)) {
            setShowOverlay(false);
        }
    };

    const TagName = props.link ? Link : "div";

    if (props.link || props.onClick) {
        return (
            <TagName
                ref={ref}
                to={props.link}
                aria-label={props.name}
                onClick={(e: any) => {
                    if (
                        props.hasOverlay &&
                        doc.className &&
                        !doc.className.includes("no-touch") &&
                        !showOverlay
                    ) {
                        e.preventDefault();
                        setShowOverlay(true);
                        doc.addEventListener("click", hideOverlay);
                        return;
                    }
                    setShowOverlay(false);
                    props.onClick && props.onClick(e);
                }}
                className={`${baseClassname} 
                ${props.large ? "product-tile--large" : ""}
                ${props.upsell ? "product-tile--upsell" : ""}
                `}
            >
                {props.upsell ? (
                    <Icon
                        className="product-tile__add-icon"
                        name="plus"
                        circle={true}
                    />
                ) : null}

                <div className={`${baseClassname}__copy-area`}>
                    <div className={`${baseClassname}__title`}>
                        <FormatSymbols>{props.name}</FormatSymbols>
                    </div>

                    {props.fromPrice ? (
                        <div className={`${baseClassname}__price`}>
                            From {formatMoney(props.fromPrice)} / {props.fromKj}
                            kJ
                        </div>
                    ) : props.default_price ? (
                        <div className={`${baseClassname}__price`}>
                            {formatMoney(props.default_price)}
                        </div>
                    ) : null}
                </div>
                <div className={`${baseClassname}__image-area`}>
                    <div className={`${baseClassname}__image-area__bg`} />

                    <ProductImage
                        className={`${baseClassname}__image`}
                        src={String(props.imageUrl)}
                        alt={props.name}
                    />
                </div>
                {props.hasOverlay && (
                    <div
                        className={`${baseClassname}__overlay ${
                            showOverlay ? "force" : ""
                        }`}
                    >
                        <Button className={`${baseClassname}__overlay__button`}>
                            Get delivery
                        </Button>
                    </div>
                )}
            </TagName>
        );
    } else {
        return null;
    }
};

export default ProductTile;
