// @flow
import * as React from "react";
import { Row, Col } from "@containers/Grid";
import { ReactFormField } from "@adrenalin/react-form";
import Icon from "@components/atoms/Icon";

const ThumbInput = (props: any): any => {
    // if (props.inputElement.ref.current) {
    //     props.inputElement.ref.current.className = "";
    //     if (props.classes.isInvalid) {
    //         props.inputElement.ref.current.classList.add(
    //             props.classes.isInvalid
    //         );
    //     }
    // }
    return (
        <React.Fragment>
            {props.inputElement}
            {/*errors*/}
            {!props.hideErrors &&
            props.errors &&
            props.errors.length &&
            props.showErrors ? (
                <div className={`react-form__field__errors`}>
                    <div
                        className={`react-form__field__errors__item`}
                        id={props.accessibilityIds.error}
                    >
                        {props.errors[0]}
                    </div>
                </div>
            ) : null}
        </React.Fragment>
    );
};

const ThumbsFeedback = (
    name: string,
    maxRating: number,
    question: string,
    prefilledRating: ?number
): React.Node => {
    const ratingArray = [];
    for (var i = 0; i < maxRating; i++) {
        ratingArray.push(Number(i + 1));
    }

    return (
        <div className="thumbs-feedback-container">
            <Row>
                <Col className="thumbs-feedback-title-container" xs={12} sm={6}>
                    <div className="thumbs-feedback-title">{question}</div>
                </Col>
                <Col xs={12} sm={6}>
                    <div className="thumbs-feedback">
                        <div className="thumbs-feedback-scale">
                            <div className="thumbs-feedback__radios">
                                <ReactFormField
                                    type="radio"
                                    name={name}
                                    defaultChecked={!prefilledRating}
                                    fieldComponent={ThumbInput}
                                />
                                {ratingArray.map(
                                    (rating: any, index: any): any => (
                                        <React.Fragment key={index}>
                                            <ReactFormField
                                                type="radio"
                                                name={name}
                                                id={`rating_${name}_${rating}`}
                                                value={Number(rating)}
                                                fieldComponent={ThumbInput}
                                                defaultChecked={
                                                    Number(rating) ===
                                                    Number(prefilledRating)
                                                }
                                                required={true}
                                                errorMessages={{
                                                    required:
                                                        "Please select a rating for this question."
                                                }}
                                            />
                                            <label
                                                htmlFor={`rating_${name}_${rating}`}
                                            >
                                                <Icon
                                                    className="thumbs-feedback-icon"
                                                    name="rate-on"
                                                />
                                            </label>
                                        </React.Fragment>
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            {/*<div className="react-form__field__errors">
                Please select a rating for this question
            </div>*/}
        </div>
    );
};

export default ThumbsFeedback;
