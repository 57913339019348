// @flow

import _ from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import Modal from "@components/organisms/Modal";
import Alert from "@components/molecules/Alert";
import AddressLocator from "@components/organisms/AddressLocator";
import LoginPrompt from "@components/organisms/LoginPrompt";
import AddCreditCard from "@components/organisms/AddCreditCard";
import ChangeCreditCard from "@components/organisms/ChangeCreditCard";
import CompCodeModal from "@components/organisms/CompCodeModal";
import NameModal from "@components/organisms/NameModal";
import EmailModal from "@components/organisms/EmailModal";
import actions from "@actions";
import TermsAgreement from "@components/molecules/TermsAgreement";
import * as GoogleAnalytics from "@utils/GoogleAnalytics";
import modalIds from "../constants/GlobalModals";
import VoucherDetail from "@components/molecules/VoucherDetail";
import BarcodeOverlay from "@components/molecules/BarcodeOverlay";
import UpsellsModal from "../components/organisms/UpsellsModal";

const { Fragment } = React;

type PropsType = {
    modals: any,
    deliveryLocation: any,
    closeGlobalModal: (modalId: string, success?: boolean) => any,
    closeVoucherModal: any,
    closeBarcodeOverlay: any
};

const GlobalModals = ({
    modals,
    closeGlobalModal,
    closeVoucherModal,
    closeBarcodeOverlay,
    deliveryLocation
}: PropsType): React.Node => {
    const xsModalWidth = 335;
    const smallModalWidth = 468;
    const largeModalWidth = 802;

    return (
        <Fragment>
            {/* Global Alert Modal */}
            <Alert {...modals[modalIds.ALERT]} />
            {/* Login prompt Modal*/}
            <Modal
                maxWidth={largeModalWidth}
                active={_.get(modals, [modalIds.LOGIN_PROMPT, "active"])}
                onClose={() => {
                    GoogleAnalytics.logEvent({
                        category: "Close_LoginPanel",
                        action: "Close_LoginPanel",
                        label: "Close"
                    });
                    closeGlobalModal(modalIds.LOGIN_PROMPT);
                }}
            >
                <LoginPrompt
                    onSuccess={_.get(modals, [
                        modalIds.LOGIN_PROMPT,
                        "onSuccess"
                    ])}
                    onClose={() => {
                        closeGlobalModal(modalIds.LOGIN_PROMPT);
                    }}
                    toPath={_.get(modals, [modalIds.LOGIN_PROMPT, "toPath"])}
                    fromCoffeeLoyalty={_.get(modals, [modalIds.LOGIN_PROMPT, "fromCoffeeLoyalty"])}
                />
            </Modal>
            {/*Address Locator Modal*/}
            <Modal
                className="address-locator-modal"
                active={_.get(modals, [modalIds.ADDRESS_LOCATOR, "active"])}
                onClose={() => {
                    GoogleAnalytics.logEvent({
                        category: "OrderHome",
                        action: "AddressSearch",
                        label: "Close"
                    });
                    closeGlobalModal(modalIds.ADDRESS_LOCATOR);
                }}
            >
                <AddressLocator
                    modalId={modalIds.ADDRESS_LOCATOR}
                    onSuccess={_.get(modals, [
                        modalIds.ADDRESS_LOCATOR,
                        "onSuccess"
                    ])}
                    preloadedAddress={deliveryLocation}
                    toPath={_.get(modals, [modalIds.ADDRESS_LOCATOR, "toPath"])}
                />
            </Modal>
            {/*Add payment method (card) Modal*/}
            <Modal
                maxWidth={smallModalWidth}
                active={_.get(modals, [modalIds.ADD_PAYMENT_METHOD, "active"])}
                onClose={() => {
                    closeGlobalModal(modalIds.ADD_PAYMENT_METHOD);
                }}
            >
                <AddCreditCard
                    modalId={modalIds.ADD_PAYMENT_METHOD}
                    onSuccess={_.get(modals, [
                        modalIds.ADD_PAYMENT_METHOD,
                        "onSuccess"
                    ])}
                />
            </Modal>
            {/* Change payment method (card) Modal*/}
            <Modal
                maxWidth={smallModalWidth}
                active={_.get(modals, [
                    modalIds.CHANGE_PAYMENT_METHOD,
                    "active"
                ])}
                onClose={() => {
                    closeGlobalModal(modalIds.CHANGE_PAYMENT_METHOD);
                }}
            >
                <ChangeCreditCard
                    modalId={modalIds.CHANGE_PAYMENT_METHOD}
                    onSuccess={_.get(modals, [
                        modalIds.CHANGE_PAYMENT_METHOD,
                        "onSuccess"
                    ])}
                />
            </Modal>
            {/* View TNC Modal*/}
            <Modal
                maxWidth={600}
                active={_.get(modals, [modalIds.TNC, "active"])}
                onClose={() => {
                    closeGlobalModal(modalIds.TNC);
                }}
                internalScroller={true}
            >
                <TermsAgreement
                    onConfirm={() => {
                        closeGlobalModal(modalIds.TNC);
                        _.get(modals, [modalIds.TNC, "onSuccess"], () => {})();
                    }}
                    onClose={() => {
                        closeGlobalModal(modalIds.TNC);
                    }}
                />
            </Modal>
            {/* Comp Code Modal*/}
            <Modal
                maxWidth={smallModalWidth}
                active={_.get(modals, [modalIds.COMP, "active"])}
                hideClose
                onClose={() => {}}
            >
                <CompCodeModal
                    onSuccess={() => {
                        closeGlobalModal(modalIds.COMP);
                        _.get(modals, [modalIds.COMP, "onSuccess"], () => {})();
                    }}
                    onClose={() => {
                        closeGlobalModal(modalIds.COMP);
                    }}
                />
            </Modal>
            {/* Name Modal*/}
            <Modal
                maxWidth={smallModalWidth}
                active={_.get(modals, [modalIds.NAME, "active"])}
                onClose={() => {
                    closeGlobalModal(modalIds.NAME);
                }}
            >
                <NameModal
                    onSuccess={input => {
                        closeGlobalModal(modalIds.NAME);
                        _.get(modals, [modalIds.NAME, "onSuccess"], () => {})(
                            input
                        );
                    }}
                />
            </Modal>
            {/* Email Modal*/}
            <Modal
                maxWidth={smallModalWidth}
                active={_.get(modals, [modalIds.EMAIL, "active"])}
                onClose={() => {
                    closeGlobalModal(modalIds.EMAIL);
                }}
            >
                <EmailModal
                    onSuccess={input => {
                        closeGlobalModal(modalIds.EMAIL);
                        _.get(modals, [modalIds.EMAIL, "onSuccess"], () => {})(
                            input
                        );
                    }}
                />
            </Modal>

            {/* Voucher Modal */}
            <Modal
                maxWidth={xsModalWidth}
                active={_.get(modals, [modalIds.VOUCHER_MODAL, "active"])}
                onClose={closeVoucherModal}
            >
                <VoucherDetail
                    voucher={_.get(modals, [modalIds.VOUCHER_MODAL, "voucher"])}
                    isComp={_.get(modals, [modalIds.VOUCHER_MODAL, "isComp"])}
                    onClose={closeVoucherModal}
                />
            </Modal>

            {/* Barcode Modal */}
            <Modal
                maxWidth={xsModalWidth}
                maxWidthSet={false}
                active={_.get(modals, [modalIds.BARCODE_OVERLAY, "active"])}
                // clickBackgroundClose={true}
                overlayStyle={true}
                onClose={closeBarcodeOverlay}
            >
                <BarcodeOverlay onClose={closeBarcodeOverlay} />
            </Modal>

            {/* Upsells Modal*/}
            <Modal
                maxWidth={560}
                active={_.get(modals, [modalIds.UPSELLS, "active"])}
                onClose={() => {
                    closeGlobalModal(modalIds.UPSELLS);
                }}
                darkClose
            >
                <UpsellsModal
                    onSuccess={input => {
                        closeGlobalModal(modalIds.UPSELLS);
                        _.get(
                            modals,
                            [modalIds.UPSELLS, "onSuccess"],
                            () => {}
                        )(input);
                    }}
                />
            </Modal>
        </Fragment>
    );
};

const mapStateToProps = (state: any): any => ({
    modals: state.globalModals,
    deliveryLocation: state.session.deliveryLocation
});

export default connect(
    mapStateToProps,
    {
        closeGlobalModal: actions.globalModals.closeGlobalModal,
        closeVoucherModal: actions.globalModals.closeVoucherModal,
        closeBarcodeOverlay: actions.globalModals.closeBarcodeOverlay
    }
)(GlobalModals);
