// @flow

import * as React from "react";
import _ from "lodash";

import Config from "@Config";
import ActionTypes from "@actionTypes";
import ApiFactory from "@apis/APIFactory";
import actions from "@actions";

import * as GoogleAnalytics from "@utils/GoogleAnalytics";

export const getMenuRequest = (): any => ({
    type: ActionTypes.GET_MENU_REQUEST
});

export const getMenuSuccess = (menu: any): any => (
    dispatch: any,
    getState: any
): any => {
    // Log in GA
    GoogleAnalytics.logEvent({
        category: "Menu",
        action: "Start",
        label: `Menu_${menu.store_id}`,
        value: menu.store_id
    });

    // https://hungryjacks.atlassian.net/browse/SLA-638
    for (const item of menu.sales_items) {
        item.energy_kj = Math.round(item.energy_kj);
    }

    // set store menu in state
    dispatch({
        type: ActionTypes.GET_MENU_SUCCESS,
        menu
    });

    // process menu time based restrictions
    dispatch({
        type: ActionTypes.SET_DAYPART_MENU,
        orderTime: getState().session.deliveryTime
    });

    // check any voucher deeplinks
    dispatch(actions.vouchers.checkAndApplyVoucher());

    // check any product deeplinks
    dispatch(actions.session.checkProductDeeplink());

    // check cart items against new menu
    dispatch({
        type: ActionTypes.CHECK_CART_AVAILABILITY,
        menu: getState().menu,
        orderTime: getState().session.deliveryTime
        // showPriceDifference: true // currently price difference is shown in cart items after hitting the /checkout api
    });

    // check if store supports any voucher/promo payment constraints
    dispatch(checkStoreSupportsPayments());

    dispatch({
        type: ActionTypes.CHECK_DISCOUNTED_ITEM,
        discountedMenuItems: getState().config.menu.discounted_menu_items
    });

    dispatch(actions.cart.recalculateCartTotals());
};

export const getMenuFailure = (): any => ({
    type: ActionTypes.GET_MENU_FAILURE
});

export const getMenu = (storeID: number, lastUpdated: any): any => (
    dispatch: any,
    getState: any
): any => {
    const { session } = getState();

    if (!storeID || isNaN(storeID)) {
        return dispatch(getMenuFailure());
    }

    dispatch(getMenuRequest());

    return ApiFactory.StoresAPI.getMenu(
        storeID,
        session.orderMethod,
        lastUpdated
    )
        .then((menuResponse: any): any => {
            if (menuResponse) {
                // dispatch menu success
                dispatch(getMenuSuccess(menuResponse));
            } else {
                dispatch(getMenuFailure());
            }
        })
        .catch((err: any): any => {
            dispatch(getMenuFailure());
            throw err;
        });
};

export const checkStoreSupportsPayments = (
    payment_methods_constraints?: any,
    callback?: Function
): any => (dispatch: any, getState: any): any => {
    const { session, cart } = getState();
    const selectedStoreID = _.get(session, "selectedStore.store_id");
    let paymentMethodsConstraints;

    if (payment_methods_constraints) {
        // constraints provided
        paymentMethodsConstraints = payment_methods_constraints;
    } else {
        // collect payment method constraints from any applied voucher or voucher line items in the cart
        paymentMethodsConstraints =
            cart.compCode && cart.compCode.payment_methods_constraints
                ? cart.compCode.payment_methods_constraints
                : [];
        cart.line_items.forEach(lineItem => {
            if (lineItem.voucher_id && lineItem.payment_methods_constraints) {
                paymentMethodsConstraints =
                    lineItem.payment_methods_constraints;
            }
        });
    }

    if (paymentMethodsConstraints && paymentMethodsConstraints.length) {
        return ApiFactory.OrderApi.getSupportedPaymentMethods(
            selectedStoreID,
            paymentMethodsConstraints || []
        )
            .then((response): any => {
                // clear existig voucher/promo errors
                dispatch({
                    type: ActionTypes.CLEAR_PROMO_ERROR
                });

                if (
                    response.payment_methods &&
                    response.payment_methods.paymentMethods &&
                    response.payment_methods.paymentMethods.length
                ) {
                    // store supports payment method for applied voucher/promo
                    // call callback if provided
                    if (callback) {
                        callback();
                    }
                } else {
                    // store does not support voucher payment method

                    let unsupportedPaymentConstraints = "";
                    paymentMethodsConstraints.forEach((constraint, i) => {
                        unsupportedPaymentConstraints =
                            unsupportedPaymentConstraints +
                            (i > 0 ? ", " : "") +
                            _.find(Config.PaymentOptions, {
                                constraint: constraint
                            }).text;
                    });

                    // IF no new voucher/promo constraints provided so assume already added to cart and show errors
                    if (!payment_methods_constraints) {
                        dispatch({
                            type: ActionTypes.SET_PROMO_ERROR,
                            error: `${unsupportedPaymentConstraints} not accepted by this restaurant`
                        });
                    }

                    // show confirm overwrite voucher prompt
                    dispatch(
                        actions.globalModals.openAlertModal({
                            title: (
                                <>
                                    {unsupportedPaymentConstraints} not accepted
                                    by restaurant
                                </>
                            ),
                            body: (
                                <p>
                                    Sorry the selected restaurant does not
                                    accept {unsupportedPaymentConstraints}
                                </p>
                            ),
                            confirmText: "OK",
                            onConfirm: () => {
                                dispatch(
                                    actions.globalModals.closeAlertModal()
                                );
                            }
                        })
                    );
                }
            })
            .catch((err: any): any => {
                throw err;
            });
    } else {
        // clear existig voucher/promo errors
        dispatch({
            type: ActionTypes.CLEAR_PROMO_ERROR
        });

        if (callback) {
            callback();
        }
    }
};
