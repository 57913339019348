// @flow

import _ from "lodash";
// import { push } from "@lagunovsky/redux-react-router";
// import Config from "@Config";
import ActionTypes from "@actionTypes";
// import actions from "@actions";
// import GlobalModals from "@Modals";
import * as GoogleAnalytics from "@utils/GoogleAnalytics";

//
// Sets menuItems for product detail based off a base posID
//
export const setProductFromPosID = (
    posID: number,
    comboLevel: number = 1,
    isVoucher: boolean
): any => (dispatch: any, getState: any): any => {
    let menuItems = [];
    let parentCategory;

    // get base pos item by pos_item_id url param
    let basePosItem = _.find(getState().menu.menu_items, {
        pos_item_id: posID
    });
    if (basePosItem) {
        // find parent category
        getState().menu.categories.forEach((category: any) => {
            parentCategory = _.find(category.menu_items, {
                base_menu_item_id: basePosItem.base_menu_item_id
            })
                ? category
                : parentCategory;
        });

        // get menu items from base pos item
        menuItems = _.filter(getState().menu.menu_items, {
            base_menu_item_id: basePosItem.base_menu_item_id
        });
    }

    dispatch({
        type: ActionTypes.SET_PRODUCT,
        menuItems,
        parentCategory: _.cloneDeep(parentCategory)
    });

    // set default combo level if menu items exist
    if (menuItems[0]) {
        dispatch(
            setComboLevel(
                _.find(menuItems, { combo_level: comboLevel })
                    ? comboLevel
                    : menuItems[0].combo_level
            )
        );
    }
};

//
// sets menuItems for product detail based off a base promoID
//
export const setProductFromPromoID = (
    promoID: number,
    comboLevel: number = 1,
    isVoucher: boolean,
    voucherModifierId: number
): any => (dispatch: any, getState: any): any => {
    let menuItems = [];
    let parentCategory;

    // find parent category
    getState().menu.categories.forEach((category: any) => {
        parentCategory = _.find(category.menu_items, {
            promo_id: promoID
        })
            ? category
            : parentCategory;
    });

    menuItems = _.filter(getState().menu.menu_items, {
        promo_id: promoID
    });

    dispatch({
        type: ActionTypes.SET_PRODUCT,
        menuItems,
        parentCategory: _.cloneDeep(parentCategory)
    });

    // set default combo level if menu items exist
    if (menuItems[0]) {
        dispatch(
            setComboLevel(
                _.find(menuItems, { combo_level: comboLevel })
                    ? comboLevel
                    : menuItems[0].combo_level
            )
        );
    }

    // if voucher add voucher modifier
    if (isVoucher) {
        // loop over all sales items
        getState().product.sales_items.forEach((voucherSalesItem: any) => {
            // get the "Voucher" modifier group based on the provided modifier id (modifier's pos item id)
            voucherSalesItem.modifier_groups.forEach((modifierGroup: any) => {
                const voucherModifier = _.find(modifierGroup.modifiers, {
                    pos_item_id: voucherModifierId
                });

                if (voucherModifier) {
                    voucherModifier.isHidden = true;

                    dispatch(
                        updateModifierQuantity(
                            1,
                            voucherSalesItem,
                            voucherModifier,
                            modifierGroup,
                            voucherSalesItem.combo_line_item_number
                        )
                    );
                }
            });
        });
    }
};

//
// Recalcuate product totals (uses product sales items/modifiers and quanitity)
//
export const recalculateProductTotals = (): any => ({
    type: ActionTypes.CALCULATE_PRODUCT_TOTALS
});

//
// Set meal combo level (meal/item size)
//
export const setComboLevel = (combo_level: number): any => (
    dispatch: any,
    getState: any
): any => {
    let { product } = getState();
    if (!product.productNotFound) {
        dispatch({
            type: ActionTypes.SET_COMBO_LEVEL,
            combo_level: combo_level
        });
        dispatch(recalculateProductTotals());
        product = getState().product;
        GoogleAnalytics.logEvent({
            category: "Menu",
            action: "Change",
            label: `Product_${combo_level}_${product.promo_id ||
                product.pos_item_id}`
        });
    }
};

//
// Set product quantity level
//
export const setQuantity = (quantity: number): any => (
    dispatch: any,
    getState: any
): any => {
    const { product } = getState();

    dispatch({
        type: ActionTypes.SET_QUANTITY,
        quantity: quantity
    });
    dispatch(recalculateProductTotals());

    GoogleAnalytics.logEvent({
        category: "Cart",
        action: "Change",
        label: `Quantity_${product.promo_id || product.pos_item_id}`
    });
};

//
// Set sales item selection
//
export const setSalesItem = (
    salesGroup: {},
    newSalesItem: {},
    salesItemIndex?: number
): any => (dispatch: any, getState: any): any => {
    dispatch({
        type: ActionTypes.SET_SALES_ITEM,
        salesGroup: salesGroup,
        salesItemIndex: salesItemIndex,
        newSalesItem: newSalesItem
    });

    dispatch(recalculateProductTotals());

    GoogleAnalytics.logEvent({
        category: "Cart",
        action: "Change",
        label: `Side_${_.get(newSalesItem, "pos_item_id")}`
    });
};

//
// Reset sales item modifiers
//
export const resetSalesItemModifiers = (salesItemIndex: number): any => (
    dispatch: any,
    getState: any
): any => {
    dispatch({
        type: ActionTypes.RESET_SALES_ITEM_MODIFICATIONS,
        salesItemIndex: salesItemIndex
    });
    dispatch(recalculateProductTotals());
};

//
// Modifer quantity control, add/remove modifiers
//
export const updateModifierQuantity = (
    updateQuantity: number, // a positive or negative number representing the amount to update by
    salesItem: any,
    modiferItem: any,
    modiferGroup: any,
    comboLineItemNumber: number
): any => (dispatch: any, getState: any): any => {
    GoogleAnalytics.logEvent({
        category: "Menu",
        action: updateQuantity > 0 ? "Add" : "Remove",
        label: `Customize_${modiferItem.modifier_id}`
    });

    // get item modifier from modifier actions
    const modiferRules = _.find(modiferItem.item_modifiers, {
        sales_item_pos_item_id: salesItem.pos_item_id,
        modifier_group_id: modiferGroup.modifier_group_id
    });
    //loop through modifierRules actions and replace with the found modifers by id
    Object.keys(modiferRules.actions).forEach((action: any): any => {
        let modiferActionID = modiferRules.actions[action];
        let menuModifier = getState().menu.modifiers[modiferActionID];
        if (menuModifier) {
            modiferRules.actions[action] = menuModifier;
        }
    });
    dispatch({
        type: ActionTypes.SET_SALES_ITEM_MODIFIER,
        updateQuantity: updateQuantity,
        salesItem: salesItem,
        modiferItem: modiferItem,
        modiferRules: modiferRules,
        comboLineItemNumber: comboLineItemNumber,
        menu: getState().menu
    });

    // recalculate product totals
    dispatch(recalculateProductTotals());
};

//
// Clear sales item changes
//
export const resetProductChanges = (initialProduct: any): any => (
    dispatch: any
): any => {
    dispatch({
        type: ActionTypes.RESET_PRODUCT_CHANGES,
        initialProduct: initialProduct
    });
    dispatch(recalculateProductTotals());
};

//
// Edit cart item by its cart line number id
//
export const editCartItem = (uuid: string): any => (
    dispatch: any,
    getState: any
): any => {
    const { menu } = getState();

    const cartItemToEdit = _.find(getState().cart.line_items, {
        uuid: uuid
    });

    if (cartItemToEdit) {
        dispatch(setComboLevel(cartItemToEdit.combo_level));

        // add missing applied modifer properties (kj etc)
        cartItemToEdit.sales_items.forEach((sales_item: any) => {
            if (sales_item.modifiers) {
                sales_item.modifiers.forEach((modifier: any) => {
                    modifier.energy_kj =
                        menu.modifiers[modifier.action_id].energy_kj;
                });
            }
        });

        dispatch({
            type: ActionTypes.EDIT_CART_ITEM,
            cartItem: cartItemToEdit
        });
    }
};

export const setProductUneditable = (): any => ({
    type: ActionTypes.PRODUCT_UNEDITABLE
});
