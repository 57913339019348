// @flow

import _ from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "@utils/hooks/withRouter";

import MappedMetaTags from "@containers/MappedMetaTags";
import Footer from "@components/organisms/Footer";
import LandingPageContent from "@components/organisms/LandingPageContent";
import StartOrder from "@components/organisms/StartOrder";
import Hero from "@components/organisms/Hero";
import ReorderRecent from "@components/organisms/ReorderRecent";
import SignupLogin from "@components/organisms/SignupLogin";
import LimitedTimeOnly from "@components/organisms/LimitedTimeOnly";
import PopularProducts from "@components/organisms/PopularProducts";
// import YourOffers from "@components/molecules/YourOffers";
import MoreFromHJs from "@components/molecules/MoreFromHJs";
import LegacyCoffeeLoyaltyTracker from "@components/organisms/LegacyCoffeeLoyaltyTracker";
import CoffeeLoyaltyTracker from "@components/organisms/CoffeeLoyaltyTracker";
import FeatureFlags from "../constants/FeatureFlags";

type PropsType = {
    handleAddVoucher: () => void,
    session: any,
    user: any,
    pastOrders: any,
    isEarnAtCounterEnabled?: boolean,
    isCMJCLEnabled?: boolean
};

const Landing = (props: PropsType): React.Node => {
    const isAuthenticated = _.get(props, "session.isAuthenticated", false);

    return (
        <div className="view-flex-col">
            <MappedMetaTags defaultTitle="Welcome" />

            <Hero className="hero--home">
                {isAuthenticated ? (
                    <p className="hero__greeting">
                        Hi {_.get(props, "user.first_name", "")},
                    </p>
                ) : null}
                <h1 className="heading-1 hero__title">
                    Craving a whopper? <br />
                    Order now
                </h1>
            </Hero>

            <LandingPageContent>
                <div className="landing-page-content__top">
                    {/* Start deliery/app pickyp prompt */}
                    <StartOrder />

                    {/* Coffee loyalty progress tracker, only show Legacy of FF is off */}
                    {!props.isCMJCLEnabled &&
                        (props.isEarnAtCounterEnabled ? (
                            <CoffeeLoyaltyTracker isLanding={true} />
                        ) : (
                            <LegacyCoffeeLoyaltyTracker isLanding={true} />
                        ))
                    }
                    {/* Show recent orders module for authenticated users
                        Otherwise show sign up login */}
                    {isAuthenticated ? (
                        <ReorderRecent />
                    ) : (
                        <SignupLogin />
                    )}

                    <LimitedTimeOnly />
                </div>

                <PopularProducts />

                {/* Temporarily removing YourOffers as part of ticket: AOOT-1422 */}
                {/* <YourOffers /> */}
                {/* Only show Braze content tiles for users with an Appboy/Braze user ID */}
                {props.user.appboy_user_id && !props.user.is_guest_user && (
                    <MoreFromHJs />
                )}
            </LandingPageContent>

            <Footer />
        </div>
    );
};

const mapStateToProps = (state: any): any => {
    return {
        session: state.session,
        user: state.user,
        pastOrders: state.orders.past,
        isEarnAtCounterEnabled:
            state.launchDarkly.flags[FeatureFlags.ENABLE_JS_EARN_AT_COUNTER],
        isCMJCLEnabled:
            state.launchDarkly.flags[FeatureFlags.IS_CM_MVP_JCL_ENABLED]
    };
};

const mapDispatchToProps = (dispatch: any): any => ({});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Landing)
);
