import Config from "@Config";
import ActionTypes from "@actionTypes";

import * as GoogleAnalytics from "@utils/GoogleAnalytics";

import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { matchLocationChangeAction } from "@lagunovsky/redux-react-router";

gsap.registerPlugin(ScrollToPlugin);

let currentPage = "";

export const routeChange = store => next => action => {
    next(action);
    // console.log(action)
    // console.log(next)
    // console.log(store)
    if (
        !window.hj_marketing_site &&
        matchLocationChangeAction(action)
    ) {
        const nextPage = `${action.payload.location.pathname}${action.payload.location.search}`;

        if (!currentPage || currentPage !== nextPage) {
            currentPage = nextPage;

            if (window.prerenderReady) {
                // GA page send/tracking here
                GoogleAnalytics.logPageView({
                    pagePath: window.location.pathname,
                    pageTitle: document.title
                });
            }

            // close any open global modals
            store.dispatch({
                type: ActionTypes.CLOSE_ALL_MODALS
            });

            // scroll to top of page for mobile
            gsap.to(".app-wrapper", {
                scrollTo: {
                    y: 0,
                    autoKill: false,
                    duration: 1
                }
            });
                    
            // scroll to top of page for desktop
            gsap.to(window, {
                scrollTo: {
                    y: 0,
                    autoKill: false,
                    duration: 1
                }
            });

            // Persistent cart toggle functionality
            let showPersistentCart = false;
            for (let route of Config.routes.persistentCartRoutes) {
                if (action.payload.location.pathname.indexOf(route) > -1) {
                    showPersistentCart = true;
                }
            }
            if (
                store.getState().session && store.getState().session.showPersistentCart !==
                showPersistentCart
            ) {
                store.dispatch({
                    type: ActionTypes.TOGGLE_PERSISTENT_CART,
                    showPersistentCart: showPersistentCart
                });
            }

            // Nav showing/hiding functionality
            let hideNav = false;
            Config.routes.hideNavRoutes.forEach(route => {
                if (action.payload.location.pathname.indexOf(route) > -1) {
                    hideNav = true;
                }
            });
            if (store.getState().session && store.getState().session.hideNav !== hideNav) {
                store.dispatch({ type: ActionTypes.TOGGLE_NAV, hideNav });
            }

            // Active order showing/hiding functionality
            let hideActiveOrder = false;
            Config.routes.hideActiveOrderRoutes.forEach(route => {
                if (action.payload.location.pathname.indexOf(route) > -1) {
                    hideActiveOrder = true;
                }
            });
            if (store.getState().session && store.getState().session.hideActiveOrder !== hideActiveOrder) {
                store.dispatch({
                    type: ActionTypes.TOGGLE_ACTIVE_ORDER,
                    hideActiveOrder
                });
            }
        }
    }
    return;
};

export default routeChange;
