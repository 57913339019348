// @flow

import * as React from "react";
import { isMobileBreakpoint } from "../responsive";

const { useState, useEffect } = React;

// Hook to use inside function components
const useMobileBreakpoint = (): boolean => {
    const [isMobile, setIsMobile] = useState(false);
    useEffect((): any => {
        const handleWindowResize = () => {
            setIsMobile(isMobileBreakpoint());
        };
        handleWindowResize();
        window.addEventListener("resize", handleWindowResize);
        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, [isMobile]);
    return isMobile;
};

// Provider to use inside class components
export const MobileBreakpointProvider = (props: {
    children: (isMobile: boolean) => React.Node
}): React.Node => {
    const isMobile = useMobileBreakpoint();
    return props.children(isMobile);
};

// Class HOC
export const withMobileBreakpoint = (
    SomeComponent: React.ElementType
): (any => React.Node) => {
    const isMobile = useMobileBreakpoint();
    return (props: any): React.Node => {
        return <SomeComponent isMobile={isMobile} {...props} />;
    };
};

export default useMobileBreakpoint;
