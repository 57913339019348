import * as React from "react";
import { createPortal } from "react-dom";
import { connect } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { withRouter } from "@utils/hooks/withRouter";

import Config from "@Config";
import actions from "@actions";

import MobilePersistentCartFooter from "@components/molecules/MobilePersistentCartFooter";
import AddToCartFooter from "@components/molecules/AddToCartFooter";
import MobileCartFooter from "@components/molecules/MobileCartFooter";
import MobileCheckoutFooter from "@components/molecules/MobileCheckoutFooter";

type PropsType = {
    children: any,
    session: any
};

const footersRoutes = [
    {
        path: "/menu/:storeID?/:category?",
        FooterComponent: MobilePersistentCartFooter
    },
    {
        path: "/account/:subroute?",
        FooterComponent: MobilePersistentCartFooter
    },
    {
        path: `/menu/:storeID/:category/:product`,
        FooterComponent: AddToCartFooter,
        props: {
            className: "fixed-footer__section hide-desktop"
        }
    },
    {
        path: `/menu/:storeID/:category/:product/:promo`,
        FooterComponent: AddToCartFooter,
        props: {
            className: "fixed-footer__section hide-desktop"
        }
    },
    {
        path: "/cart",
        FooterComponent: MobileCartFooter
    },
    {
        path: "/checkout",
        FooterComponent: MobileCheckoutFooter
    }
];

const FixedFooters = ({ children, ...props }: PropsType): React.Node => {
    const portalElement = document.querySelector(Config.appFixedFooterSelector);

    if (portalElement) {
        return createPortal(
            <TransitionGroup component={null}>
                <CSSTransition
                    key={props.router.location.pathname}
                    timeout={Config.pageTransitionDuration}
                    classNames="slide-up-down"
                    unmountOnExit
                >
                    <Routes location={props.router.location}>
                        {footersRoutes.map((footerRoute: any): any => (
                            <Route
                                key={footerRoute.path}
                                path={footerRoute.path}
                                element={
                                    <footerRoute.FooterComponent
                                        {...footerRoute.props}
                                    />
                                }
                            />
                        ))}
                        <Route key={"/"} element={<></>}/>
                    </Routes>
                </CSSTransition>
            </TransitionGroup>
            ,
            portalElement
        );
    } else {
        return null;
    }
};

const mapStateToProps = (state: any): any => ({
    session: state.session
});

export default withRouter(
    connect(
        mapStateToProps,
        {
            ...actions.fixedFooter
        }
    )(FixedFooters)
);
