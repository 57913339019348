// @flow

import * as React from "react";
import { connect } from "react-redux";
import _ from "lodash";

import { appboyLogCustomEvent } from "@utils/AppBoy";
import GlobalModals from "../../constants/GlobalModals";
import actions from "@actions";
import Icon from "@components/atoms/Icon";
import Button from "@components/atoms/Button";
import { Row } from "@containers/Grid";
import * as GoogleAnalytics from "@utils/GoogleAnalytics";

import FeatureFlags from "../../constants/FeatureFlags";

type PropsType = {
    isLanding?: boolean,
    isJacksCafeLoyaltyDisabled?: boolean,
    coffeeLoyalty: {
        credits_required: number,
        credits_earned: number,
        content_settings: any,
        has_voucher_assigned: boolean,
        voucher: any,
        has_free_coffee_reward: boolean,
        barista_coffee_category_id_delivery: number
    },
    isCoffeeFetching?: boolean,
    coffeeLoyaltyGuest: any,
    handleProductDeeplink: any,
    openGlobalModal: typeof actions.globalModals.openGlobalModal,
    openAlertModal: any,
    closeAlertModal: any,
    openSignUpModal: any,
    openVoucherModal: any,
    closeVoucherModal: any,
    openBarcodeOverlay: any,
    closeBarcodeOverlay: any
    // user: any,
    // active: boolean
};

const CoffeeLoyaltyTracker = (props: PropsType): React.Node => {
    const isAuthenticated = _.get(props, "session.isAuthenticated", false);
    const baristaCoffeeCategoryIdDelivery = (): number => {
        if (isAuthenticated) {
            if (
                props.coffeeLoyalty &&
                props.coffeeLoyalty.barista_coffee_category_id_delivery
            ) {
                return props.coffeeLoyalty.barista_coffee_category_id_delivery;
            }
        } else {
            if (
                props.coffeeLoyaltyGuest &&
                props.coffeeLoyaltyGuest.barista_coffee_category_id_delivery
            ) {
                return props.coffeeLoyaltyGuest.barista_coffee_category_id_delivery;
            }
        }
        return 0;
    };
    const totalPointsRequired = 4;
    const totalPointsEarned =
        props.coffeeLoyalty && props.coffeeLoyalty.credits_earned
            ? props.coffeeLoyalty.credits_earned
            : 0;
    const hasFreeCoffee =
        props.coffeeLoyalty && props.coffeeLoyalty.has_free_coffee_reward
            ? props.coffeeLoyalty.has_free_coffee_reward
            : false;

    const coffeeLoyaltyContent =
        props.coffeeLoyalty && props.coffeeLoyalty.content_settings
            ? props.coffeeLoyalty.content_settings
            : props.coffeeLoyaltyGuest;

    const handleClick = () => {
        // close voucher modal
        props.closeVoucherModal();

        if (!isAuthenticated) {
            // trigger signup prompt
            // props.openSignUpModal();
            props.openGlobalModal({
                modalId: GlobalModals.LOGIN_PROMPT,
                fromCoffeeLoyalty: baristaCoffeeCategoryIdDelivery()
            });
        } else if (baristaCoffeeCategoryIdDelivery()) {
            window.dataLayer.push({
                event: "jc_order_coffee",    // hardcoded value
                jc_loyalty_progress: hasFreeCoffee ? "Free" : totalPointsEarned    // dynamic value
            });
            handleCoffeeOrder();
        }

        // log braze/appboy attribute
        appboyLogCustomEvent("CoffeeLoyaltyTileTap");
    };

    const handleTermsClick = () => {
        if (props.isJacksCafeLoyaltyDisabled) {
            JCLDisabledModal();
        } else {
            // launch loyalty modal (pass custom loyalty detail props to voucher modal)
            props.openVoucherModal({
                image: _.get(
                    coffeeLoyaltyContent,
                    "loyalty_detail_tile_image.path"
                ),
                altText: _.get(
                    coffeeLoyaltyContent,
                    "loyalty_detail_tile_image.alt_text"
                ),
                title:
                    coffeeLoyaltyContent.loyalty_detail_tile_title ||
                    "Jack's Cafe Loyalty",
                terms_conditions:
                    coffeeLoyaltyContent.loyalty_detail_tile_body ||
                    "Not valid with any other offer. One voucher per customer, per transaction. Only available at participating Jack's Cafe restaurants.",
                termsOnClick: (): any => {
                    // close voucher modal
                    props.closeVoucherModal();
    
                    // open terms modal
                    props.openAlertModal({
                        title: "Terms & Conditions",
                        body: coffeeLoyaltyContent.loyalty_detail_tile_terms || "",
                        onConfirm: props.closeAlertModal,
                        html: true,
                        size: 375,
                        textAlign: 'left',
                        terms: true
                    });
                },
                coffeeLoyaltyCta: {
                    text: "Order Coffee",
                    onClick: (): any => {
                        if (props.isJacksCafeLoyaltyDisabled) {
                            JCLDisabledModal();
                        } else {
                            handleClick();
                        }
                    }
                },
                showBarcodeCta: {
                    text: "Scan Barcode",
                    onClick: (e): any => {
                        e.stopPropagation();
                        if (isAuthenticated) {
                            props.openBarcodeOverlay();
                            // close voucher modal
                            props.closeVoucherModal();
                        } else {
                            props.openGlobalModal({
                                modalId: GlobalModals.LOGIN_PROMPT,
                                fromCoffeeLoyalty: baristaCoffeeCategoryIdDelivery()
                            });
                            // close voucher modal
                            props.closeVoucherModal();            
                        }
                    }
                }
            });
        }
    };

    const JCLDisabledModal = e => {
        props.openAlertModal({
            title: "Jack's cafe loyalty is currently unavailable",
            body: (
                <React.Fragment>
                    <p>
                        You can continue to order your favourite barista coffee, however you can not accumulate stamps, nor redeem any free coffee at this time.
                    </p>
                </React.Fragment>
            ),
            confirmText: "Order coffee",
            onConfirm: (): any => {
                handleCoffeeOrder();
                props.closeAlertModal();
            },
            cancelText: "Cancel",
            onCancel: props.closeAlertModal,
            size: 375,
            textAlign: 'centre',
        });
    }

    const handleCoffeeOrder = () => {
        props.handleProductDeeplink(
            null,
            null,
            null,
            baristaCoffeeCategoryIdDelivery(),
            true
        );
    }

    const handleOpenBarcode = (e) => {
        e.stopPropagation();
        if (props.isJacksCafeLoyaltyDisabled) {
            JCLDisabledModal();
        } else {
            if (!isAuthenticated) {
                // trigger signup prompt
                // props.openSignUpModal();
                props.openGlobalModal({
                    modalId: GlobalModals.LOGIN_PROMPT,
                    fromCoffeeLoyalty: baristaCoffeeCategoryIdDelivery()
                });
                // close voucher modal
                props.closeVoucherModal();
            } else {
                GoogleAnalytics.logEvent({
                    category: "Rewards",
                    action: "JC-Loyalty",
                    label: "Scan Barcode"
                });
                props.openBarcodeOverlay();
            }
        }
    };

    const termsLink = (): React.Node => (
        <button onClick={handleTermsClick}>
            <span>View T&amp;Cs</span> <Icon name="arrow-right" />
        </button>
    );

    return (
        <section className="coffee-loyalty-tracker">
            <div className={`${props.isLanding ? "container-fluid" : ""}`}>
                <h2 className="heading-2">Jack's Cafe Loyalty</h2>
                <Row className="middle-xs coffee-loyalty-tracker__row">
                    {/* Main banner */}
                    <div className="coffee-loyalty-tracker__banner">
                        <div
                            onClick={(e): any => {
                                e.stopPropagation();
                                if (props.isJacksCafeLoyaltyDisabled) {
                                    handleCoffeeOrder();
                                } else {
                                    handleClick();
                                }
                            }}
                            className="coffee-loyalty-tracker__banner__inner"
                        >
                            {/* mobile image */}
                            <img
                                className="coffee-loyalty-tracker__banner__image invisible-sm"
                                src={
                                    !isAuthenticated
                                        ? _.get(
                                              props,
                                              "coffeeLoyaltyGuest.mobile_home_page_tile_guests_image.path"
                                          )
                                        : hasFreeCoffee
                                        ? _.get(
                                              props,
                                              "coffeeLoyalty.content_settings.mobile_home_page_tile_earned_image.path"
                                          )
                                        : _.get(
                                              props,
                                              "coffeeLoyalty.content_settings.mobile_home_page_tile_earning_image.path"
                                          )
                                }
                                alt={
                                    hasFreeCoffee
                                        ? _.get(
                                              props,
                                              "coffeeLoyalty.content_settings.mobile_home_page_tile_earned_image.alt_text"
                                          )
                                        : _.get(
                                              props,
                                              "coffeeLoyalty.content_settings.mobile_home_page_tile_earning_image.alt_text"
                                          )
                                }
                            />

                            {/* // desktop images */}
                            <img
                                className="coffee-loyalty-tracker__banner__image visible-sm"
                                src={
                                    // "assets/images/coffee-loyalty-desktop-fallback.png"
                                    !isAuthenticated
                                        ? _.get(
                                              props,
                                              "coffeeLoyaltyGuest.desktop_home_page_tile_guests_image.path"
                                          )
                                        : hasFreeCoffee
                                        ? _.get(
                                              props,
                                              "coffeeLoyalty.content_settings.desktop_home_page_tile_earned_image.path"
                                          )
                                        : _.get(
                                              props,
                                              "coffeeLoyalty.content_settings.desktop_home_page_tile_earning_image.path"
                                          )
                                }
                                alt={
                                    hasFreeCoffee
                                        ? _.get(
                                              props,
                                              "coffeeLoyalty.content_settings.desktop_home_page_tile_earned_image.alt_text"
                                          )
                                        : _.get(
                                              props,
                                              "coffeeLoyalty.content_settings.desktop_home_page_tile_earning_image.alt_text"
                                          )
                                }
                            />

                            {/* Progress/Redemption */}
                            <div className="coffee-loyalty-tracker__btn-section">
                                <div className="coffee-loyalty-tracker__progress">
                                    <div className="coffee-loyalty-tracker__progress__markers">
                                        {[...Array(totalPointsRequired)].map(
                                            (value, index): any => {
                                                const pointEarnt =
                                                    hasFreeCoffee || index < totalPointsEarned;

                                                return (
                                                    <div
                                                        key={index}
                                                        className={`coffee-loyalty-tracker__progress__markers__item ${
                                                            pointEarnt
                                                                ? "is-active"
                                                                : ""
                                                        }`}
                                                    >
                                                        {pointEarnt ? (
                                                            <Icon
                                                                className="coffee-loyalty-tracker__progress__markers__item__icon"
                                                                name="bean"
                                                            />
                                                        ) : (
                                                            <span className="coffee-loyalty-tracker__progress__markers__item__text">
                                                                {index + 1}
                                                            </span>
                                                        )}
                                                    </div>
                                                );
                                            }
                                        )}

                                        <div
                                            className={`coffee-loyalty-tracker__progress__markers__item coffee-loyalty-tracker__progress__markers__item--large ${
                                                hasFreeCoffee
                                                    ? "is-active"
                                                    : ""
                                            }`}
                                        >
                                            <span className="coffee-loyalty-tracker__progress__markers__item__text">
                                                Free
                                            </span>
                                        </div>
                                    </div>
                                    {/*)}*/}
                                </div>
                                <div className="coffee-loyalty-tracker__btn-section__functional-section">
                                    <Button
                                        className={`coffee-loyalty-tracker__btn-section__functional-section__functional-button hide-mobile ${props.isCoffeeFetching && 'red-disabled'}`}
                                        styleType="red"
                                        loading={props.isCoffeeFetching}
                                        onClick={(e): any => {
                                            e.stopPropagation();
                                            if (props.isJacksCafeLoyaltyDisabled) {
                                                handleCoffeeOrder();
                                            } else {
                                                handleClick();
                                            }
                                        }}
                                    >
                                        <i className={`icon icon-cup`} />
                                        <span className="coffee-loyalty-tracker__btn-section__functional-section__functional-button__text">
                                            ORDER COFFEE
                                        </span>
                                    </Button>
                                    <Button
                                        className="coffee-loyalty-tracker__btn-section__functional-section__functional-button hide-desktop"
                                        styleType="red"
                                        onClick={handleOpenBarcode}
                                    >
                                        <i className={`icon icon-barcode`} />
                                        <span className="coffee-loyalty-tracker__btn-section__functional-section__functional-button__text">
                                            SCAN CARD
                                        </span>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Row>
                <p>
                    {termsLink()}
                </p>
            </div>
        </section>
    );
};

const mapStateToProps = (state: any): any => {
    return {
        session: state.session,
        coffeeLoyalty: state.vouchers.coffeeLoyalty,
        coffeeLoyaltyGuest: state.vouchers.coffeeLoyaltyGuest,
        vouchers: state.vouchers.vouchers,
        isCoffeeFetching: state.vouchers.isCoffeeFetching,
        isJacksCafeLoyaltyDisabled:
            state.launchDarkly.flags[FeatureFlags.IS_JC_LOYALTY_DISABLED]
    };
};

export default connect(mapStateToProps, {
    openBarcodeOverlay: actions.globalModals.openBarcodeOverlay,
    closeBarcodeOverlay: actions.globalModals.closeBarcodeOverlay,
    openVoucherModal: actions.globalModals.openVoucherModal,
    closeVoucherModal: actions.globalModals.closeVoucherModal,
    handleProductDeeplink: actions.session.handleProductDeeplink,
    openAlertModal: actions.globalModals.openAlertModal,
    closeAlertModal: actions.globalModals.closeAlertModal,
    openJacksCafeLoyaltyDisabledModal: actions.globalModals.openJacksCafeLoyaltyDisabledModal,
    closeJacksCafeLoyaltyDisabledModal: actions.globalModals.closeJacksCafeLoyaltyDisabledModal,
    openGlobalModal: actions.globalModals.openGlobalModal,
    // openSignUpModal: (): any =>
    //     actions.globalModals.openGlobalModal({
    //         modalId: GlobalModals.LOGIN_PROMPT,
    //         toPath: "/"
    //     })
})(CoffeeLoyaltyTracker);
