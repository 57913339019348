import UserApi from "@apis/UserApi";
import actions from "@actions";
import store from "@Store";

class Refresher {
    constructor() {
        this.promise = null;
        this.queue = [];
    }
    refresh(callbacks) {
        this.queue.push(callbacks);
        if (this.promise === null) {
            this.promise = Promise.resolve()
                .then(() => {
                    const user = store.getState().user;
                    let token;
                    let isFB = false;
                    if (user.fb_token) {
                        token = user.fb_token;
                        isFB = true;
                    } else if (user.api_access_token_response) {
                        token =
                            user.api_access_token_response.email_user_token
                                .refresh_token;
                    } else {
                        throw new Error("No valid user token object.");
                    }
                    return UserApi.refreshToken(token, user.key, isFB);
                })
                .then(response => {
                    if (response.status === 200) {
                        return response.json();
                    }
                    throw response;
                })
                .then(resJson => {
                    store.dispatch(actions.user.refreshSuccess(resJson));
                    while (this.queue.length > 0) {
                        this.queue.pop().callback();
                    }
                })
                .catch(err => {
                    while (this.queue.length > 0) {
                        this.queue.pop().failCallback(err);
                    }
                })
                .finally(() => {
                    this.promise = null;
                });
        }
    }
}

const refresher = new Refresher();

export default {
    checkStatus(response) {
        const status = response.status;
        if (status >= 200 && status < 300) {
            return response;
        }
        return response.json().then(body => {
            const error = new Error(body.message);
            error.status = status;
            error.body = body;
            throw error;
        });
    },

    refreshToken(callback, failCallback) {
        refresher.refresh({
            callback,
            failCallback
        });
    }
};
