// @flow

import * as React from "react";
import { Link } from "react-router-dom";
import Button from "@components/atoms/Button";

import FormatSymbols from "@components/atoms/FormatSymbols";
import ProductDefaultImg from "../../../assets/images/product-default.png";

const { useState, useRef } = React;
const doc: any = document.documentElement;

type PropsType = {
    large?: boolean,
    name: string,
    image: string,
    pickup: boolean,
    delivery: boolean,
    link?: ?string,
    hasOverlay?: boolean,
    onClick?: ?any
};

const LimitedTimeProduct = ({
    large,
    name,
    image,
    pickup,
    delivery,
    hasOverlay,
    link,
    onClick
}: PropsType): React.Node => {
    const baseClassname = "limited-time-product";

    // const tag = (): React.Node => {
    //     if (delivery && !pickup) {
    //         return <p className={`limited-time-product__tag`}>Delivery only</p>;
    //     } else if (!delivery && pickup) {
    //         return <p className={`limited-time-product__tag`}>Pick-up only</p>;
    //     }
    //     return null;
    // };

    const [showOverlay, setShowOverlay] = useState(false);
    const ref = useRef(null);

    const hideOverlay = (e: any) => {
        doc.removeEventListener("click", hideOverlay);
        if (ref.current && !ref.current.contains(e.target)) {
            setShowOverlay(false);
        }
    };

    const TagName = link ? Link : "div";

    return (
        <TagName
            ref={ref}
            to={link}
            onClick={(e: any) => {
                if (
                    hasOverlay &&
                    doc.className &&
                    !doc.className.includes("no-touch") &&
                    !showOverlay
                ) {
                    e.preventDefault();
                    setShowOverlay(true);
                    doc.addEventListener("click", hideOverlay);
                    return;
                }
                setShowOverlay(false);
                onClick && onClick(e);
            }}
            className={`${baseClassname} ${
                large ? `${baseClassname}--large` : ""
            }`}
            style={{
                backgroundImage: `url(/static/media/hero-table-bg-xl.ebede827.jpg)`
            }}
        >
            <div className={`${baseClassname}__content`}>
                <img
                    className={`${baseClassname}__image lazy-load`}
                    src={image}
                    alt={"abc"}
                    onError={function(e: any) {
                        e.currentTarget.onerror = null;
                        e.currentTarget.classList.add("has-error");
                        e.currentTarget.src = ProductDefaultImg;
                    }}
                    onLoad={(e: any) => {
                        e.currentTarget.classList.add("is-loaded");
                    }}
                />
                {/*tag()*/}
                <p className={`${baseClassname}__name`}>
                    <FormatSymbols>{name}</FormatSymbols>
                </p>
            </div>
            {hasOverlay && (
                <div
                    className={`${baseClassname}__overlay ${
                        showOverlay ? "force" : ""
                    }`}
                >
                    <Button className={`${baseClassname}__overlay__button`}>
                        Get delivery
                    </Button>
                </div>
            )}
        </TagName>
    );
};

export default LimitedTimeProduct;
