// @flow

const formatAddressComponents = (
    components2dArray: string[][],
    outerJoin: string = ", ",
    innerJoin: string = " "
): string => {
    return (
        components2dArray
            // Filter out empty components & join with innerJoin
            .map((a: string[]): string =>
                a.filter((b: string): boolean => !!b).join(innerJoin)
            )
            // Filter out empty lines
            .filter((c: string): boolean => c.length > 0)
            // Join each component with outerJoin
            .join(outerJoin)
    );
};

export default formatAddressComponents;
