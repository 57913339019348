// @flow

//import { type ProfileType } from "@SharedTypes";

import React, { Component } from "react";
import { connect } from "react-redux";
//import { withRouter } from "@utils/hooks/withRouter";
import { ReactForm, ReactFormField } from "@adrenalin/react-form";

import Button from "@components/atoms/Button";

type PropsType = {
    onSuccess: (input: any) => void,
    onClose: () => void
};

type StateType = {
    loading: boolean,
    error: ?string,
    firstName: string,
    lastName: string
};

class NameModal extends Component<PropsType, StateType> {
    state = {
        loading: false,
        error: null,
        firstName: "",
        lastName: ""
    };

    handleFirstNameChange = (
        event: SyntheticInputEvent<HTMLInputElement>
    ): any => {
        const { value } = event.target;
        this.setState({ firstName: value });
    };

    handleLastNameChange = (
        event: SyntheticInputEvent<HTMLInputElement>
    ): any => {
        const { value } = event.target;
        this.setState({ lastName: value });
    };

    render(): any {
        return (
            <div className="alert">
                <div className="alert__title">
                    <h2 className="heading-3">Tell us your name</h2>
                </div>
                <div className="alert__body">
                    We need your full name in order to complete your account.
                </div>
                <ReactForm>
                    <ReactFormField
                        type="text"
                        name="firstName"
                        label="First Name"
                        onChange={this.handleFirstNameChange}
                    />
                    <ReactFormField
                        type="text"
                        name="lastName"
                        label="Last Name"
                        onChange={this.handleLastNameChange}
                    />
                    <Button
                        disabled={!this.state.firstName || !this.state.lastName}
                        onClick={() => {
                            this.props.onSuccess({
                                firstName: this.state.firstName,
                                lastName: this.state.lastName
                            });
                        }}
                    >
                        SUBMIT
                    </Button>
                </ReactForm>
            </div>
        );
    }
}

const mapStateToProps = (state: any): any => ({});

export default connect(
    mapStateToProps,
    {}
)(NameModal);
