// @flow

import * as React from "react";
import { connect } from "react-redux";
import _ from "lodash";

import AccountPage from "./AccountPage";
import VoucherTile, {
    /*PlaceholderVoucher,*/
    CheckBackSoonVoucher
} from "@components/atoms/VoucherTile";
import actions from "@actions";
import { appboyLogCustomEvent } from "@utils/AppBoy";
import CoffeeLoyaltyTracker from "@components/organisms/CoffeeLoyaltyTracker";
import LegacyCoffeeLoyaltyTracker from "@components/organisms/LegacyCoffeeLoyaltyTracker";

import { type VoucherType } from "@SharedTypes";
import FeatureFlags from "../../constants/FeatureFlags";

const { useEffect, Fragment } = React;

// const testVoucher = {
//     id: 232,
//     pos_item_id: null,
//     promo_id: 8238,
//     is_apply_all_items: false,
//     has_linked_reward: true,
//     terms_conditions: "For Google Pay transaction only",
//     name: "BOGOF Grill Masters Pulled Beef & Angus",
//     image: "233.png",
//     thumb: "233_thumb.png",
//     expiry: "2021-06-10T05:00:00Z",
//     channels: ["App", "Web", "Store", "All"],
//     order_modes: ["Pickup", "Delivery", "All"],
//     payment_methods_constraints: ["googlepay"]
// };

type PropsType = {
    isFetching: boolean,
    vouchers: Array<VoucherType>,
    comps: Array<any>,
    isEarnAtCounterEnabled?: boolean,
    isRewardWalletDisabled?: boolean,
    getVouchers: () => void,
    getUserCoffeeLoyalty: () => void,
    addVoucher: (voucher: VoucherType) => void
};

const MyVouchers = (props: PropsType): React.Node => {
    const coffeeLoyaltyVoucher = _.get(props, "coffeeLoyalty.voucher");

    // Runs once on component mount
    useEffect(() => {
        appboyLogCustomEvent("OpenVoucherList");
        !props.isRewardWalletDisabled && props.getVouchers();
        props.getUserCoffeeLoyalty();
    }, []);

    const getSortedVouchers = (): any => {
        // Only get the vouchers that include 'Web' in the channels list
        const vouchers = props.vouchers.filter(
            (a: VoucherType): boolean =>
                a.channels.includes("Web") || a.channels.includes("Store")
        );
        const comps = props.comps.filter((a: VoucherType): boolean =>
            a.channels.includes("Web")
        );

        const generalVouchers =
            !vouchers || vouchers.length == 0
                ? vouchers
                : vouchers.map((eachVoucher: any): any => ({
                      ...eachVoucher,
                      type: "voucher"
                  }));

        const sortedByIdGeneralVouchers =
            !generalVouchers || generalVouchers.length == 0
                ? generalVouchers
                : generalVouchers.sort((a: any, b: any): any => {
                      return b.id - a.id;
                  });

        const compcodeVouchers =
            !comps || comps.length == 0
                ? comps
                : comps.map((eachComp: any): any => ({
                      ...eachComp,
                      type: "comp"
                  }));

        const allVouchers = [...sortedByIdGeneralVouchers, ...compcodeVouchers];
        const sortedVouchers = allVouchers.sort((a: any, b: any): any => {
            const futureDefaultDate = new Date();
            futureDefaultDate.setFullYear(futureDefaultDate.getFullYear() + 10);

            // Order by ascending 'expiry' => 'expiration_date' => futureDefaultDate
            const a_date =
                a && a.expiry
                    ? new Date(a.expiry)
                    : a.expiration_date
                    ? new Date(a.expiration_date)
                    : futureDefaultDate;
            const b_date =
                b && b.expiry
                    ? new Date(b.expiry)
                    : b.expiration_date
                    ? new Date(b.expiration_date)
                    : futureDefaultDate;
            const result = a_date - b_date;
            return result;
        });

        return sortedVouchers;
    };

    const renderVoucher = (voucher: VoucherType): React.Node => (
        <VoucherTile key={voucher.id} voucher={voucher} />
    );

    const renderComp = (comp: any): React.Node => (
        <VoucherTile key={comp.name} voucher={comp} isComp />
    );

    const renderVouchers = (): React.Node => {
        const sortedVouchers = getSortedVouchers();

        if (!sortedVouchers || sortedVouchers.length === 0) {
            return <CheckBackSoonVoucher />;
        }

        const renderComponents = [];
        for (const eachVoucher of sortedVouchers) {
            if (eachVoucher.type === "voucher") {
                renderComponents.push(renderVoucher(eachVoucher));
            } else if (eachVoucher.type === "comp") {
                renderComponents.push(renderComp(eachVoucher));
            }
        }

        // if (vouchers.length < 5) {
        //     // Render the placeholder vouchers as well as real vouchers
        //     return [...Array(5)].map((_: any, index: number): any => {
        //         if (vouchers[index]) {
        //             return renderVoucher(props.vouchers[index]);
        //         }
        //         return <PlaceholderVoucher key={index} />;
        //     });
        // }
        // Just render vouchers as normal
        return renderComponents;
    };

    return (
        <AccountPage
            title="My Rewards"
            intro={
                <>
                    This is your rewards wallet. Check back regularly to find
                    new deals waiting for you.
                </>
            }
            postIntro={
                props.isEarnAtCounterEnabled ? (
                    <CoffeeLoyaltyTracker />
                ) : (
                    <LegacyCoffeeLoyaltyTracker />
                )
            }
        >
            {props.isFetching ? (
                <p>Loading...</p>
            ) : (
                <Fragment>
                    <div className="vouchers-grid">
                        {/* Coffee loyalty voucher */}
                        {coffeeLoyaltyVoucher
                            ? renderVoucher(coffeeLoyaltyVoucher)
                            : null}

                        {/* normal vouchers */}
                        {props.isRewardWalletDisabled ? <CheckBackSoonVoucher /> : renderVouchers()}
                    </div>
                </Fragment>
            )}
        </AccountPage>
    );
};

const mapStateToProps = (state: any): any => ({
    isFetching: state.vouchers.isFetching,
    vouchers: state.vouchers.vouchers,
    coffeeLoyalty: state.vouchers.coffeeLoyalty,
    comps: state.vouchers.comps,
    isEarnAtCounterEnabled:
        state.launchDarkly.flags[FeatureFlags.ENABLE_JS_EARN_AT_COUNTER],
    isRewardWalletDisabled:
        state.launchDarkly.flags[FeatureFlags.IS_REWARD_WALLET_DISABLED]
});

export default connect(
    mapStateToProps,
    {
        getUserCoffeeLoyalty: actions.vouchers.getUserCoffeeLoyalty,
        getVouchers: actions.vouchers.getVouchers,
        addVoucher: actions.vouchers.voucherDeeplink
    }
)(MyVouchers);
