// @flow

import * as React from "react";

import { formatMoney } from "@utils";

type PropsType = {
    price: number
};

const defaultProps = {
    price: 0
};

const DeliveryFee = (props: PropsType): any => {
    const baseClassname = "delivery-fee";

    const { price } = props;

    // Generate the classlist for this component
    const classes = [baseClassname, `${baseClassname}--regular`];

    return (
        <div className={classes.join(" ")}>
            <div className={`${baseClassname}__name`}>Delivery Fee</div>
            <div className={`${baseClassname}__price`}>
                {formatMoney(price)}
            </div>
        </div>
    );
};

DeliveryFee.defaultProps = defaultProps;

export default DeliveryFee;
