// @flow

import * as React from "react";

type PropsType = {
    children: React.Node
};

const LandingPageContent = (props: PropsType): React.Node => {
    return (
        <div className="landing-page-content">
            <div className="landing-page-content__inner">{props.children}</div>
        </div>
    );
};

export default LandingPageContent;
