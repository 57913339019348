// @flow

// import Config from "@Config";
import ActionTypes from "@actionTypes";
import { appboySetCustomAttribute } from "@utils/AppBoy";

const getPosition = (options: any): any => {
    return new Promise((res: any, rej: any) => {
        navigator.geolocation.getCurrentPosition(res, rej, options);
    });
};

export const getGeoStatus = (): any => (dispatch: any): any => {
    return getPosition({ enableHighAccuracy: true })
        .then((position: {}): any => {
            // set appboy/braze custom attribute
            appboySetCustomAttribute("LocationServicesActive", true);

            return dispatch({
                type: ActionTypes.SET_GEOLOCATION_STATUS,
                access: true,
                position: position
            });
        })
        .catch(() => {
            // set appboy/braze custom attribute
            appboySetCustomAttribute("LocationServicesActive", false);
        });
};

// check if device supports apple pay (window.ApplePaySession)
// and if user can make payment with active apple pay card (window.ApplePaySession.canMakePaymentsWithActiveCard)
// Reference - https://developer.apple.com/documentation/apple_pay_on_the_web/apple_pay_js_api/checking_for_apple_pay_availability
export const checkApplePaySupport = (): any => (dispatch: any): any => {
    return new Promise((resolve: any, reject: any): any => {
        if (window.ApplePaySession) {
            dispatch({
                type: ActionTypes.SET_APPLEPAY_SUPPORT,
                supported: true
            });
            resolve();

            // window.ApplePaySession.canMakePaymentsWithActiveCard(
            //     Config.applePay.merchantIdentifier
            // ).then((canMakePayments: any): any => {
            //     if (canMakePayments) {
            //         dispatch({
            //             type: ActionTypes.SET_APPLEPAY_SUPPORT,
            //             supported: true
            //         });
            //     } else {
            //         dispatch({
            //             type: ActionTypes.SET_APPLEPAY_SUPPORT,
            //             supported: false
            //         });
            //     }
            //     resolve();
            // });
        } else {
            dispatch({
                type: ActionTypes.SET_APPLEPAY_SUPPORT,
                supported: false
            });
            resolve();
        }
    });
};
