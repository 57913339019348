// @flow

import * as React from "react";
import { Link } from "react-router-dom";

const urlRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

const LinkHandler = (props: any): any => {
    const hostname = document.location.hostname;
    const port = document.location.port;
    let finalUrl = props.to;

    // debugging
    // const hostname = "order-uat.hungryjacks.com.au";
    // const port = "";
    // let finalUrl = "https://order-uat.hungryjacks.com.au/menu/992/56/1004";

    // check/process internal urls that include the full hostname
    if (finalUrl && finalUrl.indexOf(hostname) > -1) {
        finalUrl = finalUrl.split(hostname);
        if (port) {
            finalUrl = finalUrl[1].split(port);
        }
        finalUrl = finalUrl[1] || finalUrl[0];
    }

    // Decide which element type to use based off relative/absolute url
    const LinkElement =
        !props.disabled && finalUrl
            ? finalUrl.match(urlRegex)
                ? "a"
                : Link
            : "button";

    return (
        <LinkElement
            {...props}
            {...(finalUrl
                ? finalUrl.match(urlRegex)
                    ? { href: finalUrl }
                    : { to: finalUrl }
                : null)}
        >
            {props.children}
        </LinkElement>
    );
};

export default LinkHandler;
