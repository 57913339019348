// @flow

//import { type ProfileType } from "@SharedTypes";

import React, { Component } from "react";
import { connect } from "react-redux";
//import { withRouter } from "@utils/hooks/withRouter";
import { ReactForm, ReactFormField } from "@adrenalin/react-form";

import Button from "@components/atoms/Button";

type PropsType = {
    onSuccess: (input: any) => void,
    onClose: () => void
};

type StateType = {
    loading: boolean,
    error: ?string
};

class EmailModal extends Component<PropsType, StateType> {
    state = {
        loading: false,
        error: null
    };
    formRef = React.createRef<any>();

    render(): any {
        return (
            <div className="alert">
                <div className="alert__title">
                    <h2 className="heading-3">Tell us your email</h2>
                </div>
                <div className="alert__body">
                    It looks like you don’t have an email account linked to your
                    Facebook. We need your email address to send you your order
                    receipts and other important account information.
                </div>
                <ReactForm
                    ref={this.formRef}
                    onSuccess={formData => {
                        this.props.onSuccess(formData.email);
                    }}
                >
                    <ReactFormField
                        type="email"
                        name="email"
                        label="Email"
                        required={true}
                        validators={["email"]}
                        errorMessages={{
                            required: "Please enter your email address."
                        }}
                    />
                </ReactForm>
                <div className="alert__buttons">
                    <Button
                        onClick={() => {
                            if (this.formRef.current) {
                                this.formRef.current.submit();
                            }
                        }}
                    >
                        SUBMIT
                    </Button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any): any => ({});

export default connect(
    mapStateToProps,
    {}
)(EmailModal);
