// @flow

import * as React from "react";

type CounterColorType = "green" | "red" | "clear" | "grey";

type PropsType = {
    className?: string,
    count: number,
    color?: ?CounterColorType | Array<CounterColorType>,
    floating?: boolean
};

const CounterDot = (props: PropsType): any => {
    // Base class list
    let classes = ["counter-dot"];

    // Type classes
    if (props.color) {
        const typeString = (t: string): string => `counter-dot--${t}`;
        classes.push(
            typeof props.color === "string"
                ? typeString(props.color)
                : props.color.map(typeString).join(" ")
        );
    }

    // Floating class
    if (props.floating) {
        classes.push("counter-dot--floating");
    }

    // Additional classes
    if (props.className) {
        classes.push(props.className);
    }

    if (props.count) {
        return (
            <i aria-label="hidden" className={classes.join(" ")}>
                {props.count < 100 ? (
                    <div className="counter-dot__text">{props.count}</div>
                ) : (
                    <span className="counter-dot__text counter-dot__text--small">
                        99<span className="counter-dot__plus">+</span>
                    </span>
                )}
            </i>
        );
    } else {
        return null;
    }
};

export default CounterDot;
