// @flow

import _ from "lodash";
import moment from "moment";
import DateService from "@utils/DateService";
import StoreUtils from "@utils/storeUtils";
import * as React from "react";
import { push, replace } from "@lagunovsky/redux-react-router";

import Strings from "@Strings";
import Config from "@Config";
import ActionTypes from "@actionTypes";
import actions from "@actions";
import ApiFactory from "@apis/APIFactory";
import {
    intialProductState,
    setComboLevel,
    calculateProductTotals
} from "@utils/ProductUtils";
import {
    checkCategories,
    findEndingSoonCategory
} from "@utils/timeRestrictions";
import GlobalModals from "../constants/GlobalModals";
import { appboySetCustomAttribute } from "@utils/AppBoy";
import * as GoogleAnalytics from "@utils/GoogleAnalytics";
import AddressAPI from "@apis/AddressAPI";
import { logAddToCart, logCustomEventWithDelivery } from "@utils/AppBoy";
// import { ReactForm, ReactFormField } from "@adrenalin/react-form";
// import { matchPath } from "react-router";

import { type VoucherType } from "@SharedTypes";

//
// Recalculate cart totals (price, kj etc)
//
export const recalculateCartTotals = (): any => (
    dispatch: any,
    getState: any
): any => {
    dispatch({
        type: ActionTypes.RECALCULATE_CART_TOTALS
    });

    // check coffee loyalty eligibility of updated cart items
    dispatch(actions.vouchers.checkCoffeeLoyalty());
};

//
// Add item to cart
//
export const addItemToCart = (
    item: any,
    voucher?: VoucherType,
    fromMenuPage?: boolean = true
): any => (dispatch: any, getState: any): any => {
    return new Promise((resolve: any, reject: any) => {
        const { navigator, menu, cart, config } = getState();
        logAddToCart(item);
        if (item.uuid && !_.find(cart.line_items, { uuid: item.uuid })) {
            dispatch(
                actions.globalModals.openAlertModal({
                    title: "Sorry, we couldn’t update that item",
                    body: (
                        <p>
                            It looks like that item was removed from your cart.
                        </p>
                    ),
                    confirmText: "OK",
                    onConfirm: () => {
                        dispatch(actions.globalModals.closeAlertModal());
                        dispatch(replace(item.menuLink));
                    }
                })
            );
            return;
        }

        // Add item variant name
        item.item_variant = item.menuItems ? item.menuItems[(item.isUpsell ? 0 : item.combo_level || 0)].option_name : "";

        // add cart item edit link
        item.menuLink = fromMenuPage
            ? navigator.location.pathname
            : `${Config.routes.menu}/${menu.store_id}/category/${
                  item.promo_id ? `promo/${item.promo_id}` : item.pos_item_id
              }`;

        // availability check
        // let parentCategory;
        // menu.categories.forEach((category: any) => {
        //     parentCategory = _.find(category.menu_items, {
        //         base_menu_item_id: Number(item.menuItems[0].base_menu_item_id)
        //     })
        //         ? category
        //         : parentCategory;
        // });

        const parentCategories = _.filter(
            menu.categories,
            (category: any): boolean => {
                return !!_.find(category.menu_items, {
                    base_menu_item_id: Number(
                        item.menuItems[0].base_menu_item_id
                    )
                });
            }
        );

        if (parentCategories && !checkCategories(parentCategories)) {
            // product is unavailable (no parent category or outside of time restrictions)
            dispatch(
                actions.globalModals.openAlertModal({
                    title: "Product could not be added to cart",
                    body: (
                        <p>
                            This product is currently unavailable at this time.
                        </p>
                    ),
                    confirmText: "OK",
                    onConfirm: () => {
                        dispatch(actions.globalModals.closeAlertModal());
                    }
                })
            );
        } else if (
            config.menu.discounted_menu_items.includes(item.menu_item_id) &&
            cart.hasDiscount
        ) {
            dispatch(
                actions.globalModals.openAlertModal({
                    title: "Only one discount per cart!",
                    body: (
                        <p>
                            To use this discount instead, please remove the{" "}
                            {cart.hasDiscount} currently in your cart.
                        </p>
                    ),
                    confirmText: "VIEW CART",
                    onConfirm: () => {
                        dispatch(actions.globalModals.closeAlertModal());
                        dispatch(replace(Config.routes.cart));
                    }
                })
            );
        } else {
            // product is available, add to cart
            dispatch({
                type: ActionTypes.ADD_ITEM_TO_CART,
                line_item: _.cloneDeep(item),
                voucher: voucher
            });

            dispatch({
                type: ActionTypes.CHECK_DISCOUNTED_ITEM,
                discountedMenuItems: config.menu.discounted_menu_items
            });

            // recalculate totals
            dispatch(recalculateCartTotals());

            // get updated cart upsells
            dispatch(getUpsell());

            // GA Tracking
            if (item.isUpsell) {
                GoogleAnalytics.logEvent({
                    category: "Cart",
                    action: "Add",
                    label: `Upsell_${item.promo_id || item.pos_item_id}`
                });
            } else {
                GoogleAnalytics.logEvent({
                    category: "Cart",
                    action: "Add",
                    label: `Product_${item.promo_id || item.pos_item_id}`
                });
            }
            const parentCategory = fromMenuPage
                ? _.find(getState().menu.categories, {
                      category_id: Number(item.menuLink.split("/")[3])
                  })
                : item.parentCategory;

            let customised = false;
            item.sales_items.forEach((line): any => {
                if (!_.isEmpty(line.modifiers))
                    customised = true
            })

            window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object
            window.dataLayer.push({
                event: "add_to_cart",
                ecommerce: {
                    currency: "AUD", 
                    value: item.price,
                    items: [{
                        item_id: (item.promo_id || item.menu_item_id),
                        item_name: item.menu_item_name,
                        coupon: voucher ? "Reward Voucher" : "",
                        discount: 0,
                        index: 0,
                        item_category:  _.get(parentCategory, "name"),
                        item_category2: item.isUpsell ? "Upsell" : customised ? "Customised" : "",
                        item_variant: item.item_variant,
                        line_items: [item.sales_items.map((line): any => {
                            return `${line.sales_item_name}${!_.isEmpty(line.modifiers) ?
                                ` (${line.modifiers.map((mod, i): any => {
                                    return `+1 ${mod.modifier_name}${line.modifiers.length - 1 > i ? `, ` : ``}`
                                })})` : ''
                            }`
                        })].join(`, `),
                        price: item.defaultComboPrice,
                        quantity: item.quantity
                    }]
                }
            });

            // if first item added to cart, set custom appboy/braze attribute
            if (!_.get(cart, "line_items.length")) {
                appboySetCustomAttribute("AddToCart", new Date());
            }

            // appboy/braze log add to cart item purchase
            /**
             * Reports a single item being added to the cart.
             * Prefixes the item id with ADD-
             * Price is always set to $0.00
             */
            // appboyLogPurchase(
            //     `ADD-${item.promo_id || item.pos_item_id}`,
            //     "0",
            //     "AUD",
            //     item.quantity,
            //     {}
            // );

            // if voucher, handle redirect after adding to cart
            if (voucher) {
                if (voucher.editProduct) {
                    // redirect to edit voucher item
                    const cartItem = _.find(getState().cart.line_items, {
                        promo_id: item.promo_id
                    });

                    if (cartItem) {
                        dispatch(
                            push(
                                `${getState().product.menuLink}?${
                                    Config.routes.editCartNumberQueryParam
                                }=${cartItem.uuid}`
                            )
                        );
                    }
                } else {
                    // redirect to cart
                    dispatch(push(Config.routes.cart));
                }
            }

            resolve();
        }
    });
};

//
// Apply cart wide voucher
//
export const applyVoucherToCart = (voucher: any): any => ({
    type: ActionTypes.APPLY_CART_VOUCHER,
    voucher
});

//
// Remove cart wide voucher
//
export const removeCartVoucher = (): any => ({
    type: ActionTypes.REMOVE_CART_VOUCHER
});

export const showCompModal = (): any => (dispatch: any, getState: any): any => {
    dispatch(
        actions.globalModals.openGlobalModal({
            modalId: GlobalModals.COMP
        })
    );
};

//
// Remove item by its line_item_number in the cart
//
export const removeItemFromCart = (itemId: string): any => (
    dispatch: any,
    getState: any
): any => {
    const { cart, config } = getState();
    const cartItem = _.find(cart.line_items, { uuid: itemId });
    if (!cartItem) {
        return;
    }
    dispatch({
        type: ActionTypes.REMOVE_ITEM_FROM_CART,
        itemId,
        voucher_id: cartItem.voucher_id
    });

    dispatch({
        type: ActionTypes.CHECK_DISCOUNTED_ITEM,
        discountedMenuItems: config.menu.discounted_menu_items
    });

    // redirect to base product detail link if currently on edit mode
    if (
        window.location.search ===
        `?${Config.routes.editCartNumberQueryParam}=${cartItem.uuid}`
    ) {
        dispatch(replace(cartItem.menuLink));
    }

    // track remove item/upsell
    if (cartItem.isUpsell) {
        GoogleAnalytics.logEvent({
            category: "Cart",
            action: "Remove",
            label: `Upsell_${cartItem.promo_id || cartItem.pos_item_id}`
        });
    } else {
        GoogleAnalytics.logEvent({
            category: "Cart",
            action: "Remove",
            label: `Product_${cartItem.promo_id || cartItem.pos_item_id}`
        });
    }
    const parentCategory =
        cartItem.menuLink &&
        _.find(getState().menu.categories, {
            category_id: Number(cartItem.menuLink.split("/")[3])
        });

    let customised = false;
    cartItem.sales_items.forEach((line): any => {
        if (!_.isEmpty(line.modifiers))
            customised = true
    })

    window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object
    window.dataLayer.push({
        event: "remove_from_cart",
        ecommerce: {
            currency: "AUD", 
            value: cartItem.price,
            items: [{
                item_id: (cartItem.promo_id || cartItem.pos_id),
                item_name: cartItem.menu_item_name,
                coupon: "",
                discount: 0,
                index: 0,
                item_category: _.get(parentCategory, "name"),
                item_category2: cartItem.is_reorder ? "Reorder" : cartItem.isUpsell ? "Upsell" : customised ? "Customised" : "",
                item_variant: cartItem.item_variant,
                line_items: [cartItem.sales_items.map((line): any => {
                    return `${line.sales_item_name}${!_.isEmpty(line.modifiers) ?
                        ` (${line.modifiers.map((mod, i): any => {
                            return `+1 ${mod.modifier_name}${line.modifiers.length - 1 > i ? `, ` : ``}`
                        })})` : ''
                    }`
                })].join(`, `),
                price: cartItem.defaultComboPrice,
                quantity: cartItem.quantity
            }]
        }
    });

    // recheck cart items against new menu
    // dispatch({
    //     type: ActionTypes.CHECK_CART_AVAILABILITY,
    //     menu: getState().menu
    // });

    dispatch(recalculateCartTotals());

    return dispatch(getUpsell());
};

//
// Get upsell items for the cart
//
export const getUpsell = (callback: any): any => (
    dispatch: any,
    getState: any
): any => {
    const state = getState();
    const itemIds = [];
    // push each cart line item sales_item_id to array
    state.cart.line_items.forEach((lineItem: any): any => {
        lineItem.sales_items.forEach((salesItem: any): any => {
            if (salesItem.is_visible) {
                itemIds.push(salesItem.sales_item_id);
            }
        });
    });

    if (itemIds.length === 0) {
        // no items in cart, clear upsells
        dispatch({
            type: ActionTypes.CLEAR_UPSELL
        });
        return Promise.resolve();
    }

    // fetch upsells with the array of cart item sales item ids
    return ApiFactory.StoresAPI.getUpsell(
        state.menu.store_id,
        _.uniq(itemIds),
        state.menu.menu_id
    )
        .then((res: any) => {
            dispatch({
                type: ActionTypes.GET_UPSELL_SUCCESS,
                upsells: res.upsell_suggestions,
                menu: state.menu
            });

            if (callback) callback();
        })
        .catch((err: any) => {
            dispatch({
                type: ActionTypes.CLEAR_UPSELL
            });
        });
};

//
// Add menu item to cart - (upsell items)
//
export const addMenuItemToCart = (
    menu_item_id: number,
    sales_item_id: number,
    isUpsell: boolean
): any => (dispatch: any, getState: any): any => {
    const { menu } = getState();

    if (menu) {
        try {
            // find matching base base_menu_item_id
            let baseMenuItem = _.find(
                menu.menu_items,
                (item: any): any =>
                    item.menu_item_id === menu_item_id &&
                    item.sales_item_ids.includes(sales_item_id)
            );

            // find all menu items with the base_menu_item_id
            let menuItems = _.filter(menu.menu_items, {
                base_menu_item_id: baseMenuItem.base_menu_item_id
            });

            // if found menu items
            // create product object with intial state and the found menu items
            // combo level in setComboLevel default is 1 (mediunm)
            let product = {
                ...intialProductState,
                ...setComboLevel(menuItems, menu.sales_items, 1)
            };
            // extend product object out with calculted totals
            product = {
                ...product,
                ...calculateProductTotals(product),
                isUpsell: isUpsell || false
            };

            // add the product to cart
            dispatch(addItemToCart(product, {}, false));
        } catch {
            dispatch(
                actions.globalModals.openAlertModal({
                    title: "This item could not be added to the cart.",
                    body: (
                        <React.Fragment>
                            <p>
                                The product could not currently be found within
                                the menu.
                            </p>
                        </React.Fragment>
                    ),
                    confirmText: "OK",
                    onConfirm: () => {
                        dispatch(actions.globalModals.closeAlertModal());
                    }
                })
            );
        }
    }
};

//
// Add pos/promo item to cart - (deeplink)
//
export const addPosPromoItemToCart = (
    posID: number,
    promoID: number,
    uneditable: boolean = false,
    comboLevel: number = 1
): any => (dispatch: any, getState: any): any => {
    const { menu } = getState();

    if (menu) {
        try {
            // find matching base base_menu_item_id
            let baseMenuItem = posID
                ? _.find(menu.menu_items, {
                      pos_item_id: posID
                  })
                : _.find(menu.menu_items, {
                      promo_id: promoID
                  });

            // find all menu items with the base_menu_item_id
            let menuItems = _.filter(menu.menu_items, {
                base_menu_item_id: baseMenuItem.base_menu_item_id
            });

            // if found menu items
            // create product object with intial state and the found menu items
            // combo level in setComboLevel default is 1 (mediunm)
            let product = {
                ...intialProductState,
                ...setComboLevel(menuItems, menu.sales_items, comboLevel)
            };
            // extend product object out with calculted totals
            product = {
                ...product,
                ...calculateProductTotals(product),
                quantityUneditable: uneditable
            };

            // add the product to cart
            dispatch(addItemToCart(product, {}, false));
        } catch {
            dispatch(
                actions.globalModals.openAlertModal({
                    title: "This item could not be added to the cart.",
                    body: (
                        <React.Fragment>
                            <p>
                                The product could not currently be found within
                                the menu.
                            </p>
                        </React.Fragment>
                    ),
                    confirmText: "OK",
                    onConfirm: () => {
                        dispatch(actions.globalModals.closeAlertModal());
                    }
                })
            );
        }
    }
};

//
// Upsells pre checkout check
//
export const upsellsPrecheckoutCheck = (): any => async (
    dispatch: any,
    getState: any
): any => {
    const { upsells } = getState();

    if (upsells && upsells.length) {
        // show upsells modal
        dispatch(
            actions.globalModals.openGlobalModal({
                modalId: GlobalModals.UPSELLS,
                onSuccess: () => {
                    // continue normal precheckout
                    dispatch(preCheckout());
                }
            })
        );
    } else {
        // contine normal precheckout
        dispatch(preCheckout());
    }
};

//
// Upsells pre cart check
//
export const upsellsCartCheck = (): any => async (
    dispatch: any,
    getState: any
): any => {
    const { upsells } = getState();

    if (upsells && upsells.length) {
        // show upsells modal
        dispatch(
            actions.globalModals.openGlobalModal({
                modalId: GlobalModals.UPSELLS,
                onSuccess: () => {
                    // dispatch(preCheckout());
                    // Continue to cart
                    dispatch(replace(Config.routes.cart));
                }
            })
        );
    } else {
        // dispatch(preCheckout());
        // Continue to cart
        dispatch(replace(Config.routes.cart));
    }
};

// check closure times
export const handleStoreClosingChecks = (): any => async (
    dispatch: any,
    getState: any
): any => {
    return new Promise((resolve: any, reject: any) => {
        const { menu, config } = getState();
        const store = _.get(getState(), "stores.list").find(
            (s: any): boolean => s.store_id === menu.store_id
        );

        const dayOfWeek = StoreUtils.getStoreCurrentDayOfWeek();
        const storeDay = store.hours.delivery[dayOfWeek];
        const openingHours = StoreUtils.BuildStoreOpeningHoursRange(storeDay);
        if (!openingHours) {
            return;
        }

        const storeTodayClosingTime = openingHours.storeCloseTime;

        const minutesFromConfig = parseInt(
            String(config.order_delivery.default_expected_time_frame).split(
                ":"
            )[1]
        );

        const storeWarningTimeStart = StoreUtils.convertStoreTimezone(
            storeTodayClosingTime
        ).subtract(
            config.order_interval.delivery_stores_warning_gap_in_mins,
            "m"
        );

        const storeWarningTimeEnd = StoreUtils.convertStoreTimezone(
            storeTodayClosingTime
        ).subtract(
            config.order_interval.delivery_stores_closing_gap_in_mins +
                minutesFromConfig,
            "m"
        );

        const storeBlockedTime = StoreUtils.convertStoreTimezone(
            storeTodayClosingTime
        ).subtract(
            config.order_interval.delivery_stores_closing_gap_in_mins +
                minutesFromConfig,
            "m"
        );

        // DT: use store now time
        const storeCurrentTime = StoreUtils.selectedStoreNow();

        // debugging for current time
        // currentTime.hour(22);
        // currentTime.minute(28);
        // console.log("current", currentTime.format());
        // console.log("minutesFromConfig", minutesFromConfig);
        // console.log(
        //     "warning",
        //     storeWarningTimeStart.format(),
        //     storeWarningTimeEnd.format()
        // );
        // console.log("blocked", storeBlockedTime.format());
        // console.log("closed", moment(storeTodayClosingTime).format());

        if (storeCurrentTime.isAfter(storeTodayClosingTime)) {
            // store is closed, block order
            dispatch(
                actions.globalModals.openAlertModal({
                    title: `Restaurant closed`,
                    body: (
                        <React.Fragment>
                            <p>
                                Sorry, the kitchen has closed and cannot accept
                                new orders.{" "}
                            </p>{" "}
                        </React.Fragment>
                    ),
                    confirmText: "OK",
                    onConfirm: () => {
                        reject();
                        dispatch(actions.globalModals.closeAlertModal());
                    }
                })
            );
        } else if (storeCurrentTime.isAfter(storeBlockedTime)) {
            // store about to close, block order
            dispatch(
                actions.globalModals.openAlertModal({
                    title: `Closing soon`,
                    body: (
                        <React.Fragment>
                            <p>
                                Sorry, the restaurant will close soon and cannot
                                accept new orders.{" "}
                            </p>{" "}
                        </React.Fragment>
                    ),
                    confirmText: "OK",
                    onConfirm: () => {
                        reject();
                        dispatch(actions.globalModals.closeAlertModal());
                    }
                })
            );
        } else if (
            storeCurrentTime.isBetween(
                storeWarningTimeStart,
                storeWarningTimeEnd
            )
        ) {
            // store closing soon, warn order
            dispatch(
                actions.globalModals.openAlertModal({
                    title: `Store closing soon`,
                    body: (
                        <React.Fragment>
                            <p>
                                The restaurant is closing soon. Please complete
                                the order before{" "}
                                {storeWarningTimeEnd.format("hh:mm A")}. Would
                                you like to continue?
                            </p>{" "}
                        </React.Fragment>
                    ),
                    confirmText: "OK",
                    onConfirm: () => {
                        resolve();
                        dispatch(actions.globalModals.closeAlertModal());
                    },
                    cancelText: "Cancel",
                    onCancel: () => {
                        reject();
                        dispatch(actions.globalModals.closeAlertModal());
                    }
                })
            );
        } else {
            resolve();
        }
    });
};

//
// Pre-checkout login prompt and cart verification
//
export const preCheckout = (): any => async (
    dispatch: any,
    getState: any
): any => {
    const { cart, session, menu } = getState();
    let endTime: any = null;
    let endingSoonCategory: any;

    // check store closing times/logic
    await dispatch(handleStoreClosingChecks())
        .then(async (): any => {
            // limited time menu category logic
            try {
                for (const item: any of cart.line_items) {
                    const baseMenuItem = _.find(menu.menu_items, {
                        menu_item_id: item.menu_item_id
                    });
                    const parentCategories = _.filter(
                        menu.categories,
                        (category: any): boolean => {
                            return !!_.find(category.menu_items, {
                                base_menu_item_id: Number(
                                    baseMenuItem.base_menu_item_id
                                )
                            });
                        }
                    );

                    [endingSoonCategory, endTime] = findEndingSoonCategory(
                        parentCategories
                    );
                    if (endingSoonCategory) {
                        break;
                    }
                }
                if (endingSoonCategory) {
                    await new Promise((resolve: Function, reject: Function) => {
                        dispatch(
                            actions.globalModals.openAlertModal({
                                title: `${
                                    endingSoonCategory.name
                                } CLOSES AT ${endTime.format("hh:mm A")}`,
                                body: (
                                    <React.Fragment>
                                        <p>{`${
                                            endingSoonCategory.name
                                        } closes at ${endTime.format(
                                            "hh:mm A"
                                        )}. Please place your order before ${endTime.format(
                                            "hh:mm A"
                                        )}.`}</p>
                                    </React.Fragment>
                                ),
                                confirmText: "YES, CONTINUE",
                                onConfirm: () => {
                                    resolve();
                                    dispatch(
                                        actions.globalModals.closeAlertModal()
                                    );
                                },
                                cancelText: "Cancel",
                                onCancel: () => {
                                    reject();
                                    dispatch(
                                        actions.globalModals.closeAlertModal()
                                    );
                                }
                            })
                        );
                    });
                }
            } catch (e) {
                console.error(e);
                return;
            }

            if (session.isAuthenticated || session.isGuestUser) {
                if (cart.line_items.length && !cart.cartItemsUnavailable) {
                    // user is logged in and has cart items, redirect to checkout
                    if (cart.cartPriceDifferences) {
                        // replace history state if reviewing price changes
                        dispatch(replace(Config.routes.checkout));
                    } else {
                        // normal redirect
                        dispatch(push(Config.routes.checkout));
                    }
                } else if (cart.cartItemsUnavailable) {
                    // no cart items, show error
                    dispatch(
                        actions.globalModals.openAlertModal({
                            title: "Item unavailable",
                            body: (
                                <React.Fragment>
                                    <p>
                                        Oops! There are items in your cart that
                                        are currently unavailable. Please remove
                                        them to continue.
                                    </p>
                                </React.Fragment>
                            ),
                            confirmText: "OK",
                            onConfirm: () => {
                                dispatch(
                                    actions.globalModals.closeAlertModal()
                                );
                            }
                        })
                    );
                } else if (!cart.line_items.length) {
                    // no cart items, show error
                    dispatch(
                        actions.globalModals.openAlertModal({
                            title: "Your cart is empty! ",
                            body: (
                                <React.Fragment>
                                    <p>Please add some items to check out.</p>
                                </React.Fragment>
                            ),
                            confirmText: "OK",
                            onConfirm: () => {
                                dispatch(
                                    actions.globalModals.closeAlertModal()
                                );
                            }
                        })
                    );
                } else {
                    // no cart items, show error
                    dispatch(
                        actions.globalModals.openAlertModal({
                            title: "Please recheck your cart items.",
                            body: (
                                <React.Fragment>
                                    <p>
                                        It looks like either there are no items
                                        in your cart or some items may not
                                        currently be available.
                                    </p>
                                    <p>
                                        Add some items or remove any items
                                        flagged as unavailable to continue.
                                    </p>
                                </React.Fragment>
                            ),
                            confirmText: "OK",
                            onConfirm: () => {
                                dispatch(
                                    actions.globalModals.closeAlertModal()
                                );
                            }
                        })
                    );
                }
            } else {
                // no hj user or guest user, close upsell modal and show login prompt and pass create order callback for later use
                dispatch(
                    actions.globalModals.closeGlobalModal(GlobalModals.UPSELLS)
                );
                dispatch(
                    actions.globalModals.openGlobalModal({
                        modalId: GlobalModals.LOGIN_PROMPT
                    })
                );
            }
        })
        .catch(() => {
            // checkout cancelled or not allowed due to time restrictions

            // close upsells modal
            dispatch(
                actions.globalModals.closeGlobalModal(GlobalModals.UPSELLS)
            );
        });
};

//
// Create order - prep and pass the cart object to the checkout API to check availability
//
export const createOrder = (): any => (dispatch: any, getState: any): any => {
    const { cart, menu, user, session, vouchers } = getState();

    if (!user.email && !user.guest_email) {
        dispatch(
            actions.globalModals.openAlertModal({
                title: "Verify your email",
                body: <p>Please verify your email to place an order.</p>,
                confirmText: "Go to verification",
                onConfirm: () => {
                    dispatch(actions.globalModals.closeAlertModal());
                    dispatch(replace(Config.routes.emailVerification));
                }
            })
        );
        return Promise.resolve();
    }

    // Check if voucher/compcode works for delivery orders
    // This validation will need to be updated if we ever support pickup orders on the web
    if (cart.voucher_id || cart.compCode) {
        // Get the order methods for the comp_code/voucher
        let voucher_order_methods = [];
        if (cart.compCode) {
            voucher_order_methods = cart.compCode.order_modes;
        } else if (cart.voucher_id) {
            let voucher: ?VoucherType = vouchers.vouchers.find(
                (v: any): boolean => v.id === cart.voucher_id
            );

            // set voucher as coffee loyalty voucher if matched
            voucher =
                vouchers.coffeeLoyalty &&
                vouchers.coffeeLoyalty.voucher &&
                vouchers.coffeeLoyalty.voucher.id === cart.voucher_id
                    ? vouchers.coffeeLoyalty.voucher
                    : voucher;

            voucher_order_methods =
                voucher && voucher.order_modes ? voucher.order_modes : [];
        }

        // Determine if order modes include delivery
        if (
            !(
                voucher_order_methods.includes("All") ||
                voucher_order_methods.includes("Delivery")
            )
        ) {
            dispatch(
                actions.globalModals.openAlertModal({
                    title: "Error",
                    body: (
                        <p>
                            The voucher in the cart cannot be used with a
                            delivery order. Please remove it from your cart and
                            try again.
                        </p>
                    ),
                    confirmText: "OK",
                    onConfirm: () => {
                        dispatch(actions.globalModals.closeAlertModal());
                        dispatch(replace(Config.routes.cart));
                    }
                })
            );
            return Promise.resolve();
        }
    }

    // set user id and order creation time on cart object
    // DT: it uses Confirmed time if available. No impact to use user device time.
    cart.created_time = DateService.now().format("DD MMM YYYY  hh:mma");

    // assign user id/key on cart object if exists
    if (user.key) {
        cart.user_id = user.key;
    }

    if (cart.line_items.length) {
        dispatch({
            type: ActionTypes.CREATE_ORDER_REQUEST
        });

        logCustomEventWithDelivery("Checkout", {});

        // clear uncessasary extra cart data not required by checkout api but required client side business logic
        cart.line_items.forEach((lineItem: any) => {
            lineItem.sales_items.forEach((salesItem: any) => {
                delete salesItem.allergens;
                delete salesItem.modifier_groups;
            });
        });

        return ApiFactory.OrderApi.createOrder({
            ...cart,
            address_record_id: session.deliveryLocation.record_id,
            requested_delivery_datetime:
                session.deliveryTime === "asap"
                    ? null
                    : moment(session.deliveryTime).format("D MMMM YYYY  h:mma")
        })
            .then((order: any) => {
                dispatch({
                    type: ActionTypes.CREATE_ORDER_SUCCESS,
                    order
                });

                // redirect to cart if any price or availability issues
                if (
                    getState().cart.cartPriceDifferences ||
                    getState().cart.cartItemsUnavailable
                ) {
                    dispatch(replace(Config.routes.cart));
                }
            })
            .catch((err: any) => {
                if (
                    _.get(err, "httpStatus") === 400 &&
                    _.get(err, "code") === "6021"
                ) {
                    // Return alert for minimum order of $1.00 required
                    dispatch(
                        actions.globalModals.openAlertModal({
                            title:
                                Strings.minimumOrderAmount,
                            body: <p>{err.friendly_message || err.message}</p>,
                            confirmText: "SEE MENU",
                            onConfirm: () => {
                                dispatch(
                                    actions.globalModals.closeAlertModal()
                                );
                                dispatch(replace(`${Config.routes.menu}/${menu.store_id}`));
                            }
                        })
                    );
                } else if (
                    !(
                        _.get(err, "httpStatus") === 400 &&
                        _.has(err, "order_id")
                    )
                ) {
                    // unknown error? no user key passed/not authed user, order not created
                    dispatch(
                        actions.globalModals.openAlertModal({
                            title:
                                err.title ||
                                "Something went wrong with the checkout process.",
                            body: <p>{err.friendly_message || err.message}</p>,
                            confirmText: "OK",
                            onConfirm: () => {
                                dispatch(
                                    actions.globalModals.closeAlertModal()
                                );
                                dispatch(replace(Config.routes.cart));
                            }
                        })
                    );
                } else {
                    // items unavailable
                    dispatch(
                        actions.globalModals.openAlertModal({
                            title: "Item unavailable",
                            body: (
                                <p>
                                    Oops! There are items in your cart that are
                                    currently unavailable at this restaurant.
                                    Please remove them to continue.
                                </p>
                            ),
                            confirmText: "OK",
                            onConfirm: () => {
                                dispatch(
                                    actions.globalModals.closeAlertModal()
                                );
                            }
                        })
                    );
                }

                // dispatch cart error
                dispatch({
                    type: ActionTypes.CREATE_ORDER_FAILURE,
                    order:
                        _.get(err, "httpStatus") === 400 &&
                        _.has(err, "order_id")
                            ? err
                            : getState().cart
                });

                // redirect to cart
                dispatch(replace(Config.routes.cart));
            });
    }
};

export const addPastOrderToCart = (order: any): any => async (
    dispatch: any,
    getState: () => any
): any => {
    const { menu } = getState();

    // Add line items
    dispatch({
        type: ActionTypes.ADD_PAST_ORDER_TO_CART,
        lineItems: order.line_items
    });

    dispatch({
        type: ActionTypes.CHECK_DISCOUNTED_ITEM,
        discountedMenuItems: getState().config.menu.discounted_menu_items
    });
    // log add to cart
    order.line_items.forEach((item: any) => {
        logAddToCart(item);
        if (item.isUpsell) {
            GoogleAnalytics.logEvent({
                category: "Cart",
                action: "Add",
                label: `Upsell_${item.promo_id || item.pos_item_id}`
            });
        } else {
            GoogleAnalytics.logEvent({
                category: "Cart",
                action: "Add",
                label: `Product_${item.promo_id || item.pos_item_id}`
            });
        }
        // window.dataLayer.push({
        //     event: "addToCart",
        //     ecommerce: {
        //         add: {
        //             products: [
        //                 {
        //                     name: item.menu_item_name,
        //                     id: item.promo_id || item.pos_item_id,
        //                     price: item.price,
        //                     category: _.get(item, "parentCategory.name"),
        //                     quantity: item.quantity
        //                 }
        //             ]
        //         }
        //     }
        // });

        // Add item variant name
        item.item_variant = item.menuItems ? 
                            item.menuItems[(item.isUpsell ? 0 : item.combo_level || 0)].option_name : 
                            item.menu_item_id ?
                            menu.menu_items.filter(m => m.menu_item_id === item.menu_item_id && m.combo_level === item.combo_level).option_name : "";

        let customised = false;
        item.sales_items.forEach((line): any => {
            if (!_.isEmpty(line.modifiers))
                customised = true
        })

        window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object
        window.dataLayer.push({
            event: "add_to_cart",
            ecommerce: {
                currency: "AUD", 
                value: item.price,
                items: [{
                    item_id: (item.promo_id || item.pos_id),
                    item_name: item.menu_item_name,
                    coupon: "",
                    discount: 0,
                    index: 0,
                    item_category: item.category ? item.category.name : "",
                    item_category2: `Reorder${customised ? `, Customised` : ``}`,
                    item_variant: item.item_variant,
                    line_items: [item.sales_items.map((line): any => {
                        return `${line.sales_item_name}${!_.isEmpty(line.modifiers) ?
                            ` (${line.modifiers.map((mod, i): any => {
                                return `+1 ${mod.modifier_name}${line.modifiers.length - 1 > i ? `, ` : ``}`
                            })})` : ''
                        }`
                    })].join(`, `),
                    price: item.price,
                    quantity: item.quantity
                }]
            }
        });


    });

    // Recalculate prices
    dispatch(recalculateCartTotals());

    const getAsapDeliveryTime = (): string => {
        const deliveryTimeFrame = moment(
            getState().config.order_delivery.default_expected_time_frame,
            "HH:mm:ss"
        );
        const addHours = deliveryTimeFrame.format("HH");
        const addMinutes = deliveryTimeFrame.format("mm");
        const addSeconds = deliveryTimeFrame.format("ss");
        // DT: this passes the timezone with user device time to JE. No need to use StoreUtils.selectedStoreNow()
        return DateService.now()
            .add(addHours, "hours")
            .add(addMinutes, "minutes")
            .add(addSeconds, "seconds")
            .toISOString(true);
    };
    dispatch(actions.session.showLoading());

    if (
        !order.delivery_details ||
        _.get(getState(), "session.deliveryLocation.record_id")
    ) {
        if (_.get(getState(), "session.selectedStore.store_id")) {
            return dispatch(
                actions.menu.getMenu(
                    _.get(getState(), "session.selectedStore.store_id")
                )
            )
                .then(() => {
                    // Redirect to cart
                    dispatch(push(Config.routes.cart));
                })
                .finally(() => {
                    dispatch(actions.session.hideLoading());
                });
        } else {
            dispatch(actions.session.hideLoading());
            dispatch(push(Config.routes.cart));
        }
    }

    try {
        const result = await AddressAPI.getDeliveryStore(
            order.delivery_details.address.record_id,
            getAsapDeliveryTime()
        );
        const store = _.get(getState(), "stores.list").find(
            (s: any): boolean => s.store_id === result[0].store_id
        );
        if (store) {
            // Save everything into session
            dispatch(
                actions.session.setDeliveryLocation(
                    order.delivery_details.address
                )
            );
            dispatch(
                actions.session.setOrderMethod(order.order_mode.toLowerCase())
            );
            dispatch(actions.session.setDeliveryTime("asap"));
            dispatch(actions.session.setSelectedStore(store));
            // Load the menu for the selected store
            dispatch(actions.menu.getMenu(store.store_id))
                .then(() => {
                    // Redirect to cart
                    dispatch(push(Config.routes.cart));
                })
                .finally(() => {
                    dispatch(actions.session.hideLoading());
                });
        } else {
            dispatch(actions.session.hideLoading());
        }
    } catch (error) {
        dispatch(
            actions.globalModals.openAlertModal({
                title: error.title || "Something went wrong",
                body: (
                    <React.Fragment>
                        <p>{error.friendly_message}</p>
                    </React.Fragment>
                ),
                confirmText: "OK",
                onConfirm: () => {
                    dispatch(actions.globalModals.closeAlertModal());
                }
            })
        );
        dispatch(actions.session.hideLoading());
    }
    // Ensure the user has a store selected
    // if (_.isEmpty(getState().session.selectedStore)) {
    //     // Open store selector if they haven't
    //     dispatch(
    //         actions.globalModals.openGlobalModal({
    //             modalId: GlobalModals.ADDRESS_LOCATOR,
    //             onSuccess: () => {
    //                 dispatch(
    //                     actions.globalModals.closeGlobalModal(
    //                         GlobalModals.ADDRESS_LOCATOR
    //                     )
    //                 );
    //                 // Load the menu for the selected store
    //                 dispatch(
    //                     actions.menu.getMenu(
    //                         getState().session.selectedStore.store_id
    //                     )
    //                 ).then(() => {
    //                     // Redirect to cart
    //                     dispatch(push(Config.routes.cart));
    //                 });
    //             }
    //         })
    //     );
    // } else {
    //     // Redirect to cart
    //     dispatch(push(Config.routes.cart));
    // }
};

export const getCompDetailRequest = (): any => ({
    type: ActionTypes.GET_COMP_DETAIL_REQUEST
});

export const getCompDetailSuccess = (compCode: any): any => (
    dispatch: Function,
    getState: Function
): any => {
    const { session } = getState();
    const selectedStoreID = _.get(session, "selectedStore.store_id");

    if (
        selectedStoreID &&
        compCode.payment_methods_constraints &&
        compCode.payment_methods_constraints &&
        compCode.payment_methods_constraints.length
    ) {
        // check if promo payment constraints are supported by the selected store
        dispatch(
            actions.menu.checkStoreSupportsPayments(
                compCode.payment_methods_constraints,
                (): any =>
                    dispatch({
                        type: ActionTypes.GET_COMP_DETAIL_SUCCESS,
                        compCode
                    })
            )
        );
    } else {
        // no store to check against, add promo to cart
        dispatch({
            type: ActionTypes.GET_COMP_DETAIL_SUCCESS,
            compCode
        });
    }
};

export const getCompDetailFailure = (): any => ({
    type: ActionTypes.GET_COMP_DETAIL_FAILURE
});

export const getCompDetailComplete = (): any => ({
    type: ActionTypes.GET_COMP_DETAIL_COMPLETE
});

export const removeCompCode = (): any => ({
    type: ActionTypes.REMOVE_COMP_CODE
});

export const applyCompCode = (compCode: string): any => async (
    dispatch: Function,
    getState: Function
): any => {
    // check if comp code already applied and prompt to replace
    if (getState().cart.compCode !== null) {
        const toRplace = await new Promise(resolve => {
            dispatch(
                actions.globalModals.openAlertModal({
                    title: "Replace promo code?",
                    body: (
                        <p>
                            You have a promo code in your cart already, do you
                            want to replace?
                        </p>
                    ),
                    confirmText: "Yes, replace",
                    onConfirm: () => {
                        dispatch(actions.globalModals.closeAlertModal());
                        resolve(true);
                    },
                    cancelText: "No, do not use promo code",
                    onCancel: () => {
                        dispatch(actions.globalModals.closeAlertModal());
                        resolve(false);
                    }
                })
            );
        });
        if (!toRplace) return false;
    }

    dispatch(getCompDetailRequest());

    return ApiFactory.VoucherAPI.getCompDetail(compCode)
        .then((response: any): any => {
            // check if comp code has payment constraints
            let voucherConstraintsupported =
                response.payment_methods_constraints &&
                response.payment_methods_constraints.length
                    ? false
                    : true;

            // check if device supports promo payment constraints
            if (response.payment_methods_constraints) {
                response.payment_methods_constraints.forEach(
                    (paymentMethod: any) => {
                        if (getState().device.paymentSupport[paymentMethod]) {
                            voucherConstraintsupported = getState().device
                                .paymentSupport[paymentMethod];
                        }
                    }
                );
            }

            if (!voucherConstraintsupported) {
                // device does not promo not supported
                const unsupportedPaymentConstraints = _.filter(
                    Config.PaymentOptions,
                    (paymentOption: any): any => {
                        if (response.payment_methods_constraints) {
                            if (
                                response.payment_methods_constraints.includes(
                                    paymentOption.constraint
                                )
                            ) {
                                return true;
                            } else {
                                return false;
                            }
                        } else {
                            return true;
                        }
                    }
                );

                dispatch(
                    actions.globalModals.openAlertModal({
                        title: (
                            <>
                                {unsupportedPaymentConstraints.map(
                                    (paymentOption: any): any => {
                                        return paymentOption.text + " ";
                                    }
                                )}{" "}
                                not available
                            </>
                        ),
                        body: (
                            <p>
                                To be eligible for this promotion, you must pay
                                with{" "}
                                {unsupportedPaymentConstraints.map(
                                    (paymentOption: any): any => {
                                        return paymentOption.text + " ";
                                    }
                                )}
                                . Please use a device with{" "}
                                {unsupportedPaymentConstraints.map(
                                    (paymentOption: any): any => {
                                        return paymentOption.text + " ";
                                    }
                                )}
                                to continue
                            </p>
                        ),
                        onConfirm: () => {
                            dispatch(actions.globalModals.closeAlertModal());
                        }
                    })
                );

                // do nothing, prevent voucher being added
                return false;
            }

            dispatch(getCompDetailComplete());

            if (
                getState().cart.hasDiscount ||
                getState().cart.hasDiscountedItem
            ) {
                dispatch(
                    actions.globalModals.openAlertModal({
                        title: "Only one discount per cart!",
                        body: (
                            <p>
                                {`To use this discount instead, please remove the ${getState()
                                    .cart.hasDiscount ||
                                    getState().cart
                                        .hasDiscountedItem} currently in your cart.`}
                            </p>
                        ),
                        confirmText: "VIEW CART",
                        onConfirm: () => {
                            dispatch(actions.globalModals.closeAlertModal());
                            dispatch(replace(Config.routes.cart));
                        }
                    })
                );
                return false;
            }

            const storeId = _.get(
                getState(),
                "session.selectedStore.store_id",
                null
            );

            if (
                _.isArray(response.stores_ids_constraints) &&
                !response.stores_ids_constraints.includes(storeId)
            ) {
                dispatch(
                    actions.globalModals.openAlertModal({
                        title: "Unable to use promotion",
                        body: (
                            <p>
                                Sorry, the offer is not valid at your selected
                                store.
                            </p>
                        ),
                        onConfirm: () => {
                            dispatch(actions.globalModals.closeAlertModal());
                        }
                    })
                );
                return false;
            }

            // no store set, prompt address locator
            if (!storeId) {
                return new Promise((resolve, reject) => {
                    dispatch(
                        actions.globalModals.openGlobalModal({
                            modalId: GlobalModals.ADDRESS_LOCATOR,
                            onSuccess: () => {
                                dispatch(
                                    actions.globalModals.closeGlobalModal(
                                        GlobalModals.ADDRESS_LOCATOR
                                    )
                                );
                                dispatch(getCompDetailSuccess(response));
                                resolve(true);
                            }
                        })
                    );
                });
            }

            dispatch(getCompDetailSuccess(response));
            return true;
        })
        .catch((err): any => {
            dispatch(getCompDetailFailure());
            console.log(err);
            // return false;
            throw err;
        });
};
