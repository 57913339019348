// @flow

import * as React from "react";

import Modal from "@components/organisms/Modal";
import Button from "@components/atoms/Button";
import TextButton from "@components/atoms/TextButton";

const Alert = (props: {
    active: boolean,
    title: string,
    body: string | React.Node,
    confirmText?: string,
    cancelText?: string,
    onConfirm?: ?() => void,
    onCancel?: ?() => void,
    html?: boolean,
    size?: number,
    textAlign?: string,
    terms?: boolean,
    hideClose?: boolean
}): React.Node => {
    const { onConfirm = () => {}, onCancel = () => {} } = props;
    return (
        <Modal
            active={props.active}
            onClose={() => {}}
            maxWidth={props.size || 468}
            hideClose={props.hideClose === false ? props.hideClose : true}
            className="modal--alert"
        >
            <div className={`alert ${props.terms ? 'terms' : ''}`}>
                <div className="alert__title">
                    <h3 className="heading-3">{props.title}</h3>
                </div>
                <div className="alert__body"
                    style={props.textAlign ? {
                        textAlign: props.textAlign
                    } : {}}>
                    {props.html && props.body ? (
                        <div dangerouslySetInnerHTML={{ __html: props.body }} />
                    ) : (
                        props.body
                    )}
                </div>
                <div className="alert__buttons">
                    <Button onClick={onConfirm}>
                        {props.confirmText ? props.confirmText : "Ok"}
                    </Button>
                    {props.cancelText ? (
                        <TextButton onClick={onCancel}>
                            {props.cancelText}
                        </TextButton>
                    ) : null}
                </div>
            </div>
        </Modal>
    );
};

export default Alert;
