// @flow

import * as React from "react";
import { connect } from "react-redux";
import { ReactForm } from "@adrenalin/react-form";
import { Link } from "react-router-dom";
import { withRouter } from "@utils/hooks/withRouter";

import actions from "@actions";
import Config from "@Config";
import { getQueryString } from "@utils";
import Footer from "@components/organisms/Footer";
import Button from "@components/atoms/Button";
import CheckoutHero from "@components/molecules/CheckoutHero";
import ThumbsFeedback from "@components/molecules/ThumbsFeedback";
import PastOrder from "@components/molecules/PastOrder";
import ServerErrorMessage from "@components/atoms/ServerErrorMessage";
import MappedMetaTags from "@containers/MappedMetaTags";
import useOrderDetails from "@utils/hooks/useOrderDetails";
import useAsyncState from "@utils/hooks/useAsyncState";
import { appboySetCustomAttribute } from "@utils/AppBoy";
import * as GoogleAnalytics from "@utils/GoogleAnalytics";
import orderUtils from "../utils/orderUtils";
// import actions from "@actions";

type PropsType = {
    questions: any,
    questionsContent: any,
    rateOrderFeedback: any,
    isAuthenticated: boolean,
    router: any,
    dispatch: any
};

const Feedback = (props: PropsType): React.Node => {
    const { token } = props.router.params;
    const prefilledRating = getQueryString("prefillFeedback");
    const [order, loading] = useOrderDetails(null, token);

    //const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
    const [state, localActions] = useAsyncState();

    const handleFormSubmit = (formData: any) => {
        GoogleAnalytics.logEvent({
            category: "RateOrder",
            action: "Submit",
            label: "Submit"
        });
        // set request state
        localActions.request();

        // prepare feedback post object
        let feedbackForm = {};
        // convert answers to integers
        Object.keys(formData).forEach((key: any) => {
            formData[key] = formData[key] ? Number(formData[key]) : 0;
        });
        feedbackForm = {
            ...feedbackForm,
            ratings: formData
        };

        // post form to rate order action
        props
            .rateOrderFeedback(order, feedbackForm)
            .then(() => {
                // set success/thank you state
                localActions.success("Success!");
                //setFeedbackSubmitted(true);

                // Appboy set feedback score attributes
                props.questions.forEach((question: string, idx: number) => {
                    appboySetCustomAttribute(
                        `FeedbackScore_q${idx + 1}`,
                        formData[question]
                    );
                });
            })
            .catch((error: any) => {
                localActions.fail(
                    error.friendly_message || "Something went wrong"
                );
                console.error(error);
            });
    };
    // const storeId: ?number = _.get(props, "session.selectedStore.store_id");

    return (
        <div className="feedback">
            <MappedMetaTags defaultTitle="Rate your order" />

            <CheckoutHero>
                <div className="container-fluid-xsmall">
                    <CheckoutHero.Title
                        button={
                            props.isAuthenticated ? (
                                <CheckoutHero.Link
                                    text="My Orders"
                                    to={`${Config.routes.account}/my-orders`}
                                    onClick={() => {
                                        GoogleAnalytics.logEvent({
                                            category: "RateOrder",
                                            action: "Close",
                                            label: "Rating"
                                        });
                                    }}
                                />
                            ) : null
                        }
                    >
                        Rate your order
                    </CheckoutHero.Title>
                </div>
            </CheckoutHero>

            <div className="container-fluid-xsmall cart-page">
                <div className="content-card content-card--with-sections">
                    <div className="content-card__section">
                        {!state.success && orderUtils.canGiveFeedback(order) ? (
                            <>
                                <div className="feedback__header">
                                    <div className="feedback__header-title">
                                        Tell us how it was!
                                    </div>
                                    <div className="feedback__header-description">
                                        Your feedback is important and we use it
                                        to make sure our food, restaurants and
                                        service are meeting your high standards.
                                    </div>
                                </div>

                                <div className="feedback__delivery-container">
                                    {!loading && order && (
                                        <PastOrder
                                            summary={true}
                                            order={order}
                                        />
                                    )}
                                </div>

                                {!order ? (
                                    !loading ? (
                                        <ServerErrorMessage>
                                            Sorry, the link is invalid or has
                                            expired.
                                        </ServerErrorMessage>
                                    ) : null
                                ) : (
                                    <ReactForm onSuccess={handleFormSubmit}>
                                        {props.questions &&
                                            props.questions.map(
                                                (
                                                    question: any,
                                                    index: any
                                                ): any => (
                                                    <React.Fragment key={index}>
                                                        {ThumbsFeedback(
                                                            question,
                                                            5,
                                                            props
                                                                .questionsContent[
                                                                question
                                                            ],
                                                            index === 0
                                                                ? prefilledRating
                                                                : null
                                                        )}
                                                    </React.Fragment>
                                                )
                                            )}

                                        <ServerErrorMessage>
                                            {state.error}
                                        </ServerErrorMessage>

                                        <Button
                                            type="submit"
                                            success={!!state.success}
                                            loading={state.loading}
                                        >
                                            Submit
                                        </Button>
                                    </ReactForm>
                                )}
                            </>
                        ) : (
                            <>
                                <div className="feedback__header">
                                    <div className="feedback__header-title">
                                        Thank you!
                                    </div>
                                    <div className="feedback__header-description">
                                        {props.isAuthenticated ? (
                                            <>
                                                Your feedback has been received.
                                                You can find all of your order
                                                details in your account under{" "}
                                                <Link
                                                    to={`${Config.routes.account}/my-orders`}
                                                >
                                                    My Order History
                                                </Link>
                                            </>
                                        ) : (
                                            <>
                                                Your feedback has been received.
                                                You can view this order's
                                                details again{" "}
                                                <Link
                                                    to={`${Config.routes.order}/guest/${token}`}
                                                >
                                                    here.
                                                </Link>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

const mapStateToProps = (state: any): any => ({
    questions: state.config.feedback_delivery_questions,
    questionsContent: state.config.feedback_questions,
    isAuthenticated: state.session.isAuthenticated
});

const mapDispatchToProps = {
    rateOrderFeedback: actions.orders.rateOrderFeedback
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Feedback));
