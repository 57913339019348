// @flow

import * as React from "react";
import { connect } from "react-redux";

import { pubsub } from "@utils";
import CartTotals from "@components/molecules/CartTotals";
import CheckoutSubmitButton from "@components/atoms/CheckoutSubmitButton";
import Button from "@components/atoms/Button";

type PropsType = {
    session: any,
    user: any
};

const MobileCheckoutFooter = (props: PropsType): any => {
    if (props.session.isAuthenticated || props.session.isGuestUser) {
        // Checkout confirm submit button/order summary
        return (
            <div className="mobile-checkout-footer fixed-footer__section hide-desktop">
                <CartTotals items={["total"]} divider={false} />
                <CheckoutSubmitButton />
            </div>
        );
    } else {
        // Pre-Checkout guest form submit button
        return (
            <div className="mobile-checkout-footer fixed-footer__section hide-desktop">
                <Button
                    onClick={(e: any): any => {
                        pubsub.publish("GUEST_FORM_SUBMIT");
                    }}
                    styleType="green"
                    loading={props.user.guestLoginFetching}
                    disabled={!props.session.validGuestForm}
                >
                    Continue
                </Button>
            </div>
        );
    }
};

const mapStateToProps = (state: any): any => {
    return {
        session: state.session,
        user: state.user
    };
};

export default connect(
    mapStateToProps,
    {}
)(MobileCheckoutFooter);
