// @flow

import * as React from "react";

import BirthdayWhopper from "@assets/images/birthday-whopper.png";

type PropsType = {
    percent: number
};

const CompleteProfile = (props: PropsType): React.Node => {
    return (
        <div className="complete-profile">
            <div className="complete-profile__top">
                <div className="complete-profile__left">
                    <p className="complete-profile__eyebrow">
                        Complete your profile and get a
                    </p>
                    <p className="complete-profile__title complete-profile__title--1">
                        <span>FREE WHOPPER</span>
                    </p>
                    <p className="complete-profile__title complete-profile__title--2">
                        <span>FOR YOUR BIRTHDAY</span>
                    </p>
                </div>
                <div className="complete-profile__right">
                    <img src={BirthdayWhopper} alt="Whopper burger with candle" />
                </div>
            </div>
            <div className="complete-profile__progress">
                <div className="complete-profile__progress__text">
                    {props.percent}% complete
                </div>
                <div className="complete-profile__progress__track">
                    <div
                        className="complete-profile__progress__thumb"
                        style={{ width: `${props.percent}%` }}
                    />
                </div>
            </div>
        </div>
    );
};

export default CompleteProfile;
