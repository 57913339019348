// @flow

import * as React from "react";
import { connect } from "react-redux";

import actions from "@actions";
import Icon from "@components/atoms/Icon";

type PropsType = {
    voucher_name: string,
    editable?: boolean,
    // Below from redux connect HOC
    removeCartVoucher: typeof actions.cart.removeCartVoucher
};

const defaultProps = {
    editable: false
};

const OrderVoucher = (props: PropsType): React.Node => {
    const baseClassname = "order-voucher";
    return (
        <div className={`${baseClassname}`}>
            <div className={`${baseClassname}__name`}>
                <Icon name="voucher" />
                {props.voucher_name}
            </div>
            {/* Voucher discount price here */}
            {/* <div className={`${baseClassname}__price`}>-$5.00</div> */}
            {props.editable ? (
                <div className={`${baseClassname}__remove`}>
                    <button
                        className={`${baseClassname}__remove__btn`}
                        type="button"
                        onClick={(event: any): any => {
                            event.preventDefault();
                            props.removeCartVoucher();
                        }}
                    >
                        <Icon name="close" />
                    </button>
                </div>
            ) : null}
        </div>
    );
};

OrderVoucher.defaultProps = defaultProps;

const mapStateToProps = (props: any): any => ({});

export default connect(
    mapStateToProps,
    {
        removeCartVoucher: actions.cart.removeCartVoucher
    }
)(OrderVoucher);
