// @flow

import React from "react";
import ActionTypes from "@actionTypes";
import ApiFactory from "@apis/APIFactory";
import actions from "@actions";
import { appboySetCustomAttribute, appboyLogPurchase } from "@utils/AppBoy";
import * as GoogleAnalytics from "@utils/GoogleAnalytics";
import { replace } from "@lagunovsky/redux-react-router";
import Config from "@Config";
import _ from "lodash";
import { logCustomEventWithDelivery } from "@utils/AppBoy";
import promiseSetInterval from "@utils/promiseSetInterval";

// Active Orders Polling
// --------------

let activeOrdersPollingInterval;
export const startActiveOrdersPolling = (): any => (
    dispatch: any,
    getState: any
): any => {
    if (activeOrdersPollingInterval) {
        // if called whilst already active, reset polling
        stopActiveOrderPolling();
        startActiveOrdersPolling();
    } else {
        // start new promise set interval to poll
        activeOrdersPollingInterval = promiseSetInterval(
            (): any => {
                return dispatch(getAllOrders());
            },
            getState().config.order_delivery.status_polling_interval_in_ms ||
                45000,
            true
        );
    }
};
export const stopActiveOrderPolling = (): any => (
    dispatch: any,
    getState: any
): any => {
    if (activeOrdersPollingInterval) {
        activeOrdersPollingInterval.remove();
        activeOrdersPollingInterval = null;
    }
};

// Get All Orders
// --------------

const getAllOrdersRequest = (): any => ({
    type: ActionTypes.GET_ALL_ORDERS_REQUEST
});

const getAllOrdersSuccess = (orders: any): any => ({
    type: ActionTypes.GET_ALL_ORDERS_SUCCESS
});

const getAllOrdersFail = (error: any): any => ({
    type: ActionTypes.GET_ALL_ORDERS_FAIL,
    error
});

export const getAllOrders = (): any => (dispatch: any): any => {
    dispatch(getAllOrdersRequest());
    return Promise.all([dispatch(getPastOrders()), dispatch(getActiveOrders())])
        .then(() => {
            dispatch(getAllOrdersSuccess());
        })
        .catch((error: any) => {
            dispatch(getAllOrdersFail(error));
        });
};

// Get Past Orders
// ---------------

const getPastOrdersRequest = (): any => ({
    type: ActionTypes.GET_PAST_ORDERS_REQUEST
});

const getPastOrdersSuccess = (orders: any): any => ({
    type: ActionTypes.GET_PAST_ORDERS_SUCCESS,
    orders
});

const getPastOrdersFail = (error: any): any => ({
    type: ActionTypes.GET_PAST_ORDERS_FAIL,
    error
});

export const getPastOrders = (): any => (dispatch: any, getState: any): any => {
    dispatch(getPastOrdersRequest());
    return ApiFactory.OrderApi.getPastOrders(getState().user.key)
        .then((res: any): any => {
            dispatch(getPastOrdersSuccess(res.orders));
            return res.orders;
        })
        .catch((error: any): any => {
            dispatch(getPastOrdersFail(error));
            throw error;
        });
};

// Get Active Orders
// -----------------

const getActiveOrdersRequest = (): any => ({
    type: ActionTypes.GET_ACTIVE_ORDERS_REQUEST
});

const getActiveOrdersSuccess = (orders: any): any => (
    dispatch: (action: any) => any,
    getState: any
): any => {
    return dispatch({
        type: ActionTypes.GET_ACTIVE_ORDERS_SUCCESS,
        orders,
        status_progress_steps: getState().config.order_delivery
            .status_progress_steps
    });
};

const getActiveOrdersFail = (error: any): any => ({
    type: ActionTypes.GET_ACTIVE_ORDERS_FAIL,
    error
});

export const getActiveOrders = (): any => (
    dispatch: (action: any) => any,
    getState: () => any
): Promise<any> => {
    dispatch(getActiveOrdersRequest());
    return ApiFactory.OrderApi.getActiveOrders(getState().user.key)
        .then((res: any): any => {
            const orders = _.filter(res.orders, (order: any): boolean => {
                return order.is_confirmed !== false;
            });
            dispatch(getActiveOrdersSuccess(orders));
            return orders;
        })
        .catch((error: any): any => {
            dispatch(getActiveOrdersFail(error));
            throw error;
        });
};

// Confirm Order (pay and complete order)
// -------------------

const confirmOrderRequest = (): any => ({
    type: ActionTypes.CONFIRM_ORDER_REQUEST
});

const confirmOrderSuccess = (): any => ({
    type: ActionTypes.CONFIRM_ORDER_SUCCESS
});

const confirmOrderFail = (error: any): any => ({
    type: ActionTypes.CONFIRM_ORDER_FAILURE,
    error
});

export const confirmOrder = (confirmPayload: any): any => (
    dispatch: (action: any) => any,
    getState: any
): Promise<any> => {
    const { cart, session } = getState();

    // GA tracking
    GoogleAnalytics.logEvent({
        category: "Cart",
        action: "Confirm",
        label: "Payment"
    });

    logCustomEventWithDelivery("ConfirmOrder", {
        deliveryMethod: "Delivery",
        paymentMethod: session.paymentMethodName === "PayPal" ? "PayPal" : "CC",
        deliveryTime:
            cart.requested_delivery_datetime === null ? "ASAP" : "ForLater"
    });

    logCustomEventWithDelivery("PayOrder", {
        deliveryMethod: "Delivery",
        deliveryTime:
            cart.requested_delivery_datetime === null ? "ASAP" : "ForLater",
        compCode: cart.comp_code
    });

    dispatch(confirmOrderRequest());

    return ApiFactory.OrderApi.confirmOrder(confirmPayload)
        .then((res: any): any => {
            window.localStorage.removeItem("deliveryTime");
            // set appboy/braze attributes
            appboySetCustomAttribute("LastOrder", new Date());
            appboySetCustomAttribute("TotalOrderValue", cart.total_amount);
            appboySetCustomAttribute("LastOrderStoreId", cart.store_id);
            // log each order line item in appboy/braze
            cart.line_items.forEach((item: any): any => {
                appboyLogPurchase(
                    `${item.promo_id || item.pos_item_id}`,
                    (item.price.toFixed(2) / item.quantity).toFixed(2),
                    "USD",
                    item.quantity,
                    {}
                );
            });
            if (session.isGuestUser) {
                window.localStorage.setItem(
                    "pastGuestOrder",
                    JSON.stringify(
                        _.omit(
                            {
                                ...cart,
                                order_status_key: _.last(
                                    getState().config.order_delivery
                                        .status_progress_steps
                                ).status_keys[0]
                            },
                            ["delivery_details", "address_record_id"]
                        )
                    )
                );
            }
            dispatch(confirmOrderSuccess());

            // rerun user coffee loyalty check for updated counts
            dispatch(actions.vouchers.getUserCoffeeLoyalty());

            // refetch all the users orders (now including order that was just made)
            if (session.isAuthenticated || session.isGuestUser) {
                dispatch(getAllOrders()).then(() => {
                    const {
                        menu: { categories },
                        orders: { active }
                    } = getState();
                    const order = _.find(active, {
                        order_id: cart.order_id
                    });

                    window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object
                    window.dataLayer.push({
                        event: "purchase",
                        ecommerce: {
                            currency: "AUD",
                            transaction_id: order.friendly_order_id,
                            tax: order.tax_amount,
                            shipping: cart.delivery_cost,
                            value: order.total_amount,
                            coupon: order.voucher_id || order.comp_code,
                            payment_type: /\d/.test(session.paymentMethodName) ? "Credit Card" : session.paymentMethodName,
                            discount: order.discounts_total,
                            items: 
                                cart.line_items.map((item, i): any => {
                                    const parentCategory =
                                        item.menuLink &&
                                        _.find(categories, {
                                            category_id: Number(
                                                item.menuLink.split("/")[3]
                                            )
                                        });
                                    
                                    let customised = false;
                                    item.sales_items.forEach((line): any => {
                                        if (!_.isEmpty(line.modifiers))
                                            customised = true
                                    })

                                    return {
                                        item_id: (item.promo_id || item.pos_item_id),
                                        item_name: item.menu_item_name,
                                        coupon: item.voucher_id ? "Reward Voucher" : item.comp_code ? item.comp_code : "",
                                        discount: item.priceDiff ? item.priceDiff.toFixed(2) : 0,
                                        index: i,
                                        item_category:  _.get(parentCategory, "name"),
                                        item_category2: item.is_reorder ? "Reorder" : item.isUpsell ? "Upsell" : customised ? "Customised" : "",
                                        item_variant: item.item_variant,
                                        line_items: [item.sales_items.map((line): any => {
                                            return `${line.sales_item_name}${!_.isEmpty(line.modifiers) ?
                                                ` (${line.modifiers.map((mod, i): any => {
                                                    return `+1 ${mod.modifier_name}${line.modifiers.length - 1 > i ? `, ` : ``}`
                                                })})` : ''
                                            }`
                                        })].join(`, `),
                                        price: item.price.toFixed(2),
                                        quantity: item.quantity
                                    }
                                })
                        }
                    });

                    if (session.isGuestUser) {
                        dispatch({
                            type: ActionTypes.GET_PAST_ORDERS_SUCCESS,
                            orders: [
                                _.omit(
                                    {
                                        ...cart,
                                        order_status_key: _.last(
                                            getState().config.order_delivery
                                                .status_progress_steps
                                        ).status_keys[0]
                                    },
                                    ["delivery_details", "address_record_id"]
                                )
                            ]
                        });
                    }
                });
            }

            // return order result with order token
            return res;
        })
        .catch((error: any): any => {
            dispatch(confirmOrderFail(error));

            dispatch(
                actions.globalModals.openAlertModal({
                    title:
                        error.title ||
                        "There was an issue confirming your order.",
                    body: (
                        <React.Fragment>
                            <p>{error.friendly_message || "Unknown error"}</p>
                        </React.Fragment>
                    ),
                    confirmText: "OK",
                    onConfirm: () => {
                        if (
                            error.httpStatus >= 500 &&
                            error.httpStatus !== 504
                        ) {
                            dispatch({
                                type: ActionTypes.CREATE_ORDER_FAILURE,
                                order: getState().cart
                            });
                            dispatch(replace(Config.routes.cart));
                        }
                        dispatch(actions.globalModals.closeAlertModal());
                    }
                })
            );

            throw error;
        });
};

// Submit/Rate Order Feedback
// -------------------

const rateOrderFeedbackRequest = (): any => ({
    type: ActionTypes.RATE_ORDER_REQUEST
});

const rateOrderFeedbackSuccess = (): any => ({
    type: ActionTypes.RATE_ORDER_SUCCESS
});

const rateOrderFeedbackFail = (error: any): any => ({
    type: ActionTypes.RATE_ORDER_FAILURE,
    error
});

export const rateOrderFeedback = (
    order: any,
    feedbackForm: {
        comments: ?string,
        ratings: {}
    }
): any => (dispatch: (action: any) => any, getState: any): Promise<any> => {
    dispatch(rateOrderFeedbackRequest());
    const { user } = getState();

    return ApiFactory.OrderApi.submitOrderFeedback(
        order,
        feedbackForm,
        user.key
    )
        .then((res: any): any => {
            dispatch(rateOrderFeedbackSuccess());
            if (getState().session.isAuthenticated) {
                //refresh past order if logged in user
                dispatch(getPastOrders());
            }
            return res;
        })
        .catch((error: any): any => {
            dispatch(rateOrderFeedbackFail(error));

            throw error;
        });
};
