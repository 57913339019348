// @flow

import * as React from "react";
const { useReducer } = React;

type StateType = {
    loading: boolean,
    success: any,
    error: any
};

type ActionsType = {
    request: () => void,
    success: (success?: any) => void,
    fail: (error: any) => void
};

const initialState: StateType = {
    loading: false,
    success: "",
    error: ""
};

const reducer = (state: StateType = initialState, action: any): StateType => {
    switch (action.type) {
        case "REQUEST":
            return {
                ...initialState,
                loading: true
            };
        case "SUCCESS":
            return {
                ...state,
                loading: false,
                success: action.success,
                error: ""
            };
        case "FAIL":
            return {
                ...state,
                loading: false,
                success: "",
                error: action.error
            };
        default:
            return state;
    }
};

const useAsyncState = (): [StateType, ActionsType] => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const actions: ActionsType = {
        request: (): any => dispatch({ type: "REQUEST" }),
        success: (success: any = true): any =>
            dispatch({ type: "SUCCESS", success }),
        fail: (error: any): any => dispatch({ type: "FAIL", error })
    };
    return [state, actions];
};

export default useAsyncState;
