// @flow

import React from "react";
import _ from "lodash";
import { push, replace } from "@lagunovsky/redux-react-router";
import Config from "@Config";
import ActionTypes from "@actionTypes";
import actions from "@actions";
import GlobalModals from "@Modals";
import { logCustomEventWithDelivery } from "@utils/AppBoy";
import ApiFactory from "@apis/APIFactory";
import { pubsub } from "@utils";
import appboy from "appboy-web-sdk";

export const showLoading = (): any => ({
    type: ActionTypes.SHOW_LOADING
});

export const hideLoading = (): any => ({
    type: ActionTypes.HIDE_LOADING
});

export const setSelectedStore = (store: any): any => (dispatch: any): any => {
    dispatch({
        type: ActionTypes.SET_SELECTED_STORE,
        store
    });
    logCustomEventWithDelivery("StartOrder", {
        storeID: store.store_id
    });

    window.localStorage.setItem("newSession", JSON.stringify(true));
    window.localStorage.setItem("selectedStore", JSON.stringify(store));
};

export const getSelectedStore = (): any => {
    if (window.localStorage.getItem("selectedStore")) {
        return JSON.parse(window.localStorage.getItem("selectedStore"));
    }

    return null;
};

export const setOrderMethod = (method: string): any => (dispatch: any): any => {
    dispatch({
        type: ActionTypes.SET_ORDER_METHOD,
        orderMethod: method
    });
    window.localStorage.setItem("orderMethod", method);
};

export const setDeliveryLocation = (location: any): any => (
    dispatch: any
): any => {
    dispatch({
        type: ActionTypes.SET_DELIVERY_LOCATION,
        location
    });
    window.localStorage.setItem("deliveryLocation", JSON.stringify(location));
};

export const setDeliveryTime = (time: any): any => (dispatch: any): any => {
    dispatch({ type: ActionTypes.SET_DELIVERY_TIME, time });
    window.localStorage.setItem("deliveryTime", time);
};

export const resetOrdering = (): any => (dispatch: any): any => {
    dispatch({
        type: ActionTypes.RESET_ORDERING
    });
    window.localStorage.removeItem("selectedStore");
    window.localStorage.removeItem("orderMethod");
    window.localStorage.removeItem("deliveryLocation");
    window.localStorage.removeItem("cart");
    window.localStorage.removeItem("deliveryTime");
};

export const setTooltipId = (id: any): any => (
    dispatch: any,
    getState: any
): any => {
    const { session } = getState();
    const ids = _.get(session, "tooltipIds");
    // add id to ids array
    // const updatedIds = ids.push(id);
    let newIds = ids;
    newIds.push(id);
    window.localStorage.setItem("tooltipIds", JSON.stringify(newIds));
    dispatch({
        type: ActionTypes.SET_TOOLTIP_IDS,
        tooltipIds: newIds
    });
};

export const handleSaveCompCode = (compCode: string): any => (
    dispatch: any,
    getState: any
): any => {
    let remove = null;
    const saveComp = (): any => {
        if (remove) {
            remove();
        }
        const userId = _.get(getState(), "user.key", false);
        return ApiFactory.VoucherAPI.saveCompCode(userId, compCode)
            .then(() => {
                dispatch(actions.vouchers.getVouchers());
                const message = new appboy.SlideUpMessage("Promo code saved");
                message.slideFrom = appboy.InAppMessage.SlideFrom.TOP;
                appboy.display.showInAppMessage(message);
            })
            .catch(err => {
                const message = new appboy.SlideUpMessage(
                    err.friendly_message || "Promo code not saved"
                );
                message.slideFrom = appboy.InAppMessage.SlideFrom.TOP;
                appboy.display.showInAppMessage(message);
            });
    };
    const { user } = getState();
    if (user.email) {
        return saveComp();
    }
    remove = pubsub.subscribe("VERIFIED_USER_LOG_IN", saveComp).remove;
    dispatch(replace(Config.routes.login));
    return;
};

export const handleAddProduct = (
    posID: number,
    promoID: number,
    uneditable: boolean = false,
    comboLevel: number
): any => (dispatch: any, getState: any): any => {
    const { session, menu } = getState();
    const selectedStoreID = _.get(session, "selectedStore.store_id");
    const loadedMenuID = _.get(menu, "store_id");
    const addItem = () => {
        dispatch(
            actions.cart.addPosPromoItemToCart(
                posID,
                promoID,
                uneditable,
                comboLevel
            )
        );
    };
    if (!selectedStoreID) {
        return new Promise((resolve, reject) => {
            dispatch(
                actions.globalModals.openGlobalModal({
                    modalId: GlobalModals.ADDRESS_LOCATOR,
                    onSuccess: () => {
                        dispatch(
                            actions.globalModals.closeGlobalModal(
                                GlobalModals.ADDRESS_LOCATOR
                            )
                        );
                        dispatch(
                            actions.menu.getMenu(
                                _.get(
                                    getState(),
                                    "session.selectedStore.store_id"
                                )
                            )
                        ).then(() => {
                            addItem();
                            resolve();
                        });
                    }
                })
            );
        });
    }
    if (!loadedMenuID) {
        return dispatch(actions.menu.getMenu(selectedStoreID)).then(addItem);
    }
    addItem();
    return Promise.resolve();
};

export const handleOpenCategory = (categoryId: number): any => (
    dispatch: any,
    getState: any
): any => {
    const { session, menu } = getState();
    const selectedStoreID = _.get(session, "selectedStore.store_id");
    const loadedMenuID = _.get(menu, "store_id");
    const openCategory = () => {
        dispatch(
            push(
                `${Config.routes.menu}/${_.get(
                    session,
                    "selectedStore.store_id"
                )}/${categoryId}`
            )
        );
    };
    if (!selectedStoreID) {
        return new Promise((resolve, reject) => {
            dispatch(
                actions.globalModals.openGlobalModal({
                    modalId: GlobalModals.ADDRESS_LOCATOR,
                    onSuccess: () => {
                        dispatch(
                            actions.globalModals.closeGlobalModal(
                                GlobalModals.ADDRESS_LOCATOR
                            )
                        );
                        dispatch(
                            actions.menu.getMenu(
                                _.get(
                                    getState(),
                                    "session.selectedStore.store_id"
                                )
                            )
                        ).then(() => {
                            openCategory();
                            resolve();
                        });
                    }
                })
            );
        });
    }
    if (!loadedMenuID) {
        return dispatch(actions.menu.getMenu(selectedStoreID)).then(
            openCategory
        );
    }
    openCategory();
    return Promise.resolve();
};
//
// Handle Product deeplink
//
export const handleProductDeeplink = (
    posID: string,
    promoID: string,
    uneditable: boolean = false,
    categoryID: string,
    jacksCafeCheck: boolean
): any => (dispatch: any, getState: any): any => {
    const { session, menu } = getState();
    const selectedStoreID = _.get(session, "selectedStore.store_id");
    const loadedMenuID = _.get(menu, "store_id");

    // store product deeplink config
    dispatch({
        type: ActionTypes.SAVE_DEEPLINK,
        posID,
        promoID,
        uneditable,
        categoryID,
        jacksCafeCheck
    });

    if (selectedStoreID) {
        // store already selected, go to store menu/check deeplink

        if (selectedStoreID === loadedMenuID) {
            // already loaded the selected store menu, check deeplink right away
            dispatch(actions.session.checkProductDeeplink());
        } else {
            // menu not loaded or is different to the selected store, redirect to the menu to fetch menu object,
            // checkProductDeeplink action is checked again after downloading a new menu
            dispatch(push(`${Config.routes.menu}/${selectedStoreID}`));
        }
    } else {
        dispatch(
            actions.globalModals.openGlobalModal({
                modalId: GlobalModals.ADDRESS_LOCATOR
            })
        );
    }
};

//
// Check Product deeplink
//
export const checkProductDeeplink = (): any => (
    dispatch: any,
    getState: any
): any => {
    const { session, menu } = getState();

    const posIdDeeplink = _.get(session, "deeplink.posID");
    const promoIdDeeplink = _.get(session, "deeplink.promoID");
    const uneditable = _.get(session, "deeplink.uneditable", false);
    const categoryIdDeeplink = _.get(session, "deeplink.categoryID");
    const jacksCafeCheck = _.get(session, "deeplink.jacksCafeCheck");

    if (categoryIdDeeplink) {
        // menu category deeplink found
        const matchedCategory = menu.categories
            ? _.find(menu.categories, {
                  category_id: categoryIdDeeplink
              })
            : null;

        const jacksCafeSupported = _.get(
            session,
            "selectedStore.facilities.barista_coffee"
        );

        if (matchedCategory) {
            // link to category
            dispatch(
                replace(
                    `${Config.routes.menu}/${menu.store_id}/${categoryIdDeeplink}`
                )
            );
        } else {
            // link to base menu
            dispatch(push(`${Config.routes.menu}/${menu.store_id}`));

            // show errors
            dispatch(
                actions.globalModals.openAlertModal({
                    title: jacksCafeCheck
                        ? !jacksCafeSupported
                            ? "Jack's cafe not available"
                            : "Jack's cafe not available"
                        : "Category not available",
                    body: jacksCafeCheck ? (
                        !jacksCafeSupported ? (
                            <p>
                                Sorry, your nearby Hungry Jack’s does not have a
                                Jack’s Cafe yet.
                            </p>
                        ) : (
                            <p>
                                Sorry, Barista coffee doesn’t exist for this
                                delivery method.
                            </p>
                        )
                    ) : (
                        <p>
                            Sorry, your nearby Hungry Jack’s does not have this
                            menu category available.
                        </p>
                    ),
                    onConfirm: () => {
                        dispatch(actions.globalModals.closeAlertModal());
                    }
                })
            );
        }
    } else if (posIdDeeplink || promoIdDeeplink) {
        // try to find matching product
        const product = posIdDeeplink
            ? _.find(menu.menu_items, {
                  pos_item_id: parseInt(posIdDeeplink)
              })
            : _.find(menu.menu_items, {
                  promo_id: parseInt(promoIdDeeplink)
              });

        if (product) {
            // get parent category (used for menu link)
            const parentCategory = _.find(
                menu.categories,
                (category: any): boolean => {
                    return _.find(category.menu_items, {
                        menu_item_id: product.base_menu_item_id
                    });
                }
            );

            if (posIdDeeplink) {
                // link to pos product
                dispatch(
                    push({
                        pathname: `${Config.routes.menu}/${menu.store_id}/${
                            parentCategory
                                ? parentCategory.category_id
                                : "category"
                        }/${posIdDeeplink}`,
                        state: { uneditable }
                    })
                );
            } else {
                // link to promo product
                dispatch(
                    push({
                        pathname: `${Config.routes.menu}/${menu.store_id}/${
                            parentCategory
                                ? parentCategory.category_id
                                : "category"
                        }/promo/${promoIdDeeplink}`,
                        state: { uneditable }
                    })
                );
            }
        } else {
            dispatch(
                push({
                    pathname: `${Config.routes.menu}/${menu.store_id}/category/not-found`,
                    state: { uneditable }
                })
            );

            // product could not be found in menu
            dispatch(
                actions.globalModals.openAlertModal({
                    title: "Product could not be found in the menu.",
                    body: (
                        <p>
                            This product is currently unavailable at this time.
                        </p>
                    ),
                    confirmText: "OK",
                    onConfirm: () => {
                        dispatch(actions.globalModals.closeAlertModal());
                    }
                })
            );
        }
    }

    // clear deeplink
    dispatch({
        type: ActionTypes.CLEAR_DEEPLINK
    });
};

// export const setLoginRedirect = (redirect: string): any => ({
//     type: ActionTypes.SET_LOGIN_REDIRECT,
//     redirect
// });

// export const clearLoginRedirect = (): any => ({
//     type: ActionTypes.CLEAR_LOGIN_REDIRECT
// });

export const storeGuestForm = (guestForm: any, isValid: any): any => ({
    type: ActionTypes.STORE_GUEST_FORM,
    guestForm,
    isValid
});

export const setPaymentMethod = (paymentName: string): any => ({
    type: ActionTypes.SET_PAYMENT_METHOD,
    name: paymentName
});
