// @flow

import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "@utils/hooks/withRouter";
import Button from "@components/atoms/Button";

import actions from "@actions";
import Config from "@Config";

import GlobalModals from "../constants/GlobalModals";
// import TextButton from "@components/atoms/TextButton";

import * as GoogleAnalytics from "@utils/GoogleAnalytics";

import FeatureFlags from "../constants/FeatureFlags";

const { Component, Fragment } = React;

type PropsType = {
    mode: "connect" | "register",
    text: string,
    inline: ?string,
    history: any,
    FBLogin: (token: string, tnc: any) => any,
    connectFB: (token: string) => Promise<any>,
    onSuccess: any => void,
    onError: (error: string) => void,
    openGlobalModal: (modal: any) => void,
    isAccountCreationDisabled?: boolean,
    openAlertModal: typeof actions.user.openAlertModal,
    closeAlertModal: typeof actions.user.closeAlertModal
};

type StateType = {
    loading: boolean,
    fbToken: string,
    error: string
};

class FacebookSignIn extends Component<PropsType, StateType> {
    state = {
        loading: false,
        fbToken: "",
        error: ""
    };

    componentWillMount() {
        window.fbAsyncInit = () => {
            window.FB.init({
                appId: Config.facebookAppId,
                cookie: true, // enable cookies to allow the server to access the session
                xfbml: true, // parse social plugins on this page
                version: "v2.9" // use version 2.1
            });
        };

        // Load the SDK asynchronously
        (function(d: any, s: any, id: any) {
            var js,
                fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s);
            js.id = id;
            js.src = "//connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        })(document, "script", "facebook-jssdk");
    }

    checkLoginState = (): Promise<any> => {
        this.setState({ error: "" });
        return new Promise((resolve: any, reject: any) => {
            window.FB.getLoginStatus((response: any) => {
                if (response.status === "connected") {
                    // Logged into your app and Facebook.
                    this.handleLogin(response.authResponse.accessToken);
                } else {
                    if (response.status === "not_authorized") {
                        // The person is logged into Facebook, but not your app.
                    } else {
                        // The person is not logged into Facebook, so we're not sure if
                        // they are logged into this app or not.
                    }
                    reject(response);
                }
            });
        });
    };

    loginWithFB = (reRequestPermissions: boolean = false) => {
        this.setState({ error: "" });
        const options: any = { scope: "public_profile, email" };
        if (reRequestPermissions) {
            options.auth_type = "rerequest";
        }
        if (window.FB) {
            window.FB.login((res: any) => {
                if (res.authResponse) {
                    // logged into Facebook
                    if (this.props.mode === "connect") {
                        this.handleConnect(res.authResponse.accessToken);
                    } else {
                        this.handleLogin(res.authResponse.accessToken);
                    }
                }
            }, options);
        }
    };

    globalHandleSuccess = () => {
        this.props.onSuccess(true);
    };

    globalHandleError = (error: any) => {
        console.error(error);
        const { friendly_message = "" } = error;
        this.props.onError(friendly_message || "Unknown error");
    };

    handleLogin = (token: string, tnc?: any = {}, email?: any) => {
        this.setState({ fbToken: token, loading: true, error: "" });
        this.props
            .FBLogin(token, tnc || null)
            .then((res: any) => {
                // User already exists
                this.setState({ loading: false });
                // Call success handler
                this.globalHandleSuccess();
            })
            .catch((error: any) => {
                this.setState({ loading: false });
                switch (error.code) {
                    // NCR issue
                    case "7017":
                        if (this.props.isAccountCreationDisabled) {
                            this.props.openAlertModal({
                                title: "Signing up is not available right now",
                                body: (
                                    <React.Fragment>
                                        <p>
                                            You can still place a delivery order as a guest. 
                                        </p>
                                    </React.Fragment>
                                ),
                                confirmText: "OK",
                                onConfirm: () => {
                                    this.props.closeAlertModal();
                                }
                            });
                        } else {
                            // Generic error
                            this.globalHandleError(error);
                        }
                        break;
                    case "7010":
                        // User hasn't signed in before
                        if (this.props.isAccountCreationDisabled) {
                            this.props.openAlertModal({
                                title: "Signing up is not available right now",
                                body: (
                                    <React.Fragment>
                                        <p>
                                            You can still place a delivery order as a guest. 
                                        </p>
                                    </React.Fragment>
                                ),
                                confirmText: "OK",
                                onConfirm: () => {
                                    this.props.closeAlertModal();
                                }
                            });
                        } else {
                            // prompt them to agree to T&Cs
                            this.handleTermsConfirmed();
                            // this.props.openGlobalModal({
                            //     modalId: GlobalModals.TNC,
                            //     onSuccess: (): any => this.handleTermsConfirmed()
                            // });
                        }
                        break;
                    case "7011":
                        // Email permission not supplied
                        this.props.openGlobalModal({
                            modalId: GlobalModals.EMAIL,
                            onSuccess: (email): any =>
                                this.handleLogin(token, {
                                    terms_and_conditions: true,
                                    over_14: true,
                                    email
                                })
                        });
                        break;
                    default:
                        // Generic error
                        this.globalHandleError(error);
                        break;
                }
            });
    };

    handleConnect = (token: string) => {
        this.setState({ loading: true, error: "" });
        this.props
            .connectFB(token)
            .then(this.globalHandleSuccess)
            .catch(this.globalHandleError)
            .finally(() => {
                this.setState({ loading: false });
            });
    };

    handleClick = () => {
        GoogleAnalytics.logEvent({
            category: "OnBoarding",
            action: "Click",
            label: "Login_Facebook"
        });
        if (this.props.mode === "connect") {
            this.loginWithFB();
        } else {
            this.checkLoginState().catch(this.loginWithFB);
        }
    };

    handleTermsConfirmed = () => {
        this.handleLogin(this.state.fbToken, {
            terms_and_conditions: true,
            over_14: true
        });
    };

    render(): any {
        return (
            <Fragment>
                <Button
                    onClick={this.handleClick}
                    styleType={["facebook"]}
                    disabled={this.state.loading}
                >
                    <span className="icon icon-facebook-square" />
                    <span className="button-text">
                        {this.state.loading ? "Loading" : this.props.text}
                    </span>
                </Button>
            </Fragment>
        );
    }
}

const mapStateToProps = (state: any): any => ({
    isAccountCreationDisabled:
        state.launchDarkly.flags[FeatureFlags.IS_ACCOUNT_CREATION_DISABLED]
});

export default withRouter(
    connect(
        mapStateToProps,
        {
            FBLogin: actions.user.FBLogin,
            connectFB: actions.user.connectFB,
            openGlobalModal: actions.globalModals.openGlobalModal,
            openAlertModal: actions.globalModals.openAlertModal,
            closeAlertModal: actions.globalModals.closeAlertModal
        }
    )(FacebookSignIn)
);
