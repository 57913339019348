// @flow

import * as React from "react";
import { connect } from "react-redux";
import _ from "lodash";

import ProductLockup from "@components/molecules/ProductLockup";

type PropsType = {
    cms: any
};

const LimitedTimeOnly = (props: PropsType): React.Node => {
    const products = _.filter(_.get(props, "cms.popularProducts", []), {
        delivery: true
    });

    if (products && products.length) {
        return (
            <section className="landing-page-section landing-page-section--popular-products">
                <div className="container-fluid">
                    <h2 className="landing-page-section__title heading-2">
                        Popular Products
                    </h2>
                    <ProductLockup type="standard" items={products} />
                </div>
            </section>
        );
    } else {
        return null;
    }
};

const mapStateToProps = (state: any): any => ({
    cms: state.cms
});

export default connect(mapStateToProps)(LimitedTimeOnly);
