// @flow

import _ from "lodash";
import moment from "moment";
import * as React from "react";
import { connect } from "react-redux";

import Config from "@Config";
import actions from "@actions";
import Classes from "@Classes";
import GlobalModals from "@Modals";
import AnimateHeight from "@components/atoms/AnimateHeight";
import Button from "@components/atoms/Button";
import TextButton from "@components/atoms/TextButton";
import CounterDot from "@components/atoms/CounterDot";

type PropsType = {
    className?: string,
    session: any,
    cart: any,
    openGlobalModal: typeof actions.globalModals.openGlobalModal,
    preCheckout: typeof actions.cart.preCheckout
};

const MobileOrderHeader = (props: PropsType): any => {
    const baseClassname = "mobile-order-header";
    const activeClass = _.get(props, "session.showPersistentCart")
        ? Classes.active
        : "";

    const handleDeliveryCTAClick = () => {
        props.openGlobalModal({ modalId: GlobalModals.ADDRESS_LOCATOR });
    };

    // const handleCheckoutClick = () => {
    //     props.preCheckout();
    // };

    return (
        <AnimateHeight
            duration={500}
            height={!_.get(props, "session.showPersistentCart") ? 0 : null}
            hideOverflow={true}
            className={`${baseClassname} ${activeClass}`}
        >
            <div className={`${baseClassname}__inner`}>
                {!_.get(props, "session.orderMethod") ? (
                    <React.Fragment>
                        <div className="heading-3 center-xs">
                            Start Ordering Now
                        </div>

                        <div className={`${baseClassname}__buttons`}>
                            <Button onClick={handleDeliveryCTAClick}>
                                Get Started
                            </Button>
                            <Button
                                className={`${baseClassname}__cart-status`}
                                styleType="clear"
                                to={Config.routes.cart}
                            >
                                <CounterDot
                                    count={props.cart.line_items.reduce(
                                        (sum: number, item: any): number =>
                                            sum + item.quantity,
                                        0
                                    )}
                                />
                                <span>Cart</span>
                            </Button>
                        </div>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className={`${baseClassname}__details`}>
                            <div className={`${baseClassname}__details__copy`}>
                                <div
                                    className={`${baseClassname}__details__method heading-4`}
                                >
                                    {_.get(props, "session.orderMethod") ===
                                    "pickup"
                                        ? "Pick Up "
                                        : "Deliver "}

                                    {_.get(props, "session.deliveryTime") ===
                                    "asap"
                                        ? "ASAP"
                                        : moment(
                                              _.get(
                                                  props,
                                                  "session.deliveryTime"
                                              )
                                          ).format("dddd, DD/MM/YY, h:mm a")}
                                </div>

                                <div
                                    className={`${baseClassname}__details__location`}
                                >
                                    {_.get(props, "session.orderMethod") ===
                                    "delivery"
                                        ? _.get(
                                              props,
                                              "session.deliveryLocation.full_address"
                                          )
                                        : null}
                                </div>
                            </div>
                            {/* <Tooltip
                                title="Change Order Details"
                                identifier="change-order-details"
                                message={`You can change your order details here at any time`}
                                position="bottom"
                                locked={true}
                            > */}
                            <TextButton onClick={handleDeliveryCTAClick}>
                                Change
                            </TextButton>
                            {/* </Tooltip> */}
                        </div>

                        {/* <div className={`${baseClassname}__buttons`}>
                            <Button
                                className={`${baseClassname}__cart-status`}
                                styleType="clear"
                                to={Config.routes.cart}
                            >
                                <CounterDot
                                    count={props.cart.line_items.reduce(
                                        (sum: number, item: any): number =>
                                            sum + item.quantity,
                                        0
                                    )}
                                />
                                <span>Cart</span>
                            </Button>
                            <Button
                                styleType="green"
                                loading={props.cart.isFetching}
                                disabled={
                                    !props.cart.line_items.length ||
                                    !_.get(
                                        props,
                                        "session.selectedStore.store_id"
                                    )
                                }
                                onClick={handleCheckoutClick}
                            >
                                Check out
                            </Button>
                        </div> */}
                    </React.Fragment>
                )}
            </div>
        </AnimateHeight>
    );
};

const mapStateToProps = (state: any): any => {
    return {
        session: state.session,
        cart: state.cart
    };
};

export default connect(
    mapStateToProps,
    {
        openGlobalModal: actions.globalModals.openGlobalModal,
        preCheckout: actions.cart.preCheckout
    }
)(MobileOrderHeader);
