// @flow

import Configs from "@Config";
import API from "./API";

export default class CardAPI {
    static getCards(userKey: string): Promise<any> {
        return API.get(
            `${Configs.orderApi}/user/me/paymentMethods`,
            null,
            null
        );
    }
    // static setupAddCard(userKey, token, channel) {
    //     return API.put(`${Configs.orderApi}/user/me/setup`, null, {
    //         token,
    //         mobile_channel: channel
    //     });
    // }

    // static setupAddCard(userKey, token, channel) {
    //     return fetch('')
    // }

    static getOrderPaymentSession(orderID: string): Promise<any> {
        return API.post(
            `${Configs.orderApi}/${orderID}/user/me/payment/drop-in/methods`,
            null,
            {
                channel: "web"
            }
        );
    }

    static getRecurringPaymentSession(userKey: string): Promise<any> {
        return API.post(`${Configs.orderApi}/user/me/payment`, null, {
            origin: document.location.origin,
            returnurl: document.location.origin,
            platform: "web"
        });
    }

    static verifyAddCard(userKey: string, payload: string): Promise<any> {
        return API.put(`${Configs.orderApi}/user/me/verify`, null, payload);
    }

    static disableCard(userKey: string, reference: string): Promise<any> {
        return API.put(`${Configs.orderApi}/user/me/disable`, null, reference);
    }

    static getOriginKey(): Promise<any> {
        return API.post(
            `${Configs.orderApi}/payment/drop-in/origin-key`,
            null,
            {
                origin_domain: window.location.origin
            }
        );
    }

    static getApplePaySession(url: string): Promise<any> {
        return API.post(`${Configs.orderApi}/payment/apple/session`, null, {
            validation_url: url,
            host: window.location.host
        });
    }

    static payOrder(orderID: string, user_id: string, data: any, googleRecaptchaResponse: string, skipReCaptcha: boolean): Promise<any> {
        let url = `${Configs.orderApi}/${orderID}/user/${user_id}/payment/drop-in/payments`;
        if (skipReCaptcha) {
            url += `?skipRecaptcha=true`;
        } else {
            url += `?captchaResponse=${googleRecaptchaResponse}`;
        }
        return API.post(
            url,
            null,
            {
                return_url: window.location.href.split("?")[0],
                origin: window.location.origin,
                payment_method: data.paymentMethod,
                browser_info: data.browserInfo,
                allow_3ds2: true,
                redirect_from_method: "GET",
                store_payment_method: data.storePaymentMethod
            }
        );
    }

    static provideDetails(orderID: string, data: any): Promise<any> {
        return API.post(
            `${Configs.orderApi}/${orderID}/user/me/payment/drop-in/payments/details`,
            null,
            data
        );
    }
}
