import _ from "lodash";
import moment from "moment";
import DateService from "@utils/DateService";
import StoreUtils from "@utils/storeUtils";

export const timeRestrictions = (restrictions, orderTime) => {
    if (!_.isEmpty(restrictions)) {
        if (Array.isArray(restrictions)) {
            const date = isNaN(new Date(orderTime).getTime())
                ? StoreUtils.selectedStoreNow()
                : moment(orderTime);
            const day = StoreUtils.getStoreCurrentDayOfWeek();
            const restriction = restrictions.find(
                element => element.dayOfWeek === day
            );
            if (!_.isEmpty(restriction)) {
                const restrictionTimeRange = StoreUtils.BuildRestrictionCategoryTimeRange(
                    restriction
                );
                // console.log(
                //     "orderTime",
                //     orderTime,
                //     date.format(),
                //     restrictionTimeRange.startTime.format(),
                //     restrictionTimeRange.endTime.format()
                // );
                if (
                    restrictionTimeRange &&
                    date.isSameOrAfter(
                        restrictionTimeRange.startTime,
                        "second"
                    ) &&
                    date.isSameOrBefore(restrictionTimeRange.endTime, "second")
                ) {
                    return true;
                }
            }
        }
        return false;
    }
    return true;
};

export const checkCategories = (categories, timestamp) => {
    if (categories && categories.length === 0) {
        return true;
    }
    for (const category of categories) {
        if (timeRestrictions(category.restriction, timestamp)) {
            return true;
        }
    }
    return false;
};

export const findEndingSoonCategory = categories => {
    for (const category of categories) {
        const restrictions = category.restrictions;
        let endTime;
        if (!_.isEmpty(restrictions)) {
            if (Array.isArray(restrictions)) {
                const date = DateService.now();
                const day = date.day();
                const restriction = restrictions.find(
                    element => element.dayOfWeek === day
                );
                if (!_.isEmpty(restriction)) {
                    const startTime = moment(restriction.startTime);
                    startTime.year(date.year());
                    startTime.month(date.month());
                    startTime.date(date.date());
                    endTime = moment(restriction.endTime);
                    endTime.year(date.year());
                    endTime.month(date.month());
                    endTime.date(date.date());
                    if (
                        (startTime.hours() === 0 &&
                            startTime.minutes() === 0 &&
                            startTime.seconds() === 0 &&
                            endTime.hours() === 0 &&
                            endTime.minutes() === 0 &&
                            endTime.seconds() === 0) ||
                        date.isSameOrAfter(endTime, "second") ||
                        date
                            .add(30, "minutes")
                            .isSameOrBefore(endTime, "second")
                    ) {
                        continue;
                    }
                }
            }
            return [category, endTime];
        }
        continue;
    }
    return [null, null];
};

export default timeRestrictions;
