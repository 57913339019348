// @flow

import { type OrderType } from "@SharedTypes";

import * as React from "react";
import { Link } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import Config from "@Config";
import { formatMoney } from "@utils";
import useMobileBreakpoint from "@utils/hooks/useMobileBreakpoint";
import { mapToOrderWithStatusInfo } from "@utils/hooks/useOrderStatusPolling";

type PropsType = {
    order: OrderType,
    isGuestUser: ?boolean
};

const OrderDetails = (props: PropsType): React.Node => {
    const baseClassname = "order-details";
    const isMobile = useMobileBreakpoint();

    const orderStatus = mapToOrderWithStatusInfo(props.order);

    const LinkTag = window.onlineOrderingURL ? "a" : Link;

    const totalNumber = props.order.line_items.reduce(
        (sum: number, item: any): number => sum + item.quantity,
        0
    );

    const orderDetailRoute = props.isGuestUser
        ? `${Config.routes.order}/guest/${props.order.order_token}`
        : `${Config.routes.order}/${props.order.order_id}`;

    return (
        <LinkTag
            to={window.onlineOrderingURL ? null : orderDetailRoute}
            href={
                window.onlineOrderingURL
                    ? `${window.onlineOrderingURL}${orderDetailRoute}`
                    : null
            }
            className={
                !isMobile
                    ? `${baseClassname}__individual-order`
                    : `${baseClassname}__individual-order`
            }
        >
            <div className={`${baseClassname}__tracking-container`}>
                <div className={`${baseClassname}__icon-text-container`}>
                    <div className="active-order__icon">
                        <div className="lds-ring">
                            <div />
                            <div />
                            <div />
                            <div />
                        </div>
                        <div className="burger-icon" />
                    </div>
                    <div
                        className={`${baseClassname}__tracking-text-container`}
                    >
                        <div
                            className={`${baseClassname}__delivery-order-text`}
                        >
                            delivery order
                        </div>
                        <div
                            className={`${baseClassname}__delivery-order-status`}
                        >
                            {orderStatus ? (
                                <TransitionGroup>
                                    <CSSTransition
                                        key={orderStatus.title}
                                        timeout={700}
                                        classNames="text-slide"
                                        mountOnEnter
                                        unmountOnExit
                                    >
                                        <span
                                            key={orderStatus.title}
                                            style={{ display: "block" }}
                                        >
                                            {orderStatus.title}
                                        </span>
                                    </CSSTransition>
                                </TransitionGroup>
                            ) : null}
                        </div>
                        <div
                            className={
                                !isMobile
                                    ? `${baseClassname}__order-items`
                                    : `${baseClassname}__order-items--remove`
                            }
                        >
                            {`${totalNumber} item${totalNumber > 1 ? "s" : ""} / ${formatMoney(props.order.total_amount)}`}
                        </div>
                    </div>
                </div>
                <div
                    className={
                        isMobile
                            ? `${baseClassname}__order-items`
                            : `${baseClassname}__order-items--remove`
                    }
                >
                    {`${totalNumber} item${totalNumber > 1 ? "s" : ""} / ${formatMoney(props.order.total_amount)}`}
                </div>
                <div className="order-details__chevron-right">
                    <i className="icon icon-chevron-right"></i>
                </div>
            </div>
        </LinkTag>
    );
};

export default OrderDetails;
