// @flow

import * as React from "react";

import ProductDefaultImg from "../../../assets/images/product-default.png";

type PropsType = {
    className?: string,
    alt: string,
    src: string
};

const ProductImage = (props: PropsType): any => {
    if (props.src) {
        return (
            <img
                className={`${
                    props.className ? props.className : ""
                } lazy-load`}
                src={props.src}
                alt={props.alt}
                onError={function(e: any) {
                    e.currentTarget.onerror = null;
                    e.currentTarget.src = ProductDefaultImg;
                }}
                onLoad={(e: any) => {
                    e.currentTarget.classList.add("is-loaded");
                }}
            />
        );
    } else {
        return null;
    }
};

export default ProductImage;
