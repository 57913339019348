// @flow

// import _ from "lodash";

import ActionTypes from "@actionTypes";

const initialState = {
    footerLinks: [],
    // limitedTimeProducts: [
    //     {
    //         name: "Whopper®",
    //         image:
    //             "https://s3-ap-southeast-2.amazonaws.com/hungryjack-dev/image/item/1000/1000.png",
    //         banner_image: "",
    //         pos_id: 23235,
    //         promo_id: null,
    //         delivery: true,
    //         pickup: true
    //     },
    //     {
    //         name: "Whopper®",
    //         image:
    //             "https://s3-ap-southeast-2.amazonaws.com/hungryjack-dev/image/item/1000/1000.png",
    //         banner_image: "",
    //         pos_id: 23235,
    //         promo_id: null,
    //         delivery: true,
    //         pickup: true
    //     },
    //     {
    //         name: "Whopper®",
    //         image:
    //             "https://s3-ap-southeast-2.amazonaws.com/hungryjack-dev/image/item/1000/1000.png",
    //         banner_image: "",
    //         pos_id: 23235,
    //         promo_id: null,
    //         delivery: true,
    //         pickup: true
    //     },
    //     {
    //         name: "Whopper®",
    //         image:
    //             "https://s3-ap-southeast-2.amazonaws.com/hungryjack-dev/image/item/1000/1000.png",
    //         banner_image: "",
    //         pos_id: 23235,
    //         promo_id: null,
    //         delivery: true,
    //         pickup: true
    //     },
    //     {
    //         name: "Whopper®",
    //         image:
    //             "https://s3-ap-southeast-2.amazonaws.com/hungryjack-dev/image/item/1000/1000.png",
    //         banner_image: "",
    //         pos_id: 23235,
    //         promo_id: null,
    //         delivery: true,
    //         pickup: true
    //     }
    // ],

    // popularProducts: [
    //     {
    //         name: "Whopper®",
    //         image:
    //             "https://s3-ap-southeast-2.amazonaws.com/hungryjack-dev/image/item/1000/1000.png",
    //         banner_image: "",
    //         pos_id: 23235,
    //         promo_id: null,
    //         delivery: true,
    //         pickup: true
    //     },
    //     {
    //         name: "Whopper®",
    //         image:
    //             "https://s3-ap-southeast-2.amazonaws.com/hungryjack-dev/image/item/1000/1000.png",
    //         banner_image: "",
    //         pos_id: 23235,
    //         promo_id: null,
    //         delivery: true,
    //         pickup: true
    //     },
    //     {
    //         name: "Whopper®",
    //         image:
    //             "https://s3-ap-southeast-2.amazonaws.com/hungryjack-dev/image/item/1000/1000.png",
    //         banner_image: "",
    //         pos_id: 23235,
    //         promo_id: null,
    //         delivery: true,
    //         pickup: true
    //     },
    //     {
    //         name: "Whopper®",
    //         image:
    //             "https://s3-ap-southeast-2.amazonaws.com/hungryjack-dev/image/item/1000/1000.png",
    //         banner_image: "",
    //         pos_id: 23235,
    //         promo_id: null,
    //         delivery: true,
    //         pickup: true
    //     },
    //     {
    //         name: "Whopper®",
    //         image:
    //             "https://s3-ap-southeast-2.amazonaws.com/hungryjack-dev/image/item/1000/1000.png",
    //         banner_image: "",
    //         pos_id: 23235,
    //         promo_id: null,
    //         delivery: true,
    //         pickup: true
    //     }
    // ],
    pageMetaData: [],
    faq: ""
};

export default function cmsReducer(
    state: any = initialState,
    action: any = {}
): any {
    switch (action.type) {
        case ActionTypes.UPDATE_APP_CONFIG:
            return {
                ...state,
                ...action.config
            };
        case ActionTypes.SET_CMS_NAV:
            return {
                ...state,
                nav: action.nav
            };
        case ActionTypes.SET_CMS_FOOTER:
            return {
                ...state,
                footer: action.footer
            };
        case ActionTypes.GET_CMS_CONTENT_REQUEST:
            return {
                ...state,
                isMetaFetching: true
            };
        case ActionTypes.GET_CMS_CONTENT_SUCCESS: {
            return {
                ...state,
                ...action.content,
                // pageMetaData: _.get(action, "content.seo_data") || [],
                isMetaFetching: false,
                faq: action.content.faq_url
            };
        }
        case ActionTypes.GET_CMS_CONTENT_FAILURE:
            return {
                ...state,
                isMetaFetching: false
            };
        case ActionTypes.SET_MENU_SPECIAL_CATEGORIES:
            return {
                ...state,
                limitedTimeProducts: action.categories.limitedTimeProducts
                    .length
                    ? action.categories.limitedTimeProducts
                    : state.limitedTimeProducts,
                popularProducts: action.categories.popularProducts.length
                    ? action.categories.popularProducts
                    : state.popularProducts
            };
        default:
            return state;
    }
}
