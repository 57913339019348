// @flow

import _ from "lodash";
import ActionTypes from "@actionTypes";

const defaultState = window.localStorage.getItem("upsells")
    ? JSON.parse(window.localStorage.getItem("upsells"))
    : [];

const cartReducer = (state: any = defaultState, action: any = {}): any => {
    switch (action.type) {
        case ActionTypes.GET_UPSELL_SUCCESS:
            const getUpsellsFromMenu = (): any => {
                return action.upsells.map((upsell: any): any => {
                    const menuItem = _.find(
                        action.menu.menu_items,
                        (item: any): boolean =>
                            item.menu_item_id === upsell.menu_item_id &&
                            item.sales_item_ids.includes(upsell.sales_item_id)
                    );
                    if (!menuItem) {
                        return null;
                    }
                    return {
                        ...menuItem,
                        sales_item_id: upsell.sales_item_id
                    };
                });
            };

            window.localStorage.setItem(
                "upsells",
                JSON.stringify(getUpsellsFromMenu())
            );

            return getUpsellsFromMenu();
        case ActionTypes.CLEAR_UPSELL:
            return [];
        default:
            return state;
    }
};

export default cartReducer;
