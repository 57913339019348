// @flow

import _ from "lodash";

import { type OrderType } from "../constants/SharedTypes";
import store from "../store";

export const isDeliveryOrder = (order: OrderType): boolean => {
    if (!order) {
        return false;
    }
    const mode = order.order_mode.toLowerCase();
    if (mode === "delivery") {
        return true;
    }
    return false;
};

export const isActiveOrder = (order: OrderType): boolean => {
    return order.frontend_order_type === "active";
};

export const isPastOrder = (order: OrderType): boolean => {
    return order.frontend_order_type === "past";
};

export const getOrderStore = (order: OrderType): any => {
    return store
        .getState()
        .stores.list.find((s: any): boolean => s.store_id === order.store_id);
};

export const getOrderAddress = (order: OrderType): string => {
    if (isDeliveryOrder(order)) {
        const deliveryAddressDetails = _.get(
            order,
            "delivery_details.address",
            {}
        );

        if (deliveryAddressDetails) {
            const {
                address_line = "",
                locality = "",
                state = "",
                postcode = ""
            } = deliveryAddressDetails;

            return `${address_line}, ${locality} ${state} ${postcode}`;
        }
    }
    return `HJ's ${getOrderStore(order) ? getOrderStore(order).name : ""}`;
};

export const canGiveFeedback = (order: OrderType): boolean => {
    if (!order) {
        return false;
    }
    let expired = false;
    if (order.feedback_token_expiry) {
        expired = new Date() - new Date(order.feedback_token_expiry) > 0;
    }
    return !order.feedback_received && !expired;
};

export default {
    isDeliveryOrder,
    isActiveOrder,
    isPastOrder,
    getOrderStore,
    getOrderAddress,
    canGiveFeedback
};
