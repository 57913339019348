import ActionTypes from "@actionTypes";

const initialState = {
    isFetching: false,
    error: null,
    // fallback branch ios app link
    app_branch_deeplink_url:
        "https://apps.apple.com/au/app/hungry-jacks-shake-win/id518981155?_branch_match_id=699514839486048773"
};

const configReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.GET_APP_CONFIG_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case ActionTypes.GET_APP_CONFIG_FAIL:
            return {
                ...state,
                error: action.error,
                isFetching: false
            };
        case ActionTypes.GET_APP_CONFIG_SUCCESS:
            return {
                ...state,
                ...action.config,
                isFetching: false
            };
        default:
            return state;
    }
};

export default configReducer;
