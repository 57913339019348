// @flow

import * as React from "react";
// import moment from "moment";
import { connect } from "react-redux";
import JSBarcode from "jsbarcode";
import _ from "lodash";
import { push } from "@lagunovsky/redux-react-router";

// import Config from "@Config";
import actions from "@actions";
// import ApiFactory from "@apis/APIFactory";
// import Button from "@components/atoms/Button";
// import CompCountdown from "@components/atoms/CompCountdown";
// import TextButton from "@components/atoms/TextButton";
// import ProductImage from "@components/atoms/ProductImage";
// import * as GoogleAnalytics from "@utils/GoogleAnalytics";

import { type VoucherType, type ProfileType } from "@SharedTypes";

import JCLogo from "@assets/images/JCafeLogo.svg"
import JCDrink from "@assets/images/jc-drinks.png"

const { useEffect, useState, useRef } = React;

type PropsType = {
    voucher: VoucherType,
    imageBasePath: string,
    user: ProfileType,
    active: boolean,
    // onAddToCart: () => void,
    onClose: () => void,
    isComp?: boolean,
    compImageBasePath: string,
    compCodeStores: any,
    paymentSupport: any,
    applyCompCode: any,
    addVoucher: any,
    push: any,
    storeId: any,
    openAlertModal: any,
    closeAlertModal: any,
    closeVoucherModal: any,
    coffeeLoyaltyCta?: any
};

const BarcodeOverlay = ({
    // voucher,
    // imageBasePath,
    // compImageBasePath,
    user,
    active,
    // isComp,
    // onAddToCart = () => {},
    onClose = () => {},
    // compCodeStores,
    // paymentSupport,
    // applyCompCode,
    // addVoucher,
    // push,
    // storeId,
    // openAlertModal,
    // closeAlertModal,
    // closeVoucherModal,
    // coffeeLoyaltyCta
}: PropsType): React.Node => {
    const barcodeRef = useRef(null);
    const [showBarcode, setShowBarcode] = useState(false);

    // Render the barcode
    useEffect(() => {
        if (barcodeRef.current) {
            try {
                JSBarcode(barcodeRef.current, user.loyalty_card_no, {
                    width: 2,
                    height: 110,
                    font: "Roboto",
                    fontSize: 16,
                    margin: 0,
                    marginTop: 16,
                    marginBottom: 16,
                    textMargin: 10
                });
            } catch (error) {
                console.log("JSBarcode Error: ", error);
            }
        }
    }, [barcodeRef.current, user.loyalty_card_no]);

    // Auto close the barcode when the component is not active
    useEffect(() => {
        if (!active) {
            setShowBarcode(false);
        }
    }, [active]);

    // if (!voucher) {
    //     return null;
    // }

    return (
        <div className="barcode-overlay">
            <div className="barcode-overlay__content">
                <div className="barcode-overlay__content__logo">
                    <img
                        src={JCLogo}
                        alt={"Jack's Cafe logo"}
                    />
                </div>
                <p className="barcode-overlay__content__caption">
                    Jack's Cafe Loyalty
                </p>
                <h1 className="barcode-overlay__content__heading">
                    <span className="barcode-overlay__content__heading__name">
                        {user.first_name}'s
                    </span>
                    <br />
                    Reward card
                </h1>
                <div className="barcode-overlay__content__img">
                    <img
                        src={JCDrink}
                        alt={"Jack's Cafe drinks"}
                    />
                </div>
                <div
                    className={`barcode-overlay__content__barcode ${
                        showBarcode ? "is-active" : ""
                    }`}
                >
                    <p className="barcode-overlay__content__barcode__heading">
                        Scan barcode at cashier
                    </p>
                    <svg id="barcode" ref={barcodeRef} className={"barcode-overlay__content__barcode__img"}/>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state: any, ownProps: any): any => {
    return {
        user: state.user,
        imageBasePath: state.vouchers.image_base_path,
        compImageBasePath: state.vouchers.comps_image_base_path,
        compCodeStores: _.isArray(
            _.get(ownProps, "voucher.stores_ids_constraints")
        )
            ? _.compact(
                  ownProps.voucher.stores_ids_constraints.map((id: any): any =>
                      _.find(state.stores.list, { store_id: id })
                  )
              )
            : null,
        paymentSupport: state.device.paymentSupport,
        storeId: _.get(state, "session.selectedStore.store_id", 0)
    };
};

export default connect(
    mapStateToProps,
    {
        applyCompCode: actions.cart.applyCompCode,
        addVoucher: actions.vouchers.voucherDeeplink,
        openAlertModal: actions.globalModals.openAlertModal,
        closeAlertModal: actions.globalModals.closeAlertModal,
        closeBarcodeOverlay: actions.globalModals.closeBarcodeOverlay,
        push: push
    }
)(BarcodeOverlay);
