import ActionTypes from "@actionTypes";

const initialState = {};

export default function deviceReducer(state = initialState, action = {}) {
    switch (action.type) {
        case ActionTypes.GET_STORES_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case ActionTypes.GET_STORES_SUCCESS: {
            return {
                ...state,
                list: action.stores,
                isFetching: false
            };
        }

        case ActionTypes.GET_STORES_FAILURE:
            return {
                ...state,
                isFetching: false
            };
        default:
            return state;
    }
}
