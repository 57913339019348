const html = document.querySelector("html");
const body = document.querySelector("body");

export const enableBodyScroll = () => {
    if (html && body) {
        body.classList.remove("prevent-scrolling");
    }
};

export const disableBodyScroll = () => {
    if (html && body) {
        body.classList.add("prevent-scrolling");
    }
};
