import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Routes, Navigate } from "react-router-dom";
import { withRouter } from "@utils/hooks/withRouter";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Helmet } from "react-helmet";
import queryString from "query-string";

import Config from "@Config";
import actions from "@actions";
import store from "@Store";
import { ReactForm, ReactFormField } from "@adrenalin/react-form";

// Route containers
import GlobalModals from "@containers/GlobalModals";
import PageNotFound from "@containers/PageNotFound";
import Landing from "@containers/Landing";
import Menu from "@containers/Menu";
import Cart from "@containers/Cart";
import Checkout from "@containers/Checkout";
import Account from "@containers/Account";
import DeeplinkHandler from "@containers/DeeplinkHandler";
import Login from "@containers/Login";
import Logout from "@containers/Logout";
import ForgotPassword from "@containers/ForgotPassword";
import ResetPassword from "@containers/ResetPassword";
import Register from "@containers/Register";
import EmailVerification from "@containers/EmailVerification";
import VerifyUserEmail from "@containers/EmailVerification/VerifyUserEmail";
import VerifyEmailPrompt from "@containers/EmailVerification/VerifyEmailPrompt";
import Order from "@containers/Order";
import Feedback from "@containers/Feedback";

// Components
import NavPrimary from "@components/organisms/NavPrimary";
import MobileOrderHeader from "@components/molecules/MobileOrderHeader";
import ActiveOrder from "@components/molecules/ActiveOrder";
import PersistentCart from "@components/organisms/PersistentCart";
import LoaderCover from "@components/atoms/LoaderCover";
import FixedFooters from "@components/organisms/FixedFooters";
import CategoryList from "@components/organisms/CategoryList";
import ProductDetail from "@components/organisms/ProductDetail";

// Utils
import { initAppboy, appboyUpdateUser } from "@utils/AppBoy";

// Types
type PropsType = {
    loaded: boolean,
    session: any,
    navigator: any,
    fixedFooterHeight: number,
    initLaunchDarkly: () => Promise<any>,
    initialFetchAll: () => Promise<any>,
    getGeoStatus: () => Promise<any>,
    getVouchers: () => Promise<any>,
    getCMSContent: () => Promise<any>,
    getSavedAddresses: () => Promise<any>,
    productDeeplink: (
        posIdDeeplink: ?string,
        promoIdDeeplink: ?string,
        uneditable: ?boolean
    ) => void,
    expireUserToken: typeof actions.user.expireUserToken,
    cms: any
};

type StateType = {
    loaded: boolean,
    posIdDeeplink: ?string,
    promoIdDeeplink: ?string,
    fixedFooterHeight: number
};

class App extends Component<PropsType, StateType> {
    state = {
        loaded: false,
        posIdDeeplink: null,
        promoIdDeeplink: null,
        fixedFooterHeight: 0
    };

    componentDidMount() {
        // fetch all required initial apis and requests
        this.props.initLaunchDarkly().then(() => {
            this.props.initialFetchAll(this.props.router);
        });

        // attempt to get and set geolocation status on launch
        // removed as per AOOT-1936
        // this.props.getGeoStatus().catch(console.error);

        // kick off fixed footer padding setting loop
        this.setFixedFooterHeight();
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        const { user } = store.getState();

        if (!prevState.loaded && this.state.loaded) {
            // first load

            // hide preloader
            this.hidePreloader();

            // check product deeplink
            this.checkProductDeeplink();
        }

        if (this.state.loaded) {
            // already loaded

            // product deeplink check
            if (
                prevState.posIdDeeplink !== this.state.posIdDeeplink ||
                prevState.promoIdDeeplink !== this.state.promoIdDeeplink
            ) {
                this.checkProductDeeplink();
            }
            
            if (user.appboy_user_id && !user.is_guest_user) {
                initAppboy(window.braze_key, window.braze_sdk_base_url);
                appboyUpdateUser(user);
            }
        }
    }

    static getDerivedStateFromProps(nextProps: any, prevState: any): any {
        const newUrlSegments = nextProps.navigator.location.pathname.split("/");

        // check if new route should show persistent cart
        return {
            loaded: nextProps.loaded,
            posIdDeeplink:
                newUrlSegments[1] === "product" &&
                newUrlSegments[2] &&
                newUrlSegments[2] !== "promo"
                    ? newUrlSegments[2]
                    : null,
            promoIdDeeplink:
                newUrlSegments[1] === "product" && newUrlSegments[2] === "promo"
                    ? newUrlSegments[3]
                    : null
        };
    }

    checkProductDeeplink() {
        if (this.state.posIdDeeplink || this.state.promoIdDeeplink) {
            this.props.productDeeplink(
                this.state.posIdDeeplink,
                this.state.promoIdDeeplink,
                window.location.search.includes("uneditable=true")
            );
        }
    }

    hidePreloader(nextProps: any) {
        const siteLoader = document.getElementById("site-loader");
        if (siteLoader) {
            siteLoader.addEventListener("transitionend", () => {
                siteLoader.remove();
            });
            siteLoader.classList.remove("is-active");
        }
    }

    setFixedFooterHeight() {
        requestAnimationFrame(() => {
            this.setFixedFooterHeight();
        });
        const fixedFooterContainer = document.querySelector(
            ".fixed-footer-container"
        );
        if (
            fixedFooterContainer &&
            fixedFooterContainer.clientHeight !== this.state.fixedFooterHeight
        ) {
            this.setState({
                fixedFooterHeight: fixedFooterContainer.clientHeight
            });
        }
    }

    render(): any {
        const baseRouteKey =
            this.props.navigator.location.pathname.split("/")[1] || "/";

        const appLoaded = _.get(this, "props.loaded");

        const recaptchaPosition = this.state.fixedFooterHeight && `
            .grecaptcha-badge {
                bottom: calc(14px + ${this.state.fixedFooterHeight}px) !important;
            }
        `

        return (
            <div
                className="app-wrapper"
                style={this.state.fixedFooterHeight ? {
                    // paddingBottom: this.state.fixedFooterHeight
                    height: `calc(100dvh - ${this.state.fixedFooterHeight}px)`,
                } : {
                    height: `calc(100dvh)`
                }}
            >
                {this.state.fixedFooterHeight ?
                    <style>{recaptchaPosition}</style> : <></>
                }
                {/*
                    Default Helment head config,
                    will be overridden by sub component
                    MappedMetaTags and Helment components
                */}
                <Helmet
                    defaultTitle={Config.strings.documentTitle}
                    titleTemplate={`%s - ${Config.strings.documentTitle}`}
                >
                    <meta
                        name="”description”"
                        content={Config.strings.documentDescription}
                    />
                    <meta
                        itemprop="description"
                        content={Config.strings.documentDescription}
                    />
                    <meta
                        property="og:description"
                        content={Config.strings.documentDescription}
                    />
                    <meta
                        itemprop="image"
                        content={window.location.origin + Config.shareImageUrl}
                    />
                    <meta
                        property="og:image"
                        content={window.location.origin + Config.shareImageUrl}
                    />
                    <meta property="og:url" content={window.location.href} />
                </Helmet>

                {/* App loaded, main switch and app wrapper */}
                {appLoaded ? (
                    <React.Fragment>
                        <LoaderCover
                            fixed={true}
                            active={this.props.session.loading}
                        />

                        {/* Global Modals */}
                        <GlobalModals />

                        <div
                            className={`
                        main-viewport
                        ${
                            _.get(this, "props.session.showPersistentCart")
                                ? "persistent-cart-active"
                                : ""
                        }
                        `}
                        >
                            {/* Navigation */}
                            {!_.get(this, "props.device.webview") ? (
                                <NavPrimary />
                            ) : null}

                            {/* Mobile Order Header */}
                            <MobileOrderHeader />

                            {/* Base Router Switch */}
                            <TransitionGroup component={null}>
                                <CSSTransition
                                    key={baseRouteKey }
                                    timeout={Config.pageTransitionDuration}
                                    classNames="page-transition"
                                    unmountOnExit
                                >
                                    <main>
                                        <Routes
                                            location={this.props.router.location}
                                        >
                                            {/* Product Deeplink */}
                                            <Route
                                                key={`${Config.routes.productDeeplink}`}
                                                path={`${Config.routes.productDeeplink}/:posID?/:promoID?`}
                                                element={
                                                    <LoaderCover
                                                        absolute={true}
                                                        active={true}
                                                    />
                                                }
                                            />
                                            {/* Landing */}
                                            <Route
                                                key={"/"}
                                                path="/"
                                                element={<Landing/>}
                                            />
                                            {/* Deeplinks */}
                                            {Config.routes.deeplinks.map(deeplink => (
                                                <Route
                                                    key={deeplink}
                                                    path={deeplink}
                                                    element={<DeeplinkHandler/>}
                                                />
                                            ))}
                                            {/* Menu */}
                                            <Route
                                                key={Config.routes.menu}
                                                path={Config.routes.menu}
                                                element={<Menu/>}
                                            >
                                                {/* Category List */}
                                                <Route
                                                    key={":storeID?/:category?"}
                                                    path={`:storeID?/:category?`}
                                                    element={<CategoryList />}
                                                />
                                                {/* Product Detail */}
                                                <Route
                                                    key={`:storeID?/:category?/:product?/:promoID?`}
                                                    path={`:storeID?/:category?/:product?/:promoID?`}
                                                    element={<ProductDetail />}
                                                />
                                            </Route>
                                            {/* Cart */}
                                            <Route
                                                key={Config.routes.cart}
                                                path={Config.routes.cart}
                                                element={<Cart/>}
                                            />
                                            {/* Checkout */}
                                            <Route
                                                key={Config.routes.checkout}
                                                path={Config.routes.checkout}
                                                element={<Checkout/>}
                                            />
                                            {/* Account */}
                                            <Route
                                                element={
                                                    <PrivateRoute location={this.props.router.location}>
                                                        <Account />
                                                    </PrivateRoute>
                                                }
                                                key={`${Config.routes.account}/*`}
                                                path={`${Config.routes.account}/*`}
                                            />
                                            {/* Login */}
                                            <Route
                                                key={Config.routes.login}
                                                path={Config.routes.login}
                                                element={<Login/>}
                                            />
                                            {/* Logout */}
                                            <Route
                                                key={Config.routes.logout}
                                                path={Config.routes.logout}
                                                element={<Logout/>}
                                            />
                                            {/* Forgot Password */}
                                            <Route
                                                key={
                                                    Config.routes.forgotPassword
                                                }
                                                path={
                                                    Config.routes.forgotPassword
                                                }
                                                element={
                                                    <PublicRoute navigator={this.props.navigator}>
                                                        <ForgotPassword/>
                                                    </PublicRoute>
                                                }
                                            />
                                            {/* Reset Password */}
                                            <Route
                                                key={`${Config.routes.resetPassword}/:userKey?/:token?`}
                                                path={`${Config.routes.resetPassword}/:userKey?/:token?`}
                                                element={<ResetPassword/>}
                                            />
                                            {/* Register */}
                                            <Route
                                                element={
                                                    <PublicRoute navigator={this.props.navigator}>
                                                        <Register />
                                                    </PublicRoute>
                                                }
                                                key={Config.routes.register}
                                                path={Config.routes.register}
                                            />
                                            {/* Email Verification */}
                                            <Route
                                                key={Config.routes.emailVerification}
                                                path={Config.routes.emailVerification}
                                                element={<EmailVerification/>}
                                            >
                                                <Route
                                                    key={`:userKey/:token`}
                                                    path={`:userKey/:token`}
                                                    element={
                                                        <VerifyUserEmail />
                                                    }
                                                />
                                                <Route
                                                    index
                                                    key={`*`}
                                                    path={`*`}
                                                    element={
                                                        <VerifyEmailPrompt />
                                                    }
                                                />
                                            </Route>
                                            {/* Logged in Orders */}
                                            <Route
                                                element={
                                                    <PrivateRoute location={this.props.router.location}>
                                                        <Order />
                                                    </PrivateRoute>}
                                                key={`${Config.routes.order}/:orderId`}
                                                path={`${Config.routes.order}/:orderId`}
                                            />
                                            {/* Guest Orders */}
                                            <Route
                                                key={`${Config.routes.order}/guest/:orderToken`}
                                                path={`${Config.routes.order}/guest/:orderToken`}
                                                element={<Order/>}
                                            />
                                            <Route
                                                key={
                                                    `${Config.routes.orderFeedback}/:token?/:prefilledRating?`
                                                }
                                                path={
                                                    `${Config.routes.orderFeedback}/:token?/:prefilledRating?`
                                                }
                                                element={<Feedback/>}
                                            />
                                            {/* 404 */}
                                            <Route
                                                element={
                                                    <PageNotFound
                                                        title="Sorry, we can't find that page"
                                                        links={[
                                                            {
                                                                title:
                                                                    "Ordering homepage",
                                                                href: "/"
                                                            },
                                                            {
                                                                title: "FAQs",
                                                                href: this.props
                                                                    .cms.faq,
                                                                target: "_blank"
                                                            },
                                                            {
                                                                title:
                                                                    "Contact Us",
                                                                href:
                                                                    this.props
                                                                        .cms
                                                                        .contact_us_url ||
                                                                    window.hj_env ===
                                                                        "prod"
                                                                        ? "https://www.hungryjacks.com.au/contact-us"
                                                                        : "https://uat-www.hungryjacks.com.au/contact-us",
                                                                target: "_blank"
                                                            }
                                                        ]}
                                                    />
                                                }
                                            />
                                        </Routes>
                                    </main>
                                </CSSTransition>
                            </TransitionGroup>
                        </div>

                        {/* Side Persistent Cart */}
                        <PersistentCart />

                        {/* Fixed app footer portal and route switch */}
                        <FixedFooters />

                        {/* ActiveOrder Widget */}
                        <ActiveOrder />
                    </React.Fragment>
                ) : null}

                {/* Debug Pane */}
                {queryString.parse(window.location.search)["debug"] ? (
                    <ul className="debug-pane">
                        <li>
                            <ReactForm>
                                <ReactFormField
                                    type="checkbox"
                                    label="Keep session expired"
                                    name="expire-session"
                                    onChange={(e: any): any => {
                                        window.disableAuthSession =
                                            e.currentTarget.checked;
                                    }}
                                />
                            </ReactForm>
                        </li>
                        <li>
                            <button
                                type="button"
                                onClick={(): any =>
                                    this.props.expireUserToken()
                                }
                            >
                                Expire Session
                            </button>
                        </li>
                    </ul>
                ) : null}
            </div>
        );
    }
}

// Routes that require an authenticated user session (Account, etc)
const PrivateRoute = ({ children, ...props }: any): any => {
    if (!store.getState().session.isAuthenticated) {
        return (
            <Navigate
                replace
                to={{
                    pathname: Config.routes.login,
                    state: { from: props.location.pathname }
                }}
            />
        );
    }
    if (!_.get(store.getState(), "user.email")) {
        return (
            <Navigate
                replace
                to={{
                    pathname: Config.routes.emailVerification,
                    state: { from: props.location.pathname }
                }}
            />
        );
    }
    return children
}

// Public route redirects for logged in users
const PublicRoute = ({ children, ...props }: any): any => {
    if (!store.getState().session.isAuthenticated) {
        return children
    } else {
        return <Navigate
            replace
            to={{
                pathname: Config.routes.account,
                state: { from: props.navigator }
            }}
        />
    }
}

const mapStateToProps = (state: any): any => {
    return {
        loaded: state.initialFetchAll.loaded,
        session: state.session,
        device: state.device,
        navigator: state.navigator,
        page: state.page,
        cms: state.cms,
    };
};

const mapDispatchToProps = {
    initLaunchDarkly: actions.launchDarkly.init,
    initialFetchAll: actions.initialFetchAll,
    getGeoStatus: actions.device.getGeoStatus,
    productDeeplink: actions.session.handleProductDeeplink,
    expireUserToken: actions.user.expireUserToken
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(App)
);
