// @flow

import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import Config from "@Config";
import actions from "@actions";
import { formatMoney } from "@utils";
import GlobalModals from "@Modals";
import AppliedModifiers from "@components/atoms/AppliedModifiers";
import Icon from "@components/atoms/Icon";
import FormatSymbols from "@components/atoms/FormatSymbols";
import * as GoogleAnalytics from "@utils/GoogleAnalytics";

type PropsType = {
    lineItem: any, // Main line item
    editable: boolean, // Shows the delete button
    isCartItem: boolean, // Determined whether or not to link to edit page
    small: boolean,
    summary?: boolean,
    // The following props are passed in through redux connect()
    menu: any,
    openAlertModal: typeof actions.globalModals.openAlertModal,
    removeItemFromCart: typeof actions.cart.removeItemFromCart,
    closeAlertModal: typeof actions.globalModals.closeAlertModal,
    openGlobalModal: typeof actions.globalModals.openGlobalModal
};

const defaultProps = {
    editable: false,
    isCartItem: false,
    small: false
};

const LineItem = (props: PropsType): any => {
    const baseClassname = "line-item";

    const { lineItem } = props;

    const handleCartItemWithNoLinkClick = () => {
        if (props.menu.store_id) {
            // store exists so item must unavailabe in menu
            props.openAlertModal({
                title: "This item is currently unavailable",
                body: (
                    <p>
                        To remove this item from the cart click "Remove item"
                        below.
                    </p>
                ),
                cancelText: "Cancel",
                confirmText: "Remove Item",
                onConfirm: () => {
                    props.removeItemFromCart(lineItem.uuid);
                    props.closeAlertModal();
                },
                onCancel: props.closeAlertModal
            });
        } else {
            // no store set so forward to address locator
            props.openGlobalModal({
                modalId: GlobalModals.ADDRESS_LOCATOR
            });
        }
    };

    // Generate the classlist for this component
    const classes = [
        baseClassname,
        !lineItem.is_available ? `${baseClassname}--has-error` : null,
        props.small ? `${baseClassname}--small` : `${baseClassname}--regular`,
        props.isCartItem ? `${baseClassname}--cart-item` : null
    ];

    // Determines the root component of either Link or div
    // We use div if there is no .menuLink to prevent the need
    // For a .preventDefault() call to stop the href link
    const RootComponentTag =
        props.isCartItem && lineItem.menuLink ? Link : "div";

    // Generate an edit link for this line item
    const editLink =
        props.isCartItem && lineItem.menuLink
            ? `${lineItem.menuLink}?${Config.routes.editCartNumberQueryParam}=${lineItem.uuid}`
            : null;

    // If this is a cartItem add either the to="" or onClick="" params
    // based on the existence of an editLink
    // We do it like this so that we don't pass theses additional properties
    // to the line item if it is not a cart item
    const cartItemProperties = {};
    if (props.isCartItem) {
        if (editLink) {
            cartItemProperties.to = editLink;
            cartItemProperties.onClick = () => {
                GoogleAnalytics.logEvent({
                    category: "Menu_CartOverlay",
                    action: "Change",
                    label: "EditCart"
                });
            };
        } else {
            cartItemProperties.onClick = (event: any) => {
                event.stopPropagation();
                handleCartItemWithNoLinkClick();
            };
        }
    }

    return (
        <RootComponentTag className={classes.join(" ")} {...cartItemProperties}>
            {!lineItem.is_available ? (
                <div className={`${baseClassname}__error icon-text`}>
                    <button
                        className={`${baseClassname}__remove__unavailable__btn`}
                        type="button"
                        onClick={(event: any): any => {
                            event.preventDefault();
                            event.stopPropagation();
                            props.removeItemFromCart(lineItem.uuid);
                        }}
                    >
                        <Icon
                            name="minus"
                            circle={true}
                            // className={`${baseClassname}__error__icon`}
                        />
                    </button>
                    <div>This item is currently unavailable</div>
                </div>
            ) : null}

            <div className={`${baseClassname}__inner`}>
                <div className={`${baseClassname}__quantity`}>
                    {lineItem.quantity}
                </div>
                <div className={`${baseClassname}__details`}>
                    <div className={`${baseClassname}__name`}>
                        <FormatSymbols>{lineItem.menu_item_name}</FormatSymbols>{" "}
                        {props.summary ? (
                            <span className={`${baseClassname}__name__kj`}>
                                {" "}
                                {lineItem.energy_kj}kJ
                            </span>
                        ) : null}
                    </div>

                    {!props.summary
                        ? lineItem.sales_items.map(
                              (salesItem: any, index: number): any =>
                                  salesItem.name ||
                                  salesItem.sales_item_name ? (
                                      <div
                                          key={index}
                                          className={`${baseClassname}__sales-item`}
                                      >
                                          <FormatSymbols>
                                              {salesItem.name ||
                                                  salesItem.sales_item_name}
                                          </FormatSymbols>
                                          <AppliedModifiers
                                              salesItem={salesItem}
                                          />
                                      </div>
                                  ) : null
                          )
                        : null}
                </div>
                <div className={`${baseClassname}__totals`}>
                    <div className={`${baseClassname}__price`}>
                        {lineItem.hasPriceDifference ? (
                            <Icon
                                name="alert"
                                className={`${baseClassname}__price__warning`}
                            />
                        ) : null}
                        {formatMoney(lineItem.price)}
                    </div>

                    {!props.summary ? (
                        <div className={`${baseClassname}__kj`}>
                            {lineItem.energy_kj}kJ
                        </div>
                    ) : null}
                </div>
                {props.editable ? (
                    <div className={`${baseClassname}__remove`}>
                        <button
                            className={`${baseClassname}__remove__btn`}
                            type="button"
                            onClick={(event: any): any => {
                                event.preventDefault();
                                event.stopPropagation();
                                GoogleAnalytics.logEvent({
                                    category: "Menu_CartOverlay",
                                    action: "Delete",
                                    label: "Remove"
                                });
                                props.removeItemFromCart(lineItem.uuid);
                            }}
                        >
                            <Icon name="close" />
                        </button>
                    </div>
                ) : null}
            </div>
            {lineItem.voucher_id || lineItem.voucher_amount > 0 ? (
                <div
                    className={`${baseClassname}__voucher ${
                        props.editable ? "editable" : ""
                    }`}
                >
                    <div className={`${baseClassname}__voucher__name`}>
                        <Icon name="voucher" />
                        {lineItem.voucher_amount > 0
                            ? "Reward applied"
                            : "Voucher will be applied during checkout"}
                    </div>
                    {/* Line item voucher discount price here */}
                    {lineItem.voucher_amount > 0 && (
                        <div className={`${baseClassname}__voucher__price`}>
                            -${lineItem.voucher_amount.toFixed(2)}
                        </div>
                    )}
                </div>
            ) : null}
        </RootComponentTag>
    );
};

LineItem.defaultProps = defaultProps;

const mapStateToProps = (state: any): any => {
    return {
        cart: state.cart,
        menu: state.menu
    };
};

export default connect(
    mapStateToProps,
    {
        openGlobalModal: actions.globalModals.openGlobalModal,
        openAlertModal: actions.globalModals.openAlertModal,
        removeItemFromCart: actions.cart.removeItemFromCart,
        closeAlertModal: actions.globalModals.closeAlertModal
    }
)(LineItem);
