// @flow

import * as React from "react";
import { Link } from "react-router-dom";

type PropsType = {
    children: any,
    className?: string,
    color?: string,
    type?: string,
    to?: string,
    counter?: number,
    disabled?: boolean
};

const RoundedButton = (props: PropsType): any => {
    const classList = `rounded-button 
        ${props.color ? `rounded-button--${props.color}` : ""}
        ${props.disabled ? `rounded-button--disabled` : ""} 
        ${props.className ? props.className : ""}`;

    const counter = props.counter ? (
        <div className="rounded-button__counter">{props.counter}</div>
    ) : null;

    if (props.to) {
        return (
            <Link {...props} className={`${classList}`}>
                {props.children}
                {counter}
            </Link>
        );
    } else {
        return (
            <button
                {...props}
                className={`${classList}`}
                type={props.type ? props.type : "button"}
                onMouseDown={(e: any) => {
                    e.preventDefault();
                }}
            >
                {props.children}
                {counter}
            </button>
        );
    }
};

export default RoundedButton;
