// @flow

import _ from "lodash";
import * as React from "react";

type ButtonType = {
    children?: any,
    className?: string,
    salesItem: any
};

const AppliedModifiers = (props: ButtonType): any => {
    // filter any hidden modifiers
    const modifierItems = _.filter(
        props.salesItem.modifiers,
        (modifier: any): any => {
            return !modifier.isHidden && modifier.pos_item_id !== 5169;
        }
    );

    if (modifierItems && modifierItems.length) {
        return (
            <div>
                (
                {modifierItems.map(
                    (salesItemModifier: any, index: number): any => {
                        return (
                            <React.Fragment key={index}>
                                <span>
                                    {`${
                                        salesItemModifier.action === 2
                                            ? "No "
                                            : `+ ${salesItemModifier.quantity}`
                                    }  ${salesItemModifier.modifier_name}`}
                                </span>
                                {index + 1 !== props.salesItem.modifiers.length
                                    ? ", "
                                    : null}
                            </React.Fragment>
                        );
                    }
                )}
                )
            </div>
        );
    } else {
        return null;
    }
};

export default AppliedModifiers;
